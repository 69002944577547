import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAuthUser} from 'react-auth-kit'
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { showToast } from '../../../slices/toast-slice';


function RiaCompleted() {


    const navigate = useNavigate();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const location = useLocation();
    const propsData = location.state
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    const onPrintReceipt = () => {
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/ria/generateReceipt",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
            data : {
                reference_no: propsData.PIN,
                agent_location_code: globalAuthUser.location.code,
            },
            responseType: 'blob'
        })
        .then((res) => {
            dispatch(hideLoading());
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch((error) => {
            dispatch(hideLoading());
            dispatch(
                showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
            );
        });
    }

    const onSendAnother = () => {
        navigate("/sendTransaction");
    }
 
    return (
        <>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header" style={{ fontWeight: "600" }}>
                    { t("SEND_MONEY_COMPLETED") }
                    </div>
                    <div className="card-body">
                        <div style={{ textAlign: "center" }}>
                            <p><i className='bx bxs-badge-check' style={{ fontSize: "60px", color: "green", paddingTop: "20px" }}></i></p>
                            <p style={{ color: "green"}}>{ t("TRANSACTION_SAVED_SUCCESSFULLY") }</p>
                            <p style={{ color: "#333"}}> <span>{ t("YOUR_SEND_MONEY_PIN_IS") } : </span> <span style={{ fontWeight: "bold"}}>{propsData?.PIN}</span> </p>
                            {/* <p style={{ color: "#333"}}> <span>Receiver First Name: </span> <span style={{ fontWeight: "bold"}}>{propsData?.receiverFirstName}</span> </p> */}
                            {/* <p style={{ color: "#333"}}> <span>Receiver Last Name: </span> <span style={{ fontWeight: "bold"}}>{propsData?.receiverLastName}</span> </p> */}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <button type='button' className="btn btn-primary mt-3" onClick={onPrintReceipt}>{t("PRINT_RECEIPT")}</button> &nbsp;&nbsp;
                            <button type='button' className="btn btn-secondary mt-3" onClick={onSendAnother}>{t("SEND_ANOTHER")}</button> 
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default RiaCompleted