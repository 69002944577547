import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import { showToast } from "../../slices/toast-slice";
import { formatAmount } from "../../utils/helper";

const EcobankXpressDetails = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const [details, setDetails] = useState([]);
    useEffect(() => {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        }; 
        const data = {
            id: id
        };
        dispatch(showLoading());
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getXpressCashinDetails', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log("------------------");
            console.log(response);
            if (response.data.status === 1) {
                setDetails(response.data.data.details)
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        })
        .catch(error => {
            console.error(error);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }, []);

    
    return <>
        <div className="row">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-label">{t("MANUAL_CASHIN_DETAILS")}</h5>
                    <span>
                        <button type='button' className='btn btn-primary' onClick={() => navigate('/xpress-cashin')}>
                            <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                            {t('BACK')}
                        </button>
                    </span>
                </div>
                <div className="card-body row">
                    <div className="col-md-6"> 
                        <table className="table table-sm table-borderless w-100">
                            <tbody>
                                <tr>
                                    <th style={{ width: '40%' }}>{t("LBL_VALIDATION_INFO")}:</th>
                                    <th style={{ width: '60%' }}></th>
                                </tr>
                                <tr>
                                    <td>{t("AMOUNT")}:</td>
                                    {details?.amount && (
                                        <td>
                                            {details?.amount !== null ? formatAmount(details?.amount) : "-"} {details?.currency}
                                        </td>
                                    )}
                                    </tr>
                                <tr>
                                    <td>{t("BALANCE")}:</td>
                                    {details?.amount && (
                                        <td>{details?.agent_location?.balance !== null ? formatAmount(details?.agent_location?.balance) : "-"} {details?.currency}</td>
                                    )}
                                </tr>
                                
                                <tr>
                                    <td>{t("CODE")}:</td>
                                    <td>{details?.reference_no}</td>
                                </tr>
                                <tr>
                                    <td>{t("BENEFICIARY_ACCOUNT")}:</td>
                                    <td>{details?.beneficiary_account}</td>
                                </tr>
                                <tr>
                                    <td>{t("SENDER_PHONE_NUMBER")}:</td>
                                    <td>{details?.sender_phone_number}</td>
                                </tr>
                                <tr>
                                    <td>{t("SENDER_NAME")}:</td>
                                    <td>{details?.sender_name}</td>
                                </tr>
                                <tr>
                                    <td>{t("USER")}:</td>
                                    <td>{details?.agent_location_user?.first_name} {details?.agent_location_user?.last_name}</td>
                                </tr>
                                <tr>
                                    <td>{t("DESCRIPTION")}:</td>
                                    <td>{details?.description}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table className="table table-sm table-borderless w-100">
                            <tbody>
                                <tr>
                                    <th style={{ width: '40%' }}>{t("STATUS")}:</th>
                                    <th style={{ width: '60%' }}></th>
                                </tr>
                                
                                <tr>
                                    <td>{t("STATUS")}:</td>
                                    <td>{details?.status === "new" ? "In Progress" : details?.status}</td>
                                </tr>
                                
                                <tr>
                                    <td>{t("PAID_AT")}:</td>
                                    <td>{(details?.status === "rejected" || details?.status === "completed") ? details?.updated_at : "-"}</td>
                                </tr>
                                <tr>
                                    <td>{t("NOTES")}:</td>
                                    <td>{details?.reject_reason}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 
            </div>
        </div>
    </>
}

export default EcobankXpressDetails;