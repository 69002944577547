import { createSlice } from "@reduxjs/toolkit"

// initialize the state
const initialState = {
    currStep: 0,
    sendingAmount: 0,
    receivingAmount: 0,
    paymentMethod: [{abb: "CP", abb_name: "Cash Pickup"}],
    transactionFees: {},
    senderDetails: {},
    receiverDetails: {},
    transactionDetails: {},
    sending_fees: 0,
    sending_tax: 0
}

// create the slice
export const LocalSendingSlice = createSlice({
    name: "local-sending-slice",
    initialState: initialState,
    reducers: {
        setCurrentStep(state, action){
            state.currStep = action.payload;
        },
        setSendingAmount(state, action){
            state.sendingAmount = action.payload;
        },
        setReceivingAmount(state, action){
            state.receivingAmount = action.payload;
        },
        setPaymentMethod(state, action){
            state.paymentMethod = action.payload.abb_name;
        },
        setTransactionFees(state, action){
            state.transactionFees = action.payload;
        },
        setSenderDetails(state, action){
            state.senderDetails = action.payload;
        },
        setReceiverDetails(state, action){
            state.receiverDetails = action.payload;
        },
        setTransactionDetails(state, action){
            state.transactionDetails = action.payload;
        },
        setSendingFee(state, action){
            state.sending_fees = action.payload;
        },
        setSendingTax(state, action){
            state.sending_tax = action.payload;
        },
    }
});

// exporting the actions
export const { setCurrentStep, setSendingAmount, setReceivingAmount, setPaymentMethod, setTransactionFees,
setSenderDetails, setReceiverDetails, setTransactionDetails, setSendingFee, setSendingTax } = LocalSendingSlice.actions;

// exporting the reducter
export default LocalSendingSlice;