import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import { showToast } from "../../slices/toast-slice";
import { formatAmount } from "../../utils/helper";

const MultiserviceDetails = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const [transcation, setTransaction] = useState([]);
    const BILL_TYPE = 3 //Bill payement type
    const PENDING = 1 // pending status
    function fetchData() {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        }; 
        const data = {
            id: id
        };
        dispatch(showLoading());
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getMultiserviceDetails', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if (response.data.status === 1) {
                setTransaction(response.data.data);
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        })
        .catch(error => {
            console.error(error);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }
    useEffect(() => {
        fetchData()
    }, []);

    function checkStatus(event) {
        dispatch(showLoading());
        event.preventDefault();
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const transactionReferenceNo = transcation?.reference_no;
        axios.get(
        `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/sochitel/checkTransactionStatus/${transactionReferenceNo}`,{ headers }
        ).then(response => {
            console.log(response.data);
            if (response.data.status === 1) {
                dispatch(hideLoading());
                fetchData()
                navigate('/multiservices/' + transcation?.id)
            }else{
                dispatch(hideLoading());
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        })
        .catch(error => {
            dispatch(hideLoading());
            console.error(error);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }

    return <>
        <div className="row">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-label">{t("MANUAL_CASHIN_DETAILS")}</h5>
                    <span>
                        <button type='button' className='btn btn-primary' onClick={() => navigate('/multiservices')}>
                            <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                            {t('BACK')}
                        </button>
                    </span>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <table className="table table-sm table-borderless w-100">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '40%' }}>{t("LBL_MY_TXNS_DETAILS")}:</th>
                                        <th style={{ width: '60%' }}></th>
                                    </tr>
                                    <tr>
                                        <td>{t("REFERENCE")}:</td>
                                        {transcation?.reference_no && (
                                            <td>
                                                {transcation?.reference_no !== null ? (transcation?.reference_no) : "-"} 
                                            </td>
                                        )}
                                        </tr>
                                    <tr>
                                        <td>{t("OPERATOR_REFERENCE")}:</td>
                                        <td>{transcation?.operator_ref}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("OPERATORS")}:</td>
                                        <td>{transcation?.operatorName}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("PRODUCTS")}:</td>
                                        <td>{transcation?.productName}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("AMOUNT")}:</td>
                                        <td>{transcation?.topupAmount ? formatAmount(transcation?.topupAmount) : '-'} {transcation?.currency}</td>
                                    </tr>
                                    {
                                        transcation?.productTypeId == BILL_TYPE ? 
                                        <>
                                            <tr>
                                                <td>{t("ACCOUNT_NUMBER")}:</td>
                                                <td>{transcation?.accountId}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>{t("LBL_PAYER_MESSAGE")}:</td>
                                                <td>{transcation?.instructions.split('\n').map((item, index) => (
                                                    <div key={index}>{item}</div>
                                                ))}</td>

                                                
                                            </tr>
                                            
                                        </> : 
                                        <tr>
                                            <td>{t("PHONE_NUMBER")}:</td>
                                            <td>{transcation?.phoneNumber}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>{t("STATUS")}:</td>
                                        <td>
                                            <strong>{transcation?.status}</strong>
                                            <form action="" method="post">
                                                <input type="hidden" name="reference" id="reference" value={transcation?.reference_no} />
                                                {
                                                    transcation?.statutId == PENDING && 
                                                        <button type="submit" onClick={checkStatus} className="btn btn-primary" id="apply-filter-paid" data-count="0">{t('CHECK_STATUS')}</button>
                                                }
                                            </form>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <table className="table table-sm table-borderless w-100">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '40%' }}>{ !transcation?.request_by ? t("LBL_AGENT_DETAILS") : t('LBL_CUSTOMER_DETAILS')}:</th>
                                        <th style={{ width: '60%' }}></th>
                                    </tr>
                                    {
                                        !transcation?.request_by ? 
                                        <>
                                            <tr>
                                                <td>{t("LOCATION")}:</td>
                                                <td>{transcation?.agentLocationName}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("COUNTRY")}:</td>
                                                <td>{transcation?.agentCountryName}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>{t("AGENT_NAME")}:</td>
                                                <td>{transcation?.fullName}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("BALANCE_AFTER")}:</td>
                                                <td>{transcation?.agentLocationBalance ? formatAmount(transcation?.agentLocationBalance) : '-'} {transcation?.agentCountryCurrency}</td>
                                            </tr>
                                        </> 
                                        :
                                        <>
                                            <tr>
                                                <td>{t("CUSTOMER")}:</td>
                                                <td>{transcation?.customerFullName}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("COUNTRY")}:</td>
                                                <td>{transcation?.customerCountryName}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>{t("PHONE_NUMBER")}:</td>
                                                <td>{transcation?.customerPhoneNumber}</td>
                                            </tr>
                                        </>
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MultiserviceDetails;