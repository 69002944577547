import React, { useEffect } from "react";
import "./customToast.css";
import checkIcon from "./../assets/images/check.svg";
import errorIcon from "./../assets/images/error.svg";
import infoIcon from "./../assets/images/info.svg";
import warningIcon from "./../assets/images/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "../slices/toast-slice";

const CustomToast = (props) => {
  const isToastVisible = useSelector((state) => state.toast.isToastVisible);
  const toastType = useSelector((state) => state.toast.type);
  const toastTitle = useSelector((state) => state.toast.title);
  const toastMessage = useSelector((state) => state.toast.message);
  const dispatch = useDispatch();

  // // const { title, body, position } = props;
  useEffect(() => {
    console.log("isloading changed");
    setTimeout(() => {
      dispatch(closeToast(false));
    }, 10000);
    // if(isLoading) {
    //     const toastLiveExample = document.getElementById('liveToast')
    //     toastLiveExample.style.display = 'block';
    // }  else closeToasT()
  }, [isToastVisible]);

  const closeMyToast = () => {
    // console.log(isLoading)
    // console.log('isloading false')
    dispatch(closeToast(false));
    // const toastLiveExample = document.getElementById('liveToast')
    // toastLiveExample.style.display = 'none';
  };
  function getIcon() {
    if (toastType === "success") return checkIcon;
    else if (toastType === "danger") return errorIcon;
    else if (toastType === "info") return infoIcon;
    else if (toastType === "warning") return warningIcon;
  }

  function displayMessage() {
    var msg = toastMessage.split("#");
    if (msg.length > 1) {
      return `<ul><li>kdkkdkkd</li><li>kdkkdkkd</li></ul>`;
    }
  }

  return (
    <>
      <div className="notification-container bottom-right">
        <div
          id="liveToast"
          className={`notification toast show-block bottom-right ${toastType}`}
        >
          <button onClick={closeMyToast}>x</button>
          {toastTitle !== "" ? (
            <>
              <div className="notification-image">
                <img src={getIcon()} alt="" />
              </div>
              <div style={{ display: "inline-block" }}>
                <p className="notification-title">{toastTitle}</p>
                <div className="notification-message">{toastMessage}</div>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "inline-block" }}>
                <div className="notification-image">
                  <img src={getIcon()} alt="" />
                </div>
                <p className="notification-message">{toastMessage}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomToast;
