import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomPaginateOption from '../../CustomPaginateOption';
import { useAuthUser } from 'react-auth-kit';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import axios from 'axios';
import { showToast } from '../../../slices/toast-slice';
import CustomDuration from '../../CustomDuration';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

export default function TellerAccessRequests() {
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const dispatch = useDispatch();
    const [statuses, setStatuses] = useState([]);
    const navigate = useNavigate();
    const reviewCmnt = useRef();
    const [reviewComment, setReviewComment] = useState([]);

    const idTypes = useSelector(state => state.locationUsers.idTypes);
    const approvalStatuses = useSelector(state => state.locationUsers.statuses);
    const editableStats = useSelector(state => state.locationUsers.editableStatuses);
    const listingUrl = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/getAccessRequests`;

    const [filters, setFilters] = useState({
        'searchBy': '',
        'searchValue': '',
        'approval_status': '',
        'per_page': 10
    });

    const [fromPage, setFromPage] = useState(1);
    const [toPage, setToPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(1);

    const [listRequests, setListRequests] = useState();
    const [pages, setPages] = useState('');

    const showModal = () => {
        const modalEle = reviewCmnt.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        bsModal.show()
    }

    const hideModal = () => {
        const modalEle = reviewCmnt.current
        const bsModal = Modal.getInstance(modalEle)
        bsModal.hide()
    }

    const displayReviewComment = (comments = []) => {
        // get the comments and filter out where the review action is update requested
        let requested_updated_comments = comments.filter(val => val.action_taken === 4);
        // set the review comment
        setReviewComment(requested_updated_comments);

        // display the modal
        showModal();
    }

    const handleInput = (e) => {
        e.persist();
        setFilters(prevFi => {
            let updatedFilters = {...prevFi, [e.target.name]: e.target.value}
            console.log(updatedFilters);
            return updatedFilters
        })
    }

    const processSearchAction = (e) => {
        e.preventDefault();

        console.log("processing filters", filters);

        fetchRequestData(listingUrl, filters).then(res => {
            let resData = res?.data;
            console.log(resData);
            if(res.status === 1){
                // update the listing table
                updateListingTable(resData)
            }
        })
    }

    const resetSearch = (e) => {
        setFilters(prevFilters => {
            for(let key in prevFilters){
                prevFilters[key] = ''
            }

            // refresh the form
            fetchRequestData(listingUrl, filters).then(res => {
                let resData = res?.data;
                if(res.status === 1){
                    // update the listing table
                    updateListingTable(resData)
                }
            })

            return prevFilters
        });
    }

    function updateListingTable(accessReqs)
    {
        let listing = "";
        let listingData = accessReqs.data;
        if(listingData && listingData.length)
        {
            listing = listingData.map((val, indx) => (
                <tr key={indx}>
                    <td>{accessReqs.from + indx}</td>
                    <td>{`${val?.teller_first_name} ${val?.teller_other_names} ${val?.teller_last_name}`}</td>
                    <td>{val.teller_phone_no}</td>
                    <td>{val.teller_email_address}</td>
                    <td>{val.country_name}</td>
                    <td>{val.get_i_d_type.id_type_description ?? "-"}</td>
                    <td>{val.id_number}</td>
                    <td>{approvalStatuses[val.approval_status]}</td>
                    <td>{val.created_at}</td>
                    <td>
                        <button type='button' className="btn btn-sm btn-primary m-1"
                        disabled={editableStats && !editableStats.includes(val.approval_status) ? 'disabled' : ''}
                        onClick={() => navigate('/editTellerRequest/' + val.id)}>
                            {t('EDIT')}
                        </button>

                        <button type='button' className="btn btn-sm btn-secondary"
                        onClick={() => displayReviewComment(val.get_approval_logs)}
                        hidden={val.approval_status == 4 ? '' : 'hidden'}>
                            {t('COMMENTS')}
                        </button>
                    </td>
                </tr>
            ));
        }

        // set the number of pages
        let tmp_total = accessReqs?.total;
        let perPage = accessReqs?.per_page;
        let currentPage = accessReqs?.current_page;
        let total_pages = tmp_total > perPage ? Math.round(tmp_total / perPage) : 1;
        let tmp_pages = [];
        for (let i = 1; i <= total_pages; i++) {
            tmp_pages.push(i);
        }

        var pageStr = tmp_pages.map((val, indx) => (
            <li key={indx} className={val === currentPage ? 'page-item active' : 'page-item'}>
                <a href='#' className='page-link'>{val}</a>
            </li>
        ))

        setListRequests(listing)
        setFromPage(accessReqs?.from)
        setCurrentPage(currentPage)
        setToPage(accessReqs?.to)
        setTotalEntries(tmp_total)
        setPages(pageStr)
    }

    useEffect(() => {
        // fetch all the data for listing
        let url = listingUrl;

        fetchRequestData(url, filters).then(res => {
            let resData = res?.data;
            if(res.status === 1){
                // update the listing table
                updateListingTable(resData)
            }
        })
    }, [])

    async function fetchRequestData(url, payload)
    {
        // start the loading activity
        dispatch(showLoading());

        // cal the api
        return axios({
            method: "post",
            url: url,
            data: payload,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // show the success page
                return res.data;
            }
            
            const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
            dispatch(showToast(["danger", "Request Error", errorMsg]))

            return false;
        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
            return false
        })
    }

  return (
    <>

     {/* access request filters  */}
        <form id="my-form">
            <div className="form-group row ">
                <div className="col-md-3 md:mt-2">
                    <label className="" htmlFor="search_by">
                        {t("SEARCH_BY")}
                    </label>
                    <select
                        className="form-select"
                        onChange={handleInput}
                        name="searchBy"
                        id="searchBy"
                        value={filters.searchBy}
                        aria-label="Default select example"
                    >
                        <option value="">{t('SELECT')}</option>
                        <option value="first_name">{t('FIRSTNAME')}</option>
                        <option value="last_name">{t('LASTNAME')}</option>
                        <option value="email_address">{t('EMAIL_ADDRESS')}</option>
                        <option value="phone_no">{t('PHONE_NUMBER')}</option>
                        <option value="id_number">{t('ID_NUMBER')}</option>
                    </select>
                </div>

                <div className="col-12 col-md-6">
                    <label className="" htmlFor="search_value">
                        {t("SEARCH_VALUE")}
                    </label>
                    <input type="text" name="searchValue" id="searchValue" className="form-control"
                    value={filters.searchValue} onChange={handleInput} />
                </div>

            </div>

            <div className="form-group row mt-2">
                <div className="col-md-3">
                    <label className="" htmlFor="approval_status">
                        {t("STATUS")}
                    </label>
                    <select
                        className="form-select"
                        onChange={handleInput}
                        value={filters.approval_status}
                        name="approval_status"
                        id="approval_status"
                        aria-label="Default select example"
                    >
                        <option value={""}>{t("SELECT_A_STATUS")}</option>
                        {
                            approvalStatuses.map((val, indx) => (
                                <option key={indx} value={indx}>{val}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-md-center mt-3 mb-5">
                <button
                type="button"
                onClick={processSearchAction}
                className="col-sm-2 btn btn-primary"
                id="apply-filter-paid"
                data-count="0"
                >
                {t("SEARCH")}
                </button>
                &nbsp;
                <button
                type="reset"
                onClick={resetSearch}
                className="col-sm-2 btn btn-secondary"
                id="apply-filter-clear-paid"
                >
                {t("CLEAR_SEARCH")}
                </button>
            </div>

            <div className="per_page m-2">
                <CustomPaginateOption
                per_page= {filters.per_page}
                handleInput={handleInput}
                total="20"
                />
            </div>
        </form>

        <table
            id="datatable"
            className="table table-bordered grid-table responsive nowrap w-100"
            >
            <thead>
                <tr>
                    <th>#</th>
                    <th>{t("FULL_NAME")}</th>
                    <th>{t("phoneNumber")}</th>
                    <th>{t("EMAIL_ADDRESS")}</th>
                    <th>{t("COUNTRY")}</th>
                    <th>{t("PHOTO_ID_TYPE")}</th>
                    <th>{t("PHOTO_ID_NUMBER")}</th>
                    <th>{t("STATUS")}</th>
                    <th>{t("DATE")}</th>
                    <th>{t("Action")}</th>
                </tr>
            </thead>
            <tbody>{listRequests}</tbody>
        </table>

        {/* pagination  */}
        <div className="border-top bg-light p-1 d-flex justify-content-between">
            <div>
                {t('SHOWING')} {fromPage} {t('TO')} {toPage} {t('OF')} {totalEntries} {t('ENTRIES')}
            </div>

            <div className="pagination pagination-sm">
                <li className='page-item'>
                    <a href="#" className="page-link">
                        <i className="bx bx-chevrons-left"></i>
                    </a>
                </li>
                {pages}
                <li className='page-item'>
                    <a href="#" className="page-link">
                        <i className="bx bx-chevrons-right"></i>
                    </a>
                </li>
            </div>
        </div>

        {/* review comment modal */}
        <div className="modal fade" ref={reviewCmnt} id='teller_access_ctrl'>
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title">{t('LBL_REVIEW_COMMENTS')}</h5>
                        <button className="btn-close" onClick={hideModal}></button>
                    </div>

                    <div className="modal-body">
                        <div className="mb-2">
                            <ul className="list-group-flush list-group list-group-numbered">
                                {
                                    reviewComment.map((cmnt, indx) => (
                                        <li key={indx} className="list-group-item">
                                            {cmnt.comment}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}
