import axios from "axios";
import { Modal } from "bootstrap";
import { useEffect, useRef } from "react";
import { hideLoading, showLoading } from "../slices/loading-slice";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { showToast } from "../slices/toast-slice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

const GlobalConfirmPasscod = ({ isOpen, onClose, callParentFunction }) => {
    const modalRef = useRef()
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const dispatch = useDispatch();

    const showModal = () => {
        const modalEle = modalRef.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        bsModal.show()
    }

    useEffect(() => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, {
          backdrop: 'static',
          keyboard: false
        });
    
        if (isOpen === true) {
          bsModal.show();
        } else {
          bsModal.hide();
        }
      }, [isOpen]);
    
      const handleModalClose = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle);
        bsModal.hide();
        onClose(); // Appel de la fonction onClose passée en prop
      };
    const schema = yup.object().shape({
        passcode: yup.string().required(t('FIELD_REQUIRED')),
    })
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
    const hideModal = () => {
        const modalEle = modalRef.current
        const bsModal = Modal.getInstance(modalEle)
        bsModal.hide()
    }
    const validatePasscode = (data) => {
        axios.defaults.withCredentials = false;
        hideModal();
        dispatch(showLoading())
        axios({
            method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/validatePasscode',
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }, data: data
        }).then(response => {
            console.log(response);
            dispatch(hideLoading())

            var status = response.data.status
            var message = response.data.message

            if (status === 0) {
                dispatch(showToast(["danger", "NOTICE", t(message)]));
                showModal()
                return;
            }
            //Success passcod, call the next function 
            callParentFunction();
        }).catch(error =>{
            console.log(error);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }
    return (
        <form onSubmit={handleSubmit(validatePasscode)}>
                <div className='modal fade' ref={modalRef} id="modal-passcode" tabIndex="-1">
                    <div className="modal-dialog modal-md modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Passcode</h5>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className='form-group'>
                                                <label htmlFor='passcode'>{t('PASSCODE')} <span style={{ color: "red" }}>*</span></label>
                                                <input type="password" name="passcode" id="passcode" placeholder={t('PASSCODE')} className={`form-control w-100 ${errors.passcode ? 'is-invalid' : ''}`} {...register('passcode')} />
                                                {errors?.passcode && <p className="input-error-msg"> {errors.passcode?.message} </p>}
                                            </div>
                                            {/**<small className='d-flex justify-content-center mt-4'>{t('PASSCODE_FORGOT')}? {t('CLICK')} &nbsp;<Link to={"/passcode"}>{t('HERE')} </Link> </small>**/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">{t('VERIFY')}</button>
                                <button type="button" className="btn btn-secondary" onClick={handleModalClose}>{t('CLOSE')}</button>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    )
}

export default GlobalConfirmPasscod;