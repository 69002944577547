import React, { useId } from 'react'
import { useAuthUser } from 'react-auth-kit';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { setCurrentStep, setPayerFirstName, setPayerLastName, setPaymentDetails, setReceivingMerchantCode, setReceivingMerchantName } from '../../../slices/merchant-payment-slice';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { showToast } from '../../../slices/toast-slice';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { useState } from 'react';
import GlobalConfirmPasscod from '../../GlobalConfirmPasscod';

export default function PayerDetailsForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useId();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const merchantName = useSelector(state => state.merchantPayment.receiving_merchant_name);
  const current_step = useSelector(state => state.merchantPayment.current_step);

  //const paymentAmt = useSelector(state => state.merchantPayment.paymentAmount);
  const payerFname = useSelector(state => state.merchantPayment.payer_first_name);
  const payerLname = useSelector(state => state.merchantPayment.payer_last_name);
  const rcvMerchantCode = useSelector(state => state.merchantPayment.receiving_merchant_code);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paymentAmt, setPaymentAmount] = useState("");
  const [payerPhoneNumber, setPayerPhoneNumber] = useState("");
  const [payerAccountNumber, setPayerAccountNumber] = useState("");
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };

  const handleMerchantPayment = () => {
      if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
          openModal();
          return;
      }
      // save the merchant payment
      saveMerchantPayment();
  }

  const saveMerchantPayment = () => {
    console.log("payment submitted");
    dispatch(showLoading())

    let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/transaction`;
    const countryDetails = globalAuthUser.location.agent_country;

    axios({
        method: "post",
        url: url,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `${authUser().tokenType} ${authUser().token}`,
        },
        data: {
          "type": "cashin",
          "channel": "CDT",
          "amount": paymentAmt,
          "currency": countryDetails?.currency,
          "phone_number": payerPhoneNumber,
          "request_type": 0, // basic request type
          "country_code": countryDetails?.isoname,
          "request_by": 0,
          "user_id": globalAuthUser?.user_id,
          "first_name": firstName,
          "last_name": lastName,
          "customer_id": payerAccountNumber,
          "rcv_merchant_code": rcvMerchantCode
        }

    }).then(res => {
      const response = res?.data;
      console.log(response.data);

      // extract the response message
      const message = t(response?.message);

      // make suer the response is a success
      if(!response?.success) {
        dispatch(showToast(['danger', 'Payment Error', message]));
        dispatch(hideLoading());
        return;
      }

      // update the payment details
      dispatch(setPaymentDetails(response?.data))

      // show the success message to the caller
      dispatch(showToast(['success', 'Successful Payment', message]));
      dispatch(hideLoading());

      // update the current step
      dispatch(setCurrentStep(current_step + 1));

    }).catch(err => {
      console.log("The error is ", err);
      // a server error occured
      dispatch(showToast(['danger', 'Payment Error', err.message]));
      dispatch(hideLoading());
    })
    
  }

  const handleCancel = () => {
    // reset the form fields
    const paymentForm = document.getElementById('paymentForm');
    paymentForm.reset();

    // empty the other stored data
    dispatch(setCurrentStep(0));
    dispatch(setReceivingMerchantCode(""));
    dispatch(setReceivingMerchantName(""));
    dispatch(setPaymentDetails({}));

    // navigate to the dashboard
    navigate('/home');
  }
  
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-4 border-bottom">
            <h6 className='mb-2'>{t('MERCHANT_NAME')}: {merchantName}</h6>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12 col-lg-2"></div>
        <div className="col-12 col-lg-8">
          <div className="mb-2 border-bottom border-dark d-flex justify-content-center">
            <h6>{t('CUSTOMER_DETAILS')}</h6>
          </div>

          <div className="mb-4">
            <small className="text-secondary">
              {t("COLLECT_CUSTOMER_DETAILS")} (<span className='text-danger'>*</span>).
            </small>
          </div>

          <div className="mb-2">
              <form id='paymentForm'>
                {/** first and last names for the payer */}
                <div className="row mb-3">

                  <div className="col-12 col-lg-6 mb-1">
                      <label htmlFor={id + "payerFirstName"}>
                          { t("FIRSTNAME") }
                          <span className="text-danger">*</span>
                      </label>
                      <input type="text" name="" id={ id + "payerFirstName"} className="form-control"
                      value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                  </div>
                  <div className="col-12 col-lg-6 mb-1">
                      <label htmlFor={id + "payerLastName"}>
                          { t("LASTNAME") }
                          <span className="text-danger">*</span>
                      </label>
                      <input type="text" name="" id={ id + "payerLastName"} className="form-control"
                      value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                  </div>

                </div>

                {/** Phone number and account numbers */}
                <div className="row mb-3">
                  <div className="col-12 col-lg-6 mb-1">
                      <label htmlFor={id + "payerPhone"}>
                          { t("PHONE_NO") }
                          <span className="text-danger">*</span>
                      </label>
                      <input type="text" name="" id={ id + "payerPhone"} className="form-control"
                      value={payerPhoneNumber} onChange={(e) => setPayerPhoneNumber(e.target.value)} required/>
                  </div>

                  <div className="col-12 col-lg-6 mb-1">
                      <label htmlFor={id + "payerAccountNumber"}>
                          { t("PAYER_ACCOUNT_NUMBER") }
                          <span className="text-danger">*</span>
                      </label>
                      <input type="text" name="" id={ id + "payerAccountNumber"} className="form-control"
                      value={payerAccountNumber} onChange={(e) => setPayerAccountNumber(e.target.value)} required/>
                  </div>
                </div>

                {/* Payment Amount */}
                <div className="mb-4">
                  <label htmlFor={id + "paymentAmount"}>
                      { `${t("PAYMENT_AMOUNT")}` }
                      <span className='mx-1'>({globalAuthUser?.location.agent_country?.currency})</span>
                      <span className="text-danger">*</span>
                  </label>
                  <input type="text" name="" id={ id + "paymentAmount"} className="form-control"
                  value={paymentAmt} onChange={(e) => setPaymentAmount(e.target.value)} required/>
                </div>

                {/** submit merchant payment button */}
                <div className="mb-2">
                  <button type='button' className="btn btn-sm btn-primary me-3" onClick={() => handleMerchantPayment()}>
                      { t("SUBMIT") }
                  </button>

                  <button type='button' className="btn btn-sm btn-dark" onClick={() => handleCancel()}>
                      { t("CANCEL") }
                  </button>
                </div> 
              </form>

          </div>
        </div>
      </div>

      {/** passcode modal */}
      <div>
          <GlobalConfirmPasscod
              isOpen={isModalOpen}
              onClose={closeModal}
              callParentFunction={saveMerchantPayment}
          />
      </div>
    </>
  )
}
