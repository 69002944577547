import axios from "axios";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { useDispatch } from "react-redux";


const ChangeEmail = () => {

    const { t } = useTranslation();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);
    const [isEmailExist, setIsEmailExist] = useState(false)
    const [emailError, setEmailError] = useState('');
    const [myObject, setMyObject] = useState(
        {
            userEmail: '',
            userPassword: '',
        }
    );

    function updateUserEmail(event) {
        event.preventDefault();
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            'userEmail' : myObject.userEmail,
            'userPassword' : myObject.userPassword,
        };

        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/userEmail/updateUserEmail', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if(response.data.status === 1){
                navigate("/validate-code");
             }else if(response.data.message === "PASSWORD_DOES_NOT_MATCH"){
                setIsVisible(true);
                setIsEmailExist(false);
             }else if(response.data.message === "ERR_EMAIL_DUPLICATION"){
                setIsEmailExist(true);
                setIsVisible(false);
             }
             else{
                console.log(response.data); 
             }
        })
        .catch(error => {
            dispatch(hideLoading());
            console.error(error);
            //display the error alert
        });
    }

    const handleInput = (e) => {
        e.persist();
        setMyObject({...myObject, [e.target.name]: e.target.value});
    }
    const validateEmail = () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    
        if (!myObject.userEmail) {
          setEmailError(t('EMAIL_REQUIRED'));
        } else if (!emailRegex.test(myObject.userEmail)) {
          setEmailError(t('INVALID_EMAIL'));
        } else {
          setEmailError('');
          const btn = document.getElementById('btnSubmit');
          btn.removeAttribute('disabled');

        }
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("CHANGE_YOUR_EMAIL")}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/user-profile" className="btn btn-primary btn-sm">
                                        {t("BACK")}
                                    </NavLink>
                                </div>
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    {
                                        (isVisible && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {t("PASSWORD_DOES_NOT_MATCH")}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        )
                                    }
                                    {
                                        (isEmailExist && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {t("ERR_EMAIL_DUPLICATION")}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        )
                                    }
                                    <form autoComplete="off" onSubmit={updateUserEmail}>
                                        <label htmlFor="email" className="form-label">
                                            {t('EMAIL')}
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder={t('EMAIL_PLACEHOLDER')}
                                            onChange={handleInput}
                                            onBlur={validateEmail}
                                            type="email"
                                            name="userEmail"
                                            id="email"
                                            value={myObject.userEmail}
                                        />
                                        {emailError && <div className="text-danger">{emailError}</div>}
                                        <br />
                                        <label htmlFor="password" className="form-label">
                                            {t('PASSWORD')}
                                        </label>
                                        <input
                                            type="password"
                                            required
                                            className="form-control"
                                            onChange={handleInput}
                                            name="userPassword"
                                            id="password"
                                            autoComplete="new-password"
                                            value={myObject.userPassword}
                                        />

                                        <br />
                                        <button
                                            type="submit"
                                            disabled
                                            className="btn btn-success btn-sm"
                                            id="btnSubmit"
                                        >
                                            {t('SUBMIT')}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default ChangeEmail;