import { t } from "i18next"

const CustomPaginateOption = (props) => {
    return <label>
        <div className="show">{t('SHOW')}</div>
        <select name="per_page" id="per_page" value={props.per_page} onChange={props.handleInput} className="form-select">
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value={props.total}>{t('ALL')}</option>
        </select>
        <div className="entries">{t('ENTRIES')}</div>
    </label>
}

export default CustomPaginateOption;