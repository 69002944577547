import React, { useState, useEffect } from "react";
import logo from "../assets/images/bnb.png";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobalSearchPickupCodeResult,
  set_FORM_FREE_COMPLETION,
  set_FORM_FREE_RECEIVE_LOOKUP_RESP,
  set_REF_NO_RESP,
} from "../slices/auth-slice";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../slices/loading-slice";
import { useTranslation } from "react-i18next";
import { closeToast, showToast } from "../slices/toast-slice";

function PayTransaction() {
  const { t } = useTranslation();

  const [company, setCompany] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [pickupCode, setPickupCode] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isCP, setIsCP] = useState(true); 
  const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
  const FORM_FREE_COMPLETION = useSelector((state) => state.auth.FORM_FREE_COMPLETION);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

  const my_company = {
    company_id: 1,
    company_code: "BNB",
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCompanySelect = (e, comp) => {
    console.log(comp);
    // console.log(e.currentTarget.id);
    // console.log(e.currentTarget.parentElement);
    // e.currentTarget.classList.add('sCompany')
    const allWithClass = Array.from(
      e.currentTarget.parentElement.getElementsByClassName("card")
    );
    // console.log(allWithClass);
    allWithClass.forEach((element) => {
      element.classList.remove("sCompany");
    });
    e.currentTarget.classList.add("sCompany");

    // setCompany(companies.at(e.currentTarget.id).company_id);
    setCompany(comp.company_id);
    if (comp.company_name) {
      setIsCP(false); //to know if it is a CP or not
    }
    setCompanyCode(comp.company_code);
    // console.log(companies);
  };

  const onSearch = () => {
    setSearchClicked(true);
    if (company === "" || pickupCode === "") {
      return;
    }

    console.log(company);
    console.log(pickupCode);
    if (companyCode == "MG") {
      if (pickupCode.length !== 8) {
      }
      dispatch(showLoading());
      axios({
        method: "post",
        url: FORM_FREE_COMPLETION ? process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/moneygram/FFRLRN" : process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/moneygram/referenceNumber",
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + globalAuthUser?.partner_module_token
        },
        data: {
          agentID: globalAuthUser?.location.agent_id,
          agentSequence: globalAuthUser?.location.sequence,
          token: globalAuthUser?.location.token,
          referenceNumber: pickupCode,
          language: LANGUAGE,
        },
      })
        .then((res) => {
          dispatch(hideLoading());
          if (res.data.status === "SUCCESS") {
            if(FORM_FREE_COMPLETION) {
              dispatch(set_FORM_FREE_RECEIVE_LOOKUP_RESP(res.data.data.formFreeReceiveLookup));
              dispatch(set_REF_NO_RESP(res.data.data.referenceNumber));
              // navigate("/formFreeDetails", { replace: true, state: {} });
              navigate("/refNoDetails", { replace: true, state: {} });
            } else {
              dispatch(set_REF_NO_RESP(res.data.data));
              navigate("/refNoDetails", { replace: true, state: {} });
            }
          } else {
            dispatch(showToast(["danger", "", res.data.message]));
          }
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
          );
        });
    } else {
      dispatch(showLoading());
      if (pickupCode.substring(0, 2) == "CP" || isCP) { //proceed the repayment otherwise the
        const payload = { 
          company: company,
          code: pickupCode,
          type: "repayment",
          source: "REACT APP" 
        }
        axios({
          method: "post",
          url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/saveAgentRepayment",
          withCredentials: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authUser().tokenType + " " + authUser().token,
          },
          data: payload,
        }).then((res) => {
          dispatch(hideLoading());
          if (res.data.status == 1) {
            console.log(res.data.data);
            console.log("---------------------"); //CP74916301
            dispatch(setGlobalSearchPickupCodeResult(res.data));
            navigate("/payTxnConfirmation", {
              replace: true,
              state: { agentTransactionId: res.data.data.agentTransaction.id, companyCode: companyCode },
            });
          } else {
            console.log(res.data.message);
            //After translating all the message error, we can just display res.data.message
            dispatch(showToast(["danger", "", t(res.data.message)]));
          }
        });
      }else{
        axios({
          method: "post",
          url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/searchPickupCode",
          withCredentials: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authUser().tokenType + " " + authUser().token,
          },
          data: { company: company, code: pickupCode, type: "payment" },
        }).then((res) => {
          dispatch(hideLoading());
          if (res.data.status == 1) {
            dispatch(setGlobalSearchPickupCodeResult(res.data));
            navigate("/payTxnConfirmation", {
              replace: true,
              state: { agentTransactionId: res.data.data.agent_transaction_id, companyCode: companyCode },
            });
          } else {
            console.log(res.data.message);
            if(typeof res.data.message === 'object' && res.data.message !== null) {
              if(res.data.message.code !== undefined && res.data.message.code !== null && Array.isArray(res.data.message.code) && res.data.message.code.length > 0 ) {
                dispatch(showToast(["danger", "", res.data.message.code[0]]))
              } else dispatch(showToast(["danger", "", res.data.message]))
            } else dispatch(showToast(["danger", "", res.data.message]))
          }
        });
      }
    }
  };

  const authUser = useAuthUser();

  useEffect(() => {
    dispatch(set_FORM_FREE_COMPLETION(false))
    axios({
      method: "post",
      url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/agentCompanies",
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authUser().tokenType + " " + authUser().token,
      },
      data: {
        source: 'portal'
      },
    }).then((res) => {
      console.log(res);
      // var compa = res.data.data.
      setCompanies(res.data.data);
    });
  }, []);

  const onFormFreeChange = (event) => {
    dispatch(set_FORM_FREE_COMPLETION(event.target.checked));
  }

  return (
    <>
      {/* <p>{`Hello ${authUser().token}, your U-ID is: ${authUser().tokenType}`}</p> */}
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header" style={{ fontWeight: "600" }}>
            {t("PAY_TRANSACTION")}
          </div>
          <div className="card-body">
            <div className="form-group row justify-content-center">
              {
                companies != null && companies.length != 0 &&
                <>
                {/* Verify if CP repayment is enabled for the location before displaying the CP  */}
                {
                  globalAuthUser?.location?.enable_pay_repayment == 1 && (
                      <div
                      className="card col-6 col-md-2 company"
                      style={{
                        marginRight: "15px",
                        marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => onCompanySelect(e, my_company)}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          margin: "auto",
                        }}
                      >
                        <div
                          style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                            margin: "auto",
                          }}
                        >
                          CP repayment
                        </div>
                      </div>
                    </div> 
                  )
                }
                  
                {companies.filter(c => c.api_connected == 1).map((company, index) => (
                  <div
                    id={index}
                    key={index}
                    className="card col-6 col-md-2 company"
                    style={{
                      marginRight: "15px",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => onCompanySelect(e, company)}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        margin: "auto",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          margin: "auto",
                        }}
                      >
                        {/* <img src={require("http://test.bnbcashapp.com/" + company.company_logo)} className="card-img-top" alt="..." /> */}
                        <img
                          src={process.env.REACT_APP_BNB_BASE_URL + "/" + company.company_logo}
                          className="card-img-top"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                ))}
                </>
              }
            </div>
            <div className="row justify-content-center">
              {searchClicked && !company && (
                <p
                  className="input-error-msg"
                  style={{
                    textAlign: "center",
                    maxWidth: "360px",
                    marginBottom: "15px",
                  }}
                >
                  {t("SELECT_A_COMPANY")}
                </p>
              )}
            </div>

            <br />

            <div className="row justify-content-center">
              <div
                className=""
                style={{ maxWidth: "360px", marginBottom: "15px" }}
              >
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  {companyCode == "MG" ? t("referenceNumber") : t("PICKUP_CODE")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  onChange={(e) => setPickupCode(e.target.value)}
                />
                {searchClicked && !pickupCode && (
                  <p
                    className="input-error-msg"
                    style={{ marginBottom: "1px" }}
                  >
                    {t("THIS_FIELD_IS_REQUIRED")}
                  </p>
                )}
                {searchClicked &&
                  companyCode == "MG" &&
                  (pickupCode.length !== 8 ||
                    /^\d+$/.test(pickupCode) === false) && (
                    <p
                      className="input-error-msg"
                      style={{ marginBottom: "1px" }}
                    >
                      {t("REFERENCE_NUMBER_MUST_BE_8_DIGITS")}
                    </p>
                  )}

                {/* { companyCode == "MG" && <div className="form-check">
                  <br/>
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" onChange={onFormFreeChange} />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    {t("FORM_FREE_COMPLETION")}
                  </label>
                </div> } */}
              </div>
            </div>

            <div className="row justify-content-center">
              <button
                type="button"
                style={{ maxWidth: "360px" }}
                className="btn btn-primary"
                onClick={onSearch}
              >
                {t("SEARCH")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayTransaction;
