import React from 'react'

export default function CreateUserButton({children, handleClick}) {
  return (
    <>
        <div className="row mb-3">
            <div className="col-12 col-lg-2"></div>
            <div className="col-12 col-lg-8 d-flex justify-content-end">
                <button type='button' className="btn btn-sm btn-secondary btn-sm" onClick={handleClick}>
                    { children }
                </button>
            </div>
        </div>
    </>
  )
}
