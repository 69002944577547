import { param } from 'jquery';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import imageCompression from 'browser-image-compression';
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';

export default function TellerAccessRequestForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    const [requestReady, setRequestReady] = useState(false);
    const idTypes = useSelector(state => state.locationUsers.idTypes);
    const acceptedFiles = useSelector(state => state.locationUsers.acceptedFiles);

    const [params, setParams] = useState({
        'first_name': '',
        'last_name': '',
        'other_names': '',
        'phone_number': '',
        'email_address': '',
        'agent_country_id': globalAuthUser?.location?.agent_country?.id,
        'main_teller_id': globalAuthUser?.user_id,
        'id_type': '',
        'id_number': '',
        'id_front': '',
        'id_back': ''
    });

    const handleInput = (e) => {
        e.persist();
        setParams((prevParams) => {
            let updatedParams = {...prevParams, [e.target.name]: e.target.value};
            isFormComplete(updatedParams)

            return updatedParams;
        });
    }

    const handleFileInput = async (e) => {
        let file = e.target.files[0];
        let { name } = e.target;
        file = file ? file : "";

        // compress the file
        file = await compressFile(file);

        // update the file field
        setParams(tmpData => {
            // update the file part of the parameters
            const updatedData = {...tmpData, [name] : file};

            // check whether the next action is allowed
            isFormComplete(updatedData);

            // return the updated dtaa
            return updatedData;
        });
    }

    const compressFile = async(file) => {
        let compressed_file = file;
        const max_file_mb = 2;

        try {
            // extract the file type
            const file_type = file?.type;
            
            const img_fileTypes = ["image/jpeg", "image/png", "image/jpg"];
            if(img_fileTypes.includes(file_type)) {
                // commence image compression
                // get the size of the image in mb
                const file_size = file.size / (1000*1000);

                if(file_size < max_file_mb) return file; // dont compress the file

                // set the options
                const optionObj = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                // call the image compression algorithm
                const compressed_blob = await imageCompression(file, optionObj);
                console.log(compressed_file);

                compressed_file = new File([compressed_blob], file.name, { type: 'application/gzip' });

                // return the compressed file
                return compressed_file;
            }

            return compressed_file

        } catch (error) {
            console.log(error);
            return compressed_file;
        }
    }

    const isFormComplete = (params) => {
        // loop over the param fields for empth fields
        let exceptions = ['other_names', 'id_back']; 
        let isComplete = true;
        for(const key in params){
            // disregard the exceptions
            if(exceptions.includes(key)) continue

            // check for empty fields
            if(params.hasOwnProperty(key)) {
                if(!params[key] || params['key'] == "")
                {
                    isComplete = false;
                    break;
                }
            }
        }

        // update the form ready state
        setRequestReady(isComplete);
    }

    const processAccessRequest = (e) => {
        e.preventDefault();

        dispatch(showLoading())

        // check whether all the fields are present
        if(!requestReady) {
            dispatch(showToast(['danger', 'Form Error', 'INCOMPLETE_FORM_SUBMISSIONS']));
            return;
        }
        
        // set the url
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/saveRequests`;

        // prepare the payload
        let formdata = new FormData();

        for(const key in params){
            if(params.hasOwnProperty(key)) {
                if(params[key] instanceof File) {
                    formdata.append(key, params[key]);
                    continue;
                }

                formdata.append(key, params[key]);
            }
        }

        // send the request to the backend
        axios({
            method: "post",
            url: url,
            data: formdata,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // show the success page
                const successMsg = msg ? msg : "REGISTRATION_SUCCESSFUL";
                dispatch(showToast(["success", "Access Request", successMsg]));
                navigate('/tellerAccess');
                return
            }
            
            const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
            dispatch(showToast(["danger", "Request Error", errorMsg]))
        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
        })
    }

  return (
    <>
        <div style={{ padding: "20px" }}>
            <div className="card">
                 {/* the card header */}
                <div className="card-header d-flex justify-content-between">
                    {/* the label */}
                    <div className="card-label">
                        <h5>{t('CREATE_TELLER_ACCESS_REQ')}</h5>
                    </div>

                    {/* new teller access request  */}
                    <div className='card-toolbar'>
                        <button type='button' className='btn btn-primary btn-sm' onClick={() => navigate('/tellerAccess')}>
                            <FaArrowLeft size={15} fill={'white'} /> &nbsp;
                            {t('BACK')}
                        </button>
                    </div>

                </div>

                {/* the card body */}
                <div className="card-body">

                    <div className="row">
                        <div className="col-12 col-lg-1"></div>

                        <div className="col-12 col-lg-9 p-5 m-4 bg-light shadow-sm">

                            {/* location teller access request form  */}
                            <form id='access_req_form' onSubmit={processAccessRequest}>

                                {/* teller names  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="first_name">{t('FIRSTNAME')} <span className="text-danger">*</span></label>
                                        <input type="text" name="first_name" id="first_name" className="form-control"
                                        value={params.first_name} onChange={handleInput}/>
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="other_names">{t('OTHERNAMES')}</label>
                                        <input type="text" name="other_names" id="other_names" className="form-control" 
                                        value={params.other_names} onChange={handleInput}/>
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="last_name">{t('LASTNAME')} <span className="text-danger">*</span></label>
                                        <input type="text" name="last_name" id="last_name" className="form-control" 
                                        value={params.last_name} onChange={handleInput}/>
                                    </div>
                                </div>

                                {/* teller contacts  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="phone_number">{t('PHONE_NUMBER')} <span className="text-danger">*</span></label>
                                        <input type="tel" name="phone_number" id="phone_number" className="form-control" 
                                        value={params.phone_no} onChange={handleInput}/>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="email_address">{t('EMAIL_ADDRESS')} <span className="text-danger">*</span></label>
                                        <input type="email" name="email_address" id="email_address" className="form-control" 
                                        value={params.email_address} onChange={handleInput}/>
                                    </div>
                                </div>
                                
                                {/* teller id details  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_type">{t('ID_TYPE')} <span className="text-danger">*</span></label>
                                        <select name="id_type" id="id_type" className="form-control"
                                        value={params.id_type} onChange={handleInput}>
                                            <option value="">{t('SELECT')} ...</option>
                                            { 
                                                idTypes.map((val, indx) => (
                                                    <option key={indx} value={val.type_id}>{ val.type_description }</option>
                                                ))
                                            }
                                            
                                        </select>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_number">{t('ID_NUMBER')} <span className="text-danger">*</span></label>
                                        <input type="text" name="id_number" id="id_number" className="form-control" 
                                        value={params.id_number} onChange={handleInput}/>
                                    </div>

                                </div>

                                {/* id files  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_front">{t('PHOTO_ID_FRONT')} <span className="text-danger">*</span></label>
                                        <input type="file" name="id_front" id="id_front" className="form-control"
                                        accept={acceptedFiles} 
                                         onChange={handleFileInput}/>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_back">{t('PHOTO_ID_BACK')}</label>
                                        <input type="file" name="id_back" id="id_back" className="form-control"
                                        accept={acceptedFiles}
                                         onChange={handleFileInput}/>
                                    </div>
                                </div>

                                {/* the form controls  */}
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <button type='submit' className="btn btn-primary me-2"
                                        disabled={!requestReady ? 'disabled' : ''}>
                                            {t('SUBMIT')}
                                        </button>

                                        <button type='button' className="btn btn-secondary"
                                        onClick={() => navigate('/tellerAccess')}>
                                            {t('CANCEL')}
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>
  )
}
