import React, { useEffect, useState } from 'react'
import { setBusinessInfo } from '../../../slices/agent-self-registration-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';

export default function BusinessDetailsForm({processPrev, processNext}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const bines = useSelector(state => state.agentSelfRegistration.businessInfo);
    const acceptedFiles = useSelector(state => state.agentSelfRegistration.acceptedFiles);
    const [business, setBusiness] = useState(bines);

    const [allowNext, setAllowNext] = useState(false);
    const requiredKeys = ['business_name'];

    useEffect(() => {
        checkNext(business);
    }, []);

    const checkNext = (checkData) => {
        setAllowNext(false);
        for (let i = 0; i < requiredKeys.length; i++) {
            const key = requiredKeys[i];
            if(checkData[key] == "") return;
        }

        setAllowNext(true);
    }

    const handleInput = (e) => {
        // get the input field
        let { name, value } = e.target;
        value = value ? value : "";

        // update the formData field
        setBusiness(tmpData => {
            // update the business info
            const updatedData = {...tmpData, [name] : value};

            // check the next action
            checkNext(updatedData);

            // return the updated fields
            return updatedData;
        });

        // update the personal details
        dispatch(setBusinessInfo(business));
    }

    const handleFileInput = async (e) => {
        let file = e.target.files[0];
        let { name } = e.target;
        file = file ? file : "";

        // compress the file
        file = await compressFile(file);

        // update the file field
        setBusiness(tmpData => {
            // update the file input fields
            const updatedData = {...tmpData, [name] : file};

            // check the next action
            checkNext(updatedData);

            // return the updated fields
            return updatedData;
        });

        // update the personal details
        dispatch(setBusinessInfo(business));
    }

    const compressFile = async(file) => {
        let compressed_file = file;
        const max_file_mb = 3;

        try {
            // extract the file type
            const file_type = file?.type;
            
            const img_fileTypes = ["image/jpeg", "image/png", "image/jpg"];
            if(img_fileTypes.includes(file_type)) {
                // commence image compression
                // get the size of the image in mb
                const file_size = file.size / (1000*1000);

                if(file_size < max_file_mb) return file; // dont compress the file

                // set the options
                const optionObj = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                // call the image compression algorithm
                const compressed_blob = await imageCompression(file, optionObj);
                console.log(compressed_file);

                compressed_file = new File([compressed_blob], file.name, { type: 'application/gzip' });

                // return the compressed file
                return compressed_file;
            }

            return compressed_file

        } catch (error) {
            console.log(error);
            return compressed_file;
        }
    }

    const syncBusinessInfo = () => {
        // update the personal details
        dispatch(setBusinessInfo(business));
        processNext();
    }

  return (
    <>
        <form id="businessForm">
            {/* business name */}
            <div className="row mb-4">
                <div className="col-12">
                    <label htmlFor="business_name">{ t("BUSINESS_NAME") } <span className="text-danger">*</span></label>
                    <input type="text" name="business_name" id="business_name" className="form-control" value={business.business_name} 
                    onChange={handleInput}/>
                </div>
            </div>

            {/* business name */}
            <div className="mb-3 border rounded-2 p-3 bg-light">
                <div className="mb-3">
                    <small className='text-dark'>{ t("BUSINESS_REG_CERT") }</small>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-lg-5">
                        <label htmlFor="business_cert_number">{ t("CERTIFICATE_NUMBER") }</label>
                        <input type="text" name="business_cert_number" id="business_cert_number" className="form-control"
                        placeholder={ t("OPTIONAL_FIELD") }
                        value={business.business_cert_number}
                        onChange={handleInput} />
                    </div>
                    <div className="col-12 col-lg-7">
                        <label htmlFor="business_cert">{ t("REGISTRATION_CERT")}<span>({ t("OPTIONAL_FIELD") })</span></label>
                        <input type="file" name="business_cert" id="business_cert" className="form-control"
                         onChange={handleFileInput} accept={ acceptedFiles }
                        placeholder='Optional' />
                    </div>
                </div>
            </div>

            {/* tax certificate */}
            <div className="mb-3 border rounded-2 p-3 bg-light">
                <div className="mb-3">
                    <small className='text-dark'>{ t("LBL_TAX_CERT") }</small>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-lg-5">
                        <label htmlFor="tax_cert_number">{ t("CERTIFICATE_NUMBER") }</label>
                        <input type="text" name="tax_cert_number" id="tax_cert_number" placeholder={ t("OPTIONAL_FIELD") }
                        className="form-control" value={business.tax_cert_number} onChange={handleInput} />
                    </div>
                    <div className="col-12 col-lg-7">
                        <label htmlFor="tax_cert">{ t("CERTIFICATE") } ({ t("OPTIONAL_FIELD") })</label>
                        <input type="file" name="tax_cert" id="tax_cert" className="form-control"
                         onChange={handleFileInput} accept={ acceptedFiles } />
                    </div>    
                </div>
                
            </div>

            {/* city council cert number */}
            <div className="mb-3 border rounded-2 p-3 bg-light">
                <div className="mb-3">
                    <small className='text-dark'>{ t("CITY_COUNCIL_LICENSE") }</small>
                </div>

                <div className="row mb-2">
                    <div className="col-12 col-lg-5">
                        <label htmlFor="city_council_number">{ t("LICENSE_NUMBER") }</label>
                        <input type="text" name="city_council_number" id="city_council_number" className="form-control"
                        placeholder={ t("OPTIONAL_FIELD") }
                        value={business.city_council_number} onChange={handleInput} />
                    </div>
                    <div className="col-12 col-lg-7">
                        <label htmlFor="city_council_license">{ t("LICENSE") } <span>({ t("OPTIONAL_FIELD") })</span></label>
                        <input type="file" name="city_council_license" id="city_council_license" className="form-control"
                         onChange={handleFileInput} accept={acceptedFiles} />
                    </div>
                </div>
            </div>

            {/* any other document */}
            <div className="row mb-3">
                <div className="col-12">
                    <label htmlFor="other_doc">{ t("OTHER_DOCS") } ({ t("OPTIONAL_FIELD") })</label>
                    <input type="file" name="other_doc" id="other_doc" className="form-control" 
                    onChange={handleFileInput} accept={ acceptedFiles } />
                </div>
            </div>

            {/* controls */}
            <div className="row mb-3 border-top">
                <div className="col-12 d-flex justify-content-between mt-2">
                    <button type="button" className="btn btn-dark" id="basicInfoSubmit" onClick={processPrev}>{ t("BACK") }</button>
                    {
                        <button type="button" className="btn btn-primary" id="basicInfoSubmit"
                         onClick={syncBusinessInfo} disabled = {!allowNext ? "disabled" : ""}>
                            { t("NEXT") }
                        </button>
                    }
                </div>
            </div>
        </form>
    </>
  )
}
