import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomPaginateOption from '../../CustomPaginateOption';
import { useAuthUser } from "react-auth-kit";
import { showToast } from '../../../slices/toast-slice';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import axios from 'axios';
import { Button, Modal } from 'bootstrap';

export default function TellerListing() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const usrCtrl = useRef();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const dispatch = useDispatch();

    const [fromPage, setFromPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [toPage, setToPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(1);
    const [pages, setPages] = useState('');
    const [tellerListing, setTellerList] = useState();

    const tellerListUrl = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/fetchLocationUsers`;

    const [filters, setFilters] = useState({
        'searchBy': '',
        'searchValue': '',
        'status': '',
        'role': 'teller',
        'per_page': 10
    });

    /**
     * Teller access request management
     */
    const account_statuses = ['active', 'inactive']
    const [changeParams, setChangeParams] = useState({
        'teller_id': '',
        'new_account_status': '',
        'reason': ''
    })

    const showModal = () => {
        const modalEle = usrCtrl.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        bsModal.show()
    }

    const hideModal = () => {
        const modalEle = usrCtrl.current
        const bsModal = Modal.getInstance(modalEle)
        bsModal.hide()
    }

    const handleInput = (e) => {
        e.persist();
        setFilters(prevFi => {
            let updatedFilters = {...prevFi, [e.target.name]: e.target.value}
            return updatedFilters
        })
    }

    const processSearchAction = (e) => {
        e.preventDefault();

        console.log("processing filters", filters);

        fetchLocationTellers(tellerListUrl, filters).then(res => {
            let resData = res?.data;
            if(res.status === 1){
                // update the listing table
                updateTellerTable(resData)
            }
        })
    }

    const resetSearch = (e) => {
        setFilters(prevFilters => {
            for(let key in prevFilters){
                if(key === 'role') continue;
                prevFilters[key] = ''
            }

            // refresh the form
            fetchLocationTellers(tellerListUrl, filters).then(res => {
                let resData = res?.data;
                if(res.status === 1){
                    // update the listing table
                    updateTellerTable(resData)
                }
            })

            return prevFilters
        });
    }

    const handleChangeInput = (e) => {
        e.persist();
        setChangeParams(prevParams => {
            let updatedParams = {...prevParams, [e.target.name]: e.target.value}
            return updatedParams;
        })
    }

    const handleTellerAccessCtrl = (newStatus, tellerId) => {
        // update the change form params
        let invertedStatus = account_statuses.filter(val => val != newStatus)[0]
        setChangeParams(currentVals => {
            return {...currentVals, ['teller_id']: tellerId, ['new_account_status']: invertedStatus}
        });

        // display the modal
        showModal();
    }

    const changeTellerStatus = () => {
        dispatch(showLoading());

        // initialize the url
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/changeTellerAccess`;

        // make the request to update the status
        axios({
            method: "post",
            url: url,
            data: changeParams,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // the update was successful, update the table
                fetchLocationTellers(tellerListUrl, filters).then(res => {
                    let resData = res?.data;
                    if(res.status === 1){
                        // update the listing table
                        updateTellerTable(resData)
                    }
                });

                dispatch(showToast(["success", "Teller Access Update", msg]));
                hideModal();
            }
            else 
            {
                const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
                dispatch(showToast(["danger", "Request Error", errorMsg]))
                hideModal()
            }

        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
            return false
        })
    }

    function updateTellerTable(locationTellers)
    {
        let listing = "";
        let listingData = locationTellers.data;
        if(listingData && listingData.length)
        {
            listing = listingData.map((val, indx) => (
                <tr key={indx}>
                    <td>{locationTellers.from + indx}</td>
                    <td>{`${val?.first_name} ${val?.last_name}`}</td>
                    <td>{val.role}</td>
                    <td>{val.username}</td>
                    <td>{val.email}</td>
                    <td>{val.phone}</td>
                    <td>{val.status}</td>
                    <td>{val.created_at}</td>
                    <td>
                        <button type='button' className="btn btn-sm btn-success mx-1"
                        hidden={val.status == 'active' ? 'hidden' : ''}
                        onClick={() => handleTellerAccessCtrl(val.status, val.id)}>
                            {t('BTN_ACTIVATE')}
                        </button>

                        <button type='button' className="btn btn-sm btn-danger"
                        hidden={val.status == 'active' ? '' : 'hidden'}
                        onClick={() => handleTellerAccessCtrl(val.status, val.id)}>
                            {t('BTN_DEACTIVATE')}
                        </button>
                    </td>
                </tr>
            ));
        }

        // set the number of pages
        let tmp_total = locationTellers?.total;
        let perPage = locationTellers?.per_page;
        let currentPage = locationTellers?.current_page;
        let total_pages = tmp_total > perPage ? Math.round(tmp_total / perPage) : 1;
        let tmp_pages = [];
        for (let i = 1; i <= total_pages; i++) {
            tmp_pages.push(i);
        }

        var pageStr = tmp_pages.map((val, indx) => (
            <li key={indx} className={val === currentPage ? 'page-item active' : 'page-item'}>
                <a href='#' className='page-link'>{val}</a>
            </li>
        ))

        console.log(listing);
        setTellerList(listing)
        setFromPage(locationTellers?.from)
        setCurrentPage(currentPage)
        setToPage(locationTellers?.to)
        setTotalEntries(tmp_total)
        setPages(pageStr)
    }

    useEffect(() => {
        // fetch all the data for listing
        let url = tellerListUrl;

        fetchLocationTellers(url, filters).then(res => {
            let resData = res?.data;
            console.log(resData);
            if(res.status === 1){
                // update the listing table
                updateTellerTable(resData)
            }
        })
    }, [])

    async function fetchLocationTellers(url, payload)
    {
        // start the loading activity
        dispatch(showLoading());

        // cal the api
        return axios({
            method: "post",
            url: url,
            data: payload,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // show the success page
                return res.data;
            }
            
            const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
            dispatch(showToast(["danger", "Request Error", errorMsg]))

            return false;
        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
            return false
        })
    }

  return (
    <>
        <form id="my-form">
            <div className="form-group row ">
                <div className="col-md-3 md:mt-2">
                    <label className="" htmlFor="search_by">
                        {t("SEARCH_BY")}
                    </label>
                    <select
                        className="form-select"
                        onChange={handleInput}
                        name="searchBy"
                        id="searchBy"
                        aria-label="Default select example"
                        value={filters.searchBy}
                    >
                        <option value="">{t("SELECT")}</option>
                        <option value="full_name">{t('firstName')}</option>
                        <option value="username">{t('USERNAME')}</option>
                        <option value="email">{t('EMAIL_ADDRESS')}</option>
                        <option value="phone_no">{t('PHONE_NUMBER')}</option>
                    </select>
                </div>

                <div className="col-12 col-md-6">
                    <label className="" htmlFor="search_value">
                        {t("SEARCH_VALUE")}
                    </label>
                    <input type="text" name="searchValue" id="searchValue" className="form-control" 
                    value={filters.searchValue} onChange={handleInput}/>
                </div>

            </div>

            <div className="form-group row mt-2">
                <div className="col-md-3">
                    <label className="" htmlFor="status">
                        {t("STATUS")}
                    </label>
                    <select
                        className="form-select"
                        onChange={handleInput}
                        value={filters.status}
                        name="status"
                        id="status"
                        aria-label="Default select example"
                    >
                        <option value={""}>{t("SELECT_A_STATUS")}</option>
                        {
                            account_statuses.map((tmpStatus, indx) => (
                                <option key={indx} value={tmpStatus}>{tmpStatus}</option>
                            ))
                        }
                    </select>
                </div>

            </div>

            <div className="form-group row justify-content-md-center mt-3 mb-5">
                <button
                type="submit"
                onClick={processSearchAction}
                className="col-sm-2 btn btn-primary"
                >
                {t("SEARCH")}
                </button>
                &nbsp;
                <button
                type="reset"
                onClick={resetSearch}
                className="col-sm-2 btn btn-secondary"
                id="apply-filter-clear-paid"
                >
                {t("CLEAR_SEARCH")}
                </button>
            </div>

            <div className="per_page m-2">
                <CustomPaginateOption
                per_page= "10"
                handleInput={handleInput}
                total="20"
                />
            </div>
        </form>

        <table
        id="datatable"
        className="table table-bordered table-hover grid-table responsive nowrap w-100"
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>{t("FULL_NAME")}</th>
                    <th>{t("ROLE")}</th>
                    <th>{t("USERNAME")}</th>
                    <th>{t("EMAIL_ADDRESS")}</th>
                    <th>{t("phoneNumber")}</th>
                    <th>{t("STATUS")}</th>
                    <th>{t("DATE")}</th>
                    <th>{t("Action")}</th>
                </tr>
            </thead>
            <tbody>{tellerListing}</tbody>
        </table>

        {/* pagination  */}
        <div className="border-top bg-light p-1 d-flex justify-content-between">
            <div>
                {t('SHOWING')} {fromPage} {t('TO')} {toPage} {t('OF')} {totalEntries} {t('ENTRIES')}
            </div>

            <div className="pagination pagination-sm">
                <li className='page-item'>
                    <a href="#" className="page-link">
                        <i className="bx bx-chevrons-left"></i>
                    </a>
                </li>
                {pages}
                <li className='page-item'>
                    <a href="#" className="page-link">
                        <i className="bx bx-chevrons-right"></i>
                    </a>
                </li>
            </div>
        </div>

        {/* teller status change modal */}
        <div className="modal fade" ref={usrCtrl} id='teller_access_ctrl'>
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title">{t('TELLER_ACCESS_MNG_FORM')}</h5>
                        <button className="btn-close" onClick={hideModal}></button>
                    </div>

                    <div className="modal-body">
                        <form id='accessCtrlForm'>

                            <div className="mb-2">
                                <label htmlFor="new_account_status">{t('LBL_NEW_STATUS')}</label>
                                <select name="new_account_status" id="new_account_status" className="form-control"
                                value={changeParams.new_account_status} onChange={handleChangeInput} disabled>
                                    <option value="active">{t('BTN_ACTIVATE')}</option>
                                    <option value="inactive">{t('BTN_DEACTIVATE')}</option>
                                </select>
                            </div>

                            <div className="mb-2">
                                <label htmlFor="reason">{t('REASON')}</label>
                                <textarea name="reason" id="reason" className="form-control"
                                cols={30} rows={5} value={changeParams.reason} onChange={handleChangeInput}></textarea>
                            </div>

                            <div className="mb-2">
                                <button type='button' className="btn btn-sm btn-primary me-2" id="statusUpdateBtn"
                                onClick={changeTellerStatus}>
                                    {t('SUBMIT')}
                                </button>
                                <button type='button' className="btn btn-sm btn-secondary"
                                onClick={hideModal}>
                                    {t('CANCEL')}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
        
    </>
  )
}
