import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { showToast } from "../../slices/toast-slice";
import CustomDuration from "../CustomDuration";
import CustomPaginateOption from "../CustomPaginateOption";
import { Link, useNavigate } from "react-router-dom";
import { formatAmount } from "../../utils/helper";
import { FaArrowLeft } from "react-icons/fa";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const SubmitPaidTransation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const { t } = useTranslation();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

  const [idTypes, setIdTypes] = useState([]);
  const [issuingCountries, setIssuingCountries] = useState([]);
  const [companies,setCompanies] = useState([]);
  const [companyError, setCompanyError] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  const [enableSubmit, setEnableSubmit] = useState("");
  

  var schemaObject = {};


  if (companyCode == 4){
    schemaObject['pickup_code'] = yup.string().length(10, t("INVALID_CODE, 10_DIGIT_ONLY")).required(t("FIELD_REQUIRED"))
  }
  else  {
    schemaObject['pickup_code'] = yup.string().required(t("FIELD_REQUIRED"))
  }

 
  schemaObject["amount"] = yup.string().required(t("ERR_AMOUNT_REQUIRED"));
  schemaObject["id_type"] = yup.string()
  schemaObject["id_number"] = yup.string()
  schemaObject["issuing_country"] = yup.string()
  schemaObject["address"] = yup.string()
  schemaObject["notes"] = yup.string()

  const schema = yup.object().shape(schemaObject);
  var {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    fetchIDTypes();
    fetchIssuingCountries();
    fetchInfo();
  }, []);

  const fetchIDTypes = () => {
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + " " + authUser().token,
    };

    axios
      .get(process.env.REACT_APP_BNB_BASE_URL + "/api/guest/web/govtIdTypes", {
        headers,
      })
      .then((res) => {
        dispatch(hideLoading());
        setIdTypes(res.data.data.id_type_list);
      });
  };

  const fetchInfo = () => {
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + " " + authUser().token,
    };

    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + "/api/portal/getInfoForSubmitPaid",
        {},
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading());
        setIssuingCountries(res.data.data.idIssuingCountries);
        setCompanies(res.data.data.agentCompanies)
        setEnableSubmit(res.data.data.agent_country.enable_submitPaid)
      });
  };

  const fetchIssuingCountries = () => {
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + " " + authUser().token,
    };

    axios
      .get(
        process.env.REACT_APP_BNB_BASE_URL + "/api/portal/idIssueingCountries",
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading());
        setIssuingCountries(res.data.data.idIssuingCountries);
      
      });
  };

  const onCompanySelect = (e) => {
    setCompanyCode(e.currentTarget.id);
    console.log(e.currentTarget.id)
    setCompanyError(false)
      const allWithClass = Array.from(
        e.currentTarget.parentElement.getElementsByClassName("card")
      );
      allWithClass.forEach((element) => {
        element.classList.remove("sCompany");
      });
      e.currentTarget.classList.add("sCompany");
    };

  const onSaveTransaction = (data) => {
    console.log("globalAuthUser",globalAuthUser)
    // Swal.fire({
    //   title: t('CONFIRMATION'),
    //         confirmButtonText: t('CONFIRM'),
    //         confirmButtonColor: '#0d6efd',
    //         cancelButtonText: t('CANCEL'),
    //         showCancelButton: true,
    //         html: t('CASHIN_CONFIRMATION', {pickup_code:data.pickup_code +'' , amount: data.amount+' '+globalAuthUser?.location.currency}),
    // }).then ((result) => {
      if (companyCode === ""){
        setCompanyError(true)
        return;
      }

      // if (result.value !== undefined && result.value === true) {
      let params = {}
      params.code = data.pickup_code;
      params.mamount = data.amount;
      params.government_id_type = data.id_type;
      params.government_id_no = data.id_number;
      params.id_issuing_country = data.issuing_country;
      params.notes = data.notes;
      params.address = data.address;
      params.company = companyCode;
  
      const headers = {
        Authorization: authUser().tokenType + " " + authUser().token,
      };
      axios.post(
        process.env.REACT_APP_BNB_BASE_URL + "/api/portal/submitPaidTransaction",params,{headers}
      )
        .then((res) => {
          console.log('response',res.data)
          if (res && res.data.status == 1){
            dispatch( showToast(["success", t("SUCCESS"), t("SUBMIT_NEW_PAID_TRANSACTION_SUCCESS")])); 
            setTimeout(() => {
                navigate('/paid-transactions');
            }, 1000);  
          }else if (res && res.data.status === 0){
            var message = res.data.message
            dispatch( showToast(["danger", t("INFORMATION"), t(message)]));  
          }
          
          else {
            dispatch( showToast(["danger", "ERROR", t(res.data.message)]))
          }
  
          
        
        
        dispatch(hideLoading());
      }).catch(error => {
        console.log("error",error)
        dispatch(hideLoading());
        dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")], ));
      });
    //   }
    // }
    // )
   
  };

  return (
    <React.Fragment>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="col-sm-4 mt-2">
              {t("SUBMIT_NEW_PAID_TRANSACTION")}
            </h5>
            <span>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate("/home")}
              >
                <FaArrowLeft size={18} fill={"white"} /> &nbsp;
                {t("BACK")}
              </button>
            </span>
          </div>
          <div className="card-body mt-4">
            <form id="my-form" onSubmit={handleSubmit(onSaveTransaction)}>
            <div className="form-group row justify-content-center">
    { 
                companies && companies?.map((companies, index) => {
                
                        return (
                          <div id={companies.id} key={index} className="card col-6 col-md-2 company"
                          style={{ marginRight: "15px", marginBottom: "15px", cursor: "pointer"}} onClick={onCompanySelect} >
                         <div style={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px", margin:"auto"}}>
                             <div style={{ maxWidth: "100px", maxHeight: "100px", margin: "auto"}}  >
                                 <img src={process.env.REACT_APP_BNB_BASE_URL + "/" + companies.logo} className="card-img-top" alt="..."  />
                             </div>
                            
                         </div>
                        

                     </div>
                        )

                        
                    
                })
                
            }
            {companyError && (  <p className="input-error-msg text-center mb-2"> {t("ERR_COMPANY_REQUIRED")}</p>)}
    </div>
              <div className="form-group row">
                <div className="col-md-6 mb-3">
                  <label className="" htmlFor="pickup_code">
                    {t("PICKUP_CODE")} <span className="text-danger">*</span>
                  </label>
                   {(companyCode == '5')? 
                       ( <input type="text" id="pickup_code" className='form-control' {...register("pickup_code")} />) :(<input type="number" id="pickup_code" className='form-control' {...register("pickup_code")} />)
                      } 
                        
                   <p className="input-error-msg"> {errors.pickup_code?.message} </p>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="" htmlFor="amount">
                    {t("AMOUNT")} <span className="text-danger">*</span>
                  </label>
                  
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    className="form-control custom-field"
                    {...register("amount")}
                  />
                   <p className="input-error-msg"> {errors.amount?.message} </p>
                </div>

              </div>

              <div className="form-group row">
                <div className="col-md-4 mb-4 ">
                  <label className="" htmlFor="id_type">
                    {t("ID_TYPE")}
                  </label>
                  <select
                    className="form-select"
                    name="id_type"
                    id="id_type"
                    
                    aria-label="Default select example"
                    {...register("id_type")}
                  >
                    <option value={""}>{t("SELECT")}</option>
                    {idTypes.map((key, index) => (
                      <option key={index}>{key.type_description}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-4 ">
                  <label className="" htmlFor="id_number">
                    {t("ID_NUMBER")}
                  </label>
                  <input
                    type="number"
                    name="id_number"
                    id="id_number"
                    className="form-control custom-field"
                    {...register("id_number")}
                  />
                </div>
                <div className="col-md-4 mb-4 ">
                  <label className="" htmlFor="issuing_country">
                    {t("issuingCountry")}
                  </label>
                  <select
                    className="form-select"
                    name="issuing_country"
                    id="issuing_country"
                    
                    aria-label="Default select example"
                    {...register("issuing_country")}
                  >
                    <option value={""}>{t("SELECT")}</option>
                    {issuingCountries &&
                      Object.entries(issuingCountries).map(([code, name]) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="form-group-row">
                <div className="col-md-12 mb-4">
                  <label className="" htmlFor="address">
                    {t("ADDRESS")}
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="form-control custom-field"
                    {...register("address")}
                  />
                </div>
              </div>

              <div className="form-group-row">
                <div className="col-md-12 mb-4">
                  <label className="" htmlFor="notes">
                    {t("NOTES")}
                  </label>
                  <input
                    type="text"
                    name="notes"
                    id="notes"
                    className="form-control custom-field"
                    {...register("notes")}
                  />
                </div>
              </div>
              <div className="card-footer">
                <div className="form-group row justify-content-md-center mt-3">
                  
                  <button
                    type="submit"
                    className="col-sm-2 btn btn-secondary"
                    id="apply-filter-clear-paid"
                  >
                    {t("CANCEL")}
                  </button>
                  &nbsp;
                  <button
                    type="submit"
                    className="col-sm-2 btn btn-primary"
                    id="apply-filter-paid"
                  >
                    {t("SUBMIT")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubmitPaidTransation;
