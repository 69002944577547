import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import user from "../../assets/images/user.png";
import roleIcon from "../../assets/images/role-user.png";
import emailIcon from "../../assets/images/email-envelope.png";
import phoneIcon from "../../assets/images/phone-number.png";
import verifiedIcon from "../../assets/images/verified.png";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from "../../slices/toast-slice";

const UserProfile = () => {

    const { t } = useTranslation();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const authUser = useAuthUser();
    const [email, setEmail] = useState(null);
    const [role, setRole] = useState(null);
    const [phone, setPhone] = useState(null);
    const [verifiedAt, setVerifiedAt] = useState(null);
    const dispatch = useDispatch();
    const [signature, setSignature] = useState(null);
    const headers = {
        'Authorization': authUser().tokenType + ' ' + authUser().token
    };
    role === null ? dispatch(showLoading()) : dispatch(hideLoading());
    useEffect(() => {
        axios.get(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfile', { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data.data);
            if (response.data.status === 1) {
                setRole(response.data.data.role);
                setEmail(response.data.data.email);
                setPhone(response.data.data.phone);
                setSignature(response.data.data.signature);
                setVerifiedAt(response.data.data.email_verified_at);
            } else {
                //display the error alert
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
            
        })
        .catch(error => {
            console.error(error);
            dispatch(hideLoading());
            //display the error alert
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }, [])
        
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {globalAuthUser?.first_name} {globalAuthUser?.last_name}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/home" className="btn btn-primary btn-sm">
                                        {t("DASHBOARD")}
                                    </NavLink>
                                </div>
                            </h3>
                            
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-center">
                                        <img src={user} alt="user@email.com" width={150} height={150}/>
                                    </div>
                                </div>
                                <div className="col-md-8 mb-2">
                                    <div className="d-flex mb-2">
                                        <div className="mr-3">
                                            <img src={roleIcon} alt="" width={24} height={24} />
                                            <strong>{t('ROLE')}: </strong> {role}
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="mr-3">
                                            <img src={emailIcon} alt="" width={24} height={24} />
                                            <strong>{t('EMAIL')} :  <a href="mail:" style={{textDecoration: 'none'}}>{ email } </a>&nbsp; </strong>
                                            {
                                                (verifiedAt!= null && verifiedAt!=="-000001-11-30T05:00:00.000000Z") ? (
                                                    <span  datatoggle="tooltip" >
                                                        <img src={verifiedIcon} title="email validated" alt="" width={24} height={24} />    
                                                    </span>
                                                ) :
                                                (
                                                    <NavLink to="/verify-email" className="btn btn-danger btn-sm">
                                                        {t("VERIFY_YOUR_EMAIL")}
                                                    </NavLink>    
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="mr-3">
                                            <img src={phoneIcon} alt="" width={24} height={24} />
                                            <strong>{t('PHONE_NUMBER')} :  <a href="tel:" style={{textDecoration: 'none'}}>{phone}</a> </strong>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <NavLink to="/change-password" className="btn btn-primary btn-sm mt-3">
                                            { t("CHANGE_PASSWORD") }
                                        </NavLink> &nbsp;
                                        <NavLink to="/create-passcode" className="btn btn-success btn-sm mt-3">
                                            { signature === null ? t("ADD_A_PASSCODE") : t("UPDATE_A_PASSCODE")}
                                        </NavLink>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default UserProfile;