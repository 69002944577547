import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { setSEND_MONEY_OBJECT } from '../../../slices/send-money-ria-slice';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaArrowCircleRight, FaSearch, FaChevronDown } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import { formatNumber, formatNumberNoRound } from '../../../utils/helper';
import { useImmer } from 'use-immer';
import { showToast } from '../../../slices/toast-slice';
import ReactSelect from 'react-select';
import cloneDeep from 'lodash/cloneDeep';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


function BnbFees() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const codeTable = useSelector((state) => state.sendMoneyRia.codeTable);
    const [sendMoneyObject, setSendMoneyObject] = useStateCallback(SEND_MONEY_OBJECT);
    const selectInputRef = useRef();
    const [frontFile, setFrontFile] = useState();
    const [backFile, setBackFile] = useState();
    const [frontPreview, setFrontPreview] = useState()
    const [backPreview, setBackPreview] = useState()
    const navigate = useNavigate();

    console.log("sendMoneyObject in fees", sendMoneyObject)

    useEffect(() => {
        calcTxnAmount()
    }, [sendMoneyObject.feesTab.needToCalc]);

    // useEffect(() => {
    //     calcTxnAmount()
    // }, [sendMoneyObject.feesTab.sendAmount]);

    // useEffect(() => {
    //     calcTxnAmount()
    // }, [sendMoneyObject.feesTab.receiveAmount]);

    useEffect(() => {
        dispatch(setSEND_MONEY_OBJECT(sendMoneyObject))
    }, [sendMoneyObject]);

    useEffect(() => {
        if(sendMoneyObject.previousStep == 2) {
            document.getElementById('chFeesIncluded').checked = sendMoneyObject.feesTab.feesIncluded
        } else (
            console.log('not prev')
        )

    }, [sendMoneyObject.previousStep])

    useEffect(() => {
        console.log("in srecv eff")
        let sObject = cloneDeep(sendMoneyObject)
        if(sObject?.feesTab?.sReceiveCountry?.country_code == 'SEN' || sObject?.feesTab?.sReceiveCountry?.country_code == 'CIV' || sObject?.feesTab?.sReceiveCountry?.country_code == 'MRT') {
            let mwOption = sObject.feesTab.deliveryMethods.find(obj => obj.code == 'MW')
            console.log("in srecv eff not null")
            if(mwOption != null) {
                if (['SEN', 'CIV'].includes(sObject?.feesTab?.sReceiveCountry?.country_code)){
                    sObject.feesTab.deliveryMethods = sObject.feesTab.deliveryMethods.filter(obj => obj.code != 'WAVE')
                    let nWave = { code: 'WAVE', name: 'Wave Wallet', fees: mwOption.fees }
                    sObject.feesTab.deliveryMethods.push(nWave)
                } else if (['MRT'].includes(sObject?.feesTab?.sReceiveCountry?.country_code)){
                    if (sObject?.feesTab?.sReceiveCountry?.mw_desposit_companies && sObject?.feesTab?.sReceiveCountry?.mw_desposit_companies?.length != 0){
                        sObject?.feesTab?.sReceiveCountry?.mw_desposit_companies.forEach(company => {
                            if (!sObject.feesTab.deliveryMethods.some(dm => dm.code == company.key)){
                                sObject.feesTab.deliveryMethods.push({
                                    code: company.key,
                                    name: company.label,
                                    fees: mwOption.fees
                                });
                            }
                        });
                    }
                }
                setSendMoneyObject(prev => {
                    prev.feesTab.deliveryMethods = sObject.feesTab.deliveryMethods
                })
            }
        }
    }, [sendMoneyObject.feesTab?.sReceiveCountry]);

    const formatOptionLabel = ({ value, label, customAbbreviation }) => (
        <div style={{ display: "flex" }}>
            <div>{label}</div>
            <div style={{ marginLeft: "10px", color: "#ccc" }}>
                {customAbbreviation}
            </div>
        </div>
    );

    function sSendCountry() {
        let country = sendMoneyObject.countryTable.countries.send[0];
        return country;
    }

    function sReceiveCountry() {
        let country = sendMoneyObject.countryTable.countries.receive;
        return country;
    }

    function getDeliveryMethods(receiveCountry) {
        let mArrays = [];

        let receivingOptions = sSendCountry().fees.receiving_options

        Object.keys(receivingOptions)?.forEach((option) => {
            if(receivingOptions[option]?.receiving_countries != undefined || receivingOptions[option]?.receiving_countries != null) {
                Object.keys(receivingOptions[option]?.receiving_countries)?.forEach((country) => {
                    if (country == receiveCountry.country_code) {
                        Object.keys(receivingOptions[option].receiving_countries[country])?.forEach((currency) => {
                            if (currency == receiveCountry.currency_code) {
                                let myObject = {}
                                myObject.name = receivingOptions[option].receiving_option_name
                                myObject.code = receivingOptions[option].receiving_option_code
                                myObject.fees = receivingOptions[option].receiving_countries[country][currency]
                                mArrays.push(myObject)
                            }
                        })
                    }
                })
            }
        });

        return mArrays
    }

    function processAmount(sObject) {
        // let feesResult = sObject.feesTab?.feesResult
        let feesResult = {}
        feesResult.success = false
        feesResult.message =""
        feesResult.data = {}
        console.log("in if 0")
        console.log("sObject.feesTab.sendAmount ", sObject.feesTab.sendAmount)
        console.log("sObject.feesTab.receiveAmount ", sObject.feesTab.receiveAmount)
        console.log("sObject.feesTab.sDeliveryMethod ", sObject.feesTab.sDeliveryMethod)
        if (sObject.feesTab.sendAmount != undefined && sObject.feesTab.sendAmount != "")
        {
            console.log("if amount")
        }
        if (sObject.feesTab.sendAmount != undefined && sObject.feesTab.sendAmount != "" 
            && sObject.feesTab.receiveAmount != undefined && sObject.feesTab.receiveAmount != ""
            && sObject.feesTab.sDeliveryMethod != undefined 
            ) {
                console.log("sDeliveryMethod",sObject.feesTab.sDeliveryMethod)
                console.log("in if 1")
            if(sObject.feesTab.sendAmount < sObject.feesTab.sDeliveryMethod.fees.min_fee_amount) {
                feesResult.message = t("SEND_AMOUNT_IS_LESS_THAN_THE_MINIMUM", { min: formatNumberNoRound(sObject.feesTab.sDeliveryMethod.fees.min_fee_amount) })
                console.log(feesResult.message )
                return feesResult
            }
            if(sObject.feesTab.sendAmount > sObject.feesTab.sDeliveryMethod.fees.max_fee_amount) {
                feesResult.message = t("SEND_AMOUNT_IS_OVER_THE_LIMIT", { max: formatNumberNoRound(sObject.feesTab.sDeliveryMethod.fees.max_fee_amount) })
                console.log(feesResult.message )
                return feesResult
            }
            // feesResult.message = "fees not found"
            let feeRanges = sObject.feesTab.sDeliveryMethod.fees.fee_range
            console.log("feerange", feeRanges)
            Object.keys(feeRanges)?.forEach((feeRange) => {
                feesResult.message = t("FEE_NOT_FOUND") 
                let feeRangeValue = feeRanges[feeRange]
                if(sObject.feesTab.sendAmount >= feeRangeValue.min_amount && sObject.feesTab.sendAmount <= feeRangeValue.max_amount ) {
                    let fees = (feeRangeValue.fee_type == 1) ? feeRangeValue.fee_value : ((sObject.feesTab.sendAmount * feeRangeValue.fee_value) / 100)
                    if(feeRangeValue.fee_type == 2) {
                        if(fees < feeRangeValue.min_fee) fees = feeRangeValue.min_fee
                        if(fees > feeRangeValue.max_fee) fees = feeRangeValue.max_fee
                    }
                    let tax = 0
                    sSendCountry().taxes?.forEach((taxLine) => {
                        console.log('in calx tax', taxLine)
                        if(taxLine.base == 'amount') {
                            console.log('in calx tax amount', taxLine)

                            tax = tax + ((taxLine.value * sObject.feesTab.sendAmount) / 100)
                            console.log('in calx tax amount', ((taxLine.value * sObject.feesTab.sendAmount) / 100))

                        } else {
                            console.log('in calx tax fee', taxLine)

                            tax = tax + ((taxLine.value * fees) / 100)
                            console.log('in calx tax fee', ((taxLine.value * fees) / 100))
                        }
                    })
                    feesResult.success = true
                    feesResult.message = "fees found"
                    feesResult.data.toSend = sObject.feesTab.sendAmount
                    feesResult.data.fees = fees
                    feesResult.data.tax = tax
                    feesResult.data.totalToPay = sObject.feesTab.sendAmount + fees + tax
                    return feesResult
                }
            });
        } else {
            console.log("in else")
        }
        return feesResult
    }

    function sendingAmountChange(amount, mObject) 
    {
        // if(sendMoneyObject.feesTab.sReceiveCountry == null || sendMoneyObject.feesTab.sReceiveCountry == undefined ) return
        if (sendMoneyObject.feesTab.rate == null || sendMoneyObject.feesTab.rate == undefined) {
            setSendMoneyObject(prev => {
                prev.feesTab.sendAmount = amount
            })
        } else {
            // console.log("onSendAmountChange", e)
            let receiveAmount = amount * sendMoneyObject.feesTab.rate
            // if (receiveAmount == 0) receiveAmount = ""
            document.getElementById('receiveAmount').value = receiveAmount
            mObject.feesTab.sendAmount = amount
            mObject.feesTab.receiveAmount = receiveAmount

            let feesResult = processAmount(mObject)
            console.log("feesResult onSendAmountChange", feesResult)

            mObject.feesTab.feesResult = feesResult

            setSendMoneyObject(mObject)
            console.log("receiveAmount", receiveAmount)
        }
    }

    function calcTxnAmount() 
    {
        console.log('calcTxnAmount inside sendMoneyObject', sendMoneyObject);

        if(sendMoneyObject.feesTab.sReceiveCountry != undefined && sendMoneyObject.feesTab.sDeliveryMethod != undefined && (sendMoneyObject.feesTab.sendAmount != undefined || sendMoneyObject.feesTab.receiveAmount != undefined)) {
            let params = {
                sending_country_code: sSendCountry().country_code,
                receiving_country_code: sendMoneyObject.feesTab.sReceiveCountry.country_code,
                receiving_option: sendMoneyObject.feesTab.sDeliveryMethod.code,
                amount: sendMoneyObject.feesTab.sendAmount,
                // amount: (sendMoneyObject.feesTab.feesIncluded != undefined && sendMoneyObject.feesTab.feesIncluded == true)  ? sendMoneyObject.feesTab.feesResult.data.totalToPay : sendMoneyObject.feesTab.sendAmount,
                fees_included: (sendMoneyObject.feesTab.feesIncluded != undefined && sendMoneyObject.feesTab.feesIncluded == true)  ? true : false,
                // fees_included: false,
                sending_currency_code: sSendCountry().currency_code,
                receiving_currency_code: sendMoneyObject.feesTab.sReceiveCountry.currency_code,
            }
            console.log('calcTxnAmount params', params);

            axios.post(
                process.env.REACT_APP_BNB_BASE_URL + '/api/portal/calcTxnAmounts', params, {
                withCredentials: false,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: authUser().tokenType + " " + authUser().token,
                }
            }).then(res => {
                console.log('res calcTxnAmount', res);
                let resp = res.data;
                console.log('resp calcTxnAmount', resp);
                if(resp.success == true) {
                    if(sendMoneyObject.feesTab.updatedFrom != 'onSendAmountChange') {
                        document.getElementById('sendAmount').value = formatNumberNoRound(resp.sending_amount,2).replaceAll(",", '')
                    }
                    if(sendMoneyObject.feesTab.updatedFrom != 'onReceiveAmountChange') {
                        document.getElementById('receiveAmount').value = formatNumberNoRound(resp.receive_amount,2).replaceAll(",", '')
                    }

                    setSendMoneyObject(prev => {
                        prev.feesTab.receiveAmount = resp.receive_amount
                        prev.feesTab.sendAmount = resp.sending_amount
                        prev.feesTab.feesResult = {}
                        prev.feesTab.feesResult.success = true
                        prev.feesTab.feesResult.data = {}
                        prev.feesTab.feesResult.data.toSend = resp.sending_amount
                        prev.feesTab.feesResult.data.fees = resp.sending_fees
                        prev.feesTab.feesResult.data.tax = resp.sending_taxes
                        prev.feesTab.feesResult.data.totalToPay = resp.total_amount
                        // prev.feesTab.rate = resp.exchange_rate
                    })
                } 
                else {
                    setSendMoneyObject(prev => {
                        // prev.feesTab.receiveAmount = resp.receive_amount
                        // prev.feesTab.sendAmount = resp.sending_amount
                        prev.feesTab.feesResult = {}
                        // prev.feesTab.feesResult.success = true
                        // prev.feesTab.feesResult.data = {}
                        // prev.feesTab.feesResult.data.toSend = resp.sending_amount
                        // prev.feesTab.feesResult.data.fees = resp.sending_fees
                        // prev.feesTab.feesResult.data.tax = resp.sending_taxes
                        // prev.feesTab.feesResult.data.totalToPay = resp.total_amount
                        // prev.feesTab.rate = resp.exchange_rate
                    })
                }
            });
        }

        
    }

    function clearIncludeFeeBox()
    {
        // if(sendMoneyObject?.feesTab?.feesIncluded) {
            console.log('in fee')
            // sendMoneyObject.feesTab.feesIncluded = false
            let ch = document.getElementById('chFeesIncluded');
            if(ch != null)  document.getElementById('chFeesIncluded').checked = false
        // }
    }
    const onSendAmountChange = (e) => {
        let amount = Number(e.target.value)
        clearIncludeFeeBox()

        if(sendMoneyObject?.feesTab?.rate != undefined && sendMoneyObject?.feesTab?.rate != null) {
            let receiveAmount = amount * sendMoneyObject?.feesTab?.rate
            document.getElementById('receiveAmount').value = formatNumberNoRound(receiveAmount,2).replaceAll(",", '')

            setSendMoneyObject(prev => {
                prev.feesTab.sendAmount = amount
                prev.feesTab.receiveAmount = receiveAmount
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onSendAmountChange'
                prev.feesTab.feesIncluded = false

            })
        } else {
            setSendMoneyObject(prev => {
                prev.feesTab.sendAmount = amount
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onSendAmountChange'
                prev.feesTab.feesIncluded = false

            })
        }

        

        // let mObject = cloneDeep(sendMoneyObject)
        // clearIncludeFeeBox(mObject)
        // sendingAmountChange(amount, mObject)
        
    }

    function getFxRate(recvCountry) {
        var exchangeRate = 0;
        var result = sSendCountry().exchange.find(item => item.currency === recvCountry.currency_code &&  item.country_code === recvCountry.country_code);
        // var result = sSendCountry().exchange.find(item => item.country_code === recvCountry.country_code);
        if(typeof (result) != "undefined") {
            exchangeRate = result.rate;
        } else {
            var result = sSendCountry().exchange.find(item => item.currency === recvCountry.currency_code);
            if(typeof (result) != "undefined") {
                exchangeRate = result.rate;
            } else if(sSendCountry().currency_code === recvCountry.currency_code) {
                exchangeRate = 1;
            }
            
            // if(sSendCountry().currency_code == recvCountry.currency_code) {
            //     var exchangeRate = 1;
            // } else {
            //     var result = sSendCountry().exchange.find(item => item.currency === recvCountry.currency_code);
            //     var exchangeRate = result.rate;
            // }
        }
        return exchangeRate;
    }

    const onReceiveCountryChange = (e) => 
    {
        console.log("onReceiveCountryChange", e)
        clearIncludeFeeBox()

        let ratee = getFxRate(e)
        console.log("onReceiveCountryChange ratee", ratee)

        let deliveryMethodss = getDeliveryMethods(e)

        if (ratee != undefined) {
            setSendMoneyObject(prev => {
                prev.feesTab.rate = ratee
                prev.feesTab.sReceiveCountry = e
                prev.feesTab.deliveryMethods = deliveryMethodss
                prev.sendingCurrency = sSendCountry().currency_code
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onReceiveCountryChange'
                prev.feesTab.feesIncluded = false

            })
            if(sendMoneyObject.feesTab.sendAmount != undefined) {
                let receiveAmount = sendMoneyObject.feesTab.sendAmount * ratee
                setSendMoneyObject(prev => {
                    prev.feesTab.receiveAmount = receiveAmount
                    prev.feesTab.rate = ratee
                    prev.feesTab.sReceiveCountry = e
                    prev.feesTab.deliveryMethods = deliveryMethodss
                    prev.sendingCurrency = sSendCountry().currency_code
                    prev.feesTab.needToCalc += 1
                    prev.feesTab.updatedFrom = 'onReceiveCountryChange'
                    prev.feesTab.feesIncluded = false

            })
            }
            else if(sendMoneyObject.feesTab.receiveAmountAmount != undefined) {
                let sendAmount = sendMoneyObject.feesTab.receiveAmount / ratee
                setSendMoneyObject(prev => {
                    prev.feesTab.sendAmount = sendAmount
                    prev.feesTab.rate = ratee
                    prev.feesTab.sReceiveCountry = e
                    prev.feesTab.deliveryMethods = deliveryMethodss
                    prev.sendingCurrency = sSendCountry().currency_code
                    prev.feesTab.needToCalc += 1
                    prev.feesTab.updatedFrom = 'onReceiveCountryChange'
                    prev.feesTab.feesIncluded = false

            })
            }
        }

        

        console.log("sendMoneyObject essss", sendMoneyObject)

        return;

        console.log("sSendAmount", sendMoneyObject.feesTab.sSendAmount)

        // if()


        let sObject = cloneDeep(sendMoneyObject)
        clearIncludeFeeBox(sObject)
        console.log("sSendAmount", sendMoneyObject.feesTab.sSendAmount)
        let deliveryMethods = getDeliveryMethods(e)
        sObject.feesTab.sDeliveryMethod = undefined
        // clear deivery select
        let selOption = document.getElementById('deliveryOption')
        if(selOption != null) selOption.selectedIndex = 0
        // let rate = sSendCountry().exchange[e.currency_code]
        let rate = getFxRate(e)
        if (rate == undefined) {
            document.getElementById('receiveAmount').value = ""
            sObject.feesTab.sumary = undefined
            sObject.feesTab.receiveAmount = undefined
            sObject.feesTab.rate = rate
            // setSendMoneyObject(prev => {
            //     prev.feesTab.sumary = undefined
            //     prev.feesTab.receiveAmount = undefined
            //     prev.feesTab.rate = rate
            // })
            return
        } else {
            console.log("receiveAmount", sendMoneyObject.feesTab.receiveAmount)
            if ((sendMoneyObject.feesTab.sendAmount == undefined || sendMoneyObject.feesTab.sendAmount == "") && (sendMoneyObject.feesTab.receiveAmount == undefined || sendMoneyObject.feesTab.receiveAmount == "")) {
                console.log("rec", 0)
                sObject.feesTab.sReceiveCountry = e
                sObject.feesTab.rate = rate
                sObject.feesTab.deliveryMethods = deliveryMethods
                // setSendMoneyObject(prev => {
                //     prev.feesTab.sReceiveCountry = e
                //     prev.feesTab.rate = rate
                //     prev.feesTab.deliveryMethods = deliveryMethods
                // })
            } else {
                console.log("rec", 1)

                if (sendMoneyObject.feesTab.sendAmount != undefined && sendMoneyObject.feesTab.sendAmount != "") {
                    console.log("rec", 2)
                    let receiveAmount = sendMoneyObject.feesTab.sendAmount * rate
                    console.log("receiveAmount", receiveAmount)
                    document.getElementById('receiveAmount').value = receiveAmount
                    sObject.feesTab.receiveAmount = receiveAmount
                } else {
                    console.log("rec", 3)
                    let sendAmount = sendMoneyObject.feesTab.receiveAmount / rate
                    document.getElementById('sendAmount').value = sendAmount
                    sObject.feesTab.sendAmount = sendAmount
                }
                sObject.feesTab.sReceiveCountry = e
                sObject.feesTab.rate = rate
                sObject.feesTab.deliveryMethods = deliveryMethods
                
                
                // setSendMoneyObject(prev => {
                //     prev.feesTab.sReceiveCountry = e
                //     prev.feesTab.rate = rate
                //     prev.feesTab.deliveryMethods = deliveryMethods
                // })
            }
        }

        let feesResult = processAmount(sObject)
        console.log("feesResult onReceiveCountryChange", feesResult)

        sObject.feesTab.feesResult = feesResult

        setSendMoneyObject(sObject)
    }

    const onReceiveAmountChange = (e) => {
        let receiveAmount = Number(e.target.value)
        clearIncludeFeeBox()
        // clearIncludeFeeBox()
        if (sendMoneyObject.feesTab.rate == null || sendMoneyObject.feesTab.rate == undefined) {
            setSendMoneyObject(prev => {
                prev.feesTab.receiveAmount = receiveAmount
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onReceiveAmountChange'
                prev.feesTab.feesIncluded = false

            })
        } else {
            let sendAmount = receiveAmount / sendMoneyObject.feesTab.rate
            document.getElementById('sendAmount').value = formatNumberNoRound(sendAmount,2).replaceAll(",", '')
            setSendMoneyObject(prev => {
                prev.feesTab.receiveAmount = receiveAmount
                prev.feesTab.sendAmount = sendAmount
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onReceiveAmountChange'
                prev.feesTab.feesIncluded = false

            })
        }

        return

        let sObject = cloneDeep(sendMoneyObject)
        clearIncludeFeeBox(sObject)
        if (sendMoneyObject.feesTab.rate == null || sendMoneyObject.feesTab.rate == undefined) {
            setSendMoneyObject(prev => {
                prev.feesTab.receiveAmount = receiveAmount
            })
        } else {
            console.log("onReceiveAmountChange", e)
            let sendAmount = (e.target.value) / sendMoneyObject.feesTab.rate
            if (sendAmount == 0) sendAmount = ""
            document.getElementById('sendAmount').value = sendAmount
            sObject.feesTab.receiveAmount = receiveAmount
            sObject.feesTab.sendAmount = sendAmount

            let feesResult = processAmount(sObject)
            console.log("feesResult onReceiveAmountChange", feesResult)

            sObject.feesTab.feesResult = feesResult

            setSendMoneyObject(sObject)
            // setSendMoneyObject(prev => {
            //     prev.feesTab.receiveAmount = e.target.value
            //     prev.feesTab.sendAmount = sendAmount
            // })
        }

    }

    const onDeliveryMethodChange = (e) => 
    {
        console.log("onDeliveryMethodChange", e)
        clearIncludeFeeBox()

        setSendMoneyObject(prev => {
            prev.feesTab.sDeliveryMethod = sendMoneyObject.feesTab.deliveryMethods.find(ob => ob.code == e.target.value)
            prev.feesTab.needToCalc += 1
            prev.feesTab.updatedFrom = 'onDeliveryMethodChange'
            prev.feesTab.feesIncluded = false


        })

        return

        let sObject = cloneDeep(sendMoneyObject)
        clearIncludeFeeBox(sObject)
        sObject.feesTab.sDeliveryMethod = sObject.feesTab.deliveryMethods.find(ob => ob.code == e.target.value)
        console.log("testvalue 1", sObject.feesTab.sDeliveryMethod)

        // const feeJsonText = '{ "min_country_amount": 100000, "max_country_amount": 45000000, "min_fee_amount": 0, "max_fee_amount": 100000001,"fee_range": { "10000001": { "fee_id": 120, "min_amount": 10000001,"max_amount": 30000001, "fee_value": 2,"fee_type": 2, "min_fee": 300000, "max_fee": 1000000},"30000001": {"fee_id": 121,"min_amount": 30000001, "max_amount": 100000001,"fee_value": 1,  "fee_type": 2,"min_fee": 600000,"max_fee": 2000000},"0.0": {"fee_id": 117,"min_amount": 0,"max_amount": 10000001,"fee_value": 3,"fee_type": 2, "min_fee": 50000,"max_fee": 10000000 } } }';
        // var canFee = JSON.parse(feeJsonText);
        // sObject.feesTab.sDeliveryMethod.fees = canFee;

        // console.log("testvalue 2", sObject.feesTab.sDeliveryMethod)

        let feesResult = processAmount(sObject)
        console.log("feesResult onDeliveryMethodChange", feesResult)

        sObject.feesTab.feesResult = feesResult
        sObject.sendingCurrency = sSendCountry().currency_code

        setSendMoneyObject(sObject)
    }

    function calcTax(fees) {
        let tax = 0
        sSendCountry().taxes?.forEach((taxLine) => {
            if(taxLine.base != 'amount') {
                tax = tax + ((taxLine.value * fees) / 100)
            }
        })
        return tax;
    }

    function calcTacP(range) {
        let tax = 0
        sSendCountry().taxes?.forEach((taxLine) => {
            if(taxLine.base != 'amount') {
                console.log('fee', taxLine.value / 100)
                console.log('taxline', taxLine.value / 100)
                tax = tax + ((range.fee_value / 100) * (taxLine.value / 100))
            }
        })
        return tax
    } 

    function minTotal(range) {
        if(range.fee_type == 1) {
            return range.min_amount + range.fee_value + calcTax(range.fee_value)
        } else {
            return range.min_amount + range.min_fee + calcTax(range.min_fee)
        }
    }

    function maxTotal(range) {
        if(range.fee_type == 1) {
            return range.max_amount + range.fee_value + calcTax(range.fee_value)
        } else {
            return range.max_amount + range.max_fee + calcTax(range.max_fee)
        }
    }

    const onFeesIncludeChange = (event) => 
    {
        if(event.target.checked)
        {
            setSendMoneyObject(prev => {
                prev.feesTab.feesIncluded = true
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onFeesIncludeChange'
            })
        } 
        else {
            setSendMoneyObject(prev => {
                prev.feesTab.feesIncluded = false
                prev.feesTab.sendAmount = sendMoneyObject.feesTab.feesResult.data.totalToPay
                prev.feesTab.needToCalc += 1
                prev.feesTab.updatedFrom = 'onFeesIncludeChange'
            })
        }

        return;
        console.log(event.target.checked)
        let sObject = cloneDeep(sendMoneyObject)

        if(event.target.checked) { 
            let feeRanges = sObject.feesTab.sDeliveryMethod.fees.fee_range;
            let totalAmount = sObject.feesTab.feesResult.data.toSend
            console.log('legnth', Object.keys(feeRanges))

            let amount = 0
            let feeFound = false;

            for(let i=Object.keys(feeRanges).length -1; i>=0; i--) 
            {
                console.log("round", i);

                let feeRangeRow = feeRanges[Object.keys(feeRanges)[i]]
                console.log("feeRangeRow", feeRangeRow)

                // if(totalAmount < minTotal(feeRangeRow)) {
                //     amount = totalAmount
                //     continue
                // }
                // if( totalAmount > maxTotal(feeRangeRow)) {
                //     amount = totalAmount
                //     continue
                // }

                console.log('amount 0', amount);

                amount = 0

                if(feeRangeRow.fee_type == 1) {
                    var amount1 = amount = totalAmount - feeRangeRow.fee_value - calcTax(feeRangeRow.fee_value)

                    var taxTTH = sSendCountry().taxes.find(item => item.base == 'amount1');
                    console.log('myyyyy taxTTH', taxTTH)
                    if(taxTTH != undefined) {
                        amount = amount1 / (1 + (taxTTH.value / 100));
                    }

                } 
                else {

                    var amount1 = amount = totalAmount / (1 + (feeRangeRow.fee_value / 100) +  calcTacP(feeRangeRow) )
                    console.log('amount 1', amount1);

                    var taxTTH = sSendCountry().taxes.find(item => item.base == 'amount1');
                    console.log('myyyyy taxTTH', taxTTH)

                    if(taxTTH != undefined) {
                        amount = amount1 / (1 + (taxTTH.value / 100));
                    }


                    if((amount * (feeRangeRow.fee_value / 100)) > feeRangeRow.max_fee) {
                        amount = totalAmount - feeRangeRow.max_fee - calcTax(feeRangeRow.max_fee)
                    }
                    else if((amount * (feeRangeRow.fee_value / 100)) < feeRangeRow.min_fee) {
                        amount = totalAmount - feeRangeRow.min_fee - calcTax(feeRangeRow.min_fee)
                    }
                    console.log('amount 2', typeof(amount));
                    console.log('feeRangeRow.min_amount 2', typeof(feeRangeRow.min_amount));
                }

                console.log('amount 3', amount);
                console.log('type of amount', amount);

                if(feeRangeRow.min_amount <= amount && amount <= feeRangeRow.max_amount ) {
                    console.log('amount 4', amount);
                    feeFound = true;
                    break;
                } 

                amount = 0;

                // if(amount > feeRangeRow.max_amount) {
                //     amount = feeRangeRow.max_amount
                // }

                // if(amount < feeRangeRow.min_amount) {
                //     amount = feeRangeRow.min_amount
                // }

                
                // break

            }

            if(!feeFound) {
                document.getElementById("chFeesIncluded").checked = false
                toast.error(t("CANNOT_INCLUDE_FEES"), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: 'colored'
                });
            }

            sObject.feesTab.feesIncluded = true
            sObject.feesTab.oldSendAmount = sObject.feesTab.sendAmount
            document.getElementById('sendAmount').value = amount
            sendingAmountChange(amount, sObject)
        }
        else {
            sObject.feesTab.feesIncluded = false
            sObject.feesTab.sendAmount = sObject.feesTab.oldSendAmount
            document.getElementById('sendAmount').value = sObject.feesTab.sendAmount
            sendingAmountChange(sObject.feesTab.sendAmount, sObject)
        }
    }

    const onNext = async () => {
        let isBalanceOk = false
        let balanceResp = await checkAgentBalance()
        console.log('in 1')
        if(globalAuthUser.location.max_credit_limit != null) {
            console.log('in 1')
            if(balanceResp != null && (Number(balanceResp.balance) + Number(globalAuthUser.location.max_credit_limit) >= Number(sendMoneyObject.feesTab.feesResult.data.totalToPay)) ) {
                isBalanceOk = true
                console.log('in 2')
            }
        } else if(globalAuthUser.location.bnb_owned != 1) {
            console.log('in 3')
            if(balanceResp != null && (Number(balanceResp.balance)  >= Number(sendMoneyObject.feesTab.feesResult.data.totalToPay)) ) {
                isBalanceOk = true
            }
        }

        if(isBalanceOk == false) {
            dispatch(
                showToast(["danger", "ERROR", t("INSUFFICIENT_BALANCE")])
            )
            return;
        } else {
            setSendMoneyObject((prev) => {
                prev.step = 2
            })
        }


    }

    // Handle file upload event and update state
    function handleChangeFront(event) {
        console.log(event.target.files[0])
        setFrontFile(event.target.files[0]);
    }

    // Handle file upload event and update state
    function handleChangeBack(event) {
        setBackFile(event.target.files[0]);
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        console.log(frontFile)
        if (!frontFile) {
            setFrontPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(frontFile)
        console.log(objectUrl)
        setFrontPreview(objectUrl)


        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [frontFile])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        console.log(frontFile)
        if (!backFile) {
            setBackPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(backFile)
        setBackPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [backFile])

    const onCouponApplyRemove = () => {
        if(sendMoneyObject.couponVerified) {
          document.getElementById("couponCode").value = ""
          setSendMoneyObject((prev) => {
            prev.couponVerified = false
            // prev.couponCodeAdded = !prev.couponCodeAdded
          })
        }
        else {
          let coupon = document.getElementById('couponCode').value
        if(coupon == undefined || coupon == "") {
          return
        }
        dispatch(showLoading());
        axios({
          method: "post",
          url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/verifySendMoneyCouponCode',
          withCredentials: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authUser().tokenType + " " + authUser().token,
          },
          data: {
            coupon_code : document.getElementById('couponCode').value,
            sending_country_code : sSendCountry().country_code,
            receiving_country_code : sendMoneyObject.feesTab.sReceiveCountry.country_code,
            sender_id : sendMoneyObject.senderTab.sender.id
          }
        })
          .then((res) => {
            dispatch(hideLoading());
            if (res.data.status == 1) {
              setSendMoneyObject(prev => {
                prev.couponVerified = true
                
              })
            } else {
              setSendMoneyObject(prev => {
                prev.couponVerified = false
              })
              dispatch(showToast(["danger", "Error", res.data.message]))
            }
          })
          .catch((error) => {
            dispatch(hideLoading());
            console.log(error)
            setSendMoneyObject(prev => {
              prev.couponVerified = false
            })
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
          })
        }
      }

      const onClose = () => {
        navigate("/sendTransaction")
      }

    return (
        <>
        <div style={{ maxWidth: "800px", margin: "auto" }}>
                    

            <div className='row' style={{ padding: "10px" }}>
                <div className='col-md-12'>
                    <label htmlFor='product'> { t("HOW_MUCH_WOULD_YOU_LIKE_TO_SEND") } <span style={{ color: "red" }}>*</span></label>
                    <div style={{ display: "block", padding: "8px 12px", border: "2px solid #ced4da", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                        <img src={sSendCountry().country_flag} style={{ maxWidth: "30px", maxHeight: "30px", marginRight: "15px" }} alt="..." />
                        <label htmlFor="">{sSendCountry().country_name + ' (' + sSendCountry().currency_code + ')'}</label>
                    </div>
                    <input type="text" className='form-control' id="sendAmount" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }} onChange={onSendAmountChange} 
                        onKeyDown={ (evt) => evt.key === "Backspace" || (isNaN(evt.key) && evt.preventDefault()) } defaultValue={formatNumberNoRound(sendMoneyObject.feesTab?.sendAmount,2).replaceAll(",", '')}  />
                </div>
            </div>
            <div className='row' style={{ padding: "10px" }}>
                <div className='col-md-12'>
                    <label htmlFor='product'> { t("YOU_RECIPIENT_WILL_RECEIVE") } <span style={{ color: "red" }}>*</span></label>
                    <ReactSelect
                        placeholder={ t("SELECT_THE_RECEIVING_COUNTRY") }
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: "2px solid #ced4da",
                                //   borderColor: state.isFocused ? 'grey' : 'red',
                                padding: '2px',
                                borderBottomLeftRadius: "0px",
                                borderBottomLeftRadius: "0px"
                            }),
                        }}
                        defaultValue={sendMoneyObject.feesTab?.sReceiveCountry}
                        // defaultValue={sReceiveCountry()[0]}
                        options={sReceiveCountry()}
                        formatOptionLabel={(country) => (
                            <div>
                                <img style={{ maxWidth: "30px", maxHeight: "30px", marginRight: "15px" }} src={country.country_flag} alt="" />
                                <span>{country.country_name + ' (' + country.currency_code + ')'}</span>
                            </div>
                        )}
                        getOptionValue={country => `${country.country_name}`}
                        getOptionLabel={country => `${country.country_name} ${country.currency_code}`}
                        onChange={onReceiveCountryChange}
                    />
                    <input type="mumber" className='form-control' id="receiveAmount" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }} onChange={onReceiveAmountChange} onKeyDown={ (evt) => evt.key === "Backspace" || (isNaN(evt.key) && evt.preventDefault()) } defaultValue={formatNumberNoRound(sendMoneyObject.feesTab?.receiveAmount,2).replaceAll(",", '')} />
                </div>
            </div>

            {
                sendMoneyObject?.feesTab?.rate != undefined ?
                    <div className='row' style={{ padding: "10px" }}>
                        <div className='col-md-12' style={{ textAlign: 'center', padding: "0px 10px" }}>
                            <label style={{ display: "block", padding: "5px 0", background: "#f4f4f4", borderRadius: "4px", color: "#333", fontWeight: "normal" }} htmlFor='product'> { t("EXCHANGE_RATE") }: 1 {sSendCountry().currency_code} = {sendMoneyObject?.feesTab?.rate + ' ' + sendMoneyObject?.feesTab?.sReceiveCountry?.currency_code} </label>
                        </div>
                    </div>
                    : (sendMoneyObject?.feesTab?.sReceiveCountry != undefined &&
                    <label htmlFor="">{ t("NO_RATE_FOUND") }</label>)
            }

            <>
                {
                    sendMoneyObject.feesTab.rate != undefined &&
                    <div className='row' style={{ padding: "10px" }}>

                        
                        <div className="col-md-12">
                            <label htmlFor='product'> { t("HOW_WILL_YOUR_RECIPIENT_BE_PAID") } <span style={{ color: "red" }}>*</span></label>
                            <select style={{ maxWidth: "100%" }} className={'form-select'} name="deliveryOption" id="deliveryOption" onChange={(e) => onDeliveryMethodChange(e)} defaultValue={sendMoneyObject.feesTab?.sDeliveryMethod?.code}>
                                <option value=""></option>
                                {
                                    sendMoneyObject?.feesTab?.deliveryMethods?.map((option, key) => (
                                        <option value={option.code} key={key}>{option.name}</option>
                                    ))
                                }
                            </select>
                            
                        </div>


                    </div>
                }
            </>


            <div className="row" style={{ padding: "0px", maxWidth: '500px', margin: 'auto' }}>
                {
                    sendMoneyObject?.feesTab?.feesResult?.success == true &&

                    <>
                        <div className='row' style={{ padding: "15px" }}>
                            <hr />
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name='chFeesIncluded' id='chFeesIncluded' onChange={onFeesIncludeChange}  />
                            <label className="form-check-label" htmlFor='chFeesIncluded'>
                            { t("FEES_INCLUDED") }
                            </label>
                        </div>

                        <ul className='list-group'>
                            <li className='list-group-item' >
                                <div className="row">
                                    <span className='col-md-6'> { t("AMOUNT_TO_SEND") } </span>
                                    <span className='col-md-6' style={{ textAlign: 'right' }}> {formatNumberNoRound(sendMoneyObject?.feesTab?.feesResult?.data?.toSend, 2) + ' ' + sendMoneyObject.sendingCurrency} </span>
                                </div>

                            </li>
                            <li className='list-group-item' >
                                <div className="row">
                                    <span className='col-md-6'> { t("FEES") } </span>
                                    <span className='col-md-6' style={{ textAlign: 'right' }}>{formatNumberNoRound(sendMoneyObject?.feesTab?.feesResult?.data?.fees, 2) + ' ' + sendMoneyObject.sendingCurrency}</span>
                                </div>
                            </li>
                            <li className='list-group-item' >
                                <div className="row">
                                    <span className='col-md-6'>{ t("TAX") }</span>
                                    <span className='col-md-6' style={{ textAlign: 'right' }}>{formatNumberNoRound(sendMoneyObject?.feesTab?.feesResult?.data?.tax, 2)  + ' ' + sendMoneyObject.sendingCurrency}</span>
                                </div>
                            </li>
                            <li className='list-group-item' >
                                <div className="row">
                                    <span className='col-md-6'> { t("TOTAL_AMOUNT") } </span>
                                    <span className='col-md-6' style={{ textAlign: 'right' }}>{formatNumberNoRound(sendMoneyObject?.feesTab?.feesResult?.data?.totalToPay, 2)  + ' ' + sendMoneyObject.sendingCurrency}</span>
                                </div>
                            </li>
                            <li className='list-group-item' >
                                <div className="row">
                                    <span className='col-md-6'> { t("RECEIVE_AMOUNT") } </span>
                                    <span className='col-md-6' style={{ textAlign: 'right' }}>{formatNumberNoRound(sendMoneyObject?.feesTab?.receiveAmount, 2)  + ' ' + sendMoneyObject?.feesTab?.sReceiveCountry?.currency_code}</span>
                                </div>
                            </li>
                        </ul>
                    </>

                    
                }
                {
                    sendMoneyObject?.feesTab?.feesResult?.success == false && sendMoneyObject?.feesTab?.feesResult?.message != '' &&
                    <div style={{ textAlign: "center"}}>
                        <span style={{ color: "red" }}>{sendMoneyObject?.feesTab?.feesResult?.message}</span>
                     </div>
                }
                
            </div>

            <div className='row' style={{ padding: "15px" }}>
                <hr style={{ margin: '0' }} />
            </div>

            <div className='row' style={{ padding: "2px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button className='btn btn-secondary' onClick={onClose}>{t("CLOSE")}</button>
                    <button className="btn btn-primary" disabled={sendMoneyObject?.feesTab?.feesResult?.success == undefined ||sendMoneyObject?.feesTab?.feesResult?.success == false} onClick={onNext}>{t("NEXT")}</button>
                </div>
            </div>

            <br /><br />

        </div>
        <ToastContainer />
        </>
        


    );



    function useStateCallback(initialState) {
        const [state, setState] = useImmer(initialState);
        const cbRef = useRef(null);

        const setStateCallback = useCallback((state, cb) => {
            cbRef.current = cb;
            setState(state);
        }, []);

        useEffect(() => {
            if (cbRef.current) {
                cbRef.current(state);
                cbRef.current = null;
            }
        }, [state]);

        return [state, setStateCallback];
    }

    async function checkAgentBalance() {
        dispatch(showLoading());
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/locationBalance",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': authUser().tokenType + ' ' + authUser().token
                }
            })
            console.log(res.data.data)
            dispatch(hideLoading());
            // return res;
            if (res.data.status == 1) {
               return res.data.data
            } else return null
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
            return null
        }
    }

}

export default BnbFees