import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SummaryReports = () => {
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header row">
          <h5 className="col-sm-4"> {t('DAILY COMMISSION REPORTS')}</h5>
                        <h4 className="col-sm-8">
                            <div className="card-toolbar float-end ">
                                <Link to="/home" className="btn btn-primary font-weight-bolder">{t('BACK')}</Link> 
                            </div>
                        </h4>
          </div>
          <div className="card-body">
             <div className="row">
          <div className="col-12 col-sm-3" style={{ marginBottom: "15px" }}>
            <div
              style={{
                background: '#63913a',
                color: "white",
                padding: "0",
                borderRadius: "5px",
              }}
            >
              <div className="card-body" style={{ padding: "15px" }}>
                <div
                  className="text-white"
                  onClick={() => navigate("/reports/agent-commission")}
                >
                  {" "}
                  {t("DAILY COMMISSION REPORTS")}{" "}
                </div>
              </div>
            </div>
          </div>
        
        <div className="col-12 col-sm-3" style={{ marginBottom: "15px" }}>
          <div
            style={{
              background: "#f64e60",
              color: "white",
              padding: "0",
              borderRadius: "5px",
            }}
          >
            <div className="card-body" style={{ padding: "15px" }}>
              <div
                className="text-white"
                onClick={() => navigate("/reports/company-reports")}
              >
                {" "}
                {t("DAILY_COMPANY_REPORTS")}{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-3" style={{ marginBottom: "15px" }}>
          <div
            style={{
              background: "#00a2b6",
              color: "white",
              padding: "0",
              borderRadius: "5px",
            }}
          >
            <div className="card-body" style={{ padding: "15px" }}>
              <div
                className="text-white"
                onClick={() => navigate("/reports/location-summary")}
              >
                {" "}
                {t("DAILY_LOCATION_SUMMARY")}{" "}
              </div>
            </div>
          </div>
        </div>
        </div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default SummaryReports;
