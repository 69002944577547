import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";

const ValidateRequest = () => {
  const dispatch = useDispatch();
  const { reference } = useParams();
  const authUser = useAuthUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const [data, setData] = useState({});
const [validation,setValidation] = useState([]);
const [pickupcode,setPickupcode] = useState('');
const [companyName,setCompanyName] = useState('');
const [receivingAmount,setReceivingAmount]=useState('')
const [beneficiaryAddress,setBeneficiaryAddresss]=useState('')
const [beneficiaryPhone,setBenficiaryPhone]=useState('')
const [status,setStatus] = useState("")
const [location,setLocation] = useState('')


  useEffect(() => {
    dispatch(showLoading());


    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL +
          "/api/portal/getValidationRequestDetails",
        { id: reference },
        {
          headers: {
            Authorization: authUser().tokenType + " " + authUser().token,
          },
        }
      )
      .then((res) => {
        setData(res.data.data)
        dispatch(hideLoading());
        setCompanyName(res.data.data.agent_company.name)
        setLocation(res.data.data.agent_location.name)
      });
  }, []);




  return (
    <React.Fragment>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between p-4">
            <div className="card-label">
              <h5> Validation Request Details</h5>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={() => {
                  navigate("/validateRequest");
                }}
                className="btn btn-primary"
              >
                {t('BACK')}
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-custom">
                  <div className="card-header">
                    <div className="card-title">
                      <h6 className="card-label">{t('VALIDATION_INFO')}</h6>
                    </div>
                    <div className="card-body">
                      <table id="datatable"  className="table-sm table-borderless">
                        <tbody>
                          <tr>
                            <td>{t('company')}</td>
                            <th>{companyName}</th>
                          </tr>
                          <tr>
                            <td>{t('PICKUP_CODE')}</td>
                            <th>{data.pickup_code}</th>
                          </tr>
                          <tr>
                            <td>{t('RECEIVING_AMOUNT')}</td>
                            <th>{data.receiving_amount}</th>
                          </tr>
                          <tr>
                            <td>{t("BENEFICIARY_PHONE")}</td>
                            <th>{data.receiver_mobile}</th>
                          </tr>
                          <tr>
                            <td>{t("BENEFICIARY_ADDRESS")}</td>
                            <th>{data.receiver_address}</th>
                          </tr>
                          <tr>
                            <td>{t("STATUS")}</td>
                            <th>{data.status}</th>
                          </tr>
                          <tr>
                            <td>{t('RECEIVED_AT')}</td>
                            <th>{data.created_at}</th>
                          </tr>
                          <tr>
                            <td>{t('PROCESSED_BY')}</td>
                            <th>{ data?.employee_user?.first_name}{" "}{data?.employee_user?.last_name}</th>
                          </tr>
                          <tr>
                            <td>{t('COMPLETED_AT')}</td> 
                            <th>{data.paid_at}</th>
                          </tr>
                          <tr>
                            <td>{t("NOTES")}</td> 
                            <th>{data.notes}</th>
                          </tr>

                        </tbody>
                          
                        
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card card-custom mt-2">
                  <div className="card-header">
                    <div className="card-title">
                      <h6 className="card-label">{t('LOCATION')}</h6>
                    </div>
                    <div className="card-body">
                      <table className="table-sm table-borderless">
                        <tbody>
                        <tr>
                            <td>{t("LOCATION")}</td>
                            <th>{location}</th>
                          </tr>
                          <tr>
                            <td>{t("USER")}</td>
                            <th>{data?.agent_location_user?.first_name }{" "}{data?.agent_location_user?.last_name}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-custom">
                  <div className="card-header">
                    <div className="card-title">
                      <h6 className="card-label">{t('ID_INFO')}</h6>
                    </div>
                    <div className="card-body">
                      <table className="table-sm table-borderless">
                      <tbody>
                          <tr>
                            <td>{t('ID_TYPE')}</td>
                            <th>{data.government_id_type}</th>
                          </tr>
                          <tr>
                            <td>{t('ID_NUMBER')}</td>
                            <th>{data.government_id_no}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card card-custom mt-2">
                  <div className="card-header">
                    <div className="card-title">
                      <h6 className="card-label">{t('GOV_ID')}</h6>
                    </div>
                    <div className="card-body">
                        <div className="col-12 col-md-6">
                            <p>Front</p><br></br>
                            <img style={{ marginTop: '10px' }} width="200px" alt="" src={data.government_id_photo} />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValidateRequest;
