import React from 'react'
import { useState } from 'react';
import { useId } from 'react'
import { useTranslation } from 'react-i18next';

export default function CustomerRegisterForm({ handleSubmit, phoneNumber }) {
    const id = useId();
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone_number: phoneNumber
    })

    const handleCancle = () => {
        console.log("cancelled");
    }

    const handleInputChange = (e) => {
        // get the input field
        let { name, value } = e.target;

        // update the formData field
        setFormData(tmpData => ({...tmpData, [name] : value}))
    }

    return (
        <>
            <div className="row mb-2 border-bottom">
                <div className="col-12 col-lg-2"></div>

                <div className="col-12 col-lg-8">
                    <form method="post" onSubmit={handleSubmit}>
                        {/** user firstname */}
                        <div className="mb-2">
                            <label htmlFor={ id + "first_name"}>{ t("firstName") }<span className="text-danger">*</span></label>
                            <input type="text" name="first_name" id={ id + "first_name"} className="form-control" 
                            value={formData.first_name} onChange={handleInputChange} required/>
                        </div>

                        {/** user lastname */}
                        <div className="mb-2">
                            <label htmlFor={ id + "last_name"}>{ t("lastName") }<span className="text-danger">*</span></label>
                            <input type="text" name="last_name" id={ id + "last_name"} className="form-control" 
                            value={formData.last_name} onChange={handleInputChange} required/>
                        </div>

                        {/** user phone number */}
                        <div className="mb-2">
                            <label htmlFor={ id + "phone_number"}>{ t("PHONE_NO") }<span className="text-danger">*</span></label>
                            <input type="text" name="phone_number" id={ id + "phone_number"} className="form-control" 
                            value={formData.phone_number} onChange={handleInputChange} required/>
                        </div>

                        {/** action buttons */}
                        <div className="mb-2 d-flex justify-content-end">
                            <button type='reset' className="btn btn-sm btn-secondary mx-2" onClick={handleCancle}
                            >
                                { t("CANCEL") }
                            </button>
                            <button type='submit' className="btn btn-primary btn-sm">
                                { t("SAVE") }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
