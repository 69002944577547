import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import {
    setFeeInfoState, setFieldsState, setReceiveCountryState, setSenderSchemaState, setReceiverSchemaState,
    setStep, setOnPrevious, setResetSend, setResetReceive, setCities, setConsumers, setRateState
} from '../../../slices/send-money-moneygram-slice';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { complianceFields, destinationFieldsIgnore, senderFieldsIgnore } from './moneygram';
import { FaArrowCircleRight, FaSearch, FaChevronDown } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function MoneygramFees() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const codeTable = useSelector((state) => state.sendMoneyMoneygram.codeTable);
    const feeInfoState = useSelector((state) => state.sendMoneyMoneygram.feeInfo);
    const onPrevious = useSelector((state) => state.sendMoneyMoneygram.onPrevious);
    const currencyRate = useSelector((state) => state.sendMoneyMoneygram.currencyRate);
    const [receivingCountries, setReceivingCountries] = useState([]);
    const [sendingCurrencies, setSendingCurrencies] = useState([]);
    const [feeInfos, setFeeInfos] = useState([]);
    const [searchLoader, setSearchLoader] = useState(false);
    const [nextLoader, setNextLoader] = useState(false);
    const [message, setMessage] = useState(null);
    const [rate, setRate] = useState(null);
    const countriesStates = ['USA', 'CAN', 'MEX'];

    const schema = yup.object().shape({
        receiving_country: yup.string().required(t('FIELD_REQUIRED')),
        sending_currency: yup.string().required(t('FIELD_REQUIRED')),
        amount: yup.number().typeError(t('INVALID_AMOUNT')).positive(t('AMOUNT_MUST_BE_POSITIVE')).required(t('FIELD_REQUIRED')),
        ack_fraud_warning: yup.bool().oneOf([true], t('ACKNOWLEDGE_FW_REQUIRED')),
        ack_terms_conditions: yup.bool().oneOf([true], t('ACKNOWLEDGE_TCS_REQUIRED')),
    })

    useEffect(() => {
        console.log('MoneygramFees')
        var recCountries = codeTable?.country_info?.filter(c => c.receive_active === true);
        console.log("receiveCountries")
        console.log(recCountries)
        setReceivingCountries(recCountries ?? []);

        var sendingCurrencies = codeTable?.sending_currencies[globalAuthUser?.location?.agent_country?.isoname] ?? [];
        setSendingCurrencies(sendingCurrencies)

        var dselectCurrency = sendingCurrencies[0] ?? null;
        reset({ sending_currency: dselectCurrency })
        setSendingCurrency(dselectCurrency)

        var indicativeCountries = codeTable?.country_currency_info?.filter(c => c.indicative_rate_available === true).map((c) => c.country_code);
        indicativeCountries = Array.from(new Set(indicativeCountries ?? []))
        setIndicativeCountries(indicativeCountries)

        getDailyRate();

    }, [codeTable, globalAuthUser]);

    const [amount, setAmount] = useState("");
    const [sendingCurrency, setSendingCurrency] = useState(null);
    const [receivingCountry, setReceivingCountry] = useState(null);
    const [amountType, setAmountType] = useState("amount_including_fee");
    const [feeSelect, setFeeSelect] = useState("");
    const [promoCode, setPromoCode] = useState(null);
    const [mgiRewardsNumber, setMgiRewardsNumber] = useState(null);
    const [feeInfo, setFeeInfo] = useState(null);
    const [indicativeCountries, setIndicativeCountries] = useState([]);

    const onChangeReceivingCountry = event => {
        var selected = event.target.value;
        console.log('onChangeReceivingCountry')
        console.log(selected)

        if (indicativeCountries.includes(selected)) {
            setAmountType('amount_including_fee')
        }

        setReceivingCountry(selected)
        dispatch(setReceiveCountryState(selected));

        getReceiverCities(selected)
    };

    const onChangeSendingCurrency = event => {
        setSendingCurrency(event.target.value)
    };

    const onFeeSelect = (e, feeInfo) => {
        console.log(feeInfo);

        setFeeSelect(feeInfo.mgiTransactionSessionID)
        handleEpandRow(e, feeInfo.mgiTransactionSessionID)
        setFeeInfo(feeInfo)
    }

    const getFeeName = feeInfo => {
        var name = feeInfo.deliveryOptDisplayName;
        var agent = '';
        if (feeInfo.receiveAgentName != null) {
            agent += ' ' + feeInfo.receiveAgentName;
        }
        if (feeInfo.receiveAgentAbbreviation != null) {
            agent += ' (' + feeInfo.receiveAgentAbbreviation + ')';
        }

        return name + agent
    }

    const feeLookup = () => {
        console.log('feeLookup')
        setFeeInfo(null)
        setFeeInfos([])
        setMessage(null)
        dispatch(setConsumers([]));
        dispatch(setResetSend(true));
        dispatch(setResetReceive(true));

        //Check limits
        var limits = codeTable?.limits ?? []
        var limit = limits[globalAuthUser?.location?.agent_country?.isoname][sendingCurrency] ?? null
        console.log('limit', limit)
        if (limit != null && amountType != 'receiving_amount') {
            if (parseFloat(amount) > parseFloat(limit)) {
                setMessage(t('SEND_AMOUNT_LIMIT', { limit: limit + ' ' + sendingCurrency }))
                return;
            }
        }

        setSearchLoader(true)
        dispatch(showLoading());

        let params = {}
        params[amountType] = amount
        params.sending_currency = sendingCurrency
        if (promoCode != null && promoCode !== '') {
            params.promo_code = promoCode
        }
        params.receiving_country = receivingCountry
        params.language = i18next.language
        if (mgiRewardsNumber != null && mgiRewardsNumber !== '') {
            params.mgiRewardsNumber = mgiRewardsNumber
        }
        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/feeLookup', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            var feeInfo = []
            var status = res.data.status
            if (status == 1) {
                feeInfo = res.data.data?.feeInfo ?? []
                if (!Array.isArray(feeInfo)) {
                    feeInfo = [feeInfo]
                }
                if (feeInfo.length != 0) {
                    feeInfo = feeInfo.filter(fee => fee?.sendAmounts?.sendCurrency === sendingCurrency)
                }
            } else {
                setMessage(res.data?.message ?? t('ERROR_OCCURED'))
            }

            setFeeInfos(feeInfo);
            setSearchLoader(false)
            dispatch(hideLoading());
        });
    }

    const getFields = () => {
        setNextLoader(true)
        dispatch(showLoading());

        let params = {
            amount: feeInfo?.sendAmounts?.sendAmount,
            sending_country: globalAuthUser?.location?.agent_country?.isoname,
            sending_currency: feeInfo?.sendAmounts?.sendCurrency,
            receiving_country: feeInfo?.receiveCountry,
            receiving_currency: feeInfo?.validReceiveCurrency,
            delivery_option: feeInfo?.deliveryOption,
            receiving_agent_id: feeInfo?.receiveAgentID,
            language: i18next.language
        }

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/getFieldsForProduct', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            setNextLoader(false)
            dispatch(hideLoading());

            var status = res.data.status
            if (status == 1) {
                dispatch(setStep(2));
                var productFieldInfo = res.data.data?.productFieldInfo ?? []
                dispatch(setFieldsState(productFieldInfo));
                applyScheme(productFieldInfo)

                //Check Mgi Rewards
                if (mgiRewardsNumber != null && mgiRewardsNumber !== '') {
                    consumerLookup()
                }
            } else {
                setMessage(res.data?.message ?? t('ERROR_OCCURED'))
            }
        });
    }

    const consumerLookup = () => {

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/consumerLookup', {
            card_number: mgiRewardsNumber,
            language: i18next.language
        }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);

            var status = res.data.status
            if (status == 1) {
                var items = [];
                var senderInfo = res.data.data.senderInfo ?? []
                if (Array.isArray(senderInfo)) {
                    items = senderInfo
                } else {
                    items = [senderInfo]
                }
                dispatch(setConsumers(items))
            }
        });
    }

    const applyScheme = (productFieldInfo) => {
        console.log('applyScheme');
        var senderScheme = {};
        var receiverScheme = {}
        productFieldInfo?.forEach(field => {
            var val = null;
            var strings = ['enum', 'string', 'cntrycode']
            if (strings.includes(field.dataType)) {
                if (field.visibility === 'REQ') {
                    val = yup.string().required(t("FIELD_REQUIRED")).min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })).max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }));
                    if (field.validationRegEx != null) {
                        val = yup.string().required(t("FIELD_REQUIRED")).min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })).max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax })).matches(field.validationRegEx);
                    }
                } else if (field.visibility === 'OPT') {
                    val = yup.string().nullable().transform((v, o) => (o === '' ? null : v)).min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })).max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }));
                    if (field.validationRegEx != null) {
                        val = yup.string().nullable().transform((v, o) => (o === '' ? null : v)).min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })).max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax })).matches(field.validationRegEx);
                    }
                }
            } else if (field.dataType === 'int' || field.dataType === 'decimal') {
                if (field.visibility === 'REQ') {
                    val = yup.number().required(t("FIELD_REQUIRED"));
                } else if (field.visibility === 'OPT') {
                    val = yup.number().notRequired();
                }
            } else if (field.dataType === 'date') {
                if (field.visibility === 'REQ') {
                    val = yup.date().required(t("FIELD_REQUIRED"));
                } else if (field.visibility === 'OPT') {
                    val = yup.date().notRequired();
                }
            } else if (field.dataType === 'boolean') {
                if (field.visibility === 'REQ') {
                    val = yup.boolean().required(t("FIELD_REQUIRED"));
                } else if (field.visibility === 'OPT') {
                    val = yup.boolean().notRequired();
                }
            }

            if (val != null) {
                if (field.fieldCategory === 'Sender Information') {
                    if (!senderFieldsIgnore.includes(field.xmlTag)) {
                        senderScheme[field.xmlTag] = val;
                    }
                } else if (field.fieldCategory === 'Compliance Info') {
                    if (complianceFields.includes(field.xmlTag)) {
                        senderScheme[field.xmlTag] = val;
                    }
                } else if (field.fieldCategory === 'Destination Information') {
                    if (!destinationFieldsIgnore.includes(field.xmlTag)) {
                        receiverScheme[field.xmlTag] = val;
                    }
                }
            }
        });

        senderScheme['senderGovIdType'] = yup.string().when('senderPhotoIdType', {
            is: (senderPhotoIdType) => senderPhotoIdType === 'GOV',
            then: yup.string().required(t("FIELD_REQUIRED")),
            otherwise: yup.string().notRequired()
        });
        senderScheme['senderPhotoIdState'] = yup.string().when('senderPhotoIdCountry', {
            is: (senderPhotoIdCountry) => countriesStates.includes(senderPhotoIdCountry),
            then: yup.string().nullable().transform((v, o) => (o === '' ? null : v)).min(0, t("FIELD_MIN", { min: 0 })).max(2, t("FIELD_MAX", { max: 2 })),
            otherwise: yup.string().notRequired()
        });

        dispatch(setSenderSchemaState(senderScheme));
        dispatch(setReceiverSchemaState(receiverScheme));
    }

    const getReceiverCities = (country) => {
        dispatch(setCities([]))

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/cityList', {
            country: country,
            language: i18next.language
        }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);

            var status = res.data.status
            var items = [];
            if (status == 1) {
                var city = res.data.data.city ?? []
                if (Array.isArray(city)) {
                    items = city
                } else {
                    items = [city]
                }
            }
            dispatch(setCities(items))
        })
    }

    const getDailyRate = () => {
        setRate(null)

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/dailyRate', {}, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);

            var status = res.data.status
            var rate = null;
            if (status == 1) {
                rate = res?.data?.data ?? rate
            }
            console.log('rate', rate)
            setRate(rate)
            dispatch(setRateState(rate))
        });
    }

    const checkbalance = (feeInfo) => {
        if (globalAuthUser?.location?.bnb_owned) {
            return true;
        }
        if (rate != null) {
            var amount = feeInfo?.sendAmounts?.totalAmountToCollect;
            var currency = globalAuthUser?.location?.network?.currency ?? globalAuthUser?.location?.currency;
            var balance = globalAuthUser?.location?.network?.balance ?? globalAuthUser?.location?.balance;
            if (currency !== feeInfo?.sendAmounts?.sendCurrency) {
                amount = amount * rate;
            }
            if (parseFloat(balance) < parseFloat(amount)) {
                return false;
            }
        }
        return true
    }

    const next = () => {
        console.log('next')
        console.log(errors)
        if (Object.keys(errors).length === 0 && errors.constructor === Object && feeInfo != null) {
            //Compare fee

            if (!checkbalance(feeInfo)) {
                return;
            }

            if (onPrevious) {
                if (feeInfoState?.mgiTransactionSessionID == feeInfo.mgiTransactionSessionID) {
                    //Previous clicked but no changes
                    dispatch(setStep(2));
                    return;
                } else {
                    dispatch(setResetSend(true))
                    dispatch(setResetReceive(true))
                }
            }

            dispatch(setFeeInfoState(feeInfo));
            dispatch(setOnPrevious(false))
            getFields()
        }
    }

    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    const [expandedRows, setExpandedRows] = useState([]);

    const [expandState, setExpandState] = useState({});

    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleEpandRow = (event, mgiId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(mgiId);

        let obj = {};
        isRowExpanded ? (obj[mgiId] = false) : (obj[mgiId] = true);
        setExpandState(obj);

        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== mgiId) :
            currentExpandedRows.concat(mgiId);

        setExpandedRows(newExpandedRows);
    }

    const trStyle = {
        height: "30px"
    }

    return (
        <div>
            <form onSubmit={handleSubmit(feeLookup)}>
                <div className='row'>
                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <label htmlFor='receiving_country'>{t("RECEIVING_COUNTRY")} <span style={{ color: "red" }}>*</span></label>
                        <select style={{ maxWidth: "100%" }} className={`form-select ${errors.receiving_country ? 'is-invalid' : ''}`} name="receiving_country" id="receiving_country" {...register('receiving_country', {
                            onChange: (e) => onChangeReceivingCountry(e)
                        })}>
                            <option value="">{t("SELECT")}</option>
                            {
                                receivingCountries.map((receivingCountry, key) => (
                                    <option value={receivingCountry.country_code} key={key}>{receivingCountry.country_name}</option>
                                ))
                            }
                        </select>
                        <p className='input-error-msg'> {errors.receiving_country?.message} </p>
                    </div>
                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <div className="form-group">
                            <label htmlFor='amount'>{t("AMOUNT")} <span style={{ color: "red" }}>*</span></label>
                            <input type='number' step='.01' id='amount' style={{ maxWidth: "100%" }} className={`form-control ${errors.amount ? 'is-invalid' : ''}`} placeholder={t("AMOUNT")} {...register('amount', {
                                onChange: (e) => setAmount(e.target.value),
                            })} />
                        </div>
                        <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors.amount?.message} </p>
                        <div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="amountType" value="amount_including_fee" id="amount_including_fee" className="form-check-input" checked={amountType == 'amount_including_fee'} onChange={() => setAmountType('amount_including_fee')} />
                                <label className="form-check-label" htmlFor='amount_including_fee'>{t("INCLUDE_FEES")}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="amountType" value="amount_excluding_fee" id="amount_excluding_fee" className="form-check-input" checked={amountType == 'amount_excluding_fee'} onChange={() => setAmountType('amount_excluding_fee')} />
                                <label className="form-check-label" htmlFor='amount_excluding_fee'>{t("EXCLUDE_FEES")}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="amountType" value="receiving_amount" id="receiving_amount" className="form-check-input" checked={amountType == 'receiving_amount'} onChange={() => setAmountType('receiving_amount')} disabled={indicativeCountries.includes(receivingCountry)} />
                                <label className="form-check-label" htmlFor='receiving_amount'>{t("RECEIVE_AMOUNT")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <div className='form-group'>
                            <label htmlFor='sending_currency'>{t("SENDING_CURRENCY")} <span style={{ color: "red" }}>*</span></label>
                            <select style={{ maxWidth: "100%" }} className={`form-select ${errors.sending_currency ? 'is-invalid' : ''}`} name="sending_currency" id="sending_currency" {...register('sending_currency', {
                                onChange: (e) => onChangeSendingCurrency(e),
                            })}>
                                {
                                    sendingCurrencies.map((sendingCurrency, key) => (
                                        <option value={sendingCurrency} key={key}>{sendingCurrency}</option>
                                    ))
                                }
                            </select>
                            <p className='input-error-msg'> {errors.sending_currency?.message} </p>
                        </div>
                    </div>
                    <div className="col-sm-6 form-group" style={{ marginTop: "25px" }}>
                        <label htmlFor='promo_code'>{t("PROMO_CODE")}</label>
                        <input style={{ maxWidth: "100%" }} type="text" name="promo_code" placeholder={t("PROMO_CODE")} id="promo_code" className="form-control" onChange={(e) => setPromoCode(e.target.value)} />
                    </div>
                    <div className="col-sm-6 form-group" style={{ marginTop: "25px" }}>
                        <label htmlFor='mgi_rewards_number'>{t("MGI_REWARDS_NUMBER")}</label>
                        <input style={{ maxWidth: "100%" }} type="text" name="mgi_rewards_number" placeholder={t("MGI_REWARDS_NUMBER")} id="mgi_rewards_number" className="form-control" onChange={(e) => setMgiRewardsNumber(e.target.value)} />
                    </div>
                    <div className="col-sm-12 form-group" style={{ marginTop: "25px" }}>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name='ack_fraud_warning' id='ack_fraud_warning' {...register('ack_fraud_warning', {
                                onChange: (e) => console.log('ack_fraud_warning'),
                            })} />
                            <label className="form-check-label" htmlFor='ack_fraud_warning'>
                                {t("ACKNOWLEDGE_FRAUD_WARNINGS")}
                            </label>
                            {
                                errors.ack_fraud_warning?.message &&
                                <p className='input-error-msg'> {errors.ack_fraud_warning?.message} </p>
                            }
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name='ack_terms_conditions' id='ack_terms_conditions' {...register('ack_terms_conditions', {
                                onChange: (e) => console.log('ack_terms_conditions'),
                            })} />
                            <label className="form-check-label" htmlFor='ack_terms_conditions'>
                                {t("ACKNOWLEDGE_TCS")}
                            </label>
                            {
                                errors.ack_terms_conditions?.message &&
                                <p className='input-error-msg'> {errors.ack_terms_conditions?.message} </p>
                            }
                        </div>
                    </div>
                    <div className="col-sm-12 form-group" style={{ marginTop: "25px", textAlign: "right" }}>
                        <button className='btn btn-info' type='submit' disabled={searchLoader}>{t("SEARCH")} &nbsp;
                            <FaSearch size={18} color={'white'} />
                        </button>
                    </div>
                </div>
            </form>
            <br /><br />
            <div className='mt-4'>
                {
                    feeInfos.length != 0 &&
                    <div className='table-responsive'>
                        <table className="table table-hover w-100">
                            <thead>
                                <tr>
                                    <th>{t('SELECT')}</th>
                                    <th>{t('DELIVERY_OPTION')}</th>
                                    <th>{t('AMOUNT')}</th>
                                    <th>{t('FEE')}</th>
                                    <th>{t('TAX')}</th>
                                    <th>{t('FX_RATE')}</th>
                                    <th style={{ width: '25%' }}>{t('TOTAL_AMOUNT')}</th>
                                    <th>{t('RECEIVING_AMOUNT')}</th>
                                    {/**<th>{t('RECEIVING_CURRENCY')}</th>**/}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    feeInfos.map((feeInfo, key) => (
                                        <>
                                            <tr key={key} style={{ cursor: "pointer" }} onClick={(e) => onFeeSelect(e, feeInfo)}>
                                                <td><input type="radio" value={feeInfo.mgiTransactionSessionID} className="form-check-input" checked={feeSelect === feeInfo.mgiTransactionSessionID} onChange={() => setFeeSelect(feeInfo.mgiTransactionSessionID)} /></td>
                                                <td>{getFeeName(feeInfo)}</td>
                                                <td>{feeInfo?.sendAmounts?.sendAmount}</td>
                                                <td>{feeInfo?.sendAmounts?.totalSendFees}</td>
                                                <td>{feeInfo?.sendAmounts?.totalSendTaxes}</td>
                                                <td>{(feeInfo?.receiveAmounts?.validCurrencyIndicator == true) ? feeInfo?.validExchangeRate : feeInfo?.estimatedExchangeRate}</td>
                                                <td>{feeInfo?.sendAmounts?.totalAmountToCollect} {feeInfo?.sendAmounts?.sendCurrency}
                                                    {
                                                        sendingCurrency === 'USD' && rate != null && rate != 1 &&
                                                        <>
                                                            &nbsp;({parseFloat(feeInfo?.sendAmounts?.totalAmountToCollect * rate).toFixed(2)} {currencyRate[globalAuthUser?.location?.agent_country?.isoname] ?? ''})
                                                        </>
                                                    }
                                                </td>
                                                <td>{parseFloat(+feeInfo?.receiveAmounts?.receiveAmount - feeInfo?.receiveAmounts?.totalReceiveFees - feeInfo?.receiveAmounts?.totalReceiveTaxes).toFixed(2)} {feeInfo?.receiveAmounts?.payoutCurrency}</td>
                                                {/**<td>{feeInfo?.receiveAmounts?.payoutCurrency}</td>**/}
                                            </tr>
                                            {
                                                expandedRows.includes(feeInfo.mgiTransactionSessionID) ?
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <div className='row'>
                                                                <div className='col-sm-4'>
                                                                    <strong>{t('RECEIVING_FEE')}: </strong>
                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    {feeInfo?.receiveAmounts?.totalReceiveFees}
                                                                </div>
                                                                <div className='col-sm-4'>
                                                                    <strong>{t('RECEIVING_TAX')}: </strong>
                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    {feeInfo?.receiveAmounts?.totalReceiveTaxes}
                                                                </div>
                                                                {
                                                                    feeInfo?.promotionInfo?.promotionErrorMessage?.textTranslation == null && feeInfo?.promotionInfo?.promotionCode != null &&
                                                                    <>
                                                                        <div className='col-sm-4'>
                                                                            <strong>{t('PROMOTION_DISCOUNT')}: </strong>
                                                                        </div>
                                                                        <div className='col-sm-8'>
                                                                            {(() => {
                                                                                var text = ''
                                                                                if (feeInfo?.promotionInfo?.promotionDiscountId == 1) {
                                                                                    text = feeInfo?.promotionInfo?.promotionDiscount
                                                                                } else if (feeInfo?.promotionInfo?.promotionDiscountId == 2) {
                                                                                    text = feeInfo?.promotionInfo?.promotionDiscount
                                                                                } else if (feeInfo?.promotionInfo?.promotionDiscountId == 3) {
                                                                                    text = feeInfo?.promotionInfo?.promotionDiscount + '%'
                                                                                }
                                                                                return text;
                                                                            })()}
                                                                        </div>
                                                                        <div className='col-sm-4'>
                                                                            <strong>{t('PROMOTION_DISCOUNT_AMOUNT')}: </strong>
                                                                        </div>
                                                                        <div className='col-sm-8'>
                                                                            {feeInfo?.promotionInfo?.promotionDiscountAmount}
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            {
                                                                !feeInfo?.receiveAmounts?.validCurrencyIndicator &&
                                                                <p className='input-error-msg mt-2'>* {t('VALID_INDICATOR_MESSAGE')}</p>
                                                            }
                                                            {
                                                                feeInfo?.promotionInfo?.promotionErrorMessage != null &&
                                                                <p className='input-error-msg mt-2'>{feeInfo?.promotionInfo?.promotionErrorMessage?.textTranslation}</p>
                                                            }
                                                            {
                                                                !checkbalance(feeInfo) &&
                                                                <p className='input-error-msg mt-2'>{t('INSUFFICIENT_BALANCE')}</p>
                                                            }
                                                        </td>
                                                    </tr> : null
                                            }
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <div className='mt-4'>
                {
                    message != null &&
                    <p className='input-error-msg'>{message}</p>
                }
            </div>
            <br />

            <div style={{ textAlign: "right" }}>
                <button type='submit' className='btn btn-primary' onClick={next} disabled={nextLoader}>
                    {t('NEXT')} &nbsp;
                    <FaArrowCircleRight size={18} fill={'white'} />
                </button>
            </div>
        </div>
    );

}

export default MoneygramFees