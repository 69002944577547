import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAuthUser} from 'react-auth-kit'
import { setGlobalSearchPickupCodeResult } from '../slices/auth-slice';
import { useParams } from 'react-router-dom';
import { showToast } from '../slices/toast-slice';
import { useTranslation } from 'react-i18next';
import { hideLoading } from '../slices/loading-slice';
import { formatAmount } from '../utils/helper';


function PayCompleted() {

    const [codeDetails, setCodeDetails] = useState(null);
    const searchedPickupCode = useSelector(state => state.auth.globalSearchPickupCodeResult);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    console.log(searchedPickupCode)

    const navigate = useNavigate();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const location = useLocation();
    const propsData = location.state
    
    console.log(propsData);
    console.log(propsData.companyCode);

    const onPrintReceipt = () => {
        var payload = {
            agentID: globalAuthUser?.location.agent_id,
            agentSequence: globalAuthUser?.location.sequence,
            token: globalAuthUser?.location.token,
            // referenceNumber: pickupCode,
            // language: LANGUAGE,
            agentTxnId: propsData.agentTxnId
        };

        let apiUrl = "/api/portal/moneygram/reprintReceiveReceipt";
        if (propsData?.companyCode !== "MG") {
            apiUrl = "/api/portal/receiptDom";
        }

        // Object.keys(propsData).forEach((field) => {
        //       payload[field] = propsData[field];
        //   });

        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + apiUrl,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
            data: payload,
            responseType: 'blob'
        })
        .then((res) => {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' }
            );
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch((error) => {
            dispatch(hideLoading());
            dispatch(
                showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
            );
        });
    }

    const onPayAnother = () => {
        navigate("/payTransaction")
    }
 
    return (
        <>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header" style={{ fontWeight: "600" }}>
                        {t("PAYMENT_COMPLETED")}
                    </div>
                    <div className="card-body">
                        <div style={{ textAlign: "center" }}>
                            <p><i className='bx bxs-badge-check' style={{ fontSize: "60px", color: "green", paddingTop: "20px" }}></i></p>
                            <p style={{ color: "green"}}>{t("PAYMENT_COMPLETED_SUCCESSFULLY")}</p>
                            <p style={{ color: "#333"}}> <span style={{ fontWeight: "bold"}}>{propsData?.pickupCode}</span> - <span style={{ fontWeight: "bold"}}>{propsData?.companyName}</span> </p>
                            <p style={{ color: "#333"}}> <span>{t("SENT")}: </span> <span style={{ fontWeight: "bold"}}>{propsData?.sentCurrency + ' ' + formatAmount(propsData?.sentAmount)}</span> - <span>{t("RECEIVED")}: </span> <span style={{ fontWeight: "bold"}}>{propsData?.receiveCurrency + ' ' + formatAmount(propsData?.receiveAmount)}</span> 
                            { propsData.equivalentAmount != undefined && (<span style={{ fontWeight: "bold"}}>{ ' (' + propsData.equivalentCurrency + ' ' + formatAmount(propsData.equivalentAmount) + ')'}</span>) } </p>
                             <p style={{ color: "#333"}}> <span>{t("RECEIVER_FIRST_NAME")}: </span> <span style={{ fontWeight: "bold"}}>{propsData?.receiverFirstName}</span> </p>
                             <p style={{ color: "#333"}}> <span>{t("RECEIVER_LAST_NAME")}: </span> <span style={{ fontWeight: "bold"}}>{propsData?.receiverLastName}</span> </p>
                        </div>
                        <div style={{ textAlign: "center" }}>
                        <button type='button' className="btn btn-primary mt-3" onClick={onPrintReceipt}>{t("PRINT_RECEIPT")}</button>  &nbsp;
                        <button type='button' className="btn btn-secondary mt-3" onClick={onPayAnother}>{t("PAY_ANOTHER")}</button>
                    </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PayCompleted