import React, { useId } from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function OtpVerificationForm({ handleOtpSubmit }) {
    const id = useId();
    const { t } = useTranslation();
    const [otpCode, setOtpCode] = useState('');

    return (
        <>
            <div className="row mb-2 border-bottom">
                <div className="col-12 col-lg-2"></div>
                <div className="col-12 col-lg-8">
                    {/** otp validation form */}
                    <form onSubmit={handleOtpSubmit}>
                        <div className="mb-2">
                            <label htmlFor="otp_code">{ t("ENTER_OTP_CODE") }</label>
                            <input type="text" name="otp_code" id={id + "otp_code"} className="form-control"
                            value={otpCode} onChange={(e) => setOtpCode(e.target.value)} />
                        </div>

                        <div className="mb-2 d-grid">
                            <button type='submit' className="btn btn-sm btn-primary btn-block">
                                { t("VALIDATE_OTP") }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
