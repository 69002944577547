import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUser } from 'react-auth-kit';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";

const AccountStatement = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    let cmpt = 0;
    const dispatch = useDispatch();
    const [useDetails, setUserDetails] = useState(null);
    const [accountStatements, setAccountStatements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fromPage, setFromPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fromElmt, setFromElmt] = useState(0);
    const [toElmt, setToElmt] = useState(0);
    const [recordPerPage, setRecordPerPage] = useState(10);
    const npage = Math.ceil(total/recordPerPage);
    const [duration, setDuration] = useState("1");
    const [companies, setCompanies] = useState([]);
    const [types, setTypes] = useState([]);
    const [account, setAccount] = useState({
        agent_location_id: globalAuthUser?.location.id,
        agent_country_id: '',
        agent_company_id: '',
        reference: '',
        type: '',
        from_date: '',
        to_date: '',
        per_page: 10
    });
    const numbers = [];
    for (let i = 1; i <= npage; i++) {
        numbers.push(i);
    }

    // console.log(globalAuthUser?.location.id);
    function fetchData(url,currentPage, payload) {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = payload;
        dispatch(showLoading());
        axios.post(url, data, { headers })
        .then(response => {
            dispatch(hideLoading());
            setFromPage(response.data.data.accounts.from);
            setAccountStatements(response.data.data.accounts.data);
            setTotal(response.data.data.accounts.total);
            setFromElmt(response.data.data.accounts.from);
            setToElmt(response.data.data.accounts.to);
            setRecordPerPage(response.data.data.accounts.per_page);
            setCurrentPage(currentPage);
            setCompanies(response.data.data.agentCompanies);
            setTypes(response.data.data.types);
        })
        .catch(error => {
            console.error(error);
        });
    }
    useEffect(() => {
        axios({method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData', 
              withCredentials: false,
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
              }
            }).then(res => {
              console.log(res);
              setUserDetails(res.data.data)

            })
    }, []);
    useEffect(() => {
        var payload = {
            duration:1,
            agent_location_id: globalAuthUser?.location.id,
            // from_date:'2023-01-06',
            // to_date:'2023-02-06',
            per_page: 10
        };
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getAccountStatements',
        currentPage,
        payload);
    }, []);

    const currentAccounts = accountStatements;
    var listAccounts = "";
    // console.log((types));
    listAccounts = currentAccounts.map((item, index)=> {
       const {id, created_at, agent_location_name, transaction_type,
            reference, paid_at, agent_company_code, credit_amount, 
            debit_amount, balance, agent_transaction_id } = item;
        return (
            <tr key={id} >
                <td>{fromPage+index}</td>
                <td>{created_at}</td>
                <td>{agent_location_name}</td>
                <td>{types[transaction_type]}</td>
                <td>
                    {transaction_type === 'paid' || transaction_type === 'sent' ? (
                    <button type="button" className="btn btn-link" onClick={() => {
                        if (transaction_type === 'paid') {
                            navigate('/paid-transaction/' + agent_transaction_id)
                        } else {
                            navigate('/send-money/' + reference)
                        }
                    }}>
                        {reference}
                    </button>
                    ) : (
                    reference
                    )}
                </td>
                <td>{paid_at}</td>
                <td>{agent_company_code}</td>
                <td className="text-success" style={{fontWeight: "bold"}}>{credit_amount !== 0.00 ? Number(credit_amount).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ''}</td>
                <td className="text-danger" style={{fontWeight: "bold"}}>{debit_amount !==  0.00 ? Number(debit_amount).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) : ''}</td>
                <td style={{fontWeight: "bold"}}>{Number(balance).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
            </tr>
        )
    });
    function changeCPage(page) {
        dispatch(showLoading());
        var payload = {
            duration: duration,
            agent_location_id: globalAuthUser?.location.id,
            reference: account.reference,
            agent_company_id: account.agent_company_id,
            type: account.type,
            from_date: account.from_date,
            to_date:account.to_date,
            per_page: account.per_page !== null ? account.per_page : 10,

        };
        fetchData(`${process.env.REACT_APP_BNB_BASE_URL}/api/portal/getAccountStatements?page=${page}`,
        page,
        payload)
    }

    const handleInput = (e) => {
        e.persist();
        setAccount((prevAccount) => {
          const updatedAccount = { ...prevAccount, [e.target.name]: e.target.value };
          if (e.target.name === "per_page") {
            account.per_page = e.target.value;
            searchByParam(e);
          }
          if (e.target.name === 'from_date') {
            const from_date = e.target.value;
            updatedAccount.from_date = from_date;
            
            const fromDateObject = new Date(from_date);
            const toDateObject = new Date(
              fromDateObject.getFullYear(),
              fromDateObject.getMonth() + 1,
              fromDateObject.getDate()
            );
            const to_date = toDateObject.toISOString().split('T')[0];
            updatedAccount.to_date = to_date;
          }
          return updatedAccount;
        });
      };
    
      
      const handleDownloadCSV = async () => {
        try {
            dispatch(showLoading());
            var payload = {
                duration: duration,
                agent_location_id: globalAuthUser?.location.id,
                agent_company_id: account.agent_company_id,
                type: account.type,
                reference: account.reference,
                from_date: account.from_date ,
                to_date: account.to_date ,
                export: true
            };
            if(duration === "6"){
                const from_date_error = document.getElementById('from_date_error');
                const to_date_error = document.getElementById('to_date_error');
                if (account.from_date === "") {
                    from_date_error.textContent = 'Please enter a valid date';
                    dispatch(hideLoading());
                    return ;
                } else {
                    from_date_error.textContent = '';
                }
                if (account.to_date === "") {
                    to_date_error.textContent = 'Please enter a valid date';
                    dispatch(hideLoading());
                    return ;
                } else {
                    to_date_error.textContent = '';
                }
            }
            const headers = {
                'Authorization': authUser().tokenType + ' ' + authUser().token
            };
    
            const response = await axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getAccountStatements',payload,{ headers });
            console.log(response);
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Account Statement.csv';
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(hideLoading());
        } catch (error) {
            console.error('Error downloading CSV:', error);
            dispatch(hideLoading());
        }
    };

    function handleDurationChange(event) {
        setDuration(event.target.value);
    }
    function handleDateInvalid(event) {
        event.target.setCustomValidity("Please select a date.");
    }

    function resetSearch(event) {
        var myForm = document.getElementById('my-form');
        myForm.reset();
        setDuration(1);
        account.reference = "";
        dispatch(showLoading());
        event.preventDefault();
        var payload = {
            duration: 1,
            agent_location_id: globalAuthUser?.location.id,
            agent_company_id: account.agent_company_id,
            type: account.type,
            reference: account.reference,
            from_date: account.from_date ,
            to_date: account.to_date ,
            per_page: account.per_page !== null ? account.per_page : 10,
        };
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getAccountStatements',
        currentPage,
        payload);
    }

    function searchByParam(event) {
        dispatch(showLoading());
        event.preventDefault();
        var payload = {
            duration: duration,
            agent_location_id: globalAuthUser?.location.id,
            agent_company_id: account.agent_company_id,
            type: account.type,
            reference: account.reference,
            from_date: account.from_date ,
            to_date: account.to_date ,
            per_page: account.per_page !== null ? account.per_page : 10,
        };
        if(duration === "6"){
            const from_date_error = document.getElementById('from_date_error');
            const to_date_error = document.getElementById('to_date_error');
            if (account.from_date === "") {
                from_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                from_date_error.textContent = '';
            }
            if (account.to_date === "") {
                to_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                to_date_error.textContent = '';
            }
        }
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getAccountStatements',
        currentPage,
        payload);
        
    }
      
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header row">
                        <h5 className="col-sm-4">{t('ACCOUNT_STATEMENTS')}</h5>
                        <h4 className="col-sm-4">
                            <p> <span style={{ fontSize: '23px', marginRight: '10px'}}>Balance: </span> <span style={{ fontSize: '23px', fontWeight: 'bold'}}>
                                { Number(useDetails?.location?.balance).toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2}) + ' ' + useDetails?.location?.currency }
                                </span> 
                            </p>
                        </h4>
                        <div className="col-sm-4">
                            <Link to="/home" className="btn btn-primary font-weight-bolder float-end">{t('BACK')}</Link>
                            {
                                (useDetails?.location?.enable_agent_topup == true && (useDetails?.location?.parent_id == 0 || useDetails?.location?.network?.enable_agent_topup == true))
                                &&
                                <Link to="/agent-topup" className="btn btn-primary font-weight-bolder float-end" style={{marginRight:'5px'}}>{t('AGENT_TOPUP')}</Link>
                            }
                        </div>
                    </div>
                    
                    <div className="card-body">
                        <div className="row">
                            <div className="pull-right">
                                <Link onClick={handleDownloadCSV} className="btn btn-primary font-weight-bolder float-end" style={{marginRight:'5px'}}>{t('EXPORT')}</Link>
                            </div>
                        </div>
                        <form id="my-form">
                            <div className="form-group row justify-content-md-center">
                                <div className="col-md-3">
                                    <label className=""  htmlFor="agent_company_id">{t('COMPANY')}</label>
                                    <select className="form-select" onChange={handleInput} name="agent_company_id" id="agent_company_id" aria-label="Default select example">
                                        <option value={""}>{t('SELECT_A_COMPANY')}</option>
                                        {
                                            companies.map((company, index) => {
                                                return (
                                                    <option value={company.id} key={index}>{company.code}</option>
                                                    )
                                                })
                                            }
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className=""  htmlFor="type">{t('TRANSACTION_TYPE')}</label>
                                    <select className="form-select" onChange={handleInput} name="type" id="type" aria-label="Default select example">
                                        <option value={""}>{t('SELECT_A_TYPE')}</option>
                                        {
                                            Object.keys(types).map((key, index) => (
                                                <option key={index} value={key}>
                                                {types[key]}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className=""  htmlFor="duration">{t('DURATION')}</label>
                                    <select className="form-select" onChange={handleDurationChange} name="duration" id="duration" value={duration} aria-label="Default select example">
                                        <option value="1" >{t('TODAY')}</option>
                                        <option value="2" >{t('THIS_WEEK')}</option>
                                        <option value="3" >{t('THIS_MONTH')}</option>
                                        <option value="4" >{t('PREVIOUS_WEEK')}</option>
                                        <option value="5" >{t('PREVIOUS_MONTH')}</option>
                                        <option value="6" >{t('CUSTOM_DATE')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row justify-content-md-center">
                                <div className="col-md-3 " >
                                    <label htmlFor="reference">Reference </label>
                                    <input className="form-control custom-field" value={account.reference} id="reference" type="text" onChange={handleInput} name="reference"  />
                                </div>
                                {
                                    duration === "6" && (
                                        <>
                                            <div className="col-md-3 customDate" >
                                                <label htmlFor="from_date">{t('FROM_DATE')} <span style={{color: "red"}}>*</span></label>
                                                <input className="form-control custom-field" value={account.from_date} required onInvalid={handleDateInvalid} id="from_date" type="date" onChange={handleInput} name="from_date"  />
                                                <span id="from_date_error" className="text-danger"></span>
                                            </div>

                                            <div className="col-md-3 customDate">
                                                <label htmlFor="to_date">{t('TO_DATE')} <span style={{color: "red"}}>*</span></label>
                                                <input className="form-control custom-field" value={account.to_date} required readOnly onInvalid={handleDateInvalid} id="to_date" type="date" name="to_date" />
                                                <span id="to_date_error" className="text-danger"></span>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div className="form-group row justify-content-md-center mt-3">
                                <button type="submit" onClick={searchByParam} className="col-sm-2 btn btn-primary" id="apply-filter-paid" data-count="0">{t('SEARCH')}</button>
                                &nbsp;
                                <button type="reset" onClick={resetSearch} className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CLEAR_SEARCH')}</button>
                            </div>
                            <div className="per_page m-2">
                                <label>
                                    <div className="show">{t('SHOW')}</div>
                                    <select name="per_page" id="per_page" value={account.per_page} onChange={handleInput} className="form-select">
                                        <option value="10" selected>10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value={total}>{t('ALL')}</option>
                                    </select>
                                    <div className="entries">{t('ENTRIES')}</div>
                                </label>
                            </div>
                        </form>
                        <table id="datatable" className="table table-bordered grid-table responsive nowrap w-100">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('DATE')}</th>
                                    <th>{t('LOCATION')}</th>
                                    <th>{t('TRANSACTION_TYPE')}</th>
                                    <th>{t('REFERENCE')}</th>
                                    <th>{t('PAID_AT')}</th>
                                    <th>{t('COMPANY')}</th>
                                    <th>{t('CREDIT')}</th>
                                    <th>{t('DEBIT')}</th>
                                    <th>{t('BALANCE')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listAccounts}
                            </tbody>
                            
                        </table>
                    </div>
                    <div className="card-footer">
                        
                        {
                            numbers.length > 1 ? (
                                <>
                                    <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="left-info">
                                            <span>
                                            {t('SHOWING')} {fromElmt} {t('TO')} {toElmt} {t('OF')} {total} {t('ENTRIES')}
                                            </span>
                                        </div>
                                        <div className="float-end">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination" style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                                                    <li className="page-item">
                                                        <button type="button" onClick={() => changeCPage(currentPage-1)} className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                        </button>
                                                    </li>
                                                    
                                                    {numbers.map((page, index) => (
                                                    <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={index}>
                                                        {
                                                            (
                                                                page === 1 || 
                                                                page === numbers.length || 
                                                                currentPage === page || 
                                                                currentPage+1 === page || 
                                                                currentPage+2 === page ||
                                                                currentPage-1 === page || 
                                                                currentPage-2 === page )  ? 
                                                                (<button type='button' onClick={() => changeCPage(page)} className="page-link">
                                                                    {
                                                                        page
                                                                    }
                                                                </button>) 
                                                            : ((cmpt === 1 && (page === currentPage+3)) ? (
                                                                <button className="page-link">
                                                                  {(() => {
                                                                    cmpt++;
                                                                    return '...';
                                                                  })()}
                                                                </button>
                                                              ) : ((cmpt === 0) ? (
                                                                <button className="page-link">
                                                                  {(() => {
                                                                    cmpt++;
                                                                    return '...';
                                                                  })()}
                                                                </button>
                                                              ) : null))
                                                        }
                                                    </li>
                                                    ))}
                                                    <li className="page-item">
                                                        <button type="button" onClick={() => changeCPage(currentPage+1)} className={`page-link ${currentPage === numbers.length ? 'disabled' : ''}`} aria-label="Previous">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div> 
            </div>
        </React.Fragment>

    );

}

export default AccountStatement;