import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../slices/toast-slice';
import { t } from 'i18next';
import { setShowLoading } from '../../slices/auth-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';

export default function ({processPrev, processNext}) {
    const basicInfo = useSelector(state => state.agentSelfRegistration.personalInfo);
    const businessInfo = useSelector(state => state.agentSelfRegistration.businessInfo);
    const agentCountries = useSelector(state => state.agentSelfRegistration.agentCountries);
    const idTypes = useSelector(state => state.agentSelfRegistration.idTypes);
    const selectedCountry = agentCountries.filter(val => val.id == basicInfo.country);
    const dispatch = useDispatch();

    console.log(agentCountries, basicInfo);

    const handleSubmit = (e) => {
        // start the loading activity
        dispatch(showLoading());

        // set the url
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/agentRegister/saveRegistration`;

        // prepare the payload
        const payload = {
            first_name: basicInfo.firstName,
            last_name: basicInfo.lastName,
            country_id: basicInfo.country,
            city: basicInfo.city,
            address: basicInfo.address,
            phone_number: basicInfo.phoneNumber,
            email: basicInfo.emailAddress,
            idType: basicInfo.idType,
            idNumber: basicInfo.idNumber,
            idFront: basicInfo.idFront,
            idBack: basicInfo.idBack,
            referralCode: basicInfo.referralCode,

            business_name: businessInfo.business_name,
            business_cert_number: businessInfo.business_cert_number,
            business_cert: businessInfo.business_cert,
            tax_cert_number: businessInfo.tax_cert_number,
            tax_cert: businessInfo.tax_cert,
            city_council_number: businessInfo.city_council_number,
            city_council_license: businessInfo.city_council_license,
            other_doc: businessInfo.other_doc
        }

        console.log(payload);
        let formdata = new FormData();

        for(const key in payload){
            if(payload.hasOwnProperty(key)) {
                if(payload[key] instanceof File) {
                    formdata.append(key, payload[key]);
                    continue;
                }

                formdata.append(key, payload[key]);
            }
        }

        // send the request to the backend
        axios({
            method: "post",
            url: url,
            data: formdata,
            headers: {
                "Accept": "application/json",
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // show the success page
                const successMsg = msg ? msg : "REGISTRATION_SUCCESSFUL";
                dispatch(showToast(["success", "Registration", successMsg]));
                processNext();
                return
            }
            
            const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
            dispatch(showToast(["danger", "Registration Error", errorMsg]))
        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
        })
    }

  return (
    <>
        {/* the summary table  */}
        <div className="row mb-2 bg-light border p-3 rounded-2">
            <div className="col-12">
                <table className="table table-hover table-borderless">
                    <tbody>
                        <tr>
                            <td>{ t("FULL_NAME") }</td>
                            <td>
                                <strong>
                                    { `${basicInfo.firstName} ${basicInfo.lastName}` }
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{ t("ADDRESS") }</td>
                            <td>
                                <strong>
                                    { `${basicInfo.address}, ${basicInfo.city}, ${selectedCountry.length ? selectedCountry[0].name : ""}`}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{ t("ID_CARD_DETAILS") }</td>
                            <td>
                                <strong className='me-1'>
                                    {
                                     `${basicInfo.idType ? idTypes[basicInfo.idType] : ""}, ${basicInfo.idNumber}`
                                    }
                                </strong>
                                <i className="text-muted">
                                    {
                                        basicInfo.idFront && 
                                        `(${basicInfo.idFront.name} ${basicInfo.idBack ? ", " + basicInfo.idBack.name : ""})`
                                    }
                                </i>
                            </td>
                        </tr>

                        <tr>
                            <td>{ t("REFERRAL_CODE") }</td>
                            <td>
                                <strong>
                                    { basicInfo.referralCode && `${basicInfo.referralCode}`}
                                </strong>
                            </td>
                        </tr>

                        <tr>
                            <td>{ t("BUSINESS_NAME") }</td>
                            <td>
                                <strong>
                                    { `${businessInfo.business_name}` }
                                </strong>
                            </td>
                        </tr>

                        <tr>
                            <td>{ t("BUSINESS_REG_CERT") }</td>
                            <td>
                                <strong className='me-1'>
                                    { businessInfo.business_cert_number && `${businessInfo.business_cert_number}` }
                                </strong>
                                <i className="text-muted">
                                    {
                                        businessInfo.business_cert &&
                                        `(${businessInfo.business_cert.name})`
                                    }
                                </i>
                            </td>
                        </tr>

                        <tr>
                            <td>{ t("LBL_TAX_CERT") }</td>
                            <td>
                                <strong className='me-1'>{businessInfo.tax_cert_number && `${businessInfo.tax_cert_number}`}</strong>
                                <i className="text-muted">
                                    {
                                        businessInfo.tax_cert &&
                                        `(${(businessInfo.tax_cert.name)})`
                                    }
                                </i>
                            </td>
                        </tr>

                        <tr>
                            <td>{ t("CITY_COUNCIL_LICENSE") }</td>
                            <td>
                                <strong className='me-1'>{ businessInfo.city_council_number && `${businessInfo.city_council_number}`}</strong>
                                <i className="text-muted">
                                    {
                                        businessInfo.city_council_license &&
                                        `(${businessInfo.city_council_license.name})`
                                    }
                                </i>
                            </td>
                        </tr>

                        <tr>
                            <td>{ t("OTHER_DOCS") }</td>
                            <td>
                                <i className='text-muted'>{`${businessInfo.other_doc ? businessInfo.other_doc.name : ""}`}</i>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        {/* controls */}
        <div className="row mb-3 border-top">
            <div className="col-12 d-flex justify-content-between mt-2">
                <button type="button" className="btn btn-dark" id="basicInfoSubmit" onClick={processPrev}>{t("BACK")}</button>
                <button type="button" className="btn btn-primary" id="basicInfoSubmit" onClick={handleSubmit}>
                    {t("SUBMIT")}
                </button>
            </div>
        </div>
    </>
  )
}
