import { useTranslation } from "react-i18next";
import { FaTimesCircle } from "react-icons/fa";

function UnauthorizedService() {

    const { t } = useTranslation();

    return (
        <div>
            <div style={{ padding: "20px", textAlign: 'center' }}>
                <FaTimesCircle size={80} fill={'red'} />
                <h1>{t('UNAUTHORIZED_SERVICE')}</h1>
            </div>
        </div>
    );
}

export default UnauthorizedService;