import axios from 'axios';
import React, { useState, useEffect } from 'react'
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { FaCheckCircle, FaPrint, FaInfo, FaList, FaTimesCircle } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from 'react-router-dom';

function MoneygramComplete() {

    const dispatch = useDispatch();
    const authUser = useAuthUser()
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState(null);
    const sendResponse = useSelector((state) => state.sendMoneyMoneygram.sendResponse);
    const feeInfo = useSelector((state) => state.sendMoneyMoneygram.feeInfo);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const rate = useSelector((state) => state.sendMoneyMoneygram.rate);
    const currencyRate = useSelector((state) => state.sendMoneyMoneygram.currencyRate);
    const [printLoader, setPrintLoader] = useState(false);
    const [consumerText, setConsumerText] = useState('');
    const [rateFluctuation, setRateFluctuation] = useState(false);

    useEffect(() => {
        console.log('MoneygramFees')

        setStatus(sendResponse?.status)
        setMessage(sendResponse?.message)
        setConsumerText(sendResponse?.data?.commitTransaction?.referenceNumberConsumerText)

        var feeRate = (feeInfo?.receiveAmounts?.validCurrencyIndicator == true) ? feeInfo?.validExchangeRate : feeInfo?.estimatedExchangeRate
        var txnRate = sendResponse?.data?.exchangeRateApplied
        setRateFluctuation(feeRate != txnRate);

    }, [sendResponse]);

    const receipt = () => {
        setPrintLoader(true)
        dispatch(showLoading())

        let appendFields = {
            location: globalAuthUser?.location?.code
        }
        let data = { ...sendResponse?.data, ...appendFields }

        let params = {
            transaction_data: data
        }

        //Apend fields
        params.transaction_data.location = globalAuthUser?.location?.code
        params.language = i18next.language

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/receipt', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
            responseType: 'blob'
        }).then(res => {
            setPrintLoader(false)
            dispatch(hideLoading())

            console.log(res);
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }).catch((error) => {
            console.log(error);
            setPrintLoader(false)
            dispatch(hideLoading());
        });
    }

    return (
        <div>
            <div style={{ marginTop: "25px" }}>
                {
                    status == 1 &&
                    <div style={{ textAlign: "right" }}>
                        <button type='button' className='btn btn-primary' onClick={receipt} disabled={printLoader}>
                            {t('RECEIPT')} &nbsp;
                            <FaPrint size={18} fill={'white'} />
                        </button>
                    </div>
                }
                <div style={{ textAlign: "center", marginTop: "40px" }}>
                    {(() => {
                        if (status == 1) {
                            //Success
                            return <FaCheckCircle size={80} fill={'green'} />
                        } else if (status == 2) {
                            //Pending
                            return <FaCheckCircle size={80} fill={'yellow'} />
                        } else {
                            //Failed
                            return <FaTimesCircle size={80} fill={'red'} />
                        }
                    })()}
                    <h1>{t(message) ?? t('ERROR_OCCURED')}</h1>
                    {
                        status == 1 &&
                        <>
                            <h3 className='mt-5'><b>{t('REFERENCE')}:</b> {sendResponse?.data?.commitTransaction?.referenceNumber}</h3>
                            <div style={{ marginTop: "40px" }}>
                                <h6><b>{t('AMOUNT')}:</b> {sendResponse?.data?.sendAmounts?.sendAmount}</h6>
                                <h6><b>{t('FEE')}:</b> {sendResponse?.data?.sendAmounts?.totalSendFees}</h6>
                                <h6><b>{t('TAX')}:</b> {sendResponse?.data?.sendAmounts?.totalSendTaxes}</h6>
                                <h6><b>{t('DISCOUNT_AMOUNT')}:</b> {sendResponse?.data?.sendAmounts?.totalDiscountAmount}</h6>
                                <h6>
                                    <b>{t('TOTAL_AMOUNT')}:</b> {sendResponse?.data?.sendAmounts?.totalAmountToCollect} {sendResponse?.data?.sendAmounts?.sendCurrency}
                                    {
                                        sendResponse?.data?.sendAmounts?.sendCurrency === 'USD' && rate != null && rate != 1 &&
                                        <>
                                            &nbsp;({parseFloat(sendResponse?.data?.sendAmounts?.totalAmountToCollect * rate).toFixed(2)} {currencyRate[globalAuthUser?.location?.agent_country?.isoname] ?? ''})
                                        </>
                                    }
                                </h6>
                                <h6><b>{t('FX_RATE')}:</b> {sendResponse?.data?.exchangeRateApplied} {feeInfo?.receiveAmounts?.validCurrencyIndicator == false && <span style={{ color: "red" }}>*</span>}</h6>
                                <h6><b>{t('RECEIVE_AMOUNT')}:</b> {sendResponse?.data?.receiveAmounts?.totalReceiveAmount} {sendResponse?.data?.receiveAmounts?.payoutCurrency} {feeInfo?.receiveAmounts?.validCurrencyIndicator == false && <span style={{ color: "red" }}>*</span>}</h6>
                            </div>
                        </>

                    }
                </div>
                {
                    consumerText != null && consumerText != '' &&
                    <div style={{ marginTop: "40px" }}>
                        <p className='input-error-msg'>{consumerText}</p>
                    </div>
                }
                {
                    rateFluctuation == true &&
                    <div style={{ marginTop: "40px" }}>
                        <p className='input-error-msg'>{t('RATE_FLUCTUATION_MESSAGE')}</p>
                    </div>
                }
                {/*<div style={{ textAlign: "right", marginTop: "80px" }}>
                    {
                        status == 1 &&
                        <button type='button' className='btn btn-info'>
                            <FaInfo size={18} color={'white'} /> Transaction details
                        </button>
                    }
                    &nbsp;
                    <button type='button' className='btn btn-primary'> Transactions
                        <FaList size={18} color={'white'} />
                    </button>
                </div>*/}
                <div style={{ marginTop: "40px", textAlign: "right" }}>
                    <button type='button' className='btn btn-primary' onClick={() => navigate('/send-money/' + sendResponse?.data?.commitTransaction?.referenceNumber)}>
                        Transaction Details
                    </button> &nbsp;
                    <button type='button' className='btn btn-info' onClick={() => navigate('/send-money')}>
                        Sent Transactions
                    </button>
                </div>
            </div>
        </div>
    );

}

export default MoneygramComplete