const complianceFields = []
const destinationFieldsIgnore = []
const senderFieldsIgnore = []

const getFieldsByCategory = (fields, category) => {
    var filter = c => c.fieldCategory === category && array.includes(c.visibility);
    var array = ["REQ", "OPT"];
    if (category === 'Sender Information') {
        filter = c => c.fieldCategory === category && array.includes(c.visibility) && !senderFieldsIgnore.includes(c.xmlTag);
    } else if (category === 'Compliance Info') {
        filter = c => c.fieldCategory === category && array.includes(c.visibility) && complianceFields.includes(c.xmlTag);
    } else if (category === 'Destination Information') {
        filter = c => c.fieldCategory === category && array.includes(c.visibility) && !destinationFieldsIgnore.includes(c.xmlTag);
    }
    return fields?.filter(filter);
}

const getStates = (codeTable, countryCode) => {
    return codeTable?.state_province_info.filter(c => c.country_code == countryCode)
}

const getEnumLabel = (field, val) => {
    var options = field.enumeratedValues.enumeratedValueInfo;
    var element = options?.find(({ value }) => value == val);
    return element?.label
}

const getCountryName = (codeTable, countryCode) => {
    var country = codeTable?.country_info?.find(({ country_code }) => country_code === countryCode);
    return country?.country_name;
}

const getPhoneCode = (codeTable, countryCode) => {
    var country = codeTable?.country_info?.find(({ country_code }) => country_code === countryCode);
    return country?.phone_code;
}

const formatNumber = (value, currency = null) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: currency
    }).format(value);
}

const getSenderName = (send) => {
    var name = '';
    if (send.senderFirstName != null) {
        name += send.senderFirstName;
    }
    if (send.senderMiddleName != null) {
        name += ' ' + send.senderMiddleName;
    }
    if (send.senderLastName != null) {
        name += ' ' + send.senderLastName;
    }
    if (send.senderLastName2 != null) {
        name += ' ' + send.senderLastName2;
    }
    return name;
}

const getReceiverName = (receive) => {
    var name = '';
    if (receive.firstName != null) {
        name += receive.firstName;
    }
    if (receive.middleName != null) {
        name += ' ' + receive.middleName;
    }
    if (receive.lastName != null) {
        name += ' ' + receive.lastName;
    }
    if (receive.lastName2 != null) {
        name += ' ' + receive.lastName2;
    }
    return name;
}

export {
    complianceFields, destinationFieldsIgnore, senderFieldsIgnore, getCountryName, getFieldsByCategory, formatNumber,
    getPhoneCode, getEnumLabel, getStates, getSenderName, getReceiverName
}