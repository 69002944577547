import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthUser } from 'react-auth-kit';
import 'datatables.net-bs5'
import 'datatables.net-responsive-bs5'
import $ from 'jquery';
import ReactDom from 'react-dom';
import { createRoot } from 'react-dom/client'
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import { formatAmount } from "../../utils/helper";
import { useLocation } from "react-router-dom";

const PaidTxnIndex = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    let cmpt = 0;
    const dispatch = useDispatch();
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fromPage, setFromPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fromElmt, setFromElmt] = useState(0);
    const [toElmt, setToElmt] = useState(0);
    const [recordPerPage, setRecordPerPage] = useState(10);
    const npage = Math.ceil(total/recordPerPage);
    const [duration, setDuration] = useState("1");
    const [companies, setCompanies] = useState([]);
    const [submitPaid, setSubmitPaid] = useState("");
    const stateAccount = location.state ? location.state.account : null; // Check if state is passed
    const [account, setAccount] = useState(stateAccount || {
        agent_location_id: "",
        agent_company_id: '',
        type: '',
        from_date: '',
        to_date: '',
        per_page: 10
    });
    const numbers = [];
    for (let i = 1; i <= npage; i++) {
        numbers.push(i);
    }
    useEffect(() => {
        if (globalAuthUser.location.agent_country.enable_submitPaid === '0'){
            setSubmitPaid(false)
        }else if (globalAuthUser.location.agent_country.enable_submitPaid === '1'){
            setSubmitPaid(true)
        }
        var payload = {
            duration:1,
            agent_location_id: globalAuthUser?.location.id,
            // from_date:'2023-01-06',
            // to_date:'2023-02-06',
            per_page: 10
        };
        if (stateAccount){
            fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/receiveTransactions',
            currentPage,
            stateAccount);
        }else {
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/receiveTransactions',
        currentPage,
        payload);}
    }, []);

    function fetchData(url,currentPage, payload) {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = payload;
        dispatch(showLoading());
        axios.post(url, data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data.data.transactions.data);
            setFromPage(response.data.data.transactions.from);
            setTransactions(response.data.data.transactions.data);
            setTotal(response.data.data.transactions.total);
            setFromElmt(response.data.data.transactions.from);
            setToElmt(response.data.data.transactions.to);
            setRecordPerPage(response.data.data.transactions.per_page);
            setCurrentPage(currentPage);
            setCompanies(response.data.data.agentCompanies);
        })
        .catch(error => {
            console.error(error);
        });
    }
    function changeCPage(page) {
        dispatch(showLoading());
        var payload = {
            duration: duration,
            agent_location_id: globalAuthUser?.location.id,
            agent_company_id: account.agent_company_id,
            type: account.type,
            from_date: account.from_date,
            to_date:account.to_date,
            per_page: account.per_page !== null ? account.per_page : 10,
        };
        fetchData(`${process.env.REACT_APP_BNB_BASE_URL}/api/portal/partner/receiveTransactions?page=${page}`,
        page,
        payload)
    }

    const handleInput = (e) => {
        e.persist();
        setAccount((prevAccount) => {
          const updatedAccount = { ...prevAccount, [e.target.name]: e.target.value };
          if (e.target.name === "per_page") {
            account.per_page = e.target.value;
            searchByParam(e);
          }
          if (e.target.name === 'from_date') {
            const from_date = e.target.value;
            updatedAccount.from_date = from_date;
            
            const fromDateObject = new Date(from_date);
            const toDateObject = new Date(
              fromDateObject.getFullYear(),
              fromDateObject.getMonth() + 1,
              fromDateObject.getDate()
            );
            const to_date = toDateObject.toISOString().split('T')[0];
            updatedAccount.to_date = to_date;
          }
          return updatedAccount;
        });
      };
      

    function handleDurationChange(event) {
        setDuration(event.target.value);
    }
    function handleDateInvalid(event) {
        event.target.setCustomValidity("Please select a date.");
    }

    function resetSearch(event) {
        var myForm = document.getElementById('my-form');
        myForm.reset();
        setDuration(1);
        dispatch(showLoading());
        event.preventDefault();
        var payload = {
            duration: 1,
            agent_location_id: globalAuthUser?.location.id,
            agent_company_id: account.agent_company_id,
            type: account.type,
            from_date: account.from_date ,
            to_date: account.to_date ,
            per_page: account.per_page !== null ? account.per_page : 10,
        };
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/receiveTransactions',
        currentPage,
        payload);
    }

    function searchByParam(event) {
        dispatch(showLoading());
        event.preventDefault();
        var payload = {
            duration: duration,
            agent_location_id: globalAuthUser?.location.id,
            agent_company_id: account.agent_company_id,
            type: account.type,
            from_date: account.from_date ,
            to_date: account.to_date ,
            per_page: account.per_page !== null ? account.per_page : 10,
        };
        if(duration === "6"){
            const from_date_error = document.getElementById('from_date_error');
            const to_date_error = document.getElementById('to_date_error');
            if (account.from_date === "") {
                from_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                from_date_error.textContent = '';
            }
            if (account.to_date === "") {
                to_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                to_date_error.textContent = '';
            }
        }
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/receiveTransactions',
        currentPage,
        payload);
    }

    var listTransactions = "";
    listTransactions = transactions?.map((item, index)=> {
        const {id, pickup_code, company, receiving_amount, paid_at, receiving_currency, status, notes 
            , first_name, last_name
        } = item;
         return (
             <tr key={id} >
                <td>{fromPage+index}</td>
                <td>
                    <button type="button" className="btn btn-link" onClick={() => {
                        navigate('/paid-transaction/' + id)
                    }}>
                    {pickup_code}
                    </button>
                </td>
                 <td>{company}</td>
                 <td>{formatAmount(receiving_amount)} {receiving_currency}</td>
                 <td>{status}</td>
                 <td>{paid_at}</td>
                 <td>{first_name} {last_name}</td>
                 <td>{notes}</td>
            </tr>
         )
     });
    return (
        <React.Fragment>
            <div>
                <div style={{ padding: "20px" }}>
                    <div className="card ">
                    { submitPaid && (
                        <div className="card-header d-flex justify-content-between">
                        <h5>{t('PAID_TRANSACTIONS')}</h5>
                        <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate("/paid-transaction/create")}
                    >
                        {t("SUBMIT_NEW_PAID_TRANSACTION")}
                    </button>
                    </div>
                    )}   
                        <div className="card-body">
                            <form id="my-form">
                                <div className="form-group row justify-content-md-center">
                                    <div className="col-md-3">
                                        <label className=""  htmlFor="agent_company_id">{t('COMPANY')}</label>
                                        <select className="form-select" onChange={handleInput} name="agent_company_id" id="agent_company_id" aria-label="Default select example">
                                            <option value={""}>{t('SELECT_A_COMPANY')}</option>
                                            { 
                                                companies?.map((company, index) => {
                                                    if(company.company_code !== "OM"){
                                                        return (
                                                            <option value={company.company_id} key={index}>{company.company_code}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label className=""  htmlFor="duration">{t('DURATION')}</label>
                                        <select className="form-select" onChange={handleDurationChange} name="duration" id="duration" value={duration} aria-label="Default select example">
                                            <option value="1" >{t('TODAY')}</option>
                                            <option value="2" >{t('THIS_WEEK')}</option>
                                            <option value="3" >{t('THIS_MONTH')}</option>
                                            <option value="4" >{t('PREVIOUS_WEEK')}</option>
                                            <option value="5" >{t('PREVIOUS_MONTH')}</option>
                                            <option value="6" >{t('CUSTOM_DATE')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row justify-content-md-center">
                                    {
                                        duration === "6" && (
                                            <>
                                                <div className="col-md-3 customDate" >
                                                    <label htmlFor="from_date">{t('FROM_DATE')} <span style={{color: "red"}}>*</span></label>
                                                    <input className="form-control custom-field" value={account.from_date} required onInvalid={handleDateInvalid} id="from_date" type="date" onChange={handleInput} name="from_date"  />
                                                    <span id="from_date_error" className="text-danger"></span>
                                                </div>

                                                <div className="col-md-3 customDate">
                                                    <label htmlFor="to_date">{t('TO_DATE')} <span style={{color: "red"}}>*</span></label>
                                                    <input className="form-control custom-field" value={account.to_date} required readOnly onInvalid={handleDateInvalid} id="to_date" type="date" name="to_date" />
                                                    <span id="to_date_error" className="text-danger"></span>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="form-group row justify-content-md-center mt-3">
                                    <button type="submit" onClick={searchByParam} className="col-sm-2 btn btn-primary" id="apply-filter-paid" data-count="0">{t('SEARCH')}</button>
                                    &nbsp;
                                    <button type="reset" onClick={resetSearch} className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CLEAR_SEARCH')}</button>
                                </div>
                                <div className="per_page m-2">
                                    <label>
                                        <div className="show">{t('SHOW')}</div>
                                        <select name="per_page" id="per_page" value={account.per_page} onChange={handleInput} className="form-select">
                                            <option value="10" selected>10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value={total}>{t('ALL')}</option>
                                        </select>
                                        <div className="entries">{t('ENTRIES')}</div>
                                    </label>
                                </div>
                            </form>
                            <table id="datatable" className="table table-bordered grid-table responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('REFERENCE')}</th>
                                        <th>{t('COMPANY')}</th>
                                        <th>{t('AMOUNT')}</th>
                                        <th>{t('STATUS')}</th>
                                        <th>{t('PAID_AT')}</th>
                                        <th>{t('PAID_BY')}</th>
                                        <th>{t('NOTE')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listTransactions}
                                </tbody>
                                
                            </table>
                        </div>
                        <div className="card-footer">
                            
                            {
                                numbers.length > 1 ? (
                                    <>
                                        <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className="left-info">
                                                <span>
                                                {t('SHOWING')} {fromElmt} {t('TO')} {toElmt} {t('OF')} {total} {t('ENTRIES')}
                                                </span>
                                            </div>
                                            <div className="float-end">
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination" style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                                                        <li className="page-item">
                                                            <button type="button" onClick={() => changeCPage(currentPage-1)} className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} aria-label="Previous">
                                                                <span aria-hidden="true">&laquo;</span>
                                                            </button>
                                                        </li>
                                                        
                                                        {numbers.map((page, index) => (
                                                        <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={index}>
                                                            {
                                                                (
                                                                    page === 1 || 
                                                                    page === numbers.length || 
                                                                    currentPage === page || 
                                                                    currentPage+1 === page || 
                                                                    currentPage+2 === page ||
                                                                    currentPage-1 === page || 
                                                                    currentPage-2 === page )  ? 
                                                                    (<button type='button' onClick={() => changeCPage(page)} className="page-link">
                                                                        {
                                                                            page
                                                                        }
                                                                    </button>) 
                                                                : ((cmpt === 1 && (page === currentPage+3)) ? (
                                                                    <button className="page-link">
                                                                    {(() => {
                                                                        cmpt++;
                                                                        return '...';
                                                                    })()}
                                                                    </button>
                                                                ) : ((cmpt === 0) ? (
                                                                    <button className="page-link">
                                                                    {(() => {
                                                                        cmpt++;
                                                                        return '...';
                                                                    })()}
                                                                    </button>
                                                                ) : null))
                                                            }
                                                        </li>
                                                        ))}
                                                        <li className="page-item">
                                                            <button type="button" onClick={() => changeCPage(currentPage+1)} className={`page-link ${currentPage === numbers.length ? 'disabled' : ''}`} aria-label="Previous">
                                                                <span aria-hidden="true">&raquo;</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default PaidTxnIndex;