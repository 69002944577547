import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MoneygramFees from './MoneygramFees';
import { setCodeTable, setComplete, setStep, setFeeInfoState, setReceivers, setCities, setConsumers } from '../../../slices/send-money-moneygram-slice';
import { useAuthUser } from 'react-auth-kit';
import MoneygramSender from './MoneygramSender';
import MoneygramReceiver from './MoneygramReceiver';
import MoneygramSummary from './MoneygramSummary';
import '../../MoneyGram/Receive/stepper.css';
import { TiTick } from "react-icons/ti";
import MoneygramComplete from './MoneygramComplete';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import UnauthorizedService from '../../utilities/UnauthorizedService';

function MoneygramHome() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentStep = useSelector(state => state.sendMoneyMoneygram.step);
    const complete = useSelector(state => state.sendMoneyMoneygram.complete);
    const steps = [t("ESTIMATE_FEES"), t("SENDER"), t("RECEIVER"), t("SUMMARY"), t("CONFIRMATION")];
    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);

    useEffect(() => {
        console.log('MoneygramHome')
        dispatch(setStep(1))
        dispatch(setComplete(false))
        console.log('currentStep', currentStep)
        dispatch(setFeeInfoState({}))
        dispatch(setReceivers([]))
        dispatch(setCities([]))
        dispatch(setConsumers([]))

        let params = {
            language: i18next.language
        }

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/codeTable', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            dispatch(setCodeTable(res.data.data));
        });
    }, []);

    return (
        <div style={{ padding: "20px" }}>
            <div className="card">
                <div className="card-header" style={{ fontWeight: "600" }}>
                    {t("MONEYGRAM_SEND_TRANSACTION")}
                </div>
                <div className="card-body">
                    {
                        globalAuthUser == null && <p>{t('LOADING')}</p>
                    }
                    {
                        globalAuthUser?.location?.enable_send_moneygram == true &&
                        <>
                            <div className="tw-flex tw-justify-between">
                                {steps?.map((step, i) => (
                                    <div
                                        key={i}
                                        className={`step-item ${currentStep === i + 1 && "active"} ${(i + 1 < currentStep || complete) && "complete"
                                            } `}
                                    >
                                        <div className="step">
                                            {i + 1 < currentStep || complete ? <TiTick size={24} /> : i + 1}
                                        </div>
                                        <p className="tw-text-gray-500">{step}</p>
                                    </div>
                                ))}
                            </div>

                            <div style={{ display: currentStep == 1 ? 'block' : "none" }}>
                                <MoneygramFees />
                            </div>

                            <div style={{ display: currentStep == 2 ? 'block' : "none" }}>
                                <MoneygramSender />
                            </div>

                            <div style={{ display: currentStep == 3 ? 'block' : "none" }}>
                                <MoneygramReceiver />
                            </div>

                            <div style={{ display: currentStep == 4 ? 'block' : "none" }}>
                                <MoneygramSummary />
                            </div>

                            <div style={{ display: currentStep == 5 ? 'block' : "none" }}>
                                <MoneygramComplete />
                            </div>
                        </>
                    }
                    {
                        globalAuthUser?.location?.enable_send_moneygram == false && <UnauthorizedService />
                    }
                </div>
            </div>
        </div>
    );

}

export default MoneygramHome