import axios from 'axios';
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setFormFree, setReference } from '../../../../slices/send-money-moneygram-slice';
import { getCountryName, getSenderName, getReceiverName } from '../moneygram';
import { FaTimesCircle, FaArrowCircleRight, FaSearch } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function MoneygramFormFreeSend() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useAuthUser()
    const formRef = useRef();

    const codeTable = useSelector((state) => state.sendMoneyMoneygram.codeTable);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    const [confirmationNumber, setConfirmationNumber] = useState("");
    const [amount, setAmount] = useState("");
    const [lookupError, setLookupError] = useState(null);
    const [detail, setdetail] = useState({});
    const [displayDetails, setDisplayDetails] = useState(false);
    const [message, setMessage] = useState(null);

    const schema = yup.object().shape({
        confirmation_number: yup.string().required(t('FIELD_REQUIRED')).min(4, t("FIELD_MIN", { min: 4 })),
        amount: yup.number("Invalid amount").positive().required(t('FIELD_REQUIRED')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    const formFreeLookup = () => {
        setDisplayDetails(false)
        setLookupError(null)
        setMessage(null)
        dispatch(showLoading());

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/formFreeSendLookup', {
            confirmationNumber: confirmationNumber,
            amount: amount,
            language: i18next.language
        }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            dispatch(hideLoading());

            var status = res.data.status
            var message = res.data.message
            if (status == 0) {
                setLookupError(message)
            } else {
                setDisplayDetails(true)
                setdetail(res.data.data)
            }
        });
    }

    const commitTransaction = () => {
        setMessage(null)
        dispatch(showLoading());

        let params = {
            locationCode: globalAuthUser?.location?.code,
            amountTotal: +parseFloat(detail?.sendAmounts?.sendAmount) + parseFloat(detail?.sendAmounts?.totalSendFees) + parseFloat(detail?.sendAmounts?.totalSendTaxes)
        }

        let data = { ...params, ...detail }

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/commitTransaction', data, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            dispatch(hideLoading());

            var status = res.data.status
            var data = res.data.data
            if (status == 1) {
                dispatch(setFormFree(true))
                dispatch(setReference(data.referenceNumber))
            } else {
                setMessage(res.data.message)
            }
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit(formFreeLookup)} ref={formRef}>
                <div className='row mt-4'>
                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <div className="form-group">
                            <label htmlFor='confirmation_number'>{t("CONFIRMATION_NUMBER")} <span style={{ color: "red" }}>*</span></label>
                            <input type="text" id='confirmation_number' style={{ maxWidth: "100%" }} className={`form-control ${errors.confirmation_number ? 'is-invalid' : ''}`} placeholder={t("CONFIRMATION_NUMBER")} {...register('confirmation_number', {
                                onChange: (e) => setConfirmationNumber(e.target.value),
                            })} />
                        </div>
                        {errors?.confirmation_number != null && <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors?.confirmation_number?.message} </p>}
                    </div>
                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <div className="form-group">
                            <label htmlFor='amount'>{t("AMOUNT")} <span style={{ color: "red" }}>*</span></label>
                            <input type="text" id='amount' style={{ maxWidth: "100%" }} className={`form-control ${errors.amount ? 'is-invalid' : ''}`} placeholder={t("AMOUNT")} {...register('amount', {
                                onChange: (e) => setAmount(e.target.value),
                            })} />
                        </div>
                        {errors?.amount != null && <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors?.amount?.message} </p>}
                    </div>
                    <div className="col-sm-12 d-flex justify-content-end" style={{ marginTop: "25px" }}>
                        <button className='btn btn-info' type='submit'>{t("SEARCH")} &nbsp;
                            <FaSearch size={18} color={'white'} />
                        </button>
                    </div>
                    {
                        lookupError != null &&
                        <p className='input-error-msg' style={{ marginBottom: "0px" }}> {lookupError} </p>
                    }
                </div>
            </form>

            {
                displayDetails &&
                <>
                    <div style={{ marginTop: "25px" }}>
                        {
                            !detail?.receiveAmounts?.validCurrencyIndicator &&
                            <p className='input-error-msg mt-2'>{t('VALID_INDICATOR_MESSAGE')}</p>
                        }
                        <div className='row mt-4'>
                            <div className='col-sm-6'>
                                <div className='card p-3'>
                                    <div className="card-body">
                                        <h5 className="card-title">{t('SEND_DETAILS')}</h5>
                                        <hr />
                                        <table className="table table-sm table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: '40%' }}></th>
                                                    <th style={{ width: '60%' }}></th>
                                                </tr>
                                                <tr>
                                                    <td>{t('CONFIRMATION_NUMBER')}</td>
                                                    <td><strong>{detail?.confirmationNumber}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('NAME')}</td>
                                                    <td><strong>{getSenderName(detail)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('COUNTRY')}</td>
                                                    <td><strong>{getCountryName(codeTable, detail?.senderCountry)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('CITY')}</td>
                                                    <td><strong>{detail?.senderCity}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('ADDRESS')}</td>
                                                    <td><strong>{detail?.senderAddress}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('PHONE_NUMBER')}</td>
                                                    <td><strong>{detail?.senderHomePhone}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('DATE_OF_BIRTH')}</td>
                                                    <td><strong>{detail?.senderDOB}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('BIRTH_COUNTRY')}</td>
                                                    <td><strong>{getCountryName(codeTable, detail?.senderBirthCountry)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('AMOUNT')}</td>
                                                    <td><strong>{detail?.sendAmounts?.sendAmount} {detail?.sendAmounts?.sendCurrency}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('FEE')}</td>
                                                    <td><strong>{detail?.sendAmounts?.totalSendFees} {detail?.sendAmounts?.sendCurrency}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('TAX')}</td>
                                                    <td><strong>{detail?.sendAmounts?.totalSendTaxes} {detail?.sendAmounts?.sendCurrency}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('TOTAL_AMOUNT')}</td>
                                                    <td><strong>{+parseFloat(detail?.sendAmounts?.sendAmount) + parseFloat(detail?.sendAmounts?.totalSendFees) + parseFloat(detail?.sendAmounts?.totalSendTaxes)} {detail?.sendAmounts?.sendCurrency}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='card p-3'>
                                    <div className='card-body'>
                                        <h5 className="card-title">{t('RECEIVE_DETAILS')}</h5>
                                        <hr />
                                        <table className="table table-sm table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: '40%' }}></th>
                                                    <th style={{ width: '60%' }}></th>
                                                </tr>
                                                <tr>
                                                    <td>{t('NAME')}</td>
                                                    <td><strong>{getReceiverName(detail)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('COUNTRY')}</td>
                                                    <td><strong>{getCountryName(codeTable, detail?.destinationCountry)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('AMOUNT')}</td>
                                                    <td><strong>{detail?.receiveAmounts?.receiveAmount} {detail?.receiveAmounts?.payoutCurrency}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('FEE')}</td>
                                                    <td><strong>{detail?.receiveAmounts?.totalReceiveFees} {detail?.receiveAmounts?.payoutCurrency}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('TAX')}</td>
                                                    <td><strong>{detail?.receiveAmounts?.totalReceiveTaxes} {detail?.receiveAmounts?.payoutCurrency}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('TOTAL_AMOUNT')}</td>
                                                    <td><strong>{+parseFloat(detail?.receiveAmounts?.receiveAmount) - parseFloat(detail?.receiveAmounts?.totalReceiveFees) + parseFloat(detail?.receiveAmounts?.totalReceiveTaxes)} {detail?.receiveAmounts?.payoutCurrency}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "25px", textAlign: "right" }}>
                        <button type='button' className='btn btn-primary' onClick={() => commitTransaction()}>
                            {t('SUBMIT')} &nbsp;
                            <FaArrowCircleRight size={18} fill={'white'} />
                        </button>
                    </div>
                </>
            }
            {
                message != null &&
                <div style={{ marginTop: "25px" }}>
                    <div className="alert alert-danger d-flex align-items-center" role="alert">
                        <FaTimesCircle size={18} /> &nbsp;
                        <div>
                            {message}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default MoneygramFormFreeSend;