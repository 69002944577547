import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setCurrentStep, setPaymentDetails, setReceivingMerchantCode, setReceivingMerchantName } from '../../slices/merchant-payment-slice';

export default function MerchantPaymentComplete() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paymentDetails = useSelector(state => state.merchantPayment.paymentDetails);

  const resetPaymentForm = () => {
    dispatch(setCurrentStep(0));
    dispatch(setReceivingMerchantCode(""));
    dispatch(setReceivingMerchantName(""));
    dispatch(setPaymentDetails({}));
  }

  const backToDashBoard = () => {
    resetPaymentForm();
    navigate('/home');
  }

  return (
        <>
            {/** complete icon */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <i className='bx bxs-badge-check text-success p-3' style={{ fontSize: "60px" }}></i>
                </div>
            </div>

            {/** complete icon */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <span className="text-success">{ t("MERCHANT_PAYMENT_SUCCESSFUL") }</span>
                </div>
            </div>

            {/** transaction reference summary  */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <span>
                        { t("YOUR_PAYMENT_REFERENCE_IS") }: {paymentDetails.reference_no}
                    </span>
                </div>
            </div>

            {/** action buttons */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <button type='button' className="btn btn-primary btn-sm mx-2"
                    onClick={backToDashBoard}>
                        { t("DASHBOARD") }
                    </button>
                    <button type='button' className="btn btn-secondary btn-sm" onClick={resetPaymentForm}>
                        { t("PAY_ANOTHER") }
                    </button>
                </div>
            </div>
        </>
  )
}
