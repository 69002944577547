import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, setReceiverDetails } from '../../slices/local-sending-slice';
import CreateUserButton from './localSendingUtils/CreateUserButton';
import PhoneNumberSearch from './localSendingUtils/PhoneNumberSearch';
import CustomerRegisterForm from './localSendingUtils/CustomerRegisterForm';
import UserDetails from './localSendingUtils/UserDetails';
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useTranslation } from 'react-i18next';

export default function ReceiverDetails() {
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const { t } = useTranslation();

    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);
    const currentStep = useSelector(state => state.localSending.currStep);
    const receiverDetails = useSelector(state => state.localSending.receiverDetails);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [currentReceiverForm, setCurrentReceiverForm] = useState('');

    const handleNext = () => {
        // make sure the sender details is set
        if(!Object.entries(receiverDetails).length){
            // sender details not set
            dispatch(showToast(["danger", "Error", t("LOCAL_RECEIVER_REQUIRED")]));
            return;
        }

        // move to the next step
        dispatch(setCurrentStep(currentStep + 1));
    }

    /**
     * handling the click event for the add sender button
     */
    const handleAddReceiver = () => {
        // reset the selected sender
        dispatch(setReceiverDetails({}));

        // set the current form as the registration form
        setCurrentReceiverForm("register");
    }

    /**
     * processing the sender phone number search
     * @param {*} serarchPhone 
     */
    const processReceiverSearch = async(serarchPhone) => {
        // update the phone number
        setPhoneNumber(serarchPhone);
        setCurrentReceiverForm('');

        // start the loading activity
        dispatch(showLoading());

        // search for the phone number
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/searchCustomer`;
        const agentCountryDetails = globalAuthUser.location.agent_country;
        
        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                "searchParam": serarchPhone,
                "agent_country_id": agentCountryDetails.id
            }
        }).then(res => {
            // stop the loading activity
            dispatch(hideLoading());

            // process the response
            const resData = res.data;
            if(resData.status === 1){
                // extract the data 
                const userDetails = resData.data;
                
                // update the sender details in the local sending store
                dispatch(setReceiverDetails(userDetails));
                
            } else {
                // extract the message
                let errMessage = resData.message;

                // display the error message on the console
                dispatch(setReceiverDetails({}));
                dispatch(showToast(["danger", "Error", t(errMessage)]));
            }

        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            console.log(err);
            dispatch(setReceiverDetails({}));
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }

    /**
     * Processing the sender registration
     * @param {*} event 
     */
    const processReceiverRegistration = (e) => {
        e.preventDefault();

        // start the loading activity
        dispatch(showLoading());

        // extract the form data
        const formData = new FormData(e.target);
        const jsonData = Object.fromEntries(formData.entries());

        // get the agent country details
        const agentCountryDetails = globalAuthUser.location.agent_country;

        // register the user
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/api/customerRegister`;
        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            data: {
                "first_name": jsonData.first_name,
                "last_name": jsonData.last_name,
                "phone_number": jsonData.phone_number,
                "hdn_dialing_code": agentCountryDetails.phone_code,
                "country_id": globalAuthUser.location.main_country_id,
                "is_incomplete_user": 1, // user is registered as an incomplete user
                "is_otp_required": 0, // otp is not required for receiver
                "agree": 1
            }
        }).then(res => {
            // start the loading activity
            dispatch(hideLoading());

            // process response
            const resData = res.data;
            if(!resData.status){
                // registration failed notify the user
                const err_msg = resData.message ? t(resData.message) : t("ERROR_HAS_OCCURED");
                dispatch(showToast(["danger", "Registration Error", err_msg]));
                return;
            }

            // set the registered user details
            const userDetails = resData.additionalParams.userDetails;
            dispatch(setReceiverDetails(userDetails));

            // notify the user
            dispatch(showToast(["success", "Registration Success", t("USER_REGISTRATION_SUCCESSFUL")]));

        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            // extract the response data
            const err_res_data = err.response.data;
            if(err_res_data && err_res_data.status !== 500){
                // transaction failed with a valid error message
                dispatch(showToast(["danger", "Error", t(err_res_data.message)]));
                return;
            }

            console.log(err);
            // notify the user
            dispatch(setReceiverDetails({}));
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }

    return (
        <>
            {/** create user button */}
            <CreateUserButton children={ t("ADD_NEW_RECEIVER")} handleClick = {handleAddReceiver}/>

            {/** searching by phone number */}
            <PhoneNumberSearch children={t("SEARCH_RECEIVER")} handleSearch={processReceiverSearch} />

            {/** user registration form */}
            {
                currentReceiverForm === "register" &&
                <CustomerRegisterForm  handleSubmit = {processReceiverRegistration} phoneNumber={phoneNumber} />
            }

            {/** sender details */}
            <UserDetails {...receiverDetails} />

            {/** control buttons */}
            <div className="row mb-2">
                <div className="col-12 col-lg-2"></div>
                <div className="col-12 col-lg-8 d-flex justify-content-between">
                    <button type='button' className="btn btn-sm btn-secondary" onClick={() => dispatch(setCurrentStep(currentStep - 1))}>
                        { t("BACK") }
                    </button>

                    {
                        Object.entries(receiverDetails).length !== 0 &&
                        <button type='button' className="btn btn-sm btn-primary" onClick={handleNext}>
                            { t("NEXT") }
                        </button>
                    }
                </div>
            </div>
        </>
    )
}
