import React, { useEffect, useState } from 'react'
import TellerListing from './tellerAccessComps/TellerListing';
import TellerAccessRequests from './tellerAccessComps/TellerAccessRequests';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthUser } from 'react-auth-kit';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { showToast } from '../../slices/toast-slice';
import axios from 'axios';
import { setIDTypes } from '../../slices/location-users-slice';

export default function TellerAccessIndex() {
    const [selectedTab, setSelectedTab] = useState("TELLERS");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useAuthUser();

    const fetchIDTypes = () => {
        dispatch(showLoading());
        const headers = {
            Authorization: authUser().tokenType + " " + authUser().token,
        };

        axios.get(process.env.REACT_APP_BNB_BASE_URL + "/api/guest/web/govtIdTypes", {
                headers,
            })
            .then((res) => {
                dispatch(hideLoading());
                let fetchedIdTypes = res.data.data.id_type_list ?? [];
                console.log(fetchedIdTypes);
                dispatch(setIDTypes(fetchedIdTypes))
            });
    };
    
    useEffect(() => {
        fetchIDTypes();
    }, []);
   

  return (
    <>
        <div style={{ padding: "20px" }}>
            <div className="card">
                 {/* the card header */}
                <div className="card-header d-flex justify-content-between">
                    {/* the label */}
                    <div className="card-label">
                        <h5>{t('LOCATION_ACCESS_REQS')}</h5>
                    </div>

                    {/* new teller access request  */}
                    <div className='card-toolbar'>
                        <button type="button" className="btn btn-sm btn-primary"
                        onClick={() => navigate('/creareTellerRequest')}>
                            {t('REQUEST_TELLER_ACCESS')}
                        </button>
                    </div>

                </div>

                {/* the card body */}
                <div className="card-body">
                    <ul className="nav nav-tabs nav-justified mb-3">
                        <li className="nav-item" onClick={() => setSelectedTab("TELLERS")}>
                            <a href="#tellerListing" className={ selectedTab === "TELLERS" ? "nav-link active" : "nav-link" }>
                                {t('LOCATION_TELLERS')}
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => setSelectedTab("ACCESS_REQUEST")}>
                            <a href="#accessRequests" className={ selectedTab === "ACCESS_REQUEST" ? "nav-link active" : "nav-link"}>
                                {t('TELLER_ACCESS_REQS')}
                            </a>
                        </li>
                    </ul>
                    
                    {/* tab content  */}
                    <div className="tab-content">
                        <div className={ selectedTab === "TELLERS" ? "tab-pane container active" : "tab-pane container" } id="tellerAccess">
                            
                            <TellerListing />

                        </div>

                        <div className={ selectedTab === "ACCESS_REQUEST" ? "tab-pane container active" : "tab-pane container" } id="accessRequest">
                            
                            <TellerAccessRequests />

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}
