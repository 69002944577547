import React from "react";
import { useTranslation } from "react-i18next";

const CustomDuration = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-md-3">
                <label htmlFor="duration">{t('DURATION')}</label>
                <select className="form-select" onChange={props.handleDurationChange} name="duration" id="duration" value={props.duration} aria-label="Select Duration">
                    <option value="1">{t('TODAY')}</option>
                    <option value="2">{t('THIS_WEEK')}</option>
                    <option value="3">{t('THIS_MONTH')}</option>
                    <option value="4">{t('PREVIOUS_WEEK')}</option>
                    <option value="5">{t('PREVIOUS_MONTH')}</option>
                    <option value="6">{t('CUSTOM_DATE')}</option>
                </select>
            </div>
            <div className="form-group row justify-content-md-center">
                {props.duration === "6" && (
                    <>
                        <div className="col-md-3 customDate">
                            <label htmlFor="from_date">{t('FROM_DATE')} <span style={{ color: "red" }}>*</span></label>
                            <input className="form-control custom-field" value={props.from_date} required onInvalid={props.handleDateInvalid} id="from_date" type="date" onChange={props.handleInput} name="from_date" />
                            <span id="from_date_error" className="text-danger"></span>
                        </div>

                        <div className="col-md-3 customDate">
                            <label htmlFor="to_date">{t('TO_DATE')} <span style={{ color: "red" }}>*</span></label>
                            <input className="form-control custom-field" value={props.to_date} required readOnly onInvalid={props.handleDateInvalid} id="to_date" type="date" name="to_date" />
                            <span id="to_date_error" className="text-danger"></span>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default CustomDuration;
