import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import CustomPaginateOption from "../../CustomPaginateOption";
import { showToast } from "../../../slices/toast-slice";

const CommissionReport = () => {
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];
      const formattedLastDay =today.toISOString().split('T')[0];


  const [param,setParams] = useState({
    from_date:formattedFirstDay,
    to_date:formattedLastDay,
    per_page: 10,
  })

  const [reports,setReports]= useState([]);
  const [total, setTotal] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(2);
  const npage = Math.ceil(total / recordPerPage);
  const [fromElmt, setFromElmt] = useState(0);
  const [toElmt, setToElmt] = useState(0);
  const [fromPage, setFromPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  let cmpt = 0;
  const numbers = [];
  for (let i = 1; i <= npage; i++) {
    numbers.push(i);
  }

  
  useEffect(() => {
      getDailyCommissions('/api/portal/getDailyCommissions');  
}, []);

  const handleDateInvalid = (event)  => {
    event.target.setCustomValidity("Please select a date.")
  }

  const handleInput = (e) => {
    e.persist();
    setParams((prevParams) => {
        const updatedAccount = {
            ...prevParams,
            [e.target.name]: e.target.value,
          };
          if (e.target.name === "per_page") {
            param.per_page = e.target.value;
           searchByParam(e)
          }

          if (e.target.name === "from_date") {
            const from_date = e.target.value;
            updatedAccount.from_date = from_date;
    
            const fromDateObject = new Date(from_date);
            const toDateObject = new Date(
              fromDateObject.getFullYear(),
              fromDateObject.getMonth() + 1,
              fromDateObject.getDate()
            );
            const to_date = toDateObject.toISOString().split("T")[0];
            updatedAccount.to_date = to_date;
          }
         
          return updatedAccount;
    }) 

  }

  function changeCPage(page) {
  
    setCurrentPage(page);
    getDailyCommissions(`/api/portal/getDailyCommissions?page=${page}`,page)
  }

  const getDailyCommissions = (url, page) => {
    let data = {
        
            per_page:param.per_page,
            from_date:param.from_date,
            to_date:param.to_date,
            page: page   
    }
    const headers = {
        Authorization: authUser().tokenType + " " + authUser().token,
      };

      dispatch(showLoading())

    axios.post(process.env.REACT_APP_BNB_BASE_URL + url, data,{headers})
        .then(response =>{
          dispatch(hideLoading())
          if(response.data.status === 1){
            setReports(response.data.data.data)
            setRecordPerPage(response.data.data.per_page)
            setTotal(response.data.data.total)
            setFromElmt(response.data.data.from)
            setFromPage(response.data.data.from)
            setToElmt(response.data.data.to)
            setCurrentPage(currentPage)
          }
        })
        .catch(error => {
          dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
      });
  }

  function searchByParam(event) {
    dispatch(showLoading());
    event.preventDefault();

    const from_date_error = document.getElementById('from_date_error');
    const to_date_error = document.getElementById('to_date_error');
   
        if (param.from_date === "") {
            // from_date_error.textContent = 'Please enter a valid date';
            dispatch(hideLoading());
            return ;
        } else {
            // from_date_error.textContent = '';
        }
        if (param.to_date === "") {
            // to_date_error.textContent = 'Please enter a valid date';
            dispatch(hideLoading());
            return ;
        } else {
            // to_date_error.textContent = '';
        }
    
    
    getDailyCommissions('/api/portal/getDailyCommissions',currentPage);
    
}
  

  return (
    <React.Fragment>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="col-sm-4 mt-2">{t("DAILY COMMISSION REPORTS")}</h5>
            <span>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate("/summary-reports")}
              >
                <FaArrowLeft size={18} fill={"white"} /> &nbsp;
                {t("BACK")}
              </button>
            </span>
          </div>

          <div className="card-body mt-2">
            <form  id="my-form">
                <div className="form-group row justify-content-md-center">
               
                    <div className="col-md-3">
                    <label className=""  htmlFor="from_date">{t('FROM_DATE')}</label>
                    <input
                        className="form-control custom-field"
                        value={param.from_date}
                        required
                        onInvalid={handleDateInvalid}
                        id="from_date"
                        type="date"
                        onChange={handleInput}
                        name="from_date"
                      />
                    </div>
                    <div className="col-md-3">
                    <label className=""  htmlFor="to_date">{t('TO_DATE')}</label>
                    <input
                        className="form-control custom-field"
                        value={param.to_date}
                        required
                        onInvalid={handleDateInvalid}
                        id="to_date"
                        type="date"
                        name="to_date"
                      />
                    </div>

                    <div className="form-group row justify-content-md-center mt-3 mb-5">
                  <button
                    type="button"
                    onClick={searchByParam}
                    className="col-sm-2 btn btn-primary"
                    id="apply-filter-paid"
                    data-count="0"
                  >
                    {t("SEARCH")}
                  </button>
                  &nbsp;
                </div> 
                
                </div>

                <div className="per_page m-2">
                  <CustomPaginateOption
                    per_page={param.per_page}
                    handleInput={handleInput}
                    total={total}
                  />
                  </div>
               
            </form>

            <div className="row mt-4">
                <div className="col-sm-12">
                    <table className="table table-bordered grid-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("DATE")}</th>
                                <th>{t("TOTAL_COMMISSION")}</th>
                            </tr>
                        </thead>
                       
                        <tbody>
                        {reports && reports.map((item, index) => (
                          <tr key={index }>
                            <td>{fromPage+index }</td>
                            <td>
                              <button
                              onClick={() => navigate('/reports/agent-commission/summary/' +item.paid_at )}
                                className="btn btn-link"  style={{ textDecoration: 'none' }}
                              >
                                {item.paid_at}
                              </button>
                            </td>
                      <td>{parseFloat(item.total_commission).toFixed(2)}</td>      
                          </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
            </div>
          </div>

          <div className="card-footer">
              {numbers.length > 1 ? (
                <>
                  <div
                    className="pagination"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="left-info">
                      <span>
                        {t("SHOWING")} {fromElmt} {t("TO")} {toElmt} {t("OF")}{" "}
                        {total} {t("ENTRIES")}
                      </span>
                    </div>
                    <div className="float-end">
                      <nav aria-label="Page navigation example">
                        <ul
                          className="pagination"
                          style={{
                            display: "flex",
                            listStyle: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <li className="page-item">
                            <button
                              type="button"
                              onClick={() => changeCPage(currentPage - 1)}
                              className={`page-link ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                            </button>
                          </li>

                          {numbers.map((page, index) => (
                            <li
                              className={`page-item ${
                                currentPage === page ? "active" : ""
                              }`}
                              key={index}
                            >
                              {page === 1 ||
                              page === numbers.length ||
                              currentPage === page ||
                              currentPage + 1 === page ||
                              currentPage + 2 === page ||
                              currentPage - 1 === page ||
                              currentPage - 2 === page ? (
                                <button
                                  type="button"
                                  onClick={() => changeCPage(page)}
                                  className="page-link"
                                >
                                  {page}
                                </button>
                              ) : cmpt === 1 && page === currentPage + 3 ? (
                                <button className="page-link">
                                  {(() => {
                                    cmpt++;
                                    return "...";
                                  })()}
                                </button>
                              ) : cmpt === 0 ? (
                                <button className="page-link">
                                  {(() => {
                                    cmpt++;
                                    return "...";
                                  })()}
                                </button>
                              ) : null}
                            </li>
                          ))}
                          <li className="page-item">
                            <button
                              type="button"
                              onClick={() => changeCPage(currentPage + 1)}
                              className={`page-link ${
                                currentPage === numbers.length ? "disabled" : ""
                              }`}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&raquo;</span>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommissionReport;
