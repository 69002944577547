import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import CustomPaginateOption from "../../CustomPaginateOption";
import { showToast } from "../../../slices/toast-slice";


const CompanyReports = () => {

    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const { t } = useTranslation();
    const today = new Date();
    const dayBefore = new Date(today);
    dayBefore.setDate(today.getDate() - 1);
    const formattedDayBefore = dayBefore.toISOString().split('T')[0];
        const formattedLastDay =today.toISOString().split('T')[0];

  
  
    const [account,setAccount] = useState({
      from_date:formattedDayBefore,
      to_date:'',
      per_page: 10,
      agent_company_id:''
    })

    const [reports,setReports]= useState([]);
  const [total, setTotal] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(2);
  const npage = Math.ceil(total / recordPerPage);
  const [fromElmt, setFromElmt] = useState(0);
  const [toElmt, setToElmt] = useState(0);
  const [fromPage, setFromPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [companies, setCompanies] = useState([]);

  let cmpt = 0;
  const numbers = [];
  for (let i = 1; i <= npage; i++) {
    numbers.push(i);
  }

  


    useEffect(() => {
      fetchCompanies()
        getDailyCompanySummary('/api/portal/getDailyCompanySummary');  
  }, []);

  const fetchCompanies = () => {
    dispatch(showLoading());
    const headers = {
      Authorization: authUser().tokenType + " " + authUser().token,
    };

    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + "/api/portal/agentCompanies",
        {},
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading());
        if (res.data.status === 1){
        setCompanies(res.data.data) 
        }
        
      });
  };

  const getDailyCompanySummary = (url, page) => {
    let data = {
        
            per_page:account.per_page,
            from_date:account.from_date,
            to_date:account.to_date,
            agent_company_id:account.agent_company_id,
    }
    const headers = {
        Authorization: authUser().tokenType + " " + authUser().token,
      };

      dispatch(showLoading())

    axios.post(process.env.REACT_APP_BNB_BASE_URL + url, data,{headers})
        .then(response =>{
          dispatch(hideLoading())
          if(response.data.status === 1){
           setReports(response.data.data.data)
           setRecordPerPage(response.data.data.per_page)
           setTotal(response.data.data.total)
           setFromElmt(response.data.data.from)
           setFromPage(response.data.data.from)
           setToElmt(response.data.data.to)
           setCurrentPage(currentPage)

          }
          else {
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
          }
        })
        .catch(error => {
          dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
      });
  };



  const handleDateInvalid = (event)  => {
    event.target.setCustomValidity("Please select a date.")
  }

  const handleInput = (e) => {
    e.persist();
    setAccount((prevAccount) => {
        const updatedAccount = {
            ...prevAccount,
            [e.target.name]: e.target.value,
          };
          if (e.target.name === "per_page") {
            account.per_page = e.target.value;
           searchByParam(e)
          }

          if (e.target.name === "from_date") {
            const from_date = e.target.value;
            updatedAccount.from_date = from_date; 
          }
          
          if (e.target.name === "to_date") {
            const to_date = e.target.value;
            updatedAccount.to_date = to_date;
          }
          setAccount(updatedAccount);
         
          return updatedAccount;
    }) 

  }

  function changeCPage(page) {
  
    setCurrentPage(page);
    getDailyCompanySummary(`/api/portal/getDailyCompanySummary?page=${page}`,page)
  }

  function searchByParam(event) {
    dispatch(showLoading());
    event.preventDefault();


    
    getDailyCompanySummary('/api/portal/getDailyCompanySummary',currentPage);
    
}

const handleRoute = (accountData) => {
  navigate(`/paid-transactions`, {
    state: {
     account:{from_date:account.from_date,
      agent_location_id: accountData.agent_location_id,
      agent_company_id: accountData.agent_company_id,
      to_date: '',
    }
      // Pass the specific account data for this row
    },
  });
};

 




        return (
            <React.Fragment>
            <div style={{ padding: "20px" }}>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h5 className="col-sm-4 mt-2">{t("DAILY_COMPANY_REPORTS")}</h5>
                  <span>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate("/summary-reports")}
                    >
                      <FaArrowLeft size={18} fill={"white"} /> &nbsp;
                      {t("BACK")}
                    </button>
                  </span>
                </div>
      
                <div className="card-body mt-2">
                  <form  id="my-form">
                      <div className="form-group row justify-content-md-center">

                      <div className="col-md-3">
                    <label className="" htmlFor="agent_company_id">
                      {t("COMPANY")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleInput}
                      name="agent_company_id"
                      id="agent_company_id"
                      aria-label="Default select example"
                    >
                      <option value={""}>{t("SELECT_A_COMPANY")}</option>
                      {companies.map((item, index) => (
                        <option key={index} value={item.company_id} >
                          {item.company_name}
                        </option>
                      ))}
                    </select>
                  </div>

                     
                          <div className="col-md-3">
                          <label className=""  htmlFor="from_date">{t('FROM_DATE')}</label>
                          <input
                              className="form-control custom-field"
                              value={account.from_date}
                              required
                              onInvalid={handleDateInvalid}
                              id="from_date"
                              type="date"
                              onChange={handleInput}
                              name="from_date"
                            />
                          </div>
                          <div className="col-md-3">
                          <label className=""  htmlFor="to_date">{t('TO_DATE')}</label>
                          <input
                              className="form-control custom-field"
                              value={account.to_date}
                              required
                              onInvalid={handleDateInvalid}
                              id="to_date"
                              type="date"
                              onChange={handleInput}
                              name="to_date"
                            />
                          </div>
      
                          <div className="form-group row justify-content-md-center mt-3 mb-5">
                        <button
                          type="button"
                          onClick={searchByParam}
                          className="col-sm-2 btn btn-primary"
                          id="apply-filter-paid"
                          data-count="0"
                        >
                          {t("SEARCH")}
                        </button>
                        &nbsp;
                      </div> 
                      
                      </div>
      
                      <div className="per_page m-2">
                        <CustomPaginateOption
                          per_page={account.per_page}
                          handleInput={handleInput}
                          total={total}
                        />
                        </div>
                     
                  </form>
      
                  <div className="row mt-4">
                      <div className="col-sm-12">
                          <table className="table table-bordered grid-table">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>{t("DATE")}</th>
                                      <th>{t("COMPANY")}</th>
                                      <th>{t("PAID_AMOUNT")}</th>
                                      <th>{t("TRANSACTIONS")}</th>
                                      <th>{t("ID")}</th>
                                  </tr>
                              </thead>
                             
                              <tbody>
                              {reports && reports.map((item, index) => (
                                <tr key={index }>
                                  <td>{fromPage+index }</td>
                                  <td>{item.paid_at}</td>
                                  <td>
                                    <button
                                     onClick={() => handleRoute(item)} 
                                      className="btn btn-link"  style={{ textDecoration: 'none' }}
                                    >
                                      {item.agent_company_name}
                                    </button>
                                  </td>
                                  <td>{item.transaction_total}</td>
                                  <td>{item.transaction_count}</td>
                                  <td>{item.id}</td>
                                </tr>
                                  ))}
                              </tbody> 
                          </table>
                      </div>
                  </div>
                </div>
      
                <div className="card-footer">
                    {numbers.length > 1 ? (
                      <>
                        <div
                          className="pagination"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="left-info">
                            <span>
                              {t("SHOWING")} {fromElmt} {t("TO")} {toElmt} {t("OF")}{" "}
                              {total} {t("ENTRIES")}
                            </span>
                          </div>
                          <div className="float-end">
                            <nav aria-label="Page navigation example">
                              <ul
                                className="pagination"
                                style={{
                                  display: "flex",
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li className="page-item">
                                  <button
                                    type="button"
                                    onClick={() => changeCPage(currentPage - 1)}
                                    className={`page-link ${
                                      currentPage === 1 ? "disabled" : ""
                                    }`}
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                  </button>
                                </li>
      
                                {numbers.map((page, index) => (
                                  <li
                                    className={`page-item ${
                                      currentPage === page ? "active" : ""
                                    }`}
                                    key={index}
                                  >
                                    {page === 1 ||
                                    page === numbers.length ||
                                    currentPage === page ||
                                    currentPage + 1 === page ||
                                    currentPage + 2 === page ||
                                    currentPage - 1 === page ||
                                    currentPage - 2 === page ? (
                                      <button
                                        type="button"
                                        onClick={() => changeCPage(page)}
                                        className="page-link"
                                      >
                                        {page}
                                      </button>
                                    ) : cmpt === 1 && page === currentPage + 3 ? (
                                      <button className="page-link">
                                        {(() => {
                                          cmpt++;
                                          return "...";
                                        })()}
                                      </button>
                                    ) : cmpt === 0 ? (
                                      <button className="page-link">
                                        {(() => {
                                          cmpt++;
                                          return "...";
                                        })()}
                                      </button>
                                    ) : null}
                                  </li>
                                ))}
                                <li className="page-item">
                                  <button
                                    type="button"
                                    onClick={() => changeCPage(currentPage + 1)}
                                    className={`page-link ${
                                      currentPage === numbers.length ? "disabled" : ""
                                    }`}
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">&raquo;</span>
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
              </div>
            </div>
          </React.Fragment>
        )
}

export default CompanyReports;