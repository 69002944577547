import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    login,
    setGlobalAuthUser,
    setExpiresIn
} from "../../slices/auth-slice";
import bnblogo from "../../assets/images/bnb.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from "../../slices/toast-slice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function NewPassword() {
    const propsData = useLocation().state;
    console.log('propsData', propsData);

    const [username, setUsername] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");

    const signIn = useSignIn();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const schema = yup.object().shape({
        password: yup.string().required(t('FIELD_REQUIRED')).min(8, t("FIELD_MIN", { min: 8 })).test(
            'isValidPass',
            'Passowrd must be 8 char (One UpperCase & One Symbol)',
            (value, context) => {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasNumber = /[0-9]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
                let validConditions = 0;
                const numberOfMustBeValidConditions = 3;
                const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
                conditions.forEach(condition => (condition ? validConditions++ : null));
                if (validConditions >= numberOfMustBeValidConditions) {
                    return true;
                }
                return false;
            },
        ),
        password_confirmation: yup.string().required(t('FIELD_REQUIRED')).min(8, t("FIELD_MIN", { min: 8 })).oneOf([yup.ref('password')], 'Passwords does not match'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setUsername(propsData?.username)
        setCurrentPassword(propsData?.password)
    }, [propsData])


    const submitForm = (data) => {
        let params = {
            ...data, ...{
                username: username,
                current_password: currentPassword,
            }
        }

        axios.defaults.withCredentials = false;
        dispatch(showLoading())
        axios({
            method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/resetPassword',
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }, data: params
        })
            .then(response => {
                console.log(response);
                dispatch(hideLoading())

                var status = response.data.status
                var message = response.data.message

                if (status == 0) {
                    dispatch(showToast(["danger", "NOTICE", message]));
                    return;
                }

                var token = response.data.data.user.api_token;
                console.log('token', token)

                signIn({
                    token: token,
                    expiresIn: response.data.data?.expires_in,
                    tokenType: "Bearer",
                    authState: { token: token, tokenType: "Bearer" }
                });
                dispatch(setExpiresIn(response.data.data?.expires_in))

                dispatch(showLoading())
                axios({
                    method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData',
                    withCredentials: false,
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'Authorization': "Bearer " + token
                    }
                }).then(res => {
                    console.log(res);
                    dispatch(hideLoading())
                    if (res.data.status == 0) {
                        console.log(res.data.message);
                        dispatch(showToast(["danger", "NOTICE", response.data.message]));
                        return;
                    }
                    dispatch(login());
                    dispatch(setGlobalAuthUser(res.data.data));
                    navigate("/home");
                })
            })

    };

    return (
        <>
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div className="wrapper">
                    <div className="logo">
                        <img src={bnblogo} alt="" />
                    </div>
                    <div className="text-center mt-4 name">BnB Portal</div>
                    <form onSubmit={handleSubmit(submitForm)} className="p-3 mt-3">
                        {
                            username != null &&
                            <div className="mb-4">
                                <h5 className="text-center mb-4">{t('DEFINE_NEW_PASSWORD')}</h5>
                                <strong>{t('USERNAME')}</strong>: {username}
                            </div>
                        }
                        <div className="row">
                            <div className="col-12">
                                <div className='form-group'>
                                    <label htmlFor='password'>{t('NEW_PASSWORD')} <span style={{ color: "red" }}>*</span></label>
                                    <input type="password" name="password" id="password" placeholder={t('NEW_PASSWORD')} className={`form-control w-100 ${errors.password ? 'is-invalid' : ''}`} {...register('password')} />
                                    {errors?.password && <p className="input-error-msg"> {errors.password?.message} </p>}
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className='form-group'>
                                    <label htmlFor='password_confirmation'>{t('PASSWORD_CONFIRMATION')} <span style={{ color: "red" }}>*</span></label>
                                    <input type="password" name="password_confirmation" id="password_confirmation" placeholder={t('PASSWORD_CONFIRMATION')} className={`form-control w-100 ${errors.password_confirmation ? 'is-invalid' : ''}`} {...register('password_confirmation')} />
                                    {errors?.password_confirmation && <p className="input-error-msg"> {errors.password_confirmation?.message} </p>}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn mt-4" disabled={Object.keys(errors).length !== 0 || errors.constructor !== Object}>
                            {t('SUBMIT')}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default NewPassword;
