import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { setGlobalMGreceiveCS } from "../slices/auth-slice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function PaidTransaction() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentStep = useSelector((state) => state.auth.globalMGreceiveCS);

  const payTransaction = () => {
    navigate("/payTransaction");
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header" style={{ fontWeight: "600" }}>
            {t("PAID_TRANSACTIONS")}
          </div>
          <div className="card-body" style={{ height: "700px" }}>
            <div style={{ marginTop: "20px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={payTransaction}
              >
                {t("PAY_TRANSACTION")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaidTransaction;
