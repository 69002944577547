import React, { useState, useEffect, useRef } from "react";
import {
  setGlobalMGreceiveCS,
  set_MG_GFFP_USED_OPT,
  set_RECEIVER_INFO,
} from "../../../slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import ReactInputDateMask from "react-input-date-mask";
import {
  checkAge,
  countryStates,
  dateIsValid,
  DaysList,
  MonthList,
  YearDobList,
} from "../../../utils/helper";
import { useTranslation } from "react-i18next";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";

function RcvStep1(props) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const DAYS = DaysList();
  const MONTHS = MonthList();
  const YEARS = YearDobList();

  
  const [states, setStates] = useState([]);
 
  const [dateInvalidMsg, setDateInvalidMsg] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const navigate = useNavigate();
  const GFFP_RESP = useSelector((state) => state.auth.GFFP_RESP);
  const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const [phoneCode, setPhoneCode] = useState(globalAuthUser.location.agent_country.phone_code);

  // if (GFFP_RESP === null) {
  //   console.log("GFFP_RESP  === null");
  //   navigate("/payTransaction");
  //   return;
  // }

  let rcvInfo = GFFP_RESP.requiredInfo.filter(
    (info) => info.fieldCategory === "receiveReceiverInformationSet"
  );
  rcvInfo = rcvInfo.filter(
    (info) => info.xmlTag !== "receiverPhoneCountryCode"
  );
  console.log(rcvInfo);

  // Add method
  // yup.addMethod(yup.string, 'stripEmptyString', function () {
  //     return this.transform((value) => (value === '' ? undefined : value));
  // });
  var rcvInfoScheme = {};
  rcvInfo?.forEach((field) => {
    var val = yup.string().required(t("FIELD_REQUIRED")).min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })).max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }));
    if (field.validationRegEx != null) {
      val = yup
        .string()
        .required(t("FIELD_REQUIRED"))
        .min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin }))
        .max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }))
        .matches(field.validationRegEx, t("MUST_MATCH_REGEX") + " " + field.validationRegEx);
    }
    if(field.dataType === "enum") {
      val = yup.string().required(t("FIELD_REQUIRED"))
    }
    if (field !== "receiverDOB") {
      rcvInfoScheme[field.xmlTag] = val;
    }
  });
  var rcvStateField = GFFP_RESP.productFieldInfo.find(
    (info) => info.xmlTag === "receiverState"
  );
  rcvInfoScheme["receiverState"] = yup.string().when("receiverCountry", {
    is: (receiverCountry) =>
      receiverCountry === "USA" ||
      receiverCountry === "CAN" ||
      receiverCountry === "MEX",
    then: yup
      .string()
      .required(t("FIELD_REQUIRED"))
      .min(rcvStateField.fieldMin, t("FIELD_MIN", { min: rcvStateField.fieldMin }))
      .max(rcvStateField.fieldMax, t("FIELD_MIN", { min: rcvStateField.fieldMin }))
      .matches(rcvStateField.validationRegEx, t("MUST_MATCH_REGEX") + " " + rcvStateField.validationRegEx)
  });

  var rcvZipCodeField = GFFP_RESP.productFieldInfo.find(
    (info) => info.xmlTag === "receiverZipCode"
  );
  rcvInfoScheme["receiverZipCode"] = yup.string().when("receiverCountry", {
    is: (receiverCountry) =>
      receiverCountry === "USA",
    then: yup
      .string()
      .required(t("FIELD_REQUIRED"))
      .min(rcvZipCodeField.fieldMin, t("FIELD_MIN", { min: rcvZipCodeField.fieldMin }))
      .max(rcvZipCodeField.fieldMax, t("FIELD_MIN", { min: rcvZipCodeField.fieldMin }))
      .matches(rcvZipCodeField.validationRegEx, t("MUST_MATCH_REGEX") + " " + rcvZipCodeField.validationRegEx)
  });

  rcvInfoScheme["receiverBirthDay"] = yup.string().required(t("FIELD_REQUIRED"));
  rcvInfoScheme["receiverBirthMonth"] = yup.string().required(t("FIELD_REQUIRED"));
  rcvInfoScheme["receiverBirthYear"] = yup.string().required(t("FIELD_REQUIRED"));
  rcvInfoScheme["receiverDOB"] = yup.string();

  console.log(rcvInfoScheme);

  const schema = yup.object().shape(rcvInfoScheme);

  // const schema = yup.object({
  //     receiverAddress: yup.string().required(),
  //     receiverCity: yup.string().required(),
  //     receiverCountry: yup.string().required(),
  //     // receiverPhoneCode: yup.string().required(),
  //     receiverPhone: yup.string().required(),
  //     receiverDOB: yup.string().required(),
  //     receiverBirthCountry: yup.string().required(),
  //     receiverOccupation: yup.string().required(),
  // });
  // schema.default();

  let MG_GFFP_USED_OPT = useSelector((state) => state.auth.MG_GFFP_USED_OPT);
  const codeTable = useSelector((state) => state.sendMoneyMoneygram.codeTable);

  console.log("props");
  console.log(props.items);
  console.log(codeTable);
  const propsData = props.items;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const currentStep = useSelector((state) => state.auth.globalMGreceiveCS);
  let RECEIVER_INFO = useSelector((state) => state.auth.RECEIVER_INFO);
  let CODETABLE = useSelector((state) => state.auth.CODETABLE);
  console.log("RECEIVER_INFO");
  console.log(RECEIVER_INFO);
  const dispatch = useDispatch();

  console.log("phoneCode");
  console.log(phoneCode !== "");
  console.log(RECEIVER_INFO?.receiverPhoneCountryCode);

  function testDate() {
    console.log("testDate");
  }

  useEffect(() => {
    console.log("useeffect");
    console.log(countryCode);
    if (countryCode != null) {
      let countri = CODETABLE.country_info.findIndex((a) => a.country_code === countryCode);
      console.log(countri);
      setPhoneCode(CODETABLE.country_info[countri].phone_code);
    }
  }, [countryCode]);

  useEffect(() => {
    console.log("useeffect");
    if (RECEIVER_INFO != null && RECEIVER_INFO.receiverCountry !== undefined) {
      console.log(RECEIVER_INFO.receiverCountry);

      let countri = CODETABLE.country_info.findIndex(
        (a) => a.country_code === RECEIVER_INFO?.receiverCountry
      );
      setPhoneCode(CODETABLE.country_info[countri].phone_code);
    }
  }, [RECEIVER_INFO?.receiverCountry]);

  const onCountryChange = (event) => {
    console.log(event.target.value);
    let countri = CODETABLE.country_info.findIndex(
      (a) => a.country_code === event.target.value
    );
    console.log(countri);
    setPhoneCode(CODETABLE.country_info[countri].phone_code);
    setCountryCode(event.target.value);
    setStates(countryStates(CODETABLE, event.target.value))
    console.log("countryStates")
    console.log(countryStates(CODETABLE, event.target.value))
    // console.log(CODETABLE.country_info.at(countri).phone_code);
    // if (RECEIVER_INFO !== null) {
    //   RECEIVER_INFO.receiverCountry = event.target.value;
    //   RECEIVER_INFO.receiverPhoneCountryCode = CODETABLE.country_info.at(countri).phone_code;
    // } else {
    //   RECEIVER_INFO = {};
    //   RECEIVER_INFO.receiverCountry = event.target.value;
    //   RECEIVER_INFO.receiverPhoneCountryCode = CODETABLE.country_info.at(countri).phone_code;
    // }
    // console.log(RECEIVER_INFO.receiverCountry);
    // console.log(RECEIVER_INFO.receiverPhoneCountryCode);
  };


  const onBack = () => {
    console.log("onback");
    navigate("/payTransaction");
  };

  const onDOBchange = () => {
    console.log("onDOBchange");
  };

  function labelName(xmlTag) {
    // return GFFP_RESP.productFieldInfo.find((info) => info.xmlTag === xmlTag)
    //   .fieldLabel;
    return t("MONEYGRAM." + xmlTag);
  }

  console.log(Date.parse("2012/09/11"));

  const onNext = (data, event) => {
    event.preventDefault();
    console.log("onNext");
    // if (RECEIVER_INFO?.receiverPhoneCountryCode !== undefined) {
    //   data.receiverPhoneCountryCode = RECEIVER_INFO.receiverPhoneCountryCode;
    // } else {
    //   data.receiverPhoneCountryCode = phoneCode;
    // }
    if (phoneCode !== "") {
      data.receiverPhoneCountryCode = phoneCode;
    } else
      data.receiverPhoneCountryCode = RECEIVER_INFO.receiverPhoneCountryCode;

    // setPhoneCode("");

    data.receiverDOB =
      data.receiverBirthYear +
      "-" +
      data.receiverBirthMonth +
      "-" +
      data.receiverBirthDay;

    // setDateInvalidMsg("");
    // console.log(data);
    // // if (!dateIsValid(data.receiverDOB)) {
    // //   setDateInvalidMsg("Date Invalid");
    // //   return;
    // // }
    // if (!validateDOB(data.receiverDOB)) {
    //   return;
    // }
    console.log(codeTable);
    MG_GFFP_USED_OPT = [...MG_GFFP_USED_OPT, "cars"];
    dispatch(set_MG_GFFP_USED_OPT(MG_GFFP_USED_OPT));
    console.log(MG_GFFP_USED_OPT);
    console.log(data);
    dispatch(set_RECEIVER_INFO(data));
    dispatch(setGlobalMGreceiveCS(2));
  };

  // console.log(dateIsValid("18/01/2022")); // 👉️ true
  // console.log(dateIsValid("35/01/2022")); // 👉️ false
  // console.log(dateIsValid("hello")); // 👉️ false

  function validateDOB(value) {
    // console.log(e.target.value);
    setDateInvalidMsg("");
    // var text = e.target.value;
    var text = value;
    console.log(value);
    if (text !== "__/__/____") {
      if (!dateIsValid(text, LANGUAGE)) {
        setDateInvalidMsg("Date Invalid");
        return false;
      }
      var year = text.split("/")[2];
      console.log(year);
      var agecheck = checkAge(year, 16, 100);
      if (!agecheck) {
        setDateInvalidMsg(
          t("AGE_MUST_BE_BETWEEN") + " (" + 16 + " - " + 100 + ")"
        );
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onNext)}>
        <div style={{ padding: "40px 5px" }}>
          <div
            className="row"
            style={{
              padding: "1rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-md-6">
              <span className="key">{t("firstName")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverFirstName}
              </span>
            </div>
            <div className="col-md-6">
              <span className="key">{t("MIDDLE_NAME")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverMiddleName}
              </span>
            </div>
            <div className="col-md-6">
              <span className="key">{t("lastName")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverLastName}
              </span>
            </div>
            <div className="col-md-6">
              <span className="key">{t("SECOND_LAST_NAME")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverLastName2}
              </span>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".3rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label htmlFor="receiverAddress" className="col-form-label">
                {labelName("receiverAddress")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                ref={ref}
                className="form-control"
                id="receiverAddress"
                defaultValue={RECEIVER_INFO?.receiverAddress}
                {...register("receiverAddress")}
              />
              <p className="input-error-msg">
                {" "}
                {errors.receiverAddress?.message}{" "}
              </p>
            </div>
            <div className="col-6">
              <label htmlFor="receiverCity" className="col-form-label">
                {labelName("receiverCity")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="receiverCity"
                defaultValue={RECEIVER_INFO?.receiverCity}
                {...register("receiverCity")}
              />
              <p className="input-error-msg">{errors.receiverCity?.message}</p>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".3rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label htmlFor="receiverCountry" className="col-form-label">
                {labelName("receiverCountry")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select id="receiverCountry" name="receiverCountry" className="form-select form-control"
                defaultValue={
                  RECEIVER_INFO?.receiverCountry !== undefined
                    ? RECEIVER_INFO?.receiverCountry
                    : globalAuthUser.location.agent_country.isoname
                }
                // defaultValue={RECEIVER_INFO?.receiverCountry}
                {...register("receiverCountry")}
                onChange={onCountryChange}
              >
                {CODETABLE.country_info.map((country, index) => (
                  <option key={index} value={country.country_code}>
                    {country.country_name}
                  </option>
                ))}
              </select>
              <p className="input-error-msg">
                {" "}
                {errors.receiverCountry?.message}{" "}
              </p>
              {(countryCode === "USA" ||
                RECEIVER_INFO?.receiverCountry === "USA" ||
                countryCode === "CAN" ||
                RECEIVER_INFO?.receiverCountry === "CAN" ||
                countryCode === "MEX" ||
                RECEIVER_INFO?.receiverCountry === "MEX") && (
                <>
                <select id="receiverState" name="receiverState" className="form-select form-control"
                defaultValue={RECEIVER_INFO?.receiverState}
                {...register("receiverState")}
              >
                <option value=""> { t("SELECT_STATE") }</option>
                {states.map((state, index) => (
                  <option key={index} value={state.state_province_code}>
                    {state.state_province_name}
                  </option>
                ))}
              </select>
                  <p className="input-error-msg">
                    {" "}
                    {errors.receiverState?.message}{" "}
                  </p>{" "}
                </>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="receiverPhone" className="col-form-label">
                {labelName("receiverPhone")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="input-group col-8" style={{ width: "100%" }}>
                <span className="input-group-text" id="basic-addon1">
                  {phoneCode}
                  {/* + */}
                  {/* {RECEIVER_INFO?.receiverPhoneCountryCode !== undefined
                    ? RECEIVER_INFO?.receiverPhoneCountryCode
                    : phoneCode !== ""
                    ? phoneCode
                    : ""} */}
                  {/* {phoneCode !== ""
                    ? phoneCode
                    : RECEIVER_INFO?.receiverPhoneCountryCode !== undefined
                    ? RECEIVER_INFO?.receiverPhoneCountryCode
                    : ""} */}
                </span>
                <input
                  type="text"
                  className="form-control"
                  // placeholder="receiverPhone"
                  aria-label="receiverPhone"
                  aria-describedby="basic-addon1"
                  defaultValue={RECEIVER_INFO?.receiverPhone}
                  {...register("receiverPhone")}
                />
              </div>
              <p className="input-error-msg">
                {errors.receiverPhone?.message}
              </p>
              {(countryCode === "USA" ||
                RECEIVER_INFO?.receiverCountry === "USA") && (
                  <>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={labelName("receiverZipCode")}
                      id="receiverZipCode"
                      defaultValue={RECEIVER_INFO?.receiverZipCode}
                      {...register("receiverZipCode")}
                    />
                    <p className="input-error-msg">
                      {errors.receiverZipCode?.message}
                    </p>
                  </>
                )}
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".3rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label htmlFor="receiverDOB" className="col-form-label">
                {labelName("receiverDOB")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="row">
                <div className="col-md-4">
                  <select
                    id="receiverBirthDay"
                    name="receiverBirthDay"
                    className="form-select form-control"
                    defaultValue={RECEIVER_INFO?.receiverBirthDay}
                    {...register("receiverBirthDay")}
                  >
                    <option value="">
                      {LANGUAGE === "en" ? "Day" : "Jour"}
                    </option>
                    {DAYS?.map((day, index) => (
                      <option key={index} value={day.value}>
                        {day.label}
                      </option>
                    ))}
                  </select>
                  <p className="input-error-msg">
                    {" "}
                    {errors.receiverBirthDay?.message}{" "}
                  </p>
                </div>
                <div className="col-md-4">
                  <select id="receiverBirthMonth" name="receiverBirthMonth" className="form-select form-control"
                    defaultValue={RECEIVER_INFO?.receiverBirthMonth}
                    {...register("receiverBirthMonth")}
                  >
                    <option value=""> {LANGUAGE === "en" ? "Month" : "Mois"} </option>
                    {MONTHS?.map((month, index) => (
                      <option key={index} value={month.value}>
                        {LANGUAGE === "en" ? month.labelEn : month.labelFr}
                      </option>
                    ))}
                  </select>
                  <p className="input-error-msg"> {errors.receiverBirthMonth?.message} </p>
                </div>
                <div className="col-md-4">
                  <select id="receiverBirthYear" name="receiverBirthYear" className="form-select form-control"
                    defaultValue={RECEIVER_INFO?.receiverBirthYear}
                    {...register("receiverBirthYear")}
                  >
                    <option value=""> {LANGUAGE === "en" ? "Year" : "Annee"} </option>
                    {YEARS?.map((year, index) => (
                      <option key={index} value={year.value}>
                        {year.label}
                      </option>
                    ))}
                  </select>
                  <p className="input-error-msg"> {errors.receiverBirthYear?.message} </p>
                </div>
              </div>
              {/* <InputMask
                mask="99/99/9999"
                className="form-control"
                defaultValue={RECEIVER_INFO?.receiverDOB}
                {...register("receiverDOB")}
                {...register("receiverDOB", {
                  onChange: validateDOB,
                })}
              />
              <input type="date" className="form-control" id="receiverDOB" defaultValue={RECEIVER_INFO?.receiverDOB} {...register('receiverDOB')} />
              {dateInvalidMsg ? (
                <p className="input-error-msg"> {dateInvalidMsg} </p>
              ) : (
                <p className="input-error-msg">
                  {" "}
                  {errors.receiverDOB?.message}{" "}
                </p>
              )} */}
            </div>
            <div className="col-6">
              <label htmlFor="receiverBirthCountry" className="col-form-label">
                {labelName("receiverBirthCountry")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="receiverBirthCountry"
                name="receiverBirthCountry"
                className="form-select form-control"
                defaultValue={
                  RECEIVER_INFO?.receiverBirthCountry !== undefined
                    ? RECEIVER_INFO?.receiverBirthCountry
                    : globalAuthUser.location.agent_country.isoname
                }
                {...register("receiverBirthCountry")}
              >
                {CODETABLE.country_info.map((country, index) => (
                  <option key={index} value={country.country_code}>
                    {country.country_name}
                  </option>
                ))}
              </select>
              {/* <input type="text" className="form-control" id="receiverBirthCountry" defaultValue={RECEIVER_INFO?.receiverBirthCountry} {...register('receiverBirthCountry')} /> */}
              <p className="input-error-msg">
                {" "}
                {errors.receiverBirthCountry?.message}{" "}
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".3rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label htmlFor="receiverOccupation" className="col-form-label">
                {labelName("receiverOccupation")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="receiverOccupation"
                name="receiverOccupation"
                className="form-select form-control"
                defaultValue={RECEIVER_INFO?.receiverOccupation}
                {...register("receiverOccupation")}
              >
                <option value="" defaultValue></option>
                {GFFP_RESP?.productFieldInfo
                  ?.find((e) => e.xmlTag == "receiverOccupation")
                  .enumeratedValues.enumeratedValueInfo.map(
                    (occupation, index) => (
                      <option key={index} value={occupation.value}>
                        {occupation.label}
                      </option>
                    )
                  )}
              </select>
              {/* <input type="text" className="form-control" id="receiverOccupation" defaultValue={RECEIVER_INFO?.receiverOccupation} {...register('receiverOccupation')} /> */}
              <p className="input-error-msg">
                {" "}
                {errors.receiverOccupation?.message}{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            padding: ".3rem .5rem",
            borderTop: ".5px solid rgba(0,0,0,.125)",
            textAlign: "right",
          }}
        >
          <button className="btn btn-secondary" onClick={onBack}>
            {" "}
            <FaArrowCircleLeft size={16} fill="white" /> {t("BACK")}
          </button>{" "}
          &nbsp;&nbsp;
          <button
            type="submit"
            className="btn btn-primary"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <FaArrowCircleRight size={16} fill="white" /> {t("NEXT")}{" "}
          </button>
        </div>
      </form>
    </>
  );
}

export default RcvStep1;
