import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setReceiverDetails, setReceivingAmount, setSenderDetails, setSendingAmount, setTransactionDetails, setTransactionFees } from '../../slices/local-sending-slice';
import { useTranslation } from 'react-i18next';

export default function LocalComplete() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const transactionDetails = useSelector(state => state.localSending.transactionDetails);

    const resetLocalTxn = () => {
        // reset the current step
        dispatch(setCurrentStep(0));

        // reset the sender and receiver details
        dispatch(setSenderDetails({}));
        dispatch(setReceiverDetails({}));
        dispatch(setTransactionFees({}));
        dispatch(setReceivingAmount(0));
        dispatch(setSendingAmount(0));
        dispatch(setTransactionDetails({}));
    }

    const handlePrintReceipt = () => {

    }

    return (
        <>
            {/** complete icon */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <i className='bx bxs-badge-check text-success p-3' style={{ fontSize: "60px" }}></i>
                </div>
            </div>

            {/** complete icon */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <span className="text-success">{ t("TRANSACTION_SAVED_SUCCESSFULLY") }</span>
                </div>
            </div>

            {/** transaction reference summary  */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <span>
                        { t("YOUR_SEND_MONEY_REFERENCE_NO_IS") }: {transactionDetails.reference_no}
                    </span>
                </div>
            </div>

            {/** action buttons */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <button type='button' className="btn btn-primary btn-sm mx-2"
                    onClick={handlePrintReceipt}>
                        { t("PRINT_RECEIPT") }
                    </button>
                    <button type='button' className="btn btn-secondary btn-sm" onClick={resetLocalTxn}>
                        { t("SEND_ANOTHER") }
                    </button>
                </div>
            </div>
        </>
    );
}
