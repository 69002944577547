import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    codeTable: null,
    consumer: null,
    feeInfo: null,
    fields: [],
    senderSchema: {},
    receiverSchema: {},
    receiveCountry: "",
    sendData: {},
    receiveData: {},
    complete: false,
    step: 0,
    sendResponse: {},
    onPrevious: false,
    resetSend: false,
    resetReceive: false,
    receivers: [],
    amended: false,
    reversed: false,
    reverseData: {},
    reference: null,
    DeliveryMethod: 1,
    ProductId: 111,
    CurrencyFrom: 'SLE',
    CONSUMER_RECEIVERS: [],
    SEND_MONEY_OBJECT: {}
}

export const sendMoneyRiaSlice = createSlice({
    name: "send-money-ria",
    initialState,
    reducers: {
        setCodeTable(state, action) {
            state.codeTable = action.payload;
        },
        setConsumerState(state, action) {
            state.consumer = action.payload;
        },
        setFeeInfoState(state, action) {
            state.feeInfo = action.payload;
        },
        setFieldsState(state, action) {
            state.fields = action.payload;
        },
        setSenderSchemaState(state, action) {
            state.senderSchema = action.payload;
        },
        setReceiverSchemaState(state, action) {
            state.receiverSchema = action.payload;
        },
        setSendData(state, action) {
            state.sendData = action.payload;
        },
        setReceiveData(state, action) {
            state.receiveData = action.payload;
        },
        setComplete(state, action) {
            state.complete = action.payload;
        },
        setStep(state, action) {
            state.step = action.payload;
        },
        setSendResponse(state, action) {
            state.sendResponse = action.payload;
        },
        addGovIdType(state, action) {
            var field = current(state).codeTable.additional_fields.senderGovId;
            state.fields = state.fields.concat(field);
        },
        removeGovIdType(state, action) {
            console.log('removeGovIdType')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderGovIdType')
        },
        addSenderIdState(state, action) {
            console.log('addSenderIdState')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderPhotoIdState')
            var field = current(state).codeTable.additional_fields.senderIdState;
            state.fields = state.fields.concat(field);
        },
        removeSenderIdState(state, action) {
            console.log('removeSenderIdState')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderPhotoIdState')
        },
        addSenderState(state, action) {
            console.log('addSenderState')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderState')
            var field = current(state).codeTable.additional_fields.senderState;
            state.fields = state.fields.concat(field);
        },
        removeSenderState(state, action) {
            console.log('removeSenderState')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderState')
        },
        addSenderZipCode(state, action) {
            console.log('addSenderZipCode')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderZipCode')
            var field = current(state).codeTable.additional_fields.senderZipCode;
            state.fields = state.fields.concat(field);
        },
        removeSenderZipCode(state, action) {
            console.log('removeSenderZipCode')
            state.fields = state.fields.filter(item => item.xmlTag !== 'senderZipCode')
        },
        setOnPrevious(state, action) {
            state.onPrevious = action.payload;
        },
        setResetSend(state, action) {
            state.resetSend = action.payload;
        },
        setResetReceive(state, action) {
            state.resetReceive = action.payload;
        },
        setAmended(state, action) {
            state.amended = action.payload;
        },
        setReversed(state, action) {
            state.reversed = action.payload;
        },
        setReverseData(state, action) {
            state.reverseData = action.payload;
        },
        setReference(state, action) {
            state.reference = action.payload;
        },
        setReceivers(state, action) {
            state.receivers = action.payload;
        },
        setDeliveryMethod(state, action) {
            state.DeliveryMethod = 1;
        },
        setProductId(state, action) {
            state.ProductId = 1;
        },
        setCurrencyFrom(state, action) {
            state.CurrencyFrom = 1;
        },
        setCONSUMER_RECEIVERS(state, action) {
            state.CONSUMER_RECEIVERS = action.payload;
        },
        setSEND_MONEY_OBJECT(state, action) {
            state.SEND_MONEY_OBJECT = action.payload;
        },
    }
});

export const { setCodeTable, setConsumerState, setFeeInfoState, setFieldsState, setSenderSchemaState,
    setReceiverSchemaState, setSendData, setReceiveData, setStep, setSendResponse, setComplete, addGovIdType,
    removeGovIdType, addSenderIdState, removeSenderIdState, addSenderState, removeSenderState, addSenderZipCode,
    removeSenderZipCode, setOnPrevious, setResetSend, setAmended, setReversed, setReverseData, setReference,
    setResetReceive, setReceivers, setDeliveryMethod, setProductId, setCurrencyFrom, setCONSUMER_RECEIVERS, setSEND_MONEY_OBJECT } = sendMoneyRiaSlice.actions

export default sendMoneyRiaSlice;