import React, { useState, useEffect, useRef, useCallback } from 'react'
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setReceiveData, setStep, setResetReceive, setSEND_MONEY_OBJECT } from '../../../slices/send-money-ria-slice';
import { getFieldsByCategory, getPhoneCode, getReceiverName } from './ria';
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { useImmer } from 'use-immer';




function RiaReceiver() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formRef = useRef();
    
    const receivers = useSelector((state) => state.sendMoneyRia.receivers);
    const codeTable = useSelector((state) => state.sendMoneyRia.codeTable);
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const [sendMoneyObject, setSendMoneyObject] = useStateCallback(SEND_MONEY_OBJECT);
    const [relationships, setRelationships] = useState([]);
    const [bankBranches, setBankBranches] = useState([]);
    const [phoneCode, setPhoneCode] = useState("");
    const [filterText, setFilterText] = useState("");
    const [viewMode, setViewMode] = useState("");
    const [receiver, setReceiver] = useState({});
    const [formkey, setFormkey] = useState(0);
    const [relationshipObject, setRelationshipObject] = useState({});

    const [showReceiverModal, setShowReceiverModal] = useState(false);
    const [countryCode, setCountryCode] = useState(null);
    const [transferReasonObject, setTransferReasonObject] = useState({});
    const [sourceOfFundsObject, setSourceOfFundsObject] = useState({});
    console.log("ria send receiver component render")

    var schemaObject = {};

    if(viewMode == 'show') {

    } else {
        schemaObject['deliveryId'] = yup.number().nullable()
        schemaObject['productId'] = yup.number().nullable()
        schemaObject['firstName'] = yup.string().nullable()
            .when("productId", { is: (productId) => (productId == 111) , then: yup.string().required(t("FIELD_REQUIRED")) })
        schemaObject['middleName'] = yup.string()
        schemaObject['lastName'] = yup.string().nullable()
            .when("productId", { is: (productId) => (productId == 111) , then: yup.string().required(t("FIELD_REQUIRED")) })
        schemaObject['lastName2'] = yup.string()
        schemaObject['address'] = yup.string().required()
        schemaObject['city'] = yup.string().required()
        schemaObject['state'] = yup.string().required()
        schemaObject['country'] = yup.string().required()
        schemaObject['zipCode'] = yup.string()
        schemaObject['phoneNumber'] = yup.string().required()
        schemaObject['emailAddress'] = yup.string().email()
        schemaObject['relationship'] = yup.string().nullable().when("productId", { is: (productId) => (productId == 111) , then: yup.string().required(t("FIELD_REQUIRED")) })
        schemaObject['mobileWalletAccountNo'] = yup.number().nullable().transform((value) => (isNaN(value) ? undefined : value))
            .when("deliveryId", { is: (deliveryId) => (deliveryId == 34) , then: yup.number().required(t("FIELD_REQUIRED")) })
        schemaObject['bankAccountNo'] = yup.number().nullable().transform((value) => (isNaN(value) ? undefined : value))
            .when("deliveryId", { is: (deliveryId) => (deliveryId == 2) , then: yup.number().required(t("FIELD_REQUIRED")) })
        schemaObject['entityName'] = yup.string().nullable()
            .when("productId", { is: (productId) => (productId == 116) , then: yup.string().required(t("FIELD_REQUIRED")) })

        if(SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'BankAccountNo').length > 0) {
            let field = SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'BankAccountNo')[0]
            schemaObject['bankAccountNo'] = yup.string().required(t("FIELD_REQUIRED")).min(field.MinLength, t("FIELD_MIN", { min: field.MinLength })).max(field.MaxLength, t("FIELD_MAX", { max: field.MaxLength }))
        }
    
        if(SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'BankBranchNo').length > 0) {
            schemaObject['bankBranchNo'] = yup.string().required(t("FIELD_REQUIRED"))
        }
    
        if(SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'UnitaryBankAccountNo').length > 0) {
            let field = SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'UnitaryBankAccountNo')[0]
            schemaObject['unitaryBankAccountNo'] = yup.string().required(t("FIELD_REQUIRED")).min(field.MinLength, t("FIELD_MIN", { min: field.MinLength })).max(field.MaxLength, t("FIELD_MAX", { max: field.MaxLength }))
        }
        
        if(SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'BankRoutingCode').length > 0) {
            schemaObject['bankRoutingCode'] = yup.string().required(t("FIELD_REQUIRED"))
        }

        schemaObject['transferReason'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['sourceOfFunds'] = yup.string().required(t("FIELD_REQUIRED"))

    }

    schemaObject["relationshipOtherText"] = yup.string().when("relationship", {
        is: (relationship) =>
            relationships.find((a) => a.Value === relationship) !== undefined &&
            relationships.find((a) => a.Value === relationship).Name === "Other",
        then: yup
            .string()
            .required(t("FIELD_REQUIRED"))
    });

    schemaObject["transferReasonOtherText"] = yup.string().when("transferReason", {
        is: (transferReason) =>
            sendMoneyObject?.enumTransferReasons.find((a) => a.Value === transferReason) !== undefined &&
            sendMoneyObject?.enumTransferReasons.find((a) => a.Value === transferReason).Name === "Other",
        then: yup
            .string()
            .required(t("FIELD_REQUIRED"))
    });

    schemaObject["sourceOfFundsOtherText"] = yup.string().when("sourceOfFunds", {
        is: (sourceOfFunds) =>
            sendMoneyObject?.enumSourceFunds.find((a) => a.Value === sourceOfFunds) !== undefined &&
            sendMoneyObject?.enumSourceFunds.find((a) => a.Value === sourceOfFunds).Name === "Other",
        then: yup
            .string()
            .required(t("FIELD_REQUIRED"))
    });

    const schema = yup.object().shape(
        schemaObject
    )

    const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    useEffect(() => {
        setValue("deliveryId", sendMoneyObject.sDeliveryMethod.id)
        console.log("SEND_MONEY_OBJECT start", SEND_MONEY_OBJECT)
        setValue("productId", sendMoneyObject.sProduct.id)
        var codes = codeTable?.enum_values_info.filter((a) => a.enum_type === "CustBeneRelationship");
        if (codes !== undefined && codes.length > 0) {
            setRelationships(JSON.parse(codes[0].enum_values))
        }
        // let sendObject = cloneDeep(SEND_MONEY_OBJECT)
        let sConsumerIndex = sendMoneyObject.consumers.findIndex(consumer => consumer.id === sendMoneyObject.selectedConsumerId)
        if(sendMoneyObject.previousStep == 2) {
            getRiaReceivers(sendMoneyObject.consumers[sConsumerIndex].id)
            if(sendMoneyObject.sDeliveryMethod.id == 2) {
                getBankBranches()
            }
        }
        
    }, []);

    function getBankBranches() {
        let countryTo = sendMoneyObject.sDestCountry.country_code
        let deliveryMethodId = sendMoneyObject.sDeliveryMethod.id
        let receivingCurrency = sendMoneyObject.sReceivingCurrency
        let productId = sendMoneyObject.sProduct.id
        let payoutPartnerId = sendMoneyObject.sPayoutPartner.id
        dispatch(showLoading());
        let urlStart = (deliveryMethodId == 2) ? "/api/ria/bankLocations" : "/api/ria/payoutPartnerLocations"
        let subStr = (deliveryMethodId == 2) ? "&bankId=" : "&payoutPartnerId="
        axios({
            method: "get",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + urlStart + "?sendingCountry=SL&countryTo=" + countryTo 
                + "&deliveryMethodId=" + deliveryMethodId + "&receivingCurrency=" + receivingCurrency + "&productId=" + productId + subStr + payoutPartnerId,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            }
        }).then(res => {
            dispatch(hideLoading());
            if(res.data.status == "SUCCESS") {
                console.log(res.data.data)
                setBankBranches(res.data.data.bankLocations)
            }
        }). catch(error => {
            console.log(error)
            dispatch(hideLoading());
        })
    }

    useEffect(() => {
        dispatch(setSEND_MONEY_OBJECT(sendMoneyObject))
    }, [sendMoneyObject]);

    

    function getRiaReceivers(consumerId) {
        dispatch(showLoading());
        axios({
            method: "get",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + consumerId + "/receivers?sendingCountry=SL",
            // url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + consumerId + "/bnbreceivers?sendingCountry=SL",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
        })
        .then((res) => {
            dispatch(hideLoading());
            if(res.data.status == "SUCCESS") {
                let data = res.data.data.receivers
                let nArrays = []
                let sConsumerIndex = sendMoneyObject.consumers.findIndex(consumer => consumer.id === sendMoneyObject.selectedConsumerId)
                data.forEach(element => {
                    if(element.operationalCountry == SEND_MONEY_OBJECT.sDestCountry.country_code) {
                        let nReceiver = {}
                        nReceiver.id                           =   element.id
                        nReceiver.riaCustomerId                =   consumerId
                        nReceiver.riaReceiverId                =   element.id
                        nReceiver.typeId                       =   element.typeId
                        nReceiver.operationalCountry           =   element.operationalCountry
                        nReceiver.firstName                    =   element.individualInformation.firstName
                        nReceiver.middleName                   =   element.individualInformation.middleName
                        nReceiver.lastName                     =   element.individualInformation.firstLastName
                        nReceiver.lastName2                    =   element.individualInformation.secondLastName
                        nReceiver.entityName                   =   element.individualInformation?.entityName
                        nReceiver.country                      =   element.address.country
                        nReceiver.address                      =   element.address.address
                        nReceiver.city                         =   element.address.city
                        nReceiver.state                        =   element.address.state
                        nReceiver.zipCode                      =   element.address.zipCode
                        nReceiver.phoneCode                    =   ""
                        nReceiver.phoneNumber                  =   element.contactInformation.phones.length > 0 ? element.contactInformation.phones[0].number.subscriptionNumber : undefined
                        nReceiver.emailAddress                 =   element.contactInformation.emailAddress
                        // nReceiver.mobileWalletAccountNo        =   element.mobileWalletAccountNo
                        // nReceiver.bankAccountNo                =   element.account.bankAccountNo
                        // nReceiver.bankRoutingCode              =   element.account.bankLocation.bankRoutingCode
                        // nReceiver.bankId                       =   element.bankId
                        // nReceiver.bankName                     =   element.account.bankName
                        // nReceiver.bankBranchNo                 =   element.bankBranchNo
                        // nReceiver.bankBranchName               =   element.account.bankLocation.bankBranchName
                        // nReceiver.unitaryBankAccountNo         =   element.account.unitaryBankAccountNo
                        // nReceiver.relationshipId               =   element.relationshipId
                        // nReceiver.relationshipText             =   element.relationshipText

                        

                        nArrays.push(nReceiver)
                    }
                });

                console.log("receivers", nArrays)

                setSendMoneyObject((prev) => {
                    prev.consumers[sConsumerIndex].receivers = []
                    prev.consumers[sConsumerIndex].receivers.push(...nArrays)
                })
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch(hideLoading());
        });
    }

    function getReceivers(consumerId) {
        dispatch(showLoading());
        axios({
            method: "get",
            // url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + consumerId + "/receivers?sendingCountry=SL",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + consumerId + "/bnbreceivers?sendingCountry=SL",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
        })
        .then((res) => {
            dispatch(hideLoading());
            if(res.data.status == "SUCCESS") {
                let data = res.data.data
                let nArrays = []
                let sConsumerIndex = sendMoneyObject.consumers.findIndex(consumer => consumer.id === sendMoneyObject.selectedConsumerId)
                data.forEach(element => {
                    let nReceiver = {}
                    nReceiver.id                           =   element.id
                    nReceiver.riaCustomerId                =   element.riaCustomerId
                    nReceiver.typeId                       =   element.typeId
                    nReceiver.firstName                    =   element.firstName
                    nReceiver.firstName                    =   element.firstName
                    nReceiver.middleName                   =   element.middleName
                    nReceiver.lastName                     =   element.lastName
                    nReceiver.lastName2                    =   element.lastName2
                    nReceiver.entityName                   =   element.entityName
                    nReceiver.country                      =   element.country
                    nReceiver.bankAccountNo                =   element.bankAccountNo
                    nReceiver.bankRoutingCode              =   element.bankRoutingCode
                    nReceiver.bankId                       =   element.bankId
                    nReceiver.bankName                     =   element.bankName
                    nReceiver.bankBranchNo                 =   element.bankBranchNo
                    nReceiver.bankBranchName               =   element.bankBranchName
                    nReceiver.unitaryBankAccountNo         =   element.unitaryBankAccountNo
                    nReceiver.mobileWalletAccountNo        =   element.mobileWalletAccountNo
                    nReceiver.relationshipId               =   element.relationshipId
                    nReceiver.relationshipText             =   element.relationshipText
                    nReceiver.address                      =   element.address
                    nReceiver.city                         =   element.city
                    nReceiver.state                        =   element.state
                    nReceiver.zipCode                      =   element.zipCode
                    nReceiver.phoneCode                    =   element.phoneCode
                    nReceiver.phoneNumber                  =   element.phoneNumber
                    nReceiver.emailAddress                 =   element.emailAddress
                    nArrays.push(nReceiver)
                });
                

                setSendMoneyObject((prev) => {
                    prev.consumers[sConsumerIndex].receivers = []
                    prev.consumers[sConsumerIndex].receivers.push(...nArrays)
                })
                // dispatch(setCONSUMER_RECEIVERS(res.data.data))
                // dispatch(setStep(3));
            }
        })
        .catch((error) => {
            console.log(error)
            // dispatch(setCONSUMER_RECEIVERS([]))
            dispatch(hideLoading());
            // dispatch(setStep(3));
        });
    }

    const back = () => {
        setSendMoneyObject(prev => {
            prev.previousStep = 3
            prev.step = 2
        })
    }

    const next = () => {
        dispatch(setStep(4));
    }

    function resetFormData(showMode, rcvObject) {
        let cleanData = {}
        Object.keys(schemaObject).map((thekey) => {
            cleanData[thekey] = ''
        })
        cleanData.deliveryId = sendMoneyObject.sDeliveryMethod.id
        cleanData.productId = sendMoneyObject.sProduct.id

        if(showMode == 'edit') {
            console.log("in recv relationship ", rcvObject)
            cleanData['entityName'] = rcvObject.entityName
            cleanData['firstName'] = rcvObject.firstName
            cleanData['middleName'] = rcvObject.middleName
            cleanData['lastName'] = rcvObject.lastName
            cleanData['lastName2'] = rcvObject.lastName2
            cleanData['address'] = rcvObject.address
            cleanData['city'] = rcvObject.city
            cleanData['country'] = rcvObject.country
            cleanData['state'] = rcvObject.state
            cleanData['relationship'] = rcvObject.relationshipId
            cleanData['relationshipOtherText'] = rcvObject.relationshipText
            cleanData['phoneNumber'] = rcvObject.phoneNumber
            cleanData['email'] = rcvObject.eamil
            cleanData['zipCode'] = rcvObject.zipCode
            cleanData['mobileWalletAccountNo'] = rcvObject.mobileWalletAccountNo
            cleanData['bankAccountNo'] = rcvObject.bankAccountNo
            cleanData['bankBranchNo'] = rcvObject.bankBranchNo
            cleanData['unitaryBankAccountNo'] = rcvObject.unitaryBankAccountNo
            cleanData['bankRoutingCode'] = rcvObject.bankRoutingCode
            // cleanData['bankRoutingCode'] = rcvObject.bankRoutingCode
            // cleanData['bankRoutingCode'] = rcvObject.bankRoutingCode
        }
        reset(cleanData)
    }

    const newReceiver = () => {
        setReceiver({})
        let countri = codeTable.countries_info.findIndex((a) => a.country_code === SEND_MONEY_OBJECT.sDestCountry.country_code);
        resetFormData('add')
        setValue('country', codeTable.countries_info[countri].country_code)
        setCountryCode(codeTable.countries_info[countri].country_code);
        setPhoneCode(codeTable.countries_info[countri].phone_code);
        setViewMode('add')

    }

    const onCountryChange = event => {
        let countri = codeTable.countries_info.findIndex((a) => a.country_code === event.target.value);
        setCountryCode(event.target.value);
        setPhoneCode(codeTable.countries_info[countri].phone_code);
    }

    const onReceiverModalClose = () => {
        setShowReceiverModal(false)
    }

    const onReceiverSelect = (e, receiverp) => 
    {
        console.log("in onReceiverSelect ", receiverp)
        let countri = codeTable.countries_info.findIndex((a) => a.country_code === SEND_MONEY_OBJECT.sDestCountry.country_code)
        let mPhoneCode = codeTable.countries_info[countri].phone_code
        console.log('mPhoneCode', mPhoneCode)
        setPhoneCode(mPhoneCode);
        let number = receiverp.phoneNumber
        if(receiverp.phoneNumber != undefined) {
            if(receiverp.phoneNumber.startsWith(mPhoneCode)) {
                number = receiverp.phoneNumber.substring(mPhoneCode.length)
            }
        }
        setReceiver({ ...receiverp, phoneCode: mPhoneCode, phoneNumber: number})
        setViewMode('edit')
        resetFormData('edit', { ...receiverp, phoneCode: mPhoneCode, phoneNumber: number})
    }

    const onReceiverSubmit = (data, event) => 
    {
        event.preventDefault()
        console.log('goto submit')
        let sConsumerIndex = sendMoneyObject.consumers.findIndex(consumer => consumer.id === sendMoneyObject.selectedConsumerId)
        if(viewMode == 'edit') {
            console.log('goto next edit')
            setSendMoneyObject(prev => {
                prev.selectedReceiverId = receiver.id
                let recvInd = prev.consumers[sConsumerIndex].receivers.findIndex(rec => rec.id === receiver.id)

                if(sendMoneyObject.sDeliveryMethod.id == 34) {
                    prev.consumers[sConsumerIndex].receivers[recvInd].mobileWalletAccountNo        =   data.mobileWalletAccountNo
                } else  if(sendMoneyObject.sDeliveryMethod.id == 2) {
                    prev.consumers[sConsumerIndex].receivers[recvInd].bankAccountNo                =   data.bankAccountNo
                    prev.consumers[sConsumerIndex].receivers[recvInd].bankRoutingCode              =   data.bankRoutingCode != undefined && data.bankRoutingCode != "" ? data.bankRoutingCode : undefined
                    prev.consumers[sConsumerIndex].receivers[recvInd].bankId                       =   sendMoneyObject.sPayoutPartner.id
                    prev.consumers[sConsumerIndex].receivers[recvInd].bankName                     =   sendMoneyObject.sPayoutPartner.name
                    prev.consumers[sConsumerIndex].receivers[recvInd].bankBranchNo                 =   data.bankBranchNo != undefined && data.bankBranchNo != "" ? data.bankBranchNo : undefined
                    prev.consumers[sConsumerIndex].receivers[recvInd].bankBranchName               =   bankBranches.find(o => o.id == data.bankBranchNo) != undefined ? bankBranches.find(o => o.id == data.bankBranchNo).name : undefined
                    prev.consumers[sConsumerIndex].receivers[recvInd].unitaryBankAccountNo         =   data.unitaryBankAccountNo != undefined && data.unitaryBankAccountNo != "" ? data.unitaryBankAccountNo : undefined
                }
                // prev.consumers[sConsumerIndex].receivers[recvInd].relationshipId               =   data.relationship
                // prev.consumers[sConsumerIndex].receivers[recvInd].relationshipText             =   relationships.find(r => r.Value == data.relationship).Name

                prev.consumers[sConsumerIndex].receivers[recvInd].relationshipText = data.relationshipOtherText != undefined && data.relationshipOtherText != "" ? data.relationshipOtherText : relationships.find(r => r.Value == data.relationship).Name
                prev.consumers[sConsumerIndex].receivers[recvInd].relationshipId = data.relationship

                prev.consumers[sConsumerIndex].receivers[recvInd].transferReasonId = data.transferReason
                prev.consumers[sConsumerIndex].receivers[recvInd].transferReasonText = data.transferReasonOtherText != undefined && data.transferReasonOtherText != "" ? data.transferReasonOtherText : sendMoneyObject?.enumTransferReasons.find(o => o.Value == data.transferReason).Name

                prev.consumers[sConsumerIndex].receivers[recvInd].sourceOfFundsId = data.sourceOfFunds
                prev.consumers[sConsumerIndex].receivers[recvInd].sourceOfFundsText = data.sourceOfFundsOtherText != undefined && data.sourceOfFundsOtherText != "" ? data.sourceOfFundsOtherText : sendMoneyObject?.enumSourceFunds.find(o => o.Value == data.sourceOfFunds).Name
    

                prev.previousStep = 3
                prev.step = 4
            }, c => {
                // dispatch(setStep(4));
                return
            })
            
        } else  {
            console.log('goto next add')
        
        
        let params = {}
        // params.ria_receiver_id = Math.floor(Math.random() * 99999);
        
        params.country = data.country
        params.state = data.state
        params.address = data.address
        params.zipCode = data.zipCode
        params.city = data.city
        params.phoneCode = phoneCode
        // params.phoneCode = 44
        params.phoneNumber = data.phoneNumber
        params.emailAddress = data.emailAddress
        params.relationshipId = data.relationship
        params.relationshipText = data.relationshipOtherText != undefined ? data.relationshipOtherText : relationships.find(r => r.Value == data.relationship).Name
        params.typeId = sendMoneyObject.sProduct.id == 111 ? 10 : 30

        if(sendMoneyObject.sDeliveryMethod.id == 34) {
            params.mobileWalletAccountNo = data.mobileWalletAccountNo
        } else if(sendMoneyObject.sDeliveryMethod.id == 2) {
            params.bankAccountNo = data.bankAccountNo
            params.bankRoutingCode = data.bankRoutingCode
            params.bankId = sendMoneyObject.sPayoutPartner.id
            params.bankName = sendMoneyObject.sPayoutPartner.name
            params.unitaryBankAccountNo = data.unitaryBankAccountNo != undefined && data.unitaryBankAccountNo != "" ? data.unitaryBankAccountNo : undefined
            params.bankBranchNo = data.bankBranchNo != undefined && data.bankBranchNo != "" ? data.bankBranchNo : undefined
            params.bankBranchName = bankBranches.find(o => o.id == data.bankBranchNo) != undefined ? bankBranches.find(o => o.id == data.bankBranchNo).name : undefined
        }

        if(sendMoneyObject.sProduct.id == 111) {
            params.firstName = data.firstName
            params.middleName = data.middleName
            params.lastName = data.lastName
            params.lastName2 = data.lastName2 
        } else if(sendMoneyObject.sProduct.id == 116) {
            params.entityName = data.entityName
        }

        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + sendMoneyObject.selectedConsumerId + "/saveReceiver",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
            data: params
        })
        .then((res) => {
            dispatch(hideLoading());
            if(res.data.status == "SUCCESS") 
            {
                let receiver = {}
                receiver.id = res.data.data.id;
                receiver.riaReceiverId = null;
                receiver.country = data.country
                receiver.state = data.state
                receiver.address = data.address
                receiver.zipCode = data.zipCode
                receiver.city = data.city
                receiver.phoneCode = phoneCode
                receiver.phoneNumber = data.phoneNumber
                receiver.emailAddress = data.emailAddress
                receiver.relationshipText = data.relationshipOtherText != undefined && data.relationshipOtherText != "" ? data.relationshipOtherText : relationships.find(r => r.Value == data.relationship).Name
                receiver.relationshipId = data.relationship

                if(sendMoneyObject.sDeliveryMethod.id == 34) {
                    receiver.mobileWalletAccountNo = data.mobileWalletAccountNo
                } else if(sendMoneyObject.sDeliveryMethod.id == 2) {
                    receiver.bankAccountNo = data.bankAccountNo
                    receiver.bankRoutingCode = data.bankRoutingCode
                    receiver.bankId = sendMoneyObject.sPayoutPartner.id
                    receiver.bankName = sendMoneyObject.sPayoutPartner.name
                    receiver.unitaryBankAccountNo = data.unitaryBankAccountNo
                    receiver.bankBranchNo = data.bankBranchNo
                    receiver.bankBranchName = bankBranches.find(o => o.id == data.bankBranchNo) != undefined ? bankBranches.find(o => o.id == data.bankBranchNo).name : undefined
                }

                if(sendMoneyObject.sProduct.id == 111) {
                    receiver.firstName = data.firstName
                    receiver.middleName = data.middleName
                    receiver.lastName = data.lastName
                    receiver.lastName2 = data.lastName2
                } else if(sendMoneyObject.sProduct.id == 116) {
                    receiver.entityName = data.entityName
                }

                receiver.transferReasonId = data.transferReason
                receiver.transferReasonText = data.transferReasonOtherText != undefined && data.transferReasonOtherText != "" ? data.transferReasonOtherText : sendMoneyObject?.enumTransferReasons.find(o => o.Value == data.transferReason).Name

                receiver.sourceOfFundsId = data.sourceOfFunds
                receiver.sourceOfFundsText = data.sourceOfFundsOtherText != undefined && data.sourceOfFundsOtherText != "" ? data.sourceOfFundsOtherText : sendMoneyObject?.enumSourceFunds.find(o => o.Value == data.sourceOfFunds).Name
    

                setSendMoneyObject(prev => {
                    if(prev.consumers[sConsumerIndex].receivers == undefined) {
                        console.log('receivers list after add is undefined') 
                        prev.consumers[sConsumerIndex].receivers = [];
                    }
                    prev.consumers[sConsumerIndex].receivers.push(receiver)
                    prev.selectedReceiverId = receiver.id
                    prev.previousStep = 3
                    prev.step = 4
                }, c => {
                    // dispatch(setStep(4));
                })

            }
        })
        .catch((error) => {
            console.log(error)
            dispatch(hideLoading());
        });

    }

    }

    const getInitials = (receiver) => {
        // console.log("receiver", receiver)
        var name = receiver.firstName.charAt(0);
        name += receiver.lastName.charAt(0);
        return name
    }

    const onUsePhoneNoChange = (e) => {
        if(e.target.checked == true) {
            setValue("mobileWalletAccountNo", document.getElementById('phoneNumber').value)
            document.getElementById('mobileWalletAccountNo').setAttribute('readonly', true);
        } else {
            document.getElementById('mobileWalletAccountNo').removeAttribute('readonly');
        }
    }

    const goNext = () => {
        console.log("goNext")
        dispatch(setStep(4))
    }

    function getStatesOfCountry(element) {
        let countryindex = codeTable.countries_info.findIndex((a) => a.country_code === SEND_MONEY_OBJECT.sDestCountry.country_code);
        return codeTable.countries_info[countryindex].states


        var dnd = document.querySelector(`#${element}`);
        var dnd = document.getElementById('countryRCV');
        console.log("getstate", dnd)
        if(dnd != null) {
            console.log("getStatesOfCountry", dnd.selectedIndex)
            console.log("getStatesOfCountry", codeTable.countries_info[dnd.selectedIndex])
            console.log("getStatesOfCountry", codeTable.countries_info[dnd.selectedIndex].states)
            return codeTable.countries_info[dnd.selectedIndex].states
        } else return []
    }

    // function getReceiver()
    // {
    //     let sConsumerIndex = SEND_MONEY_OBJECT.consumers.findIndex(consumer => consumer.id === SEND_MONEY_OBJECT.selectedConsumerId)
    //     let receivers = SEND_MONEY_OBJECT.consumers[sConsumerIndex].receivers

    //     if(sendMoneyObject. != undefined) {
    //         return sendMoneyObject.consumers.find(c => c.id === sendMoneyObject.selectedConsumerId)
    //     } else return null 
    // }

    const onRelationChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text);
        setRelationshipObject({
            value: event.target.value,
            text: event.target.options[event.target.selectedIndex].text
        })
    };

    const onTransferReasonChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text);
        setTransferReasonObject({
            value: event.target.value,
            text: event.target.options[event.target.selectedIndex].text
        })
    };

    const onSourceOfFundsChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text);
        setSourceOfFundsObject({
            value: event.target.value,
            text: event.target.options[event.target.selectedIndex].text
        })
    };

    return (
        <div style={{ maxWidth: "900px", margin: "auto" }}>
            <>
                <span>{receiver.firstName}</span>
                <div className='row' style={{ maxWidth: "500px", margin: "auto", marginBottom: '15px' }}>
                    <button className='btn btn-secondary' style={{ maxWidth: '200px', marginLeft: 'auto', marginRight: '0' }} onClick={newReceiver}>{t("ADD_NEW_RECEIVER")}</button>
                </div>
                <div>
                    {
                        sendMoneyObject.consumers.find(e => e.id == sendMoneyObject.selectedConsumerId)?.receivers?.length > 0 ?
                            <div>
                                {/* <div className='row' style={{ maxWidth: "500px", margin: "auto" }}>
                                    <label htmlFor="searchText" className='marg-btm-5'>{ t("FILTER_RECEIVER_BY_NAME") }</label>
                                    <input type="text" id="searchText" className='form-control marg-btm-5' onChange={e => setFilterText(e.target.value)} />
                                </div> */}
                                <hr />
                                <h5>{t('RECEIVERS')}</h5>
                                <div className='row'>
                                    {
                                        sendMoneyObject.consumers.find(e => e.id == sendMoneyObject.selectedConsumerId)?.receivers?.map((receiver, key) => (
                                            <div key={key} id={key} className="card col-6 col-md-2 company receiver" style={{ margin: "25px", cursor: "pointer" }} onClick={(e) => onReceiverSelect(e, receiver)} >
                                                <div className="card-body">
                                                    <div className="recipient-legend">
                                                        <div className="recipient-legend-avatar">{getInitials(receiver)}</div>
                                                    </div>
                                                    <div className="recipient-content mt-2" style={{ textAlign: "center" }}>
                                                        <div style={{ fontSize: '14px' }}>{getReceiverName(receiver)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            :

                            <div className="text-center">
                                <h6>{t("NO_RIA_REEIVER_FOUND")}</h6>
                            </div>

                    }
                </div>
                <hr />
                {
                    viewMode == "" &&
                    <div style={{ textAlign: "left" }}>
                        <button type='button' className='btn btn-secondary' onClick={back}> {t('BACK')} </button> &nbsp; &nbsp;
                    </div>
                }
                
            </>
            {
                viewMode != "" &&
                <div>
                    <form id="formID" onSubmit={handleSubmit(onReceiverSubmit)}>
                        <div >
                            {
                                (viewMode == 'add' || viewMode == 'edit') ?
                                    <>
                                        <div className="row">
                                            {
                                                SEND_MONEY_OBJECT.sProduct.id == 116 &&
                                                <div className="col-md-6 marg-btm">
                                                    <label className="col-form-label" htmlFor="entityName">{t("ENTITY_NAME")}</label> <span style={{ color: "red" }}>*</span>
                                                    <input type="text" id="entityName" className='form-control' {...register("entityName")} defaultValue={receiver.entityName} />
                                                    <p className="input-error-msg"> {errors.entityName?.message} </p>
                                                </div>
                                            }
                                            {
                                                SEND_MONEY_OBJECT.sProduct.id == 111 &&
                                                <>
                                                    <div className="col-md-6 marg-btm">
                                                        <label className="col-form-label" htmlFor="firstName"> {t("FIRST_NAME")}/{t("MIDDLE_NAME")} </label> <span style={{ color: "red" }}>*</span>
                                                        <div className="input-group">
                                                            <input type="text" id="firstName"  className='form-control' placeholder={t("FIRST_NAME")} {...register("firstName")} defaultValue={receiver?.firstName} />
                                                            <input type="text" id="middleName" className='form-control' placeholder={t("MIDDLE_NAME")} {...register("middleName")} defaultValue={receiver.middleName} />
                                                        </div>
                                                        {errors.firstName && <p className="input-error-msg"> {errors.firstName?.message} </p>}
                                                    </div>
                                                    <div className="col-md-6 marg-btm">
                                                        <label className="col-form-label" htmlFor="firstName"> {t("LAST_NAME")}/{t("SECOND_LAST_NAME")} </label> <span style={{ color: "red" }}>*</span>
                                                        <div className="input-group">
                                                            <input type="text" id="lastName" className='form-control' placeholder={t("LAST_NAME")}  {...register("lastName")} defaultValue={receiver.lastName} />
                                                            <input type="text" id="lastName2" className='form-control' placeholder={t("SECOND_LAST_NAME")} {...register("lastName2")} defaultValue={receiver.lastName2} />
                                                        </div>
                                                        {errors.firstName && <p className="input-error-msg"> {errors.firstName?.message} </p>}
                                                    </div>
                                                </>
                                            }
                                            <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="address">{t("ADDRESS")}</label> <span style={{ color: "red" }}>*</span>
                                                <input type="text" id="address" className='form-control' {...register("address")} defaultValue={receiver.address} />
                                                <p className="input-error-msg"> {errors.address?.message} </p>
                                            </div>
                                            <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="city">{t("CITY")}</label> <span style={{ color: "red" }}>*</span>
                                                <input type="text" id="city" className='form-control' {...register("city")} defaultValue={receiver.city} />
                                                <p className="input-error-msg"> {errors.city?.message} </p>
                                            </div>
                                            <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="countryRCV">{t("COUNTRY")}</label> <span style={{ color: "red" }}>*</span>
                                                {/* <input type="text" id="country" className='form-control' {...register("country")} readOnly /> */}
                                                <select style={{ pointerEvents: "none" }} onClick={() => { return false; }} onKeyDown={() => { return false; }} className="form-select" name="countryRCV" id="countryRCV" {...register("country")} onChange={(e) => onCountryChange(e)} defaultValue={receiver.country}
                                                >
                                                    {codeTable?.countries_info?.map((country, key) => (
                                                        <option value={country.country_code} key={key}>{country.country_name}</option>
                                                    ))}
                                                </select>
                                                <p className="input-error-msg"> {errors.country?.message} </p>
                                            </div>
                                            <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="state">{t("STATE")}</label> <span style={{ color: "red" }}>*</span>
                                                <select style={{ maxWidth: "100%" }} className="form-select" name="state" id="state" {...register("state")} defaultValue={receiver.state}
                                                >
                                                    <option value="">Select</option>
                                                    {getStatesOfCountry('countryRCV')?.map((state, key) => (
                                                        <option value={state.code} key={key}>{state.name}</option>
                                                    ))}
                                                </select>
                                                <p className="input-error-msg"> {errors.state?.message} </p>
                                            </div>

                                            <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="phoneNumber">{t("PHONE_NUMBER")}</label> <span style={{ color: "red" }}>*</span>
                                                <div className="input-group col-8" style={{ width: "100%" }}>
                                                    <span className="input-group-text" id="basic-addon1">
                                                        {phoneCode}
                                                    </span>
                                                    <input type="text" id="phoneNumber" className='form-control' aria-describedby="basic-addon1" {...register("phoneNumber")} defaultValue={receiver.phoneNumber} />
                                                </div>
                                                <p className="input-error-msg"> {errors.phoneNumber?.message} </p>
                                            </div>
                                            
                                            <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="email">{t("EMAIL")}</label>
                                                <input type="text" id="email" className='form-control' {...register("email")} defaultValue={receiver.emailAddress} />
                                                <p className="input-error-msg"> {errors.email?.message} </p>
                                            </div>
                                            {/* <div className="col-md-6 marg-btm">
                                                <label className="col-form-label" htmlFor="zipCode">{t("ZIPCODE")}</label>
                                                <input type="text" id="zipCode" className='form-control' {...register("zipCode")} defaultValue={receiver.zipCode} />
                                                <p className="input-error-msg"> {errors.zipCode?.message} </p>
                                            </div> */}

                                            {
                                                SEND_MONEY_OBJECT.sDeliveryMethod.id == 34 &&
                                                <div className="col-md-6 marg-btm">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={onUsePhoneNoChange} />
                                                        <label class="form-check-label" for="flexCheckDefault"> {t("USE_PHONE_NUMBER")} </label> <span style={{ color: "red" }}>*</span>
                                                    </div>
                                                    {/* <label className="col-form-label" htmlFor="email">{t("EMAIL")}</label> */}
                                                    <input type="mobileWalletAccountNo" id="mobileWalletAccountNo" className='form-control' {...register("mobileWalletAccountNo")} placeholder={t("MOBILE_WALLET_ACCOUNT_NUMBER")} />
                                                    <p className="input-error-msg"> {errors.mobileWalletAccountNo?.message} </p>
                                                </div>
                                            }
                                            {
                                                SEND_MONEY_OBJECT.sDeliveryMethod.id == 2 &&
                                                <>
                                                    <div className="col-md-6 marg-btm">
                                                        <label className="col-form-label" htmlFor="bankAccountNo">{t("BANK_ACCOUNT_NUMBER")}</label> <span style={{ color: "red" }}>*</span>
                                                        <input type="bankAccountNo" id="bankAccountNo" className='form-control' {...register("bankAccountNo")} />
                                                        <p className="input-error-msg"> {errors.bankAccountNo?.message} </p>
                                                    </div>
                                                    {
                                                        SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'BankBranchNo').length > 0 &&
                                                        (
                                                            <div className="col-md-6 marg-btm">
                                                                <label className="col-form-label" htmlFor="bankBranchNo">{t("BANK_BRANCH")}</label>
                                                                <select style={{ maxWidth: "100%" }} className="form-select" name="bankBranchNo" id="bankBranchNo" {...register("bankBranchNo")} >
                                                                    <option value="">{t("SELECT")}</option>
                                                                    {
                                                                        bankBranches?.map((branch, key) => (
                                                                            <option value={branch.id} key={key}>{branch.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <p className="input-error-msg"> {errors.bankBranchNo?.message} </p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'UnitaryBankAccountNo').length > 0 &&
                                                        (
                                                            <div className="col-md-6 marg-btm">
                                                                <label className="col-form-label" htmlFor="unitaryBankAccountNo">{t("BANK_IBAN")}</label> <span style={{ color: "red" }}>*</span>
                                                                <input type="unitaryBankAccountNo" id="unitaryBankAccountNo" className='form-control' {...register("unitaryBankAccountNo")} />
                                                                <p className="input-error-msg"> {errors.unitaryBankAccountNo?.message} </p>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        SEND_MONEY_OBJECT.feeInfos[0].RequiredFields.RequiredField.filter(o => o.FieldName == 'BankRoutingCode').length > 0 &&
                                                        (
                                                            <div className="col-md-6 marg-btm">
                                                                <label className="col-form-label" htmlFor="email">{t("BANK_ROUTING_CODE")}</label>
                                                                <select style={{ maxWidth: "100%" }} className="form-select" name="bankRoutingCode" id="bankRoutingCode" {...register("bankRoutingCode")} >
                                                                    <option value="">{t("SELECT")}</option>
                                                                    {
                                                                        SEND_MONEY_OBJECT.sPayoutPartner?.routingCodes?.map((routing, key) => (
                                                                            <option value={routing.code} key={key}>{routing.code}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <p className="input-error-msg"> {errors.bankRoutingCode?.message} </p>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>
                                    </>
                                    : viewMode == 'show' &&
                                    <>
                                        <div className="" >
                                            <div className="row" style={{ padding: "10px 1px" }}>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Country</span>
                                                    <span className="col-md-8 value">{receiver?.country}</span>
                                                </div>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">State</span>
                                                    <span className="col-md-8 value">{receiver?.state}</span>
                                                </div>
                                            </div>
                                            <div className="row odds" style={{ padding: "10px 1px" }}>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Address</span>
                                                    <span className="col-md-8 value">{receiver?.address}</span>
                                                </div>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">City</span>
                                                    <span className="col-md-8 value">{receiver?.city}</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ padding: "10px 1px" }}>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Zip Code</span>
                                                    <span className="col-md-8 value">{receiver?.emailAddress}</span>
                                                </div>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Phone No</span>
                                                    <span className="col-md-8 value">{receiver?.phoneCode + receiver?.phoneNumber}</span>
                                                </div>
                                            </div>
                                            <div className="row odds" style={{ padding: "10px 1px" }}>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Email</span>
                                                    <span className="col-md-8 value">{receiver?.emailAddress}</span>
                                                </div>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Relationship</span>
                                                    <span className="col-md-8 value">{receiver?.relationshipText}</span>
                                                </div>
                                            </div>
                                            <div className="row" style={{ padding: "10px 1px" }}>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Wallet Account No</span>
                                                    <span className="col-md-8 value">{receiver?.mobileWalletAccountNo}</span>
                                                </div>
                                                <div className="col-md-6 row">
                                                    <span className="col-md-4 key">Bank Account No</span>
                                                    <span className="col-md-8 value">{receiver?.bankAccountNo}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                        <hr />
                        <div className="row marg-btn">
                            {/* <hr style={{ marginTop: "40px" }} /> */}
                            <h5> Transaction Info </h5>
                        </div>
                        <div className="row marg-btm">
                        <div className="col-md-4">
                            <label className="col-form-label" htmlFor="transferReason"> {t("TRANSFER_REASON")} </label> <span style={{ color: "red" }}>*</span>
                            <select style={{ maxWidth: "100%" }} className="form-select" name="transferReason" id="transferReason" {...register("transferReason")} onChange={onTransferReasonChange} >
                                <option value="">{t("SELECT")}</option>
                                {sendMoneyObject?.enumTransferReasons?.map((reason, key) => (
                                    <option value={reason.Value} key={key}>{reason.Name}</option>
                                ))}
                            </select>
                            <p className="input-error-msg"> {errors.transferReason?.message} </p>
                            <>
                                {( transferReasonObject.text != undefined && transferReasonObject.text == 'Other') && (
                                        <>
                                            <input type="text" id="transferReasonOtherText" placeholder={t("TRANSFER_REASON_OTHER_TEXT")} name="transferReasonOtherText" className='form-control' {...register("transferReasonOtherText")} style={{ marginTop: "15px" }} />
                                            <p className="input-error-msg">
                                                {errors.transferReasonOtherText?.message}
                                            </p>
                                        </>
                                    )}
                            </>
                        </div>
                        <div className="col-md-4">
                            <label className="col-form-label" htmlFor="country"> {t("SOURCE_OF_FUNDS")} </label> <span style={{ color: "red" }}>*</span>
                            <select style={{ maxWidth: "100%" }} className="form-select" name="sourceOfFunds" id="sourceOfFunds" {...register("sourceOfFunds")} onChange={onSourceOfFundsChange}>
                                <option value="">{t("SELECT")}</option>
                                {sendMoneyObject?.enumSourceFunds?.map((sourceOfFunds, key) => (
                                    <option value={sourceOfFunds.Value} key={key}>{sourceOfFunds.Name}</option>
                                ))}
                            </select>
                            <p className="input-error-msg"> {errors.sourceOfFunds?.message} </p>
                            <>
                                {( sourceOfFundsObject.text != undefined && sourceOfFundsObject.text == 'Other') && (
                                        <>
                                            <input type="text" id="sourceOfFundsOtherText" placeholder={t("SOURCE_OF_FUNDS_OTHER_TEXT")} name="sourceOfFundsOtherText" className='form-control' {...register("sourceOfFundsOtherText")} style={{ marginTop: "15px" }} />
                                            <p className="input-error-msg">
                                                {errors.sourceOfFundsOtherText?.message}
                                            </p>
                                        </>
                                    )}
                            </>
                        </div>
                        <div className="col-md-4">
                            {
                                SEND_MONEY_OBJECT.sProduct.id == 111 &&
                                <div className="marg-btm">
                                    <label className="col-form-label" htmlFor="relationship">{t("RELATIONSHIP")}</label> <span style={{ color: "red" }}>*</span>
                                    <select style={{ maxWidth: "100%" }} className="form-select" name="relationship" id="relationship" {...register("relationship")} onChange={onRelationChange} >
                                        <option value="">{t("SELECT")}</option>
                                        {
                                            relationships?.map((relationship, key) => (
                                                <option value={relationship.Value} key={key}>{relationship.Name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="input-error-msg"> {errors.relationship?.message} </p>
                                    <>
                                        {(relationshipObject.text != undefined && relationshipObject.text == 'Other') && (
                                            <>
                                                <input type="text" id="relationshipOtherText" placeholder={t("RELATIONSHIP_OTHER_TEXT")} name="relationshipOtherText" className='form-control' defaultValue={receiver.relationshipOtherText} {...register("relationshipOtherText")} style={{ marginTop: "15px" }} />
                                                <p className="input-error-msg">
                                                    {errors.relationshipOtherText?.message}
                                                </p>
                                            </>
                                        )}
                                    </>
                                </div>
                            }
                        </div>
                    </div>
                        <div className='row' style={{ padding: "2px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button type='button' className='btn btn-secondary' onClick={back}> {t('BACK')} </button>
                                <button type='submit' className='btn btn-primary'> {t("SAVE")} </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    );

    function useStateCallback(initialState) {
        const [state, setState] = useImmer(initialState);
        const cbRef = useRef(null);
      
        const setStateCallback = useCallback((state, cb) => {
          cbRef.current = cb; 
          setState(state);
        }, []);
      
        useEffect(() => {
          if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
          }
        }, [state]);
      
        return [state, setStateCallback];
      }

}

export default RiaReceiver