import React from 'react'
import { useTranslation } from 'react-i18next';

export default function UserDetails(props) {
    const { t } = useTranslation();
    const displayStats = Object.entries(props).length > 0 ? true : false
    return (
        <>
            {
                displayStats &&
                <div className="row mb-2 border-bottom">
                    <div className="col-12 col-lg-2"></div>
                    <div className="col-12 col-lg-8">

                        <table className="table table-striped table-sm">
                            <tbody>
                                <tr>
                                    <td>{ t("FIRSTNAME") }</td>
                                    <td>
                                        <strong>{props ? props.first_name : '-'}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ t("LASTNAME") }</td>
                                    <td>
                                        <strong>{props ? props.last_name : '-'}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ t("PHONE_NO") }</td>
                                    <td>
                                        <strong>{props ? props.phone_number : '-'}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ t("ADDRESS") }</td>
                                    <td>
                                        <strong>{props ? props.address : '-'}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            }
        </>
    )
}
