import React, { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SearchMerchantCode from './merchantUtils/SearchMerchantCode';
import PayerDetailsForm from './merchantUtils/PayerDetailsForm';
import MerchantPaymentComplete from './MerchantPaymentComplete';
import { setCurrentStep, setPaymentDetails, setReceivingMerchantCode, setReceivingMerchantName } from '../../slices/merchant-payment-slice';
import { useEffect } from 'react';
import { showToast } from '../../slices/toast-slice';
import { useNavigate } from 'react-router-dom';
import PayDSD from '../PayDSD';

export default function MerchantPaymentForm() {
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const current_step = useSelector(state => state.merchantPayment.current_step);
    const initForm = useSelector(state => state.merchantPayment.initForm);
    const receivingMerchantCode = useSelector(state => state.merchantPayment.receiving_merchant_code);
    const dsdService = useSelector(state => state.merchantPayment.dsdService);



    const [ paymentAllowed, setPaymentAllowed ] = useState(false);

    const resetPaymentForm = () => {
        dispatch(setCurrentStep(0));
        dispatch(setReceivingMerchantCode(""));
        dispatch(setReceivingMerchantName(""));
        dispatch(setPaymentDetails({}));
    }

    useEffect(() => {
        // reset the payment form
        resetPaymentForm();

        // check if the authenticated user has a valid passcode
        const signature = globalAuthUser?.signature;

        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            if(!signature) {
                // no signature is set for the user, prompt the user for a new signature
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
                navigate('/create-passcode');
                return;
            }

            if(!signature?.isActive || signature?.isActive == 2) {
                // signature code not activated
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
                navigate('/activate-passcode');
                return;
            }
        }

        // set the sending allowed
        setPaymentAllowed(true);
    }, [])
    
  return (
        <div style={{ padding: "20px" }}>
            <div className="card">
                <div className="card-header">
                    { t("PAY_MERCHANT") }
                </div>

                <div className="card-body">
                    { current_step !== 2 && <SearchMerchantCode />}
                    { current_step === 1 && receivingMerchantCode != dsdService?.merchantCode && <PayerDetailsForm /> }
                    { current_step === 1 && receivingMerchantCode == dsdService?.merchantCode && <PayDSD key={initForm} /> }
                    { current_step === 2 && <MerchantPaymentComplete /> }
                </div>
            </div>
        </div>
  )
}
