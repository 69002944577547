import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setTransactionDetails } from '../../slices/local-sending-slice';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { showToast } from '../../slices/toast-slice';
import { useTranslation } from 'react-i18next';
import GlobalConfirmPasscod from '../GlobalConfirmPasscod';

export default function LocalTxnSummary() {
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const { t } = useTranslation();
    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);

    const currentStep = useSelector(state => state.localSending.currStep);
    const senderDetails = useSelector(state => state.localSending.senderDetails);
    const receiverDetails = useSelector(state => state.localSending.receiverDetails);
    const paymentMethod = useSelector(state => state.localSending.paymentMethod);
    const sendingAmount = useSelector(state => state.localSending.sendingAmount);
    const receivingAmount = useSelector(state => state.localSending.receivingAmount);
    const fees = useSelector(state => state.localSending.transactionFees);

    const globalUser = useSelector(state => state.auth.globalAuthUser);
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const handleNext = () => {
        dispatch(setCurrentStep(currentStep + 1));
    }

    const formatMoney = (money) => {
        let currency = globalAuthUser.location.agent_country ? globalAuthUser.location.agent_country.currency : "";
        let formatedAmt = Number(money).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2});
        
        // return the formatted amount including the currency
        return currency != "" ? `${formatedAmt} (${currency})` : formatedAmt;
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            openModal();
            return;
        }
        // save the local txn
        saveLocalTxn();
    }

    const saveLocalTxn = () => {
        // initiate the loading activity
        dispatch(showLoading());

        // send the transaction
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/localSend/saveTransaction`;
        const country = globalUser.location.agent_country;
        const data = {
            "sender_account_id": senderDetails.id,
            "receiver_account_id": receiverDetails.id,
            "sending_amount": sendingAmount,
            "sending_currency": country.currency,
            "receiving_option_code": paymentMethod.length ? paymentMethod[0].abb : "",
            "agent_id": globalUser.user_id,
            "tax": fees.taxes ? fees.taxes : 0,
            "sending_fees": fees.fees ? fees.fees : 0
        }

        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${authUser().tokenType} ${authUser().token}`,
            },
            data: data
        }).then(res => {
            // stop loading service
            dispatch(hideLoading());

            // process the response
            const resData = res.data;
            if(!resData.status === 1){
                // the service transaction creation failed, notify the uer
                const err_msg = resData.message && t(resData.message) ? resData.message : t("ERROR_HAS_OCCURED")
                dispatch(showToast(["danger", err_msg, err_msg]));
                return;
            }

            // transaction completed successfully
            dispatch(setTransactionDetails(resData.additionalParams));

            // move to the complete step
            dispatch(setCurrentStep(currentStep + 1));

        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            // extract the response data
            const err_res_data = err.response.data;
            if(err_res_data && err_res_data.status !== 500){
                // transaction failed with a valid error message
                dispatch(showToast(["danger", "Transaction Error", t(err_res_data.message)]));
                return;
            }
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }

    return (
        <>
            {/** the local sending summary */}
            <div className="row mb-2 border-bottom">
                <div className="col-12 col-lg-3"></div>
                <div className="col-12 col-lg-6">
                    {/** local sending summary table */}
                    <table className="table table-striped table-sm">
                        <tbody>
                            <tr>
                                <td>{ t("SENDER_NAME") }</td>
                                <td>
                                    {
                                        senderDetails && 
                                        <strong>{ `${senderDetails.first_name} ${senderDetails.last_name}`}</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("RECEIVER_NAME") }</td>
                                <td>
                                    {
                                        receiverDetails && 
                                        <strong>{ `${receiverDetails.first_name} ${receiverDetails.last_name}`}</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("DELIVERY_OPTION") }</td>
                                <td>
                                    {
                                        paymentMethod.length && 
                                        <strong>{ paymentMethod[0].abb_name }</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("SENDING_AMOUNT") }</td>
                                <td>
                                    {
                                        sendingAmount && 
                                        <strong>{ formatMoney(sendingAmount) }</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("RECEIVING_AMOUNT") }</td>
                                <td>
                                    {
                                        receivingAmount && 
                                        <strong>{ formatMoney(receivingAmount) }</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("SENDING_FEES") }</td>
                                <td>
                                    {
                                        fees && 
                                        <strong>{ formatMoney(fees.fees) }</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("SENDING_TAX") }</td>
                                <td>
                                    {
                                        fees && 
                                        <strong>{ formatMoney(fees.taxes) }</strong>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{ t("GRAND_TOTAL") }</td>
                                <td>
                                    {
                                        fees && 
                                        <strong>{ formatMoney(fees.total_amount) }</strong>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/** control buttons */}
            <div className="row mb-2">
                <div className="col-12 col-lg-2"></div>
                <div className="col-12 col-lg-8 d-flex justify-content-between">
                    <button type='button' className="btn btn-sm btn-secondary" onClick={() => dispatch(setCurrentStep(currentStep - 1))}>
                        { t("BACK") }
                    </button>
                    <button type='button' className="btn btn-sm btn-primary" onClick={handleSubmit}>
                        { t("SUBMIT") }
                    </button>
                </div>
            </div>

            {/** passcode modal */}
            <div>
                <GlobalConfirmPasscod
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callParentFunction={saveLocalTxn}
                />
            </div>
        </>
    )
}
