import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../slices/toast-slice";


const ChangePassword = () => {

    const { t } = useTranslation();
    const authUser = useAuthUser();
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [isMin, setIsMin] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [passwordDifferent, setPasswordDifferent] = useState(false);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    
    useEffect(() => {
        const btn = document.getElementById('btnSubmit');
        if (currentPassword === '') {
            btn.setAttribute('disabled','disabled');
        }else{
            if (password === confirmPassword) {
                setPasswordMatch(true);
                btn.removeAttribute('disabled');
            } else {
                setPasswordMatch(false);
                btn.setAttribute('disabled','disabled');
            }
        }
        
    }, [password, confirmPassword, currentPassword]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (e.target.value.length <6) {
           setIsMin(true); 
        }else{
            setIsMin(false);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    

    function resetPassword(event) {
        event.preventDefault();
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            'current_password': currentPassword,
            'password': password,
            'password_confirmation': confirmPassword,
            'username': globalAuthUser?.username,
        };

        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/resetPassword', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if(response.data.status === 1){
                const btn = document.getElementById('btnSubmit');
                setSuccessMessage(t('PASSWORD_SUCCESS'));
                // Navigate to a new page after a delay
                btn.setAttribute('disabled','disabled');
                setTimeout(() => {
                navigate('/home');
                }, 2000);
             }
             else if(response.data.message === "ERR_PASSWORD_DIFFERENT"){
                setPasswordDifferent(true);
                setIsIncorrect(false);
             }
             else{
                setIsIncorrect(true);
                setPasswordDifferent(false);
             }
        })
        .catch(error => {
            dispatch(hideLoading());
            console.error(error);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            //display the error alert
        });
    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("CHANGE_PASSWORD")}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/user-profile" className="btn btn-primary btn-sm">
                                        {t("BACK")}
                                    </NavLink>
                                </div>
                            </h3>
                        </div>
                        <div className="card-body">
                                <form autoComplete="off" onSubmit={resetPassword}>
                                    <div className="row">
                                        { 
                                            (isIncorrect || passwordDifferent) && (<div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {isIncorrect ? t("PASSWORD_INCORRECT") : t('PASSWORD_DIFFERENT')}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                            ) 
                                        }
                                        { successMessage !== '' && (<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            {successMessage}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                            ) 
                                        }
                                        <div className="col-md-6">
                                            <label htmlFor="current_password" className="form-label">
                                                {t('CURRENT_PASSWORD')} <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                required
                                                className="form-control"
                                                placeholder={t('CURRENT_PASSWORD')}
                                                onChange={handleCurrentPasswordChange}
                                                type="password"
                                                name="current_password"
                                                id="current_password"
                                                value={currentPassword}
                                            />
                                            <br />
                                        </div>  
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="password" className="form-label">
                                                {t('NEW_PASSWORD')} <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="password"
                                                required
                                                placeholder={t('NEW_PASSWORD')}
                                                className="form-control"
                                                onChange ={handlePasswordChange}
                                                name="password"
                                                id="password"
                                                autoComplete="new-password"
                                                value={password}
                                            />
                                            {isMin && <div className="text-danger mt-1">{t('PASSWORD_MIN')}</div>}
                                            <br />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="password_confirmation" className="form-label">
                                                {t('PASSWORD_CONFIRMATION')} <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="password"
                                                required
                                                placeholder={t('PASSWORD_CONFIRMATION')}
                                                className="form-control"
                                                onChange ={handleConfirmPasswordChange}
                                                name="password_confirmation"
                                                id="password_confirmation"
                                                autoComplete="new-password"
                                                value={confirmPassword}
                                            />
                                            {!passwordMatch && <div className="text-danger mt-1">{t('NEW_PASSWORD_NOT_MATCH')}</div>}
                                            <br />
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        disabled
                                        className="btn btn-success btn-sm"
                                        id="btnSubmit"
                                    >
                                        {t('SUBMIT')}
                                    </button>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default ChangePassword;