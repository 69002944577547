import React, { useState, useEffect } from "react";
import {
  setGlobalMGreceiveCS,
  set_RECEIVER_COMPLIANCE,
} from "../../../slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { countryStates } from "../../../utils/helper";

// const schema = yup.object().shape({
//     receiverIDType: yup.string().required(),
//     receiverIDNumber: yup.string().required(),
//     receiverIDCountry: yup.string().required(),
//     receivePurposeOfTransaction: yup.string().required(),
//     // relationshipToSender: yup.string().required(),
//     receiverGovernmentIdType: yup.string()
//         .when('receiverIDType', {
//           is: (receiverIDType) => receiverIDType === 'GOV',
//           then: yup.string()
//             .required('Field is required')
//         })
// })

function RcvStep2(props) {
  const { t } = useTranslation();
  const propsData = props.items;

  const [receiverPhotoIdType, setReceiverPhotoIdType] = useState(null);
  const [receiverGovernmentIdType, setReceiverGovernmentIdType] =
    useState(null);

  const MG_GFFP_USED_OPT = useSelector((state) => state.auth.MG_GFFP_USED_OPT);
  const GFFP_RESP = useSelector((state) => state.auth.GFFP_RESP);
  console.log(MG_GFFP_USED_OPT);
  let CODETABLE = useSelector((state) => state.auth.CODETABLE);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const [states, setStates] = useState([]);
  const [countryCode, setCountryCode] = useState(null);

  let rcvCompliance = GFFP_RESP.requiredInfo.filter(
    (info) =>
      info.fieldCategory === "receiveTransactionInfoComplianceSet" ||
      info.fieldCategory === "receiveReceiverIdentificationPrimarySet"
  );
  rcvCompliance.push(
    GFFP_RESP.productFieldInfo.find(
      (info) => info.xmlTag === "relationshipToSender"
    )
  );
  rcvCompliance.push(
    GFFP_RESP.productFieldInfo.find(
      (info) => info.xmlTag === "receiverIntendedUseOfMGIServices"
    )
  );
  // rcvCompliance = rcvCompliance.filter(info => info.xmlTag !== 'receiverPhoneCountryCode')
  console.log(rcvCompliance);

  var rcvComplianceScheme = {};
  rcvCompliance?.forEach((field) => {
    var val = yup.string().required(t("FIELD_REQUIRED")).min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })).max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }));
    if (field.validationRegEx != null) {
      val = yup
        .string()
        .required(t("FIELD_REQUIRED"))
        .min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin }))
        .max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }))
        .matches(field.validationRegEx, t("MUST_MATCH_REGEX") + " " + field.validationRegEx)
    }
    if(field.dataType === "enum") {
      val = yup.string().required(t("FIELD_REQUIRED"))
    }
    rcvComplianceScheme[field.xmlTag] = val;
  });
  rcvComplianceScheme["receiverGovernmentIdType"] = yup
    .string()
    .when("receiverPhotoIdType", {
      is: (receiverPhotoIdType) => receiverPhotoIdType === "GOV",
      then: yup.string().required(t("FIELD_REQUIRED")),
    });

    var cplStateField = GFFP_RESP.productFieldInfo.find(
      (info) => info.xmlTag === "receiverPhotoIdState"
    );
  rcvComplianceScheme["receiverPhotoIdState"] = yup.string().when("receiverPhotoIdCountry", {
    is: (receiverCountry) =>
      receiverCountry === "USA" ||
      receiverCountry === "CAN" ||
      receiverCountry === "MEX",
    then: yup
      .string()
      .required(t("FIELD_REQUIRED"))
      .min(cplStateField.fieldMin, t("FIELD_MIN", { min: cplStateField.fieldMin }))
      .max(cplStateField.fieldMax, t("FIELD_MAX", { max: cplStateField.fieldMax }))
      .matches(cplStateField.validationRegEx, t("MUST_MATCH_REGEX") + " " + cplStateField.validationRegEx)
  });

  console.log(rcvComplianceScheme);

  const schema = yup.object().shape(rcvComplianceScheme);

  const currentStep = useSelector((state) => state.auth.globalMGreceiveCS);
  let RECEIVER_COMPLIANCE = useSelector(
    (state) => state.auth.RECEIVER_COMPLIANCE
  );
  function labelName(xmlTag) {
    // return GFFP_RESP.productFieldInfo.find((info) => info.xmlTag === xmlTag)
    //   .fieldLabel;
    return t("MONEYGRAM." + xmlTag);
  }

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onPrevious = () => {
    dispatch(setGlobalMGreceiveCS(1));
  };

  const onNext = (data, event) => {
    event.preventDefault();
    console.log(errors);
    console.log(data);
    dispatch(set_RECEIVER_COMPLIANCE(data));
    dispatch(setGlobalMGreceiveCS(3));
  };

  const onReceiverPhotoIdTypeChange = (event) => {
    
    setReceiverPhotoIdType(event.target.value)
    var data = { ...RECEIVER_COMPLIANCE}
    data.receiverPhotoIdType = event.target.value
    dispatch(set_RECEIVER_COMPLIANCE(data));
    console.log("govvalue", receiverPhotoIdType)
    console.log("govvalue1", RECEIVER_COMPLIANCE?.receiverPhotoIdType)
  };

  // const onReceiverGovernmentIdTypeChange = (event) => {
  //     setReceiverGovernmentIdType(event.target.value);
  // }

  const onCountryChange = (event) => {
    console.log(event.target.value);
    let countri = CODETABLE.country_info.findIndex(
      (a) => a.country_code === event.target.value
    );
    console.log(countri);
    setCountryCode(event.target.value);
    setStates(countryStates(CODETABLE, event.target.value))
    console.log("countryStates")
    console.log(countryStates(CODETABLE, event.target.value))
  };

  return (
    <>
      <form onSubmit={handleSubmit(onNext)}>
        <div style={{ padding: "40px 5px" }}>
          <div
            className="row"
            style={{
              padding: "1rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-md-6">
              <span className="key">{t("firstName")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverFirstName}
              </span>
            </div>
            <div className="col-md-6">
              <span className="key">{t("MIDDLE_NAME")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverMiddleName}
              </span>
            </div>
            <div className="col-md-6">
              <span className="key">{t("lastName")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverLastName}
              </span>
            </div>
            <div className="col-md-6">
              <span className="key">{t("SECOND_LAST_NAME")} : </span>
              <span className="value">
                {propsData?.refNoResp.receiverLastName2}
              </span>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".75rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label htmlFor="receiverPhotoIdType" className="col-form-label">
                {labelName("receiverPhotoIdType")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="receiverPhotoIdType"
                name="receiverPhotoIdType"
                className="form-select form-control"
                defaultValue={RECEIVER_COMPLIANCE?.receiverPhotoIdType}
                {...register("receiverPhotoIdType")}
                onChange={onReceiverPhotoIdTypeChange}
              >
                <option value="" defaultValue></option>
                <option value="PAS">{ t("PASSPORT") }</option>
                <option value="GOV">{ t("GOVERNMENT_ID") }</option>
              </select>
              <p className="input-error-msg">
                {" "}
                {errors.receiverPhotoIdType?.message}{" "}
              </p>
              {(receiverPhotoIdType === "GOV" ||
                RECEIVER_COMPLIANCE?.receiverPhotoIdType === "GOV") && (
                <>
                  <select
                    id="receiverGovernmentIdType"
                    name="receiverGovernmentIdType"
                    className="form-select form-control"
                    defaultValue={RECEIVER_COMPLIANCE?.receiverGovernmentIdType}
                    {...register("receiverGovernmentIdType")}
                    style={{ marginTop: "10px" }}
                  >
                    <option value="" defaultValue>
                    { t("SELECT_GOVERNMENT_ID_TYPE") }
                    </option>
                    <option value="GOVID_NAT">{ t("NATIONAL_ID") }</option>
                    <option value="GOVID_ELC">{ t("ELECTORAL_CARD") }</option>
                    <option value="GOVID_REC">{ t("RESIDENT_CARD") }</option>
                  </select>
                  <p className="input-error-msg">
                    {" "}
                    {errors.receiverGovernmentIdType?.message}{" "}
                  </p>{" "}
                </>
              )}
            </div>
            <div className="col-6">
              <label htmlFor="receiverPhotoIdNumber" className="col-form-label">
                {labelName("receiverPhotoIdNumber")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="receiverPhotoIdNumber"
                defaultValue={RECEIVER_COMPLIANCE?.receiverPhotoIdNumber}
                {...register("receiverPhotoIdNumber")}
              />
              <p className="input-error-msg">
                {" "}
                {errors.receiverPhotoIdNumber?.message}{" "}
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".75rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label
                htmlFor="receiverPhotoIdCountry"
                className="col-form-label"
              >
                {labelName("receiverPhotoIdCountry")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="receiverPhotoIdCountry"
                name="receiverPhotoIdCountry"
                className="form-select form-control"
                defaultValue={
                  RECEIVER_COMPLIANCE?.receiverPhotoIdCountry !== undefined
                    ? RECEIVER_COMPLIANCE?.receiverPhotoIdCountry
                    : globalAuthUser.location.agent_country.isoname
                }
                {...register("receiverPhotoIdCountry")}
                onChange={onCountryChange}
              >
                {CODETABLE.country_info.map((country, index) => (
                  <option key={index} value={country.country_code}>
                    {country.country_name}
                  </option>
                ))}
              </select>
              <p className="input-error-msg">
                {" "}
                {errors.receiverPhotoIdCountry?.message}{" "}
              </p>
              {(countryCode === "USA" ||
                RECEIVER_COMPLIANCE?.receiverPhotoIdCountry === "USA" ||
                countryCode === "CAN" ||
                RECEIVER_COMPLIANCE?.receiverPhotoIdCountry === "CAN" ||
                countryCode === "MEX" ||
                RECEIVER_COMPLIANCE?.receiverPhotoIdCountry === "MEX") && (
                <>
                <select id="receiverPhotoIdState" name="receiverPhotoIdState" className="form-select form-control"
                defaultValue={RECEIVER_COMPLIANCE?.receiverPhotoIdState}
                {...register("receiverPhotoIdState")}
              >
                <option value=""> { t("SELECT_STATE") }</option>
                {states.map((state, index) => (
                  <option key={index} value={state.state_province_code}>
                    {state.state_province_name}
                  </option>
                ))}
              </select>
                  <p className="input-error-msg">
                    {" "}
                    {errors.receiverPhotoIdState?.message}{" "}
                  </p>{" "}
                </>
              )}
            </div>
            <div className="col-6">
              <label
                htmlFor="receivePurposeOfTransaction"
                className="col-form-label"
              >
                {labelName("receivePurposeOfTransaction")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="receivePurposeOfTransaction"
                name="receivePurposeOfTransaction"
                defaultValue={RECEIVER_COMPLIANCE?.receivePurposeOfTransaction}
                className="form-select form-control"
                {...register("receivePurposeOfTransaction")}
              >
                <option value="" defaultValue></option>
                {GFFP_RESP?.productFieldInfo
                  ?.find((e) => e.xmlTag == "receivePurposeOfTransaction")
                  .enumeratedValues.enumeratedValueInfo.map(
                    (purpose, index) => (
                      <option key={index} value={purpose.value}>
                        {purpose.label}
                      </option>
                    )
                  )}
              </select>
              <p className="input-error-msg">
                {" "}
                {errors.receivePurposeOfTransaction?.message}{" "}
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: ".75rem .5rem",
              borderTop: ".5px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="col-6">
              <label htmlFor="relationshipToSender" className="col-form-label">
                {labelName("relationshipToSender")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="relationshipToSender"
                name="relationshipToSender"
                defaultValue={RECEIVER_COMPLIANCE?.relationshipToSender}
                className="form-select form-control"
                {...register("relationshipToSender")}
              >
                <option value="" defaultValue></option>
                {GFFP_RESP?.productFieldInfo
                  ?.find((e) => e.xmlTag == "relationshipToSender")
                  .enumeratedValues.enumeratedValueInfo.map(
                    (purpose, index) => (
                      <option key={index} value={purpose.value}>
                        {purpose.label}
                      </option>
                    )
                  )}
              </select>
              <p className="input-error-msg">
                {" "}
                {errors.relationshipToSender?.message}{" "}
              </p>
            </div>
            <div className="col-6">
              <label
                htmlFor="receiverIntendedUseOfMGIServices"
                className="col-form-label"
              >
                {labelName("receiverIntendedUseOfMGIServices")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="receiverIntendedUseOfMGIServices"
                name="receiverIntendedUseOfMGIServices"
                defaultValue={
                  RECEIVER_COMPLIANCE?.receiverIntendedUseOfMGIServices
                }
                className="form-select form-control"
                {...register("receiverIntendedUseOfMGIServices")}
              >
                <option value="" defaultValue></option>
                {GFFP_RESP?.productFieldInfo
                  ?.find((e) => e.xmlTag == "receiverIntendedUseOfMGIServices")
                  .enumeratedValues.enumeratedValueInfo.map(
                    (purpose, index) => (
                      <option key={index} value={purpose.value}>
                        {purpose.label}
                      </option>
                    )
                  )}
              </select>
              <p className="input-error-msg">
                {" "}
                {errors.receiverIntendedUseOfMGIServices?.message}{" "}
              </p>
            </div>
          </div>
          {/* <div className='row' style={{ padding: ".75rem .5rem", borderTop: ".5px solid rgba(0,0,0,.125)" }}>
                        <div className="col-6">
                                <label htmlFor="relationshipToSender" className="col-form-label">Relationship to Sender <span style={{ color: 'red' }}>*</span></label>
                                <select id="relationshipToSender" name="relationshipToSender" className="form-select form-control" defaultValue={RECEIVER_COMPLIANCE?.relationshipToSender} {...register('relationshipToSender')}>
                                    <option value="" defaultValue={RECEIVER_COMPLIANCE?.relationshipToSender}></option>
                                    { GFFP_RESP?.productFieldInfo?.find((e) => e.xmlTag == 'relationshipToSender').enumeratedValues.enumeratedValueInfo.map((relation, index) => (
                                        <option key={index} value={relation.value}>{relation.label}</option>
                                    )) } 
                                    </select>
                                <p className='input-error-msg'> {errors.relationshipToSender?.message} </p>
                        </div>
                    </div> */}
        </div>
        <div
          className=""
          style={{
            padding: ".75rem .5rem",
            borderTop: ".5px solid rgba(0,0,0,.125)",
            textAlign: "right",
          }}
        >
          <button
            type="button"
            className="btn btn-secondary"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            onClick={onPrevious}
          >
            <FaArrowCircleLeft size={18} fill="white" /> {t("PREVIOUS")}
          </button>{" "}
          &nbsp; &nbsp;
          <button
            type="submit"
            className="btn btn-primary"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <FaArrowCircleRight size={18} fill="white" /> {t("NEXT")}
          </button>
        </div>
      </form>
    </>
  );
}

export default RcvStep2;
