import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  login,
  setGlobalAuthUser,
  setShowLoading,
  setExpiresIn
} from "../../slices/auth-slice";
import bnblogo from "../../assets/images/bnb.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from "../../slices/toast-slice";
import { useTranslation } from "react-i18next";
import { getCookieValue } from "../utilities/common";

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const signIn = useSignIn();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleLogIn = () => {
    console.log(username + " " + password);
    dispatch(login());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
      console.log("env", process.env.REACT_APP_ENVIRONMENT)
      console.log(data);
      data.AppSource = 'portal';
      data.portal = getCookieValue('portal')
      console.log(errors);
      axios.defaults.withCredentials = false;
      dispatch(showLoading())
      console.log("env", process.env.REACT_APP_BNB_BASE_URL);
      axios({method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/login', 
        withCredentials: false,
        headers: { 
          "Accept": "application/json",
          "Content-Type": "application/json" }, data: data })
        .then(loginResponse => {
          dispatch(hideLoading())
          console.log(loginResponse);
          const response = loginResponse;
          if(response.data.status == 0) {
            console.log(response.data.message);
            if(response.data.message === 'WORKING_TIME_EXPIRED') {
              dispatch(showToast(["danger", "NOTICE", t('WORKING_TIME_EXPIRED')]));
            } else {
              dispatch(showToast(["danger", "NOTICE", response.data.message]));
            }
            if (response.data.message === 'PASSWORD_EXPIRED') {
              navigate("/new-password", {
                replace: true,
                state: data,
              });
            }
            return;
          }

          /** Security Code */
          if (response.data.status == 2) {
            var id = response.data.data?.id
            navigate("/security-code/" + id, {
              replace: true,
              state: data,
            });
            return;
          }

          console.log(response.data.data.access_token)
          var $mToken = "Bearer " + loginResponse.data.data.access_token;

          signIn({
            token: loginResponse.data.data.access_token,
            expiresIn: loginResponse.data.data.expires_in,
            tokenType: "Bearer",
            authState: { token: loginResponse.data.data.access_token, tokenType: "Bearer" }
          });
          dispatch(setExpiresIn(loginResponse.data.data.expires_in))

          // 'Authorization': 'Bearer 7G42uOYOJcgyCKr21JBNPeFWrCs6hJPNdUxRipURI4BaEmz9MljlSuq6F9hVJQmAtwl91ONN5GPeVNcy'
          dispatch(showLoading())
          axios({method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData', 
              withCredentials: false,
              headers: { 
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': $mToken
              }
            }).then(res => {
              console.log(res);
              dispatch(hideLoading())
              if(res.data.status == 0) {
                console.log(res.data.message);
                dispatch(showToast(["danger", "NOTICE", 'User Profile failed']));
                return;
              }
              dispatch(login());
              dispatch(setGlobalAuthUser(res.data.data));
              navigate("/home");
              
              
          })
      })

  };

  return (
    <>
      <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
        <div className="wrapper">
          <div className="logo">
            <img src={bnblogo} alt="" />
            {/* <img src="https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-bird-symbols-png-logo-0.png" alt /> */}
          </div>
          <div className="text-center mt-4 name">BnB Portal</div>
          <form onSubmit={handleSubmit(submitForm)} className="p-3 mt-3">
            <div className="form-field d-flex align-items-center">
              <span className="far fa-user" />
              <input
                className="form-control"
                type="text"
                name="username"
                id="username"
                placeholder="Username"
                {...register("username")}
              />
            </div>
            <p className="input-error-msg"> {errors.username?.message} </p>
            {/* <br /> */}
            <div
              className="form-field d-flex align-items-center"
              style={{ marginTop: "15px" }}
            >
              <span className="fas fa-key" />
              <input
                className="form-control"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                {...register("password")}
              />
            </div>
            <p className="input-error-msg"> {errors.password?.message} </p>
            <button type="submit" className="btn mt-3">
              Login
            </button>
          </form>
          <div className="text-center fs-6">
            <Link to={"/forgot-password"}> {t('FORGOT_PASSWORD')}? </Link>
          </div>
          <div className="text-center fs-6">
            <Link to={"/agent-registration"}> {t('BECOME_AN_AGENT')} </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
