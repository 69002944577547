import React, { useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import imageCompression from 'browser-image-compression';
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import axios from 'axios';
import { showToast } from '../../../slices/toast-slice';

export default function TellerAccessRequestEdit() {
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { requestId } = useParams();
    const idTypes = useSelector(state => state.locationUsers.idTypes);
    const acceptedFiles = useSelector(state => state.locationUsers.acceptedFiles);

    const [updateParams, setUpdateParams] = useState({
        'request_id': requestId,
        'first_name': '',
        'last_name': '',
        'other_names': '',
        'phone_number': '',
        'email_address': '',
        'main_teller_id': globalAuthUser?.user_id,
        'id_type': '',
        'id_number': '',
        'id_front': '',
        'id_back': ''
    });

    const [frontIDHtml, setFrontIDHtml] = useState("");
    const [backIDHtml, setBackIDHtml] = useState("");

    useEffect(() => {
        // start the loading activity
        dispatch(showLoading());

        const encoded_ref = btoa(requestId)
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/fetchRequest/${encoded_ref}`;

        // cal the api
        axios({
            method: "get",
            url: url,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // update the data on the form
                console.log(res.data);
                updateFormData(res.data.data);
            }
            else{
                const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
                dispatch(showToast(["danger", "Request Error", errorMsg]))
            }

        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
        })
    }, [])

    function updateFormData(tellerReqData)
    {
        // set the form data
        let tmp_data = {
            'requestId': requestId,
            'first_name': tellerReqData?.first_name,
            'last_name': tellerReqData?.last_name,
            'other_names': tellerReqData?.other_names,
            'phone_number': tellerReqData?.phone_no,
            'email_address': tellerReqData?.email_address,
            'main_teller_id': globalAuthUser?.user_id,
            'id_type': tellerReqData?.id_type,
            'id_number': tellerReqData?.id_number,
            'id_front': extractIdFileName(tellerReqData?.id_front_url),
            'id_back': extractIdFileName(tellerReqData?.id_back_url),
        }

        console.log(tmp_data);
        setUpdateParams(tmp_data)
    }

    function extractIdFileName(idFileUrl)
    {
        if(!idFileUrl || idFileUrl == "") return "";

        let file_link_arr = idFileUrl.split('/');

        return file_link_arr[file_link_arr.length - 1];
    }

    const handleInput = (e) => {
        e.persist();
        setUpdateParams((prevParams) => {
            let updatedParams = {...prevParams, [e.target.name]: e.target.value};
            return updatedParams;
        });
    }

    const handleFileInput = async (e) => {
        let file = e.target.files[0];
        let { name } = e.target;
        file = file ? file : "";

        // compress the file
        file = await compressFile(file);

        // update the file field
        setUpdateParams(tmpData => {
            // update the file part of the parameters
            const updatedData = {...tmpData, [name] : file};
            return updatedData;
        });
    }

    const compressFile = async(file) => {
        let compressed_file = file;
        const max_file_mb = 2;

        try {
            // extract the file type
            const file_type = file?.type;
            
            const img_fileTypes = ["image/jpeg", "image/png", "image/jpg"];
            if(img_fileTypes.includes(file_type)) {
                // commence image compression
                // get the size of the image in mb
                const file_size = file.size / (1000*1000);

                if(file_size < max_file_mb) return file; // dont compress the file

                // set the options
                const optionObj = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                // call the image compression algorithm
                const compressed_blob = await imageCompression(file, optionObj);
                console.log(compressed_file);

                compressed_file = new File([compressed_blob], file.name, { type: 'application/gzip' });

                // return the compressed file
                return compressed_file;
            }

            return compressed_file

        } catch (error) {
            console.log(error);
            return compressed_file;
        }
    }

    const processRequestUpdate = (e) => {
        e.preventDefault();

        dispatch(showLoading())

        // set the url
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/updateRequest`;

        // prepare the payload
        let formdata = new FormData();

        for(const key in updateParams){
            if(updateParams.hasOwnProperty(key)) {
                if(updateParams[key] instanceof File) {
                    formdata.append(key, updateParams[key]);
                    continue;
                }

                formdata.append(key, updateParams[key]);
            }
        }

        // send the request to the backend
        axios({
            method: "post",
            url: url,
            data: formdata,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);
            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // show the success page
                const successMsg = msg ? msg : "UPDATE_SUCCESSFUL";
                dispatch(showToast(["success", "Access Request", successMsg]));
                navigate('/tellerAccess');
                return
            }
            
            const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
            dispatch(showToast(["danger", "Request Error", errorMsg]))
            
        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
        })
    }

    const showIDFile = (forIdFront = 0) => {
        dispatch(showLoading());

        // set the url
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/loactionAccess/fetchIDDoc`;

        // update the payload
        let payload = {
            requestRef: updateParams?.requestId,
            isIDFront: forIdFront
        }

        // send the request to the backend
        axios({
            method: "post",
            url: url,
            data: payload,
            headers: {
                "Accept": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            console.log(res);

            // get the response message
            let msg = res.data.message;

            if(res.data.status && res.data.status === 1) {
                // show a success message
                const successMsg = msg ? msg : "FILE_FETCHED";
                dispatch(showToast(["success", "Access Request", successMsg]));

                // update the image data
                const fileData = res.data.data;

                // display the image file
                displayImageFile(fileData, forIdFront)

                return
            }
            
            const errorMsg = msg ? msg : "SOMETHING_WENT_WRONG"
            dispatch(showToast(["danger", "Request Error", errorMsg]))
            
        }).catch(error => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(showToast(["danger", "Server Error", "SOMETHING_WENT_WRONG"]))
        })
    }

    function displayImageFile(fileData, isFrontId = 0)
    {
        // prepare the image element
        let src = `data:image/jpeg;base64, ${fileData?.file_content}`;
        let idFileStr = <img src={src} alt={fileData?.file_name} width={'100%'}/>;

        // display the file
        isFrontId ? setFrontIDHtml(idFileStr) : setBackIDHtml(idFileStr);
    }

  return (
    
    <>
        <div style={{ padding: "20px" }}>
            <div className="card">
                 {/* the card header */}
                <div className="card-header d-flex justify-content-between">
                    {/* the label */}
                    <div className="card-label">
                        <h5>{t('UPDATE_TELLER_ACCESS_REQ')}</h5>
                    </div>

                    {/* new teller access request  */}
                    <div className='card-toolbar'>
                        <button type='button' className='btn btn-primary btn-sm' onClick={() => navigate('/tellerAccess')}>
                            <FaArrowLeft size={15} fill={'white'} /> &nbsp;
                            {t('BACK')}
                        </button>
                    </div>

                </div>

                {/* the card body */}
                <div className="card-body">

                    <div className="row">
                        <div className="col-12 col-lg-1"></div>

                        <div className="col-12 col-lg-9 p-5 m-4 bg-light shadow-sm">

                            {/* location teller access request form  */}
                            <form id='access_req_form' onSubmit={processRequestUpdate}>

                                {/* teller names  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="first_name">{t('FIRSTNAME')} <span className="text-danger">*</span></label>
                                        <input type="text" name="first_name" id="first_name" className="form-control"
                                        value={updateParams.first_name} onChange={handleInput}/>
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="other_names">{t('OTHERNAMES')}</label>
                                        <input type="text" name="other_names" id="other_names" className="form-control" 
                                        value={updateParams.other_names} onChange={handleInput}/>
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="last_name">{t('LASTNAME')} <span className="text-danger">*</span></label>
                                        <input type="text" name="last_name" id="last_name" className="form-control" 
                                        value={updateParams.last_name} onChange={handleInput}/>
                                    </div>
                                </div>

                                {/* teller contacts  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="phone_number">{t('PHONE_NUMBER')} <span className="text-danger">*</span></label>
                                        <input type="tel" name="phone_number" id="phone_number" className="form-control" 
                                        value={updateParams.phone_number} onChange={handleInput}/>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="email_address">{t('EMAIL_ADDRESS')} <span className="text-danger">*</span></label>
                                        <input type="email" name="email_address" id="email_address" className="form-control" 
                                        value={updateParams.email_address} onChange={handleInput}/>
                                    </div>
                                </div>
                                
                                {/* teller id details  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_type">{t('ID_TYPE')} <span className="text-danger">*</span></label>
                                        <select name="id_type" id="id_type" className="form-control"
                                        value={updateParams.id_type} onChange={handleInput}>
                                            <option value="">{t('SELECT')} ...</option>
                                            { 
                                                idTypes.map((val, indx) => (
                                                    <option key={indx} value={val.type_id}>{ val.type_description }</option>
                                                ))
                                            }
                                            
                                        </select>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_number">{t('ID_NUMBER')} <span className="text-danger">*</span></label>
                                        <input type="text" name="id_number" id="id_number" className="form-control" 
                                        value={updateParams.id_number} onChange={handleInput}/>
                                    </div>

                                </div>

                                {/* id files  */}
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_front">{t('PHOTO_ID_FRONT')} <span className="text-danger">*</span></label>
                                        <input type="file" name="id_front" id="id_front" className="form-control" 
                                        accept={ acceptedFiles }
                                         onChange={handleFileInput}/>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="id_back">{t('PHOTO_ID_BACK')}</label>
                                        <input type="file" name="id_back" id="id_back" className="form-control"
                                        accept={ acceptedFiles }
                                         onChange={handleFileInput}/>
                                    </div>
                                </div>

                                {/* the form controls  */}
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <button type='submit' className="btn btn-primary me-2">
                                            {t('SUBMIT')}
                                        </button>

                                        <button type='button' className="btn btn-secondary"
                                        onClick={() => navigate('/tellerAccess')}>
                                            {t('CANCEL')}
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                    {/* image file action btns */}
                    <div className="row border-top mb-1">
                        <div className="col-12 col-lg-6 mb-2 p-1">
                            {
                                updateParams.id_front &&
                                <button type='button' className="btn btn-outline-primary btn-sm mx-1" 
                                onClick={() => showIDFile(1)}>
                                    {t('PREVIEW_ID_FRONT_FILE')}
                                </button>
                            }
                            {
                                updateParams.id_back &&
                                <button type='button' className="btn btn-outline-primary btn-sm"
                                onClick={() => showIDFile()}>
                                    {t('PREVIEW_ID_BACK_FILE')}
                                </button>
                            }
                        </div>
                    </div>
                    
                    {/* the image file display  */}
                    <div className="row mb-2">
                        <div className="col-12 col-lg-6 mb-2 p-1 shadow-sm">
                            {
                                frontIDHtml && frontIDHtml
                            }
                        </div>
                        <div className="col-12 col-lg-6 mb-2 p-1 shadow-sm">
                            {
                                backIDHtml && backIDHtml
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>
  )
}
