import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setAgentCountries, setPersonalInfo } from '../../../slices/agent-self-registration-slice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';

export default function PersonalRegForm({processNext}) {
    const dispatch = useDispatch();
    const [countries, setCountries] = useState([]);
    const info = useSelector(state => state.agentSelfRegistration.personalInfo);
    const idTypes = useSelector(state => state.agentSelfRegistration.idTypes);
    const [personalDetails, setPersonalDetails] = useState(info);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [allowNext, setAllowNext] = useState(false);
    const requiredKeys = ['firstName', 'lastName', 'country', 'city', 'address', 'phoneNumber', 'emailAddress', 'idType', 'idNumber', 'idFront'];
    const acceptedFiles = useSelector(state => state.agentSelfRegistration.acceptedFiles);
    const agntCntryList = ['GIN', 'LBR', 'SEN', 'SLE', 'GMB', 'CIV', 'MLI'];

    useEffect(() => {
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/util/agentCountries`;
        axios({
            method: "get",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        }).then(res => {
            if(res.data.status && res.data.status === 1) {
                let countries = res.data.data;

                // filter out the required countries
                countries = countries.filter(val => agntCntryList.includes(val.isoname));

                setCountries(countries);
                dispatch(setAgentCountries(countries));
            }
        })
    }, []);

    const showLogin = () => {
        navigate("/")
    }

    const handleInput = (e) => {
        // get the input field
        let { name, value } = e.target;
        value = value ? value : "";

        setPersonalDetails(tmpdata => {
            // updated the personal info data
            const updatedData = {...tmpdata, [name] : value};

            // check whether the next action is allowed
            checkNext(updatedData);

            // return the updated data
            return updatedData
        });

        // update the global personal details
        dispatch(setPersonalInfo(personalDetails))
    }

    const checkNext = (checkData) => {
        setAllowNext(false);
        for (let i = 0; i < requiredKeys.length; i++) {
            const key = requiredKeys[i];
            if(checkData[key] == "") return;
        }

        setAllowNext(true);
    }

    const handleFileInput = async (e) => {
        let file = e.target.files[0];
        let { name } = e.target;
        file = file ? file : "";

        // compress the file
        file = await compressFile(file);

        // update the file field
        setPersonalDetails(tmpData => {
            // update the file part of the personal details
            const updatedData = {...tmpData, [name] : file};

            // check whether the next action is allowed
            checkNext(updatedData);

            // return the updated dtaa
            return updatedData;
        });

        // update the personal details
        dispatch(setPersonalInfo(personalDetails));
    }

    const compressFile = async(file) => {
        let compressed_file = file;
        const max_file_mb = 2;

        try {
            // extract the file type
            const file_type = file?.type;
            
            const img_fileTypes = ["image/jpeg", "image/png", "image/jpg"];
            if(img_fileTypes.includes(file_type)) {
                // commence image compression
                // get the size of the image in mb
                const file_size = file.size / (1000*1000);

                if(file_size < max_file_mb) return file; // dont compress the file

                // set the options
                const optionObj = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                // call the image compression algorithm
                const compressed_blob = await imageCompression(file, optionObj);
                console.log(compressed_file);

                compressed_file = new File([compressed_blob], file.name, { type: 'application/gzip' });

                // return the compressed file
                return compressed_file;
            }

            return compressed_file

        } catch (error) {
            console.log(error);
            return compressed_file;
        }
    }

    const syncPersonalInfo = (e) => {
        e.preventDefault();
        // update the personal details
        dispatch(setPersonalInfo(personalDetails));
        processNext();
    }

  return (
    <>
        <div className="mb-3">
            <small className="text-muted text-bold">
                { t('SELF_REG_FORM_NOTE')}
            </small>
        </div>
        {/* personal information form */}
        <div className="p-lg-3">
            <form id="personalInforForm" encType="multipart/form-data" onSubmit={syncPersonalInfo}>
                {/* agent full name */}
                <div className="row mb-3">
                    <div className="col-12 col-lg-6">
                        <label htmlFor="firstName">{ t("firstName") }<span className="text-danger">*</span></label>
                        <input type="text" className='form-control' name="firstName" id="firstName"
                         value={personalDetails.firstName}
                         onChange={handleInput} />
                    </div>
                    <div className="col-12 col-lg-6">
                        <label htmlFor="lastName">{ t("lastName") }<span className="text-danger">*</span></label>
                        <input type="text" className='form-control' name="lastName" id="lastName" 
                        value={personalDetails.lastName} 
                        onChange={handleInput}/>
                    </div>
                </div>

                {/* country details */}
                <div className="row mb-3">
                    <div className="col-12">
                    <label htmlFor="country">{ t("COUNTRY") } <span className="text-danger">*</span></label>
                    <select name="country" id="country" className="form-control" value={personalDetails.country} onChange={handleInput}>
                        <option value="">{ t("SELECT_COUNTRY") }</option>
                        {
                            countries.map((val) => (
                                <option key={val.id} value={val.id}>{val.name}</option>
                            ))
                        }
                    </select>
                    </div>
                </div>

                {/* address details */}
                <div className="row mb-3">
                    <div className="col-12 col-lg-6">
                        <label htmlFor="city">{ t("CITY") }<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="city" name="city" 
                        value={personalDetails.city}
                        onChange={handleInput} />
                    </div>
                    <div className="col-12 col-lg-6">
                        <label htmlFor="address">{ t("ADDRESS") }<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="address" name="address"
                         value={personalDetails.address}
                         onChange={handleInput} />
                    </div>
                </div>

                {/* contact details */}
                <div className="row mb-4">
                    <div className="col-12 col-lg-6">
                        <label htmlFor="phoneNumber">{ t("PHONE_NUMBER") }<span className="text-danger">*</span></label>
                        <input type="text" name="phoneNumber" id="phoneNumber" className="form-control"
                         value={personalDetails.phoneNumber}
                         onChange={handleInput}/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <label htmlFor="emailAddress">{ t("EMAIL_ADDRESS") } <span className="text-danger">*</span></label>
                        <input type="email" name="emailAddress" id="emailAddress" className="form-control"
                         value={personalDetails.emailAddress}
                         onChange={handleInput}/>
                    </div>
                </div>

                <div className="mb-3 border rounded-2 bg-light p-3">
                    <div className="mb-3">
                        <small className='text-dark'>{ t("ID_CARD_DETAILS") }</small>
                    </div>

                    {/* id card types and details */}
                    <div className="row mb-3">
                        <div className="col-12 col-lg-6">
                            <label htmlFor="idType">{ t("PHOTO_ID_TYPE") } <span className="text-danger">*</span></label>
                            <select name="idType" id="idType" className="form-control" value={personalDetails.idType} onChange={handleInput}>
                                <option value="">{ t("SELECT_ID_TYPE") }</option>
                                {
                                    idTypes.map((val, indx) => (
                                        <option key={indx} value={indx}>{val}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="col-12 col-lg-6">
                            <label htmlFor="idNumber">{ t("PHOTO_ID_NUMBER")} <span className="text-danger">*</span></label>
                            <input type="text" name="idNumber" id="idNumber" className="form-control" 
                            value={personalDetails.idNumber} 
                            onChange={handleInput}/>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-12 col-lg-6">
                            <label htmlFor="idFront">{ t("PHOTO_ID_FRONT")}<span className="text-danger">*</span></label>
                            <input type="file" name="idFront" id="idFront" className="form-control" accept={ acceptedFiles }
                            onChange={handleFileInput}/>
                        </div>

                        <div className="col-12 col-lg-6">
                            <label htmlFor="idBack">{ t("PHOTO_ID_BACK")} ({ t("OPTIONAL_FIELD") })</label>
                            <input type="file" name="idBack" id="idBack" className="form-control" accept= { acceptedFiles }
                            onChange={handleFileInput}/>
                        </div>
                    </div>
                    
                </div>

                {/* referral code */}
                <div className="row mb-3">
                    <div className="col-12">
                    <label htmlFor="referralCode">{ t("REFERRAL_CODE") }</label>
                    <input type="text" name="referralCode" id="referralCode" className="form-control"
                    placeholder={ t("OPTIONAL_FIELD") } onChange={handleInput} />
                    </div>
                </div>

                <div className="row mb-3 border-top">
                    <div className="col-12 d-flex justify-content-between mt-2">
                        <button type="button" className="btn btn-dark" id="basicInfoSubmit" onClick={showLogin}>{ t("BACK") }</button>
                        <button type="submit" className="btn btn-primary" id="basicInfoSubmit"
                         disabled = {!allowNext ? "disabled" : "" }>
                            { t("NEXT") }
                        </button>
                    </div>
                </div>
            </form>
        </div>
        
    </>
  )
}
