import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from "react-i18next";
import StepComponent from '../utilities/StepComponent';
import LocalQuotation from './LocalQuotation';
import SenderDetails from './SenderDetails';
import ReceiverDetails from './ReceiverDetails';
import LocalTxnSummary from './LocalTxnSummary';
import LocalComplete from './LocalComplete';

export default function LocalHome() {
    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const steps = [t("QUOTATION"), t("SENDER"), t("RECEIVER"), t("SUMMARY")];
    const currentStep = useSelector(state => state.localSending.currStep);

    return (
        <div style={{ padding: "20px" }}>
            <div className="card">
                <div className="card-header">
                    { t("LOCAL_SENDING") }
                </div>

                <div className="card-body">
                    {/** the steps as the use progresses */}
                    {
                        currentStep !== 4 &&
                        <StepComponent steps={steps} currentStep={currentStep} />
                    }

                    {/** step components */}
                    {currentStep === 0 && <LocalQuotation />}
                    {currentStep === 1 && <SenderDetails />}
                    {currentStep === 2 && <ReceiverDetails />}
                    {currentStep === 3 && <LocalTxnSummary />}
                    {currentStep === 4 && <LocalComplete />}

                </div>
            </div>
        </div>
    )
}
