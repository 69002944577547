import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    setConsumerState, setSendData, setStep, addGovIdType, removeGovIdType, addSenderIdState, removeSenderIdState,
    addSenderState, removeSenderState, addSenderZipCode, removeSenderZipCode, setOnPrevious, setResetSend,
    setReceivers, setResetReceive, setConsumers
} from '../../../slices/send-money-moneygram-slice';
import { getCountryName, getFieldsByCategory, getEnumLabel, getStates, getSenderName } from './moneygram';
import { FaArrowCircleLeft, FaArrowCircleRight, FaSearch } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function MoneygramSender() {

    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);
    const fields = useSelector((state) => state.sendMoneyMoneygram.fields);
    const authUser = useAuthUser()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const codeTable = useSelector((state) => state.sendMoneyMoneygram.codeTable);
    const resetSend = useSelector((state) => state.sendMoneyMoneygram.resetSend);
    const consumers = useSelector((state) => state.sendMoneyMoneygram.consumers);
    const [consumerLookupHasError, setConsumerLookupHasError] = useState(false);
    const [consumerLookupMessage, setConsumerLookupMessage] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [consumer, setConsumer] = useState(null);
    const [searchLoader, setSearchLoader] = useState(false);
    const [categories, setCategories] = useState(['Sender Information', 'Compliance Info']);
    const [consumerSelected, setConsumerSelected] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [countries, setCountries] = useState([]);
    const senderScheme = useSelector((state) => state.sendMoneyMoneygram.senderSchema);
    const [schema, setSchema] = useState({});
    const formRef = useRef();
    const [showUpdateSender, setShowUpdateSender] = useState(false);
    const [otherNameFields, setOtherNameFields] = useState(['senderMiddleName', 'senderLastName2']);
    const [senderFields, setSenderFields] = useState([]);
    const [complianceFields, setComplianceFields] = useState([]);
    const [idStates, setIdStates] = useState([]);
    const [states, setStates] = useState([]);
    const countriesStates = ['USA', 'CAN', 'MEX'];

    useEffect(() => {
        console.log('MoneygramSender')

        var countries = codeTable?.country_info;
        setCountries(countries ?? []);

        setSchema(yup.object().shape(senderScheme))

        updateSenderFields()
        updateComplianceFields()
    }, [fields, senderScheme, codeTable, resetSend]);

    const consumerLookup = () => {
        setConsumerLookupHasError(false)
        dispatch(setConsumerState(null));
        setSearchLoader(true)
        dispatch(showLoading());
        dispatch(setConsumers([]))
        dispatch(setResetSend(false));
        dispatch(setResetReceive(true));
        setShowForm(false)
        setShowUpdateSender(false)

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/consumerLookup', {
            phone_number: customerPhone,
            language: i18next.language
        }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            setSearchLoader(false)
            dispatch(hideLoading());

            var status = res.data.status
            var message = res.data.message
            if (status == 0) {
                setConsumerLookupHasError(true)
                setConsumerLookupMessage(message)
            } else {
                var items = [];
                var senderInfo = res.data.data.senderInfo ?? []
                if (Array.isArray(senderInfo)) {
                    items = senderInfo
                } else {
                    items = [senderInfo]
                }
                dispatch(setConsumers(items))
            }
        });
    }

    const onConsumerSelect = (e, consumer) => {
        console.log(consumer.senderFirstName);

        const allWithClass = Array.from(
            e.currentTarget.parentElement.getElementsByClassName('card')
        );
        allWithClass.forEach(element => {
            element.classList.remove('sCompany')
        });
        e.currentTarget.classList.add('sCompany')

        var newFields = {}
        fields?.forEach(field => {
            var value = consumer[field.xmlTag] || '';
            if (field.xmlTag === 'senderHomePhoneCountryCode' && value !== '') {
                var country = codeTable?.country_info?.filter(c => c.phone_code === value)
                value = country[0]?.country_code || ''
            }
            newFields[field.xmlTag] = value
        });
        reset(newFields)

        dispatch(setResetSend(false));
        dispatch(setResetReceive(true));
        setShowForm(true)
        setShowUpdateSender(true)
        setConsumerSelected(true)
        dispatch(removeGovIdType())
        dispatch(removeSenderIdState())
        dispatch(removeSenderState())
        //dispatch(removeSenderZipCode())
        dispatch(setConsumerState(consumer));
        var receivers = consumer?.receiverInfo ?? []
        if (!Array.isArray(receivers)) {
            receivers = [receivers]
        }
        dispatch(setReceivers(receivers))
        setConsumer(consumer)
    }

    const getInitials = (consumer) => {
        var name = consumer.senderFirstName.charAt(0);
        name += consumer.senderLastName.charAt(0);

        return name
    }

    const { register, reset, setValue, resetField, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"

        //mode: 'all',
        //shouldUnregister: false,
        //reValidateMode: "onChange"
    });

    const back = () => {
        console.log('back');
        dispatch(setOnPrevious(true))
        dispatch(setStep(1))
    }

    const next = () => {
        console.log('next')
        console.log('errors', errors)
        if (Object.keys(errors).length === 0 && errors.constructor === Object) {
            dispatch(setStep(3));

            const formData = new FormData(formRef.current);
            const data = Object.fromEntries(formData);
            console.log('formData', data);
            dispatch(setSendData(data));
        }
    }

    const newSender = () => {
        const allWithClass = Array.from(
            document.getElementsByClassName('consumer')
        );
        allWithClass.forEach(element => {
            element.classList.remove('sCompany')
        });
        var newFields = {}
        fields?.forEach(field => {
            newFields[field.xmlTag] = ''
        });
        reset(newFields)

        setConsumer(null)
        setConsumerSelected(false)
        dispatch(removeGovIdType())
        dispatch(removeSenderIdState())
        dispatch(removeSenderState())
        //dispatch(removeSenderZipCode())
        dispatch(setConsumerState(null));
        dispatch(setResetSend(false));
        dispatch(setResetReceive(true));
        setShowForm(true)
        setShowUpdateSender(false)
    }

    const updateConsumer = () => {
        setConsumerSelected(false)
    }

    const getHiddenField = (field) => {
        var params = {
            ...register(field.xmlTag)
        }
        /*if (field.xmlTag === 'senderHomePhoneCountryCode'){
            setValue(field.xmlTag, value)
        }*/

        return <input type="hidden" name={field.xmlTag} id={field.xmlTag} {...params} />
    }

    const getField = (field) => {
        var result = '';
        var isRequired = (field.visibility === 'REQ');
        var label = <label>{field.fieldLabel}
            {
                isRequired && <span style={{ color: "red" }}> *</span>
            }
        </label>
        var error = <p className='input-error-msg'> {errors[field.xmlTag]?.message} </p>
        var params = {
            ...register(field.xmlTag, {
                onChange: (e) => onChangeField(field, e.target.value)
            })
        }

        if (field.enumerated == true || field.dataType === 'cntrycode') {
            result = <div className="form-group">
                {label}
                {(() => {
                    if (field.enumerated == true) {
                        var options = [];
                        if (field.xmlTag === 'senderPhotoIdType') {
                            options = codeTable?.id_types
                        } else {
                            options = field.enumeratedValues.enumeratedValueInfo
                        }
                        return <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                options.map((enumVal, key) => (
                                    <option value={enumVal.value}>{enumVal.label}</option>
                                ))
                            }
                        </select>;
                    } else if (field.dataType === 'cntrycode') {
                        return <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                countries.map((country, key) => (
                                    <option value={country.country_code}>{country.country_name}</option>
                                ))
                            }
                        </select>;
                    }
                })()}
                {error}
            </div>
        } else {
            if (field.dataType === 'string') {
                if (field.xmlTag.includes('PhoneCountryCode')) {
                    result = <div className="form-group">
                        {label}
                        <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                codeTable?.country_info.map((country, key) => (
                                    <option value={country.country_code}>{country.country_name} {(country.phone_code) ? '(+' + country.phone_code + ')' : ''}</option>
                                ))
                            }
                        </select>
                        {error}
                    </div>

                } else if (field.xmlTag === 'senderPhotoIdState' || field.xmlTag === 'senderState') {
                    var options = [];
                    if (field.xmlTag === 'senderPhotoIdState') {
                        options = idStates;
                    } else if (field.xmlTag === 'senderState') {
                        options = states;
                    }
                    result = <div className="form-group">
                        {label}
                        <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                options.map((state, key) => (
                                    <option value={state.state_province_code}>{state.state_province_name}</option>
                                ))
                            }
                        </select>
                        {error}
                    </div>
                } else {
                    result = <div className="form-group">
                        {label}
                        <input type="text" name={field.xmlTag} className={`form-control ${errors[field.xmlTag] ? 'is-invalid' : ''}`} {...params} />
                        {error}
                    </div>
                }
            } else if (field.dataType === 'int' || field.dataType === 'decimal') {
                result = <div className="form-group">
                    {label}
                    <input type="number" name={field.xmlTag} className='form-control' {...params} />
                    {error}
                </div>
            } else if (field.dataType === 'date') {
                result = <div className="form-group">
                    {label}
                    <input type="date" name={field.xmlTag} className='form-control' {...params} />
                    {error}
                </div>
            } else if (field.dataType === 'boolean') {
                result = <div className="form-group">
                    <input type="checkbox" name={field.xmlTag} className="form-check-input" {...params} />&nbsp;
                    {/*<label className="form-check-label" for="include_sending_fees">{field.fieldLabel}</label>*/}
                    {label}
                    {error}
                </div>
            }
        }

        return result;
    }

    const onChangeField = (field, value) => {
        console.log('onChangeField')
        console.log('field.xmlTag', field.xmlTag)
        console.log('value', value)
        setValue(field.xmlTag, value)
        if (field.xmlTag == 'senderPhotoIdType') {
            resetField('senderGovIdType', { keepError: false, keepTouch: false, keepDirty: false })

            if (value == 'GOV') {
                dispatch(addGovIdType())
            } else {
                dispatch(removeGovIdType())
            }
        } else if (field.xmlTag == 'senderPhotoIdCountry') {
            resetField('senderPhotoIdState', { keepError: false, keepTouch: false, keepDirty: false })

            if (countriesStates.includes(value)) {
                dispatch(addSenderIdState())
                setIdStates(getStates(codeTable, value))
            } else {
                dispatch(removeSenderIdState())
                setIdStates([])
            }
            reset({ senderPhotoIdCountry: value })
        } else if (field.xmlTag == 'senderCountry') {
            resetField('senderState', { keepError: false, keepTouch: false, keepDirty: false })
            //resetField('senderZipCode', { keepError: false, keepTouch: false, keepDirty: false })

            if (countriesStates.includes(value)) {
                dispatch(addSenderState())
                //dispatch(addSenderZipCode())
                setStates(getStates(codeTable, value))
            } else {
                dispatch(removeSenderState())
                //dispatch(removeSenderZipCode())
                setStates([])
            }
        }
        console.log('errors', errors)
        console.log('senderScheme', senderScheme)
    }

    const updateSenderFields = () => {
        console.log('updateSenderFields')
        setSenderFields(getFieldsByCategory(fields, categories[0]).sort((f1, f2) => f1.displayOrder - f2.displayOrder))
    }

    const updateComplianceFields = () => {
        console.log('updateComplianceFields')
        setComplianceFields(getFieldsByCategory(fields, categories[1]).sort((f1, f2) => f1.displayOrder - f2.displayOrder))
    }

    return (
        <div>
            <form onSubmit={handleSubmit(next)} ref={formRef}>
                <div className='row' style={{ marginTop: "25px" }}>
                    <div className='col-sm-6'>
                        <div>
                            <label>{t('CUSTOMER_PHONE')}</label>
                            <div className="input-group mb-3">
                                <input type="text" placeholder={t('CUSTOMER_PHONE')} className="form-control" name="customer_phone" id="customer_phone" onChange={(e) => setCustomerPhone(e.target.value)} />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-primary" onClick={consumerLookup} disabled={searchLoader} style={{ height: "100%" }}>
                                        {t('SEARCH')} &nbsp; <FaSearch size={18} fill={'white'} />
                                    </button>
                                </div>
                            </div>
                            {
                                consumerLookupHasError &&
                                <p className='input-error-msg'>{consumerLookupMessage}</p>
                            }
                        </div>
                    </div>
                    <div className='col-sm-4 offset-sm-2' style={{ textAlign: "right" }}>
                        <button type='button' className='btn btn-primary' onClick={newSender}>{t('NEW_SENDER')}</button>
                    </div>
                </div>
                <br />
                {
                    consumers.length != 0 && <div>
                        <hr />
                        <p>{t('SENDERS')}</p>
                        <div className='row'>
                            {
                                consumers.map((consumer, key) => (
                                    <div key={key} id={key} className="card col-6 col-md-2 company consumer" style={{ margin: "25px", cursor: "pointer" }} onClick={(e) => onConsumerSelect(e, consumer)} >
                                        <div className="card-body">
                                            <div className="recipient-legend">
                                                <div className="recipient-legend-avatar">{getInitials(consumer)}</div>
                                            </div>
                                            <div className="recipient-content mt-2" style={{ textAlign: "center" }}>
                                                <div style={{ fontSize: '14px' }}>{getSenderName(consumer)}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                }
                <br />
                <div className='row'>
                    {
                        showForm && !resetSend &&
                        <div>
                            <fieldset className="border p-3 mt-4">
                                <legend className="float-none w-auto">{t('SENDER_INFORMATION')}</legend>
                                <div>
                                    {
                                        showUpdateSender &&
                                        <div style={{ textAlign: "right" }}>
                                            <button type='button' className='btn btn-primary mb-3' onClick={updateConsumer}>{t('UPDATE_SENDER')}</button>
                                        </div>
                                    }

                                    {consumerSelected &&
                                        <div className="row">
                                            <div className='col-sm-9'>
                                                <table className="table table-sm table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "40%" }}></th>
                                                            <th style={{ width: "60%" }}></th>
                                                        </tr>
                                                        {
                                                            senderFields.filter(f => (consumer[f.xmlTag] != null && consumer[f.xmlTag] != '') || otherNameFields.includes(f.xmlTag)).map((field, key) => (
                                                                <tr key={key} style={{ height: "38px" }}>
                                                                    <td>
                                                                        {(() => {
                                                                            if (field.xmlTag === 'senderHomePhone' && consumer['freqCustCardNumber'] != '') {
                                                                                return t('SENDER_PRIMARY_PHONE');
                                                                            }
                                                                            return field.fieldLabel;
                                                                        })()}
                                                                        {getHiddenField(field)}:
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            {(() => {
                                                                                var label = ''
                                                                                if (field.enumerated == true) {
                                                                                    label = getEnumLabel(field, consumer[field.xmlTag])
                                                                                } else if (field.dataType === 'cntrycode') {
                                                                                    label = getCountryName(codeTable, consumer[field.xmlTag])
                                                                                } else {
                                                                                    label = consumer[field.xmlTag]
                                                                                }
                                                                                return label;
                                                                            })()}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                senderFields.filter(f => (consumer[f.xmlTag] == null || consumer[f.xmlTag] == '') && !otherNameFields.includes(f.xmlTag)).map((field, key) => (
                                                    <div key={key} className="form-group col-sm-6" style={{ marginBottom: "25px" }}>
                                                        {getField(field)}
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    }
                                    {!consumerSelected &&
                                        <div className='row'>
                                            {
                                                getFieldsByCategory(fields, categories[0]).sort((f1, f2) => f1.displayOrder - f2.displayOrder).map((field, key) => (
                                                    <div key={key} className="form-group col-sm-6" style={{ marginBottom: "25px" }}>
                                                        {getField(field)}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </fieldset>
                            <fieldset className="border p-3 mt-4">
                                <legend className="float-none w-auto">{t('COMPLIANCE_INFO')}</legend>
                                <div>
                                    <div className='row'>
                                        {
                                            complianceFields.map((field, key) => (
                                                <div key={key} className='form-group col-sm-6' style={{ marginBottom: "25px" }}>
                                                    {getField(field)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    }
                </div>

                <br />
                <div style={{ textAlign: "right" }}>
                    <button type='button' className='btn btn-info' onClick={back}>
                        <FaArrowCircleLeft size={18} color={'white'} /> {t('BACK')}
                    </button> &nbsp; &nbsp;
                    <button type='submit' className='btn btn-primary'>
                        {t('NEXT')} <FaArrowCircleRight size={18} fill={'white'} />
                    </button>
                </div>
            </form>
        </div>
    );

}

export default MoneygramSender