import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import bnblogo from "../../assets/images/bnb.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from "../../slices/toast-slice";
import { useTranslation } from "react-i18next";
import { getMailHashed } from '../utilities/common';
import {
    login,
    setGlobalAuthUser,
    setExpiresIn
} from "../../slices/auth-slice";

function ForgotPassword() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signIn = useSignIn();

    const { t } = useTranslation();
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [fields, setFields] = useState({
        username: yup.string().required(t('FIELD_REQUIRED'))
    });

    const schema = yup.object().shape(fields);

    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setStep1(true);
    }, [])


    const submitForm = (data) => {
        axios.defaults.withCredentials = false;
        dispatch(showLoading())

        if (step1) {
            console.log('submit step1')
            axios({
                method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/resetForgottenPassword',
                withCredentials: false,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }, data: data
            })
                .then(response => {
                    console.log(response);
                    dispatch(hideLoading())

                    var status = response.data.status
                    var message = response.data.message

                    if (status == 0) {
                        dispatch(showToast(["danger", "NOTICE", message]));
                        return;
                    }

                    dispatch(showToast(["success", "SUCCESS", t('MSG_PASSWORD_RESET_SUCCESS', { email: getMailHashed(response.data.data.user.email, 3) })]));
                    setStep2(true);
                    setStep1(false);
                    setStep3(false);
                    setFields({
                        code: yup.string().required(t('FIELD_REQUIRED'))
                    })
                })
        } else if (step2) {
            console.log('submit step2')
            axios({
                method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/passwordReset',
                withCredentials: false,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }, data: data
            })
                .then(response => {
                    console.log(response);
                    dispatch(hideLoading())

                    var status = response.data.status
                    var message = response.data.message

                    if (status == 0) {
                        dispatch(showToast(["danger", "NOTICE", message]));
                        return;
                    }

                    var id = response.data.data?.user?.id
                    dispatch(showToast(["success", "SUCCESS", t('CODE_VERIFIED')]));
                    setStep3(true);
                    setStep1(false);
                    setStep2(false);
                    setFields({
                        userId: yup.number().required(t('FIELD_REQUIRED')),
                        password: yup.string().required(t('FIELD_REQUIRED')).min(8, t("FIELD_MIN", { min: 8 })).test(
                            'isValidPass',
                            'Passowrd must be 8 char (One UpperCase & One Symbol)',
                            (value, context) => {
                                const hasUpperCase = /[A-Z]/.test(value);
                                const hasNumber = /[0-9]/.test(value);
                                const hasLowerCase = /[a-z]/.test(value);
                                const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
                                let validConditions = 0;
                                const numberOfMustBeValidConditions = 3;
                                const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
                                conditions.forEach(condition => (condition ? validConditions++ : null));
                                if (validConditions >= numberOfMustBeValidConditions) {
                                    return true;
                                }
                                return false;
                            },
                        ),
                        password_confirmation: yup.string().required(t('FIELD_REQUIRED')).min(8, t("FIELD_MIN", { min: 8 })).oneOf([yup.ref('password')], 'Passwords does not match'),
                    })
                    reset({ userId: id })
                })
        } else if (step3) {
            console.log('submit step3')
            axios({
                method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/updatePassword',
                withCredentials: false,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }, data: data
            })
                .then(response => {
                    console.log(response);
                    dispatch(hideLoading())

                    var status = response.data.status
                    var message = response.data.message

                    if (status == 0) {
                        dispatch(showToast(["danger", "NOTICE", message]));
                        return;
                    }

                    dispatch(showToast(["success", "SUCCESS", t('PASSWORD_RESET')]));
                    setStep1(true);
                    setStep2(false);
                    setStep3(false);

                    var token = response.data.data?.user?.api_token;

                    signIn({
                        token: token,
                        expiresIn: response.data.data?.expires_in,
                        tokenType: "Bearer",
                        authState: { token: token, tokenType: "Bearer" }
                    });
                    dispatch(setExpiresIn(response.data.data?.expires_in))

                    dispatch(showLoading())
                    axios({
                        method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData',
                        withCredentials: false,
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            'Authorization': "Bearer " + token
                        }
                    }).then(res => {
                        console.log(res);
                        dispatch(hideLoading())
                        if (res.data.status == 0) {
                            dispatch(showToast(["danger", "NOTICE", res.data.message]));
                            return;
                        }
                        dispatch(login());
                        dispatch(setGlobalAuthUser(res.data.data));
                        navigate("/home");
                    })
                })
        }

    };

    return (
        <>
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div className="wrapper">
                    <div className="logo">
                        <img src={bnblogo} alt="" />
                    </div>
                    <div className="text-center mt-4 name">BnB Portal</div>
                    <form onSubmit={handleSubmit(submitForm)} className="p-3 mt-3">
                        {
                            step1 &&
                            <div>
                                <div className="mb-4">
                                    <h5 className="text-center mb-4">{t('FORGOT_PASSWORD')}</h5>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='form-group'>
                                            <label htmlFor='username'>{t('USERNAME')} <span style={{ color: "red" }}>*</span></label>
                                            <input type="text" name="username" id="username" placeholder={t('USERNAME')} className={`form-control w-100 ${errors.username ? 'is-invalid' : ''}`} {...register('username')} />
                                            {errors?.username && <p className="input-error-msg"> {errors.username?.message} </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            step2 &&
                            <div>
                                <div className="mb-4">
                                    <h5 className="text-center mb-4">{t('CODE_VERIFICATION')}</h5>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='form-group'>
                                            <label htmlFor='code'>{t('CODE')} <span style={{ color: "red" }}>*</span></label>
                                            <input type="password" name="code" id="code" placeholder={t('CODE')} className={`form-control w-100 ${errors.code ? 'is-invalid' : ''}`} {...register('code')} />
                                            {errors?.code && <p className="input-error-msg"> {errors.code?.message} </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            step3 &&
                            <div>
                                <div className="mb-4">
                                    <h5 className="text-center mb-4">{t('DEFINE_NEW_PASSWORD')}</h5>
                                </div>
                                <input type="hidden" name="userId" id="userId" {...register('userId')} />
                                <div className="row">
                                    <div className="col-12">
                                        <div className='form-group'>
                                            <label htmlFor='password'>{t('NEW_PASSWORD')} <span style={{ color: "red" }}>*</span></label>
                                            <input type="password" name="password" id="password" placeholder={t('NEW_PASSWORD')} className={`form-control w-100 ${errors.password ? 'is-invalid' : ''}`} {...register('password')} />
                                            {errors?.password && <p className="input-error-msg"> {errors.password?.message} </p>}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className='form-group'>
                                            <label htmlFor='password_confirmation'>{t('PASSWORD_CONFIRMATION')} <span style={{ color: "red" }}>*</span></label>
                                            <input type="password" name="password_confirmation" id="password_confirmation" placeholder={t('PASSWORD_CONFIRMATION')} className={`form-control w-100 ${errors.password_confirmation ? 'is-invalid' : ''}`} {...register('password_confirmation')} />
                                            {errors?.password_confirmation && <p className="input-error-msg"> {errors.password_confirmation?.message} </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <button type="submit" className="btn mt-4">
                            {t('SUBMIT')}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
