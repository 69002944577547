
const getMailHashed = (mail, count) => {
    var parts = mail.split('@')
    var user = parts[0]
    var domain = parts[1]

    var user1 = user.substring(0, count + 1)
    for (let i = count + 1; i < user.length; i++) {
        user1 += "*";
    }
    return user1 + '@' + domain;
}

const getCookieValue = (userKey) => {
    let items = document.cookie.split(";")
    console.log("getCookieValue", items)
    for (let item of items) {
        let [key, value] = item.split("=");
        if (key.includes(userKey))
            return value;
    };
    return null;
}

export {
    getMailHashed,
    getCookieValue
}