import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { showToast } from "../../slices/toast-slice";
import GlobalConfirmPasscod from "../GlobalConfirmPasscod";

const MultiserviceCreate = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [operators, setOperators] = useState([]);
    const [products, setProducts] = useState([]);
    const [params, setParams] = useState({
        "productId": '', 
        "phoneNumber": '', // receiver's phone number, required for specific products
        "accountId": "", // receiver's product identifier, required for specific products
        "amount": 0, 
        "agentUserId": globalAuthUser?.user_id
    });
    const [isBillPayment, setIsBillPayment] = useState(false);
    const [paymentAllowed, setPaymentAllowed] = useState(false);
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    useEffect(() => {
        dispatch(showLoading());

        // check if the authenticated user has a valid passcode
        const signature = globalAuthUser?.signature;

        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            if(!signature) {
                // no signature is set for the user, prompt the user for a new signature
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
                navigate('/create-passcode');
                return;
            }

            if(!signature?.isActive || signature?.isActive == 2) {
                // signature code not activated
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
                navigate('/activate-passcode');
                return;
            }
        }

        // set the sending allowed
        setPaymentAllowed(true);

        var data = {
            'countryCode':globalAuthUser?.location?.agent_country?.sortname,
        };
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/sochitel/fetchOperators', data,{headers}
        ).then(res => {
            dispatch(hideLoading());
            if(res.data.status === 1){
                setOperators(res.data.data);
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        }).catch(error => {
            console.log(error);
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }, [])

    function fetchProduct(event) {
        dispatch(showLoading());
        setProducts([]);
        var operatorId = event.target.value;
        var data = {
            'countryCode':globalAuthUser?.location?.agent_country?.sortname,
            'operatorId': operatorId,
        };
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/sochitel/fetchProducts', data,{headers}
        ).then(res => {
            dispatch(hideLoading());
            if(res.data.status === 1){
                console.log(res.data.data);
                setProducts(res.data.data);
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        }).catch(error => {
            console.log(error);
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });

    }

    const handleInput = (e) => {
        e.persist();
        setParams((prevAccount) => {
          const updatedAccount = { ...prevAccount, [e.target.name]: e.target.value };
          if (e.target.name == "productId") {
            var selectedPr = products.filter((product => product.id == e.target.value));
            if(e.target.value != "" && e.target.value != undefined && e.target.value != null && selectedPr && selectedPr[0].productTypeId == 3){
                setIsBillPayment(true);
            }else{
                setIsBillPayment(false);
            }
          }
          return updatedAccount;
        });
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmitService = (event) => {
        event.preventDefault();
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            openModal();
            return;
        }
        // save the local txn
        createTxn();
    }

    function createTxn() {
        dispatch(showLoading());
        
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/sochitel/createTxn',params,{headers})
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data.message);
            console.log(response.data.status);
            console.log(response.data.data);
            if (response.data.status == 1) {
                dispatch( showToast(["success", t("INFORMATION"), t('SUCCESS')])); 
                setTimeout(() => {
                    navigate('/multiservices');
                }, 1000);
            } else {
                dispatch(showToast(["success", t("INFORMATION"), t(response.data.message)]))
            }
        }).catch(err => {
            console.log(err.response.data.message);
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t(err.response.data.message)]));
            return;
        }); 
    }
    
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h5 className="col-sm-4">{t('MULTISERVICES')}</h5>
                        <span>
                            <button type='button' className='btn btn-primary' onClick={() => navigate('/multiservices')}>
                                <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                                {t('BACK')}
                            </button>
                        </span>
                    </div>
                    
                    <div className="card-body">
                        <form id="my-form" onSubmit={handleSubmitService}> 
                            <div className="form-group row">
                                <div className="col-md-6 mb-3">
                                    <label className=""  htmlFor="operatorId">{t('OPERATORS')} <span className="text-danger">*</span></label>
                                    <select className="form-select" onChange={(e) => fetchProduct(e)} name="operatorId" id="operatorId" required aria-label="Default select example">
                                        <option value={""}>{t('SELECT_AN_OPERATOR')}</option>
                                        {
                                            operators.map((key, index) => (
                                                <option key={index} value={key.id}>
                                                {key.operatorName}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className=""  htmlFor="productId">{t('PRODUCTS')} <span className="text-danger">*</span></label>
                                    <select className="form-select" onChange={handleInput} name="productId" id="productId" required aria-label="Default select example">
                                        <option value={""}>{t('SELECT_A_PRODUCT')}</option>
                                        {
                                            products.map((key, index) => (
                                                <option key={index} value={key.id}>
                                                {key.productName}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6 mb-3">
                                    <label className="" htmlFor="amount">{t('AMOUNT')} <span className="text-danger">*</span></label>
                                    <input type="number" name="amount" id="amount" onChange={handleInput} className="form-control custom-field" required/>
                                </div>
                                {
                                    !isBillPayment ? 
                                    <>
                                        <div className="col-md-6">
                                            <label className="" htmlFor="phoneNumber">{t('PHONE_NUMBER')} <span className="text-danger" >*</span></label>
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                onChange={handleInput}
                                                className={`form-control custom-field mb-1`}
                                                required  
                                            />
                                        </div>
                                    </> : 
                                    <>
                                        <div className="col-md-6">
                                            <label className="" htmlFor="accountId">{t('ACCOUNT_ID')} <span className="text-danger" >*</span></label>
                                            <input
                                                type="number"
                                                name="accountId"
                                                id="accountId"
                                                onChange={handleInput}
                                                className={`form-control custom-field mb-1`}
                                                required  
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            
                            <div className="form-group row justify-content-md-center mt-3">
                                <button type="submit" className="col-sm-2 btn btn-primary" id="apply-filter-paid" disabled = {!paymentAllowed ? 'disabled' : ''} data-count="0">{t('SUBMIT')}</button>
                                &nbsp;
                                <button type="reset" className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CANCEL')}</button>
                            </div>
                            
                        </form>
                    </div>
                </div> 
            </div>

            {/** passcode modal */}
            <div>
                <GlobalConfirmPasscod
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callParentFunction={createTxn}
                />
            </div>
        </React.Fragment>

    );
}

export default MultiserviceCreate;