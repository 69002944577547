
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../slices/loading-slice";
import { useTranslation } from "react-i18next";
import { setSEND_MONEY_OBJECT } from "../slices/send-money-ria-slice";
import { showToast } from "../slices/toast-slice";

function SendTransaction() 
{
  const { t } = useTranslation();

  const [company, setCompany] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [pickupCode, setPickupCode] = useState("");
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const [searchClicked, setSearchClicked] = useState(false);
  const [companies, setCompanies] = useState([]);
  const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
  const FORM_FREE_COMPLETION = useSelector((state) => state.auth.FORM_FREE_COMPLETION);

  const [sendingAllowed, setSendingAllowed] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("globalAuthUser", globalAuthUser)
  
  useEffect(() => {
    // setSendMoneyObject({})
    dispatch(setSEND_MONEY_OBJECT({}))
    // console.log('new object', sendMoneyObject)

    // check if the authenticated user has a valid passcode
    const signature = globalAuthUser?.signature;

    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      if(!signature) {
        // no signature is set for the user, prompt the user for a new signature
        dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
        navigate('/create-passcode');
        return;
      }

      if(!signature?.isActive || signature?.isActive == 2) {
          // signature code not activated
          dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
          navigate('/activate-passcode');
          return;
      }
    }

    // set the sending allowed
    setSendingAllowed(true);

}, []);

  const onCompanySelect = (e) => {
    console.log(e.currentTarget.id)
    setCompanyCode(e.currentTarget.id);
    const allWithClass = Array.from(
      e.currentTarget.parentElement.getElementsByClassName("card")
    );
    allWithClass.forEach((element) => {
      element.classList.remove("sCompany");
    });
    e.currentTarget.classList.add("sCompany");
  };

  const onStart = () => {
    switch(companyCode) 
    {
      case "BNB": 
        console.log("in Ria")
        navigate("/send-money/bnb")
        break

      case "MG": 
        console.log("in MG")
        navigate("/send-money/moneygram")
        break
        
      case "Ria": 
        console.log("in Ria")
        navigate("/send-money/ria")
        break
    }

  }

  useEffect(() => {
    setCompanies([
        { company_id: 1, company_code: "BNB", company_name: "BnB Transfer", company_logo: "img/1615213793_logo.png", enabled: true },
        { company_id: 2, company_code: "MG", company_name: "MoneyGram", company_logo: "img/logo-1.png", 
          enabled: (globalAuthUser?.location?.enable_send_moneygram) 
        },
        { company_id: 3, company_code: "Ria", company_name: "Ria", company_logo: "img/1615214075_1600254162_ria-logo.png",
          enabled: (globalAuthUser?.location?.agent_country?.enable_ria_sending == 1 && globalAuthUser?.location?.enable_ria_sending_on_location) 
        }
    ])
  }, []);


  return (
    <>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header" style={{ fontWeight: "600" }}>
            {t("SEND_TRANSACTION")}
          </div>
          <div className="card-body">
            <div className="form-group row justify-content-center">
              {companies.map((company, index) => (
                company.enabled && <div id={company.company_code} key={index} className="card col-6 col-md-2 company"
                  style={{  marginRight: "15px", marginBottom: "15px", cursor: "pointer", }} onClick={onCompanySelect} >
                  <div style={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px", margin: "auto", }} >
                    <div style={{ maxWidth: "100px", maxHeight: "100px", margin: "auto" }} >
                      {/* <img src={require("http://test.bnbcashapp.com/" + company.company_logo)} className="card-img-top" alt="..." /> */}
                      <img src={process.env.REACT_APP_BNB_BASE_URL + "/" + company.company_logo} className="card-img-top" alt="..." />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              {searchClicked && !company && (
                <p className="input-error-msg" style={{ textAlign: "center", maxWidth: "360px", marginBottom: "15px", }} > {t("SELECT_A_COMPANY")} </p>
              )}
            </div>

            <br />

            <div className="row justify-content-center">
              <button type="button" style={{ maxWidth: "360px"}} className="btn btn-primary" disabled = {!sendingAllowed ? 'disabled' : ''} onClick={onStart}> {t("START")} </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendTransaction;
