import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    personalInfo: {
        firstName: "",
        lastName: "",
        country: "",
        city: "",
        address: "",
        phoneNumber: "",
        emailAddress: "",
        idType: "",
        idNumber: "",
        idFront: "",
        idBack: "",
        referralCode: ""
    },
    businessInfo: {
        business_name : "",
        business_cert_number : "",
        business_cert : "",
        tax_cert_number : "",
        tax_cert : "",
        city_council_number : "",
        city_council_license : "",
        other_doc : "",
    },
    agentCountries: [],
    idTypes: ["National ID", "Passport", "Voters ID", "Drivers License", "Other"],
    acceptedFiles: ".pdf, .png, .jpg, .jpeg"
}

export const AgentSelfRegistrationSlice = createSlice({
    name: "agent-self-registration-slice",
    initialState: initialState,
    reducers: {
        setPersonalInfo(state, action) {
            state.personalInfo = action.payload; 
        },
        setBusinessInfo(state, action) {
            state.businessInfo = action.payload;
        },
        setAgentCountries(state, action) {
            state.agentCountries = action.payload;
        }
    }
});

// export the actions
export const { setPersonalInfo, setBusinessInfo, setAgentCountries } = AgentSelfRegistrationSlice.actions;

// export the reducers
export default AgentSelfRegistrationSlice;