import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/images/bnb.png";
import Header from "./Header";
import user from "../assets/images/user.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { ArrowLeft } from 'react-bootstrap-icons';
// import * as Icon from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import { useAuthUser, useSignIn, useSignOut } from "react-auth-kit";
import { useSelector } from "react-redux";
import { useIdleTimer } from 'react-idle-timer';
import { Modal } from 'bootstrap';

function SideMenu() {
  const signOut = useSignOut();
  const [inactive, setInactive] = useState(false);
  const [lastActivity, setLastActivity] = useState(new Date());
  const location = useLocation()
  const authUser = useAuthUser();
  const signIn = useSignIn();
  const expiresIn = useSelector((state) => state.auth.expiresIn);
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const togg = () => {
    console.log("toggled");
    document.querySelector(".sidebar").classList.toggle("close");
  };

  const showSubMenu = (e) => {
    let arrowParent = e.target.parentElement.parentElement;
    arrowParent.classList.toggle("showMenu");
  };

  const { t } = useTranslation();

  const [lastTime, setLastTime] = useState(new Date());

  const diffMinutes = (startDate, endDate) => {
    const diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
  }

  const _onActive = event => { }
  const _onPrompt = event => { }
  const _onIdle = event => {
    console.log('onIdle')
    showModal()
  }
  const _onAction = event => {
    var current = getLastActiveTime();
    setLastTime(current)
    var diff = diffMinutes(lastTime, current);
    var minutes = expiresIn ?? 10
    if (authUser() != null && diff <= minutes && event.target.id !== 'logoutBtn') {
      signIn({
        token: authUser().token,
        expiresIn: minutes,
        tokenType: authUser().tokenType,
        authState: { token: authUser().token, tokenType: authUser().tokenType },
      });
    }
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: _onIdle,
    onActive: _onActive,
    onAction: _onAction,
    onPrompt: _onPrompt,
    debounce: 500,
    events: [
      'keydown',
      'mousedown',
    ]
  });

  const modalRef = useRef()

  const showModal = () => {
    const modalElement = modalRef.current
    const bsModal = new Modal(modalElement, {
      backdrop: 'static',
      keyboard: false
    })
    bsModal.show()
  }


  return (
    // <div className={`side-menu  ${inactive ? 'inactive' : ''}`}>
    //     <div className='top-side'>
    //         <div className='app-logo'>
    //             <img src={logo} alt="app-logo" />
    //         </div>
    //         <div className='collapse-btn' onClick={()=>{setInactive(!inactive);}}>
    //             {inactive ? <i class="bi bi-arrow-right-square-fill"></i> :  <i class="bi bi-arrow-left-square-fill"></i>}
    //         </div>
    //     </div>

    //     <div className='divider'></div>

    //     <div className='main-menu'>
    //         <ul>
    //             <li>
    //                 <a className="menu-item">
    //                     <div className='menu-icon'>
    //                         <i class="bi bi-speedometer2"></i>
    //                     </div>
    //                     <span>Dashboard</span>
    //                 </a>
    //             </li>
    //             <li>
    //                 <a className="menu-item">
    //                     <div className='menu-icon'>
    //                         <i class="bi bi-newspaper"></i>
    //                     </div>
    //                     <span>Content</span>
    //                 </a>
    //                 <ul className="sub-menu">
    //                     <li>
    //                         <a> Courses  </a>
    //                     </li>
    //                     <li>
    //                         <a> Videos  </a>
    //                     </li>
    //                 </ul>
    //             </li>
    //             <li>
    //                 <a className="menu-item">
    //                     <div className='menu-icon'>
    //                         <i class="bi bi-vector-pen"></i>
    //                     </div>
    //                     <span>Design</span>
    //                 </a>
    //             </li>
    //         </ul>

    //     </div>

    //     <div className="side-menu-footer">
    //         <div className='avatar'>
    //             <img src={logo} alt="user" />
    //         </div>
    //         <div className="user-info">
    //             <h5>Mouctar Diallo</h5>
    //             <p>mouctar.diallo@bnbtech.ai</p>
    //         </div>
    //     </div>

    // </div>

    <>
      <div className="sidebar">
        <div className="logo-details">
          {/* <i className="bx bxl-c-plus-plus" /> */}
          <img src={logo} alt="app-logo" />
          <span className="logo_name">Agent Portal</span>
        </div>
        <ul className="nav-links">
          <li>
            <NavLink to="/home" >
              <i className="bx bx-grid-alt" />
              <span className="link_name">{t("DASHBOARD")}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <NavLink to="/home" className="link_name" >
                  {t("DASHBOARD")}
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li>
                  <NavLink to="/paidTransaction">
                      <i className="bx bx-collection" />
                      <span className="link_name">Validation Requests</span>
                  </NavLink>
                  <ul className="sub-menu blank">
                      <li>
                          <NavLink className="link_name" to="/paidTransaction">Validation Requests</NavLink>

                        </li>
                  </ul>
              </li>

              <li>
                  <a href="#">
                      <i className="bx bx-book-alt" />
                      <span className="link_name">Upload Paid Codes</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Upload Paid Codes</a></li>
                  </ul>
              </li> */}

          {/* <li>
                  <a href="#">
                  <i className="bx bx-pie-chart-alt-2" />
                      <span className="link_name">Repayment Requests</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Repayment Requests</a></li>
                  </ul>
              </li> */}
    {
      globalAuthUser.role === "main teller" &&
      <li>
        <NavLink to="/tellerAccess">
          <i className="bx bx-user-plus" />
          <span className="link_name">{t("LOCATION_USER_ACCESS_MNG")}</span>
        </NavLink>
      </li>
    }

<li>
            <NavLink to="/validateRequest">
              <i className="bx bx-line-chart" />
              <span className="link_name">{t("VALIDATION_REQUEST")}</span>
            </NavLink>
            {/* <ul className="sub-menu blank">
              <li>
                <NavLink to="/paid-transactions" className="link_name">
                  {t("VALIDATION_REQUEST")}
                </NavLink>
              </li>
            </ul> */}
          </li>

          <li>
            <NavLink to="/paid-transactions">
              <i className="bx bx-line-chart" />
              <span className="link_name">{t("PAID_TRANSACTIONS")}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <NavLink to="/paid-transactions" className="link_name">
                  {t("PAID_TRANSACTIONS")}
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/send-money">
              <i className="bx bx-compass" />
              <span className="link_name">{t("SENT_TRANSACTIONS")}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <NavLink to="/send-money" className="link_name">
                  {t("SENT_TRANSACTIONS")}
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/account-statements">
              <i className="bx bx-wallet" />
              <span className="link_name">{t("ACCOUNT_STATEMENTS")}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <NavLink to="/account-statements" className="link_name">
                  {t("ACCOUNT_STATEMENTS")}
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/repayment-requests">
              <i className="bx bx-git-pull-request" />
              <span className="link_name">{t("REPAYMENT_REQUEST")}</span>
            </NavLink>
            <ul className="sub-menu blank">
              <li>
                <NavLink to="/repayment-requests" className="link_name">
                  {t("REPAYMENT_REQUEST")}
                </NavLink>
              </li>
            </ul>
          </li>
          {
            <li>
              <NavLink to="/mobile-money">
                <i className="bx bx-mobile-alt" />
                <span className="link_name">{t("CICO")}</span>
              </NavLink>
              <ul className="sub-menu blank">
                <li>
                  <NavLink to="/mobile-money" className="link_name">
                    {t("CICO")}
                  </NavLink>
                </li>
              </ul>
            </li>
          }
          {
            <li>
              <NavLink to="/agent-repayment">
                <i className="bx bx-money-withdraw" />
                <span className="link_name">{t("AGENT_REPAYMENT")}</span>
              </NavLink>
              <ul className="sub-menu blank">
                <li>
                  <NavLink to="/agent-repayment" className="link_name">
                    {t("AGENT_REPAYMENT")}
                  </NavLink>
                </li>
              </ul>
            </li>
          }
         
          {
            globalAuthUser?.location?.enable_pay_repayment == 1 && 
            <li>
              <NavLink to="/merchantPaymentReport">
                <i className="bx bx-store" />
                <span className="link_name">{t("MERCHANT_PAYMENTS")}</span>
              </NavLink>
              <ul className="sub-menu blank">
                <li>
                  <NavLink to="/agent-repayment" className="link_name">
                    {t("MERCHANT_PAYMENTS")}
                  </NavLink>
                </li>
              </ul>
            </li>
          }

          {
            globalAuthUser?.multiservice_enabled == 1 && 
            <li>
              <NavLink to="/multiservices">
                <i className="bx bx-money" />
                <span className="link_name">{t("MULTISERVICES")}</span>
              </NavLink>
              <ul className="sub-menu blank">
                <li>
                  <NavLink to="/multiservices" className="link_name">
                    {t("MULTISERVICES")}
                  </NavLink>
                </li>
              </ul>
            </li>
          }
          {
            globalAuthUser?.location?.agent_country?.enable_xpress_cashin == 1 && 
            <li>
              <NavLink to="/xpress-cashin">
                <i className="bx bx-money" />
                <span className="link_name">{t("ECOBANK_XPRESS")}</span>
              </NavLink>
              <ul className="sub-menu blank">
                <li>
                  <NavLink to="/xpress-cashin" className="link_name">
                    {t("ECOBANK_XPRESS")}
                  </NavLink>
                </li>
              </ul>
            </li>
          }

{
            <li>
              <NavLink to="/summary-reports">
                <i className="bx bx-money-withdraw" />
                <span className="link_name">{t("SUMMARY_REPORTS")}</span>
              </NavLink>
              <ul className="sub-menu blank">
                <li>
                  <NavLink to="/agent-repayment" className="link_name">
                    {t("SUMMARY_REPORTS")}
                  </NavLink>
                </li>
              </ul>
            </li>
          }

          {/* <li>
                  <a href="#">
                      <i className="bx bx-history" />
                      <span className="link_name">Cashin / Cashout</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Cashin / Cashout</a></li>
                  </ul>
              </li>

              <li>
                  <a href="#">
                      <i className="bx bx-cog" />
                      <span className="link_name">Expenses</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Expenses</a></li>
                  </ul>
              </li>

              <li>
                  <a href="#">
                      <i className="bx bx-plug" />
                      <span className="link_name">Account Statement</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Account Statement</a></li>
                  </ul>
              </li>

              <li>
                  <a href="#">
                      <i className="bx bx-collection" />
                      <span className="link_name">Summary Report</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Summary Report</a></li>
                  </ul>
              </li> */}

          {/* <li onClick={showSubMenu}>
                  <div className="iocn-link">
                      <a href="#">
                          <i className="bx bx-collection" />
                          <span className="link_name">Category</span>
                      </a>
                      <i className="bx bxs-chevron-down arrow" />
                  </div>
                  <ul className="sub-menu">
                      <li><a className="link_name" href="#">Category</a></li>
                      <li><a href="#">HTML &amp; CSS</a></li>
                      <li><a href="#">JavaScript</a></li>
                      <li><a href="#">PHP &amp; MySQL</a></li>
                  </ul>
              </li> 
              <li>
                  <div className="iocn-link">
                      <a href="#">
                          <i className="bx bx-book-alt" />
                          <span className="link_name">Posts</span>
                      </a>
                      <i className="bx bxs-chevron-down arrow" />
                  </div>
                  <ul className="sub-menu">
                      <li><a className="link_name" href="#">Posts</a></li>
                      <li><a href="#">Web Design</a></li>
                      <li><a href="#">Login Form</a></li>
                      <li><a href="#">Card Design</a></li>
                  </ul>
              </li> 
              <li>
                  <a href="#">
                      <i className="bx bx-pie-chart-alt-2" />
                      <span className="link_name">Analytics</span>
                  </a>
                  <ul className="sub-menu blank">
                      <li><a className="link_name" href="#">Analytics</a></li>
                  </ul>
              </li> 
              
              <li>
                  <div className="iocn-link">
                      <a href="#">
                          <i className="bx bx-plug" />
                          <span className="link_name">Plugins</span>
                      </a>
                      <i className="bx bxs-chevron-down arrow" />
                  </div>
                  <ul className="sub-menu">
                      <li><a className="link_name" href="#">Plugins</a></li>
                      <li><a href="#">UI Face</a></li>
                      <li><a href="#">Pigments</a></li>
                      <li><a href="#">Box Icons</a></li>
                  </ul>
              </li> 
              
              
              
              <li>
                  <div className="profile-details">
                      <div className="profile-content">
                          <img src="image/profile.jpg" alt="profileImg" />
                      </div>
                      <div className="name-job">
                          <div className="profile_name">Prem Shahi</div>
                          <div className="job">Web Desginer</div>
                      </div>
                      <i className="bx bx-log-out" />
                  </div>
              </li> */}
        </ul>
        <div className="modal fade" tabIndex={-1} role="dialog" ref={modalRef} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title w-100" id="myModalLabel">{t('SESSION_EXPIRED')}</h4>
              </div>
              <div className="modal-body">
                <span style={{ fontSize: '16px' }}>{t('SESSION_EXPIRED_MESSAGE', { expiresIn: expiresIn })}</span>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" type="button" onClick={() => signOut()}>{t('LOGIN')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="home-section"> */}
      {/* <Header /> */}
      {/* </section> */}
    </>
  );
}

export default SideMenu;
