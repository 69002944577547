import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { set_CODETABLE } from '../../../slices/auth-slice';
import { DaysList, MonthList, YearDobList, YearExpirationList } from '../../../utils/helper';
import { setCONSUMER_RECEIVERS, setSEND_MONEY_OBJECT, setStep } from '../../../slices/send-money-ria-slice';
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { GrFormView, GrView } from "react-icons/gr";
import cloneDeep from 'lodash/cloneDeep';
import { useImmer } from 'use-immer';
import { showToast } from '../../../slices/toast-slice';
// import Select from 'react-select';

function RiaSender() {

    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);
    const fields = useSelector((state) => state.sendMoneyRia.fields);
    const codeTable = useSelector((state) => state.sendMoneyRia.codeTable);
    const CONSUMER_RECEIVERS = useSelector((state) => state.sendMoneyRia.CONSUMER_RECEIVERS);
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const [countryCode, setCountryCode] = useState(null);
    const [sendMoneyObject, setSendMoneyObject] = useStateCallback(SEND_MONEY_OBJECT);
    const [apiError, setApiError] = useState(null);
    const [occupations, setOccupations] = useState([]);
    const [formStep, setFormStep] = useState(0);
    const [editSender, seteditSender] = useState(false);
    const [idTypes, setIdTypes] = useState([]);
    const [consumerId, setCounsumerId] = useState(null);
    const [phoneCode, setPhoneCode] = useState("232");
    const [mkSms, setMkSms] = useState(false);
    const [mkEmail, setMkEmail] = useState(false);
    const [ccSms, setCcSms] = useState(false);
    const [data, setCcEmail] = useState(false);
    const [showSenderModal, setShowSenderModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(true);
    const [openAddress, setOpenAddress] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [consumers, setConsumers] = useState([]);
    const [childKey, SetChildKey] = useState("0");
    const DAYS = DaysList();
    const MONTHS = MonthList();
    const YEARS = YearDobList();
    const YEARS_EXPIRY = YearExpirationList();
    const authUser = useAuthUser()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    var mSendObject = cloneDeep(SEND_MONEY_OBJECT)
    const [testval, setTestval] = useState(0);
    const [transferReasons, setTransferReasons] = useState([]);
    const [sourceOfFunds, setSourceOfFunds] = useState([]);
    const [reqOptFieldInfo, setReqOptFieldInfo] = useState([]);
    const [otherOptFieldInfo, setOtherOptFieldInfo] = useState([]);
    const [reqOptFieldIds, setReqOptFieldIds] = useState([]);
    const [otherOptFieldIds, setOtherOptFieldIds] = useState([]);
    const [showOptFieldInfo, setShowOptFieldInfo] = useState(false);
    const [occupationObject, setOccupationObject] = useState({});
    const [showOptFieldIds, setShowOptFieldIds] = useState(false);
    

    console.log("ria send sender component render")

    const maritalStatusArray = [ {id:100, name:"Single"}, {id:200, name:"Married"}, {id:300, name:"Divorced"}, {id:400, name:"Widowed"}, {id:500, name:"Civil Union"} ]
    const incomeFrequencyArray = [ {id:10, name:"Hourly"}, {id:20, name:"Weekly"}, {id:30, name:"BiWeekly"}, {id:40, name:"Monthly"}, {id:50, name:"Yearly"}, {id:999, name:"Other"} ]

    const feeReqFields = sendMoneyObject.requiredFields

    const optFieldInfo = [
        { tag: "maritalStatusId", link: "CustMaritalStatus", type: "enum", list:  maritalStatusArray, value: "id", label: "name"},
        { tag: "stateOfBirth", link: "CustStateOfBirth", type: "string" },
        { tag: "cityOfBirth", link: "CustCityOfBirth", type: "string" },
        { tag: "nationality", link: "CustNationality", type: "enum", list: codeTable?.countries_info, value: "country_code", label: "country_name" },
        { tag: "countryOfResidence", link: "CustCountryOfResidence", type: "enum", list: codeTable?.countries_info, value: "country_code", label: "country_name" },
        { tag: "businessSectorId", link: "CustBusinessSectorID", type: "enum", list: JSON.parse(codeTable?.enum_values_info.filter((a) => a.enum_type === "CustBusinessSector")[0].enum_values), value: "Value", label: "Name" },
        { tag: "zipCode", link: "CustZipCode", type: "string" },
        { tag: "incomeAmount", link: "CustIncomeAmount", type: "string" },
        { tag: "incomeCurrency", link: "CustIncomeCurrency", type: "enum", list: codeTable?.currency_info, value: "currency_code", label: "currency_code" },
        { tag: "incomeFrequencyId", link: "CustIncomeFrequency", type: "enum", list:  incomeFrequencyArray, value: "id", label: "name"},
        { tag: "incomePaymentMethodId", link: "CustIncomePaymentMethod", type: "enum", list: JSON.parse(codeTable?.enum_values_info.filter((a) => a.enum_type === "CustPaymentMethods")[0].enum_values), value: "Value", label: "Name" },
        { tag: "taxId", link: "CustTaxId", type: "string" },
        { tag: "taxCountry", link: "CustTaxCountry", type: "enum", list: codeTable?.countries_info, value: "country_code", label: "country_name" },
        { tag: "fatherName", link: "CustFatherName", type: "string" },
        { tag: "motherName", link: "CustMotherName", type: "string" },
        { tag: "employerName", link: "CustEmployerName", type: "string" },
    ]

    const optFieldIds = [
        { tag: "photoIdIssuingEntity", link: "CustID1IssuedBy", type: "string" },
        { tag: "photoIdIssuingState", link: "CustID1IssuedByState", type: "enum", list:  getStatesOfCountry('photoIdIssuingCountry'), value: "id", label: "name" },
        { tag: "photoIdIssuingDate", link: "CustID1IssuedDate", type: "date" },
    ]

    let val11 = [];
    let val21 = [];
    let otherOptFieldIdsArray = [];
    let otherOptFieldInfosArray = [];

    function loadOptionalField() 
    {
        optFieldInfo.forEach(element => {
            let regFieldInfee = feeReqFields.find(el => el.FieldName == element.link)
            if(regFieldInfee != null) {
                if(element.type == "string") {
                    element.minLength = regFieldInfee.MinLength
                    element.maxLength = regFieldInfee.MaxLength
                } 
                val11.push(element);
            } else {
                otherOptFieldInfosArray.push(element)
            }
        });
        console.log('val11', val11)

        optFieldIds.forEach(element => {
            let regFieldInfee = feeReqFields.find(el => el.FieldName == element.link)
            if(regFieldInfee != null) {
                if(element.type == "string") {
                    element.minLength = regFieldInfee.MinLength
                    element.maxLength = regFieldInfee.MaxLength
                } 
                val21.push(element);
            } else {
                otherOptFieldIdsArray.push(element);
            }
        });
        console.log('val21', val21)

        

    }

    loadOptionalField()

    var schemaObject = {};

    if(showViewModal) {
        schemaObject['photoIdType'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdNumber'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryDay'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryMonth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryYear'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdIssuingCountry'] = yup.string().required(t("FIELD_REQUIRED"))

        schemaObject['photoIdIssuingEntity'] = yup.string()
        schemaObject['photoIdIssuingState'] = yup.string()
        schemaObject['photoIdIssuingDate'] = yup.string()

        for (let field of val21) {
            if(field.type == "string") {
                schemaObject[field.tag] = yup.string().required(t("FIELD_REQUIRED")).min(field.minLength).max(field.maxLength)
            } else {
                schemaObject[field.tag] = yup.string().required(t("FIELD_REQUIRED"))
            }
        }
    } else {
        schemaObject['firstName'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['middleName'] = yup.string()
        schemaObject['firstLastName'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['secondLastName'] = yup.string()
        schemaObject['gender'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['occupation'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthDay'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthMonth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthYear'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['countryOfBirth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdType'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdNumber'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryDay'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryMonth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryYear'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdIssuingCountry'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['country'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['state'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['address'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['city'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['phoneNo'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['emailAddress'] = yup.string()
        schemaObject['customerCareEmailNotification'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['customerCareSMSNotification'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['marketingEmailNotification'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['marketingSMSNotification'] = yup.string().required(t("FIELD_REQUIRED"))
        

        schemaObject['employerName'] = yup.string()
        schemaObject['motherName'] = yup.string()
        schemaObject['fatherName'] = yup.string()
        schemaObject['taxCountry'] = yup.string()
        schemaObject['taxId'] = yup.string()
        schemaObject['incomePaymentMethodId'] = yup.string()
        schemaObject['incomeFrequencyId'] = yup.string()
        schemaObject['incomeCurrency'] = yup.string()
        schemaObject['incomeAmount'] = yup.string()
        schemaObject['zipCode'] = yup.string()
        schemaObject['businessSectorId'] = yup.string()
        schemaObject['countryOfResidence'] = yup.string()
        schemaObject['nationality'] = yup.string()
        schemaObject['cityOfBirth'] = yup.string()
        schemaObject['stateOfBirth'] = yup.string()
        schemaObject['maritalStatus'] = yup.string()

        schemaObject['photoIdIssuingEntity'] = yup.string()
        schemaObject['photoIdIssuingState'] = yup.string()
        schemaObject['photoIdIssuingDate'] = yup.string()


        for (let field of val11) {
            if(field.type == "string") {
                schemaObject[field.tag] = yup.string().required(t("FIELD_REQUIRED")).min(field.minLength).max(field.maxLength)
            } else {
                schemaObject[field.tag] = yup.string().required(t("FIELD_REQUIRED"))
            }
        }

        for (let field of val21) {
            if(field.type == "string") {
                schemaObject[field.tag] = yup.string().required(t("FIELD_REQUIRED")).min(field.minLength).max(field.maxLength)
            } else {
                schemaObject[field.tag] = yup.string().required(t("FIELD_REQUIRED"))
            }
        }

    }

    schemaObject["occupationOtherText"] = yup.string().when("occupation", {
        is: (occupation) =>
            sendMoneyObject?.enumOccupations.find((a) => a.Value === occupation) !== undefined &&
            sendMoneyObject?.enumOccupations.find((a) => a.Value === occupation).Name === "Other",
        then: yup
            .string()
            .required(t("FIELD_REQUIRED"))
    });

    const schema = yup.object().shape(
        schemaObject
    )

    function sSender() {
        if(sendMoneyObject.consumers != undefined) {
            return sendMoneyObject.consumers.find(c => c.id === sendMoneyObject.selectedConsumerId)
        } else return null
    }

    var { register, handleSubmit, formState: { errors }, trigger, setValue, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange"
    });

    useEffect(() => {
        console.log("testval", testval)
        console.log("mSendObject", mSendObject)
    }, [testval]);

    useEffect(() => {
        if(sendMoneyObject.consumers != undefined) {
            let sCustomer = sendMoneyObject.consumers.find(c => c.id === sendMoneyObject.selectedConsumerId)
        }
        dispatch(setSEND_MONEY_OBJECT(sendMoneyObject))
    }, [sendMoneyObject]);

    useEffect(() => {
        if(sendMoneyObject.consumers == undefined) {
            setSendMoneyObject(prev => {
                prev.consumers = []
            })
        }
        console.log("SEND_MONEY_OBJECT1", SEND_MONEY_OBJECT)
        if(SEND_MONEY_OBJECT.enumTransferReasons == undefined || SEND_MONEY_OBJECT.enumTransferReasons.length == 0) {
            getEnumValues()
        }

        setReqOptFieldInfo(val11)
        setOtherOptFieldInfo(otherOptFieldInfosArray)

        setReqOptFieldIds(val21) 
        setOtherOptFieldIds(otherOptFieldIdsArray)

    }, []);


    useEffect(() => {
        console.log("consumer", consumerId)
        if (consumerId != null) {
            console.log("consumerId")
            findCustomerPhotoIdInfo()
            dispatch(setStep(3));
        }
    }, [consumerId]);

    const onSearch = () => {
        var object1 = cloneDeep(sendMoneyObject)
        console.log("onSearch in", sendMoneyObject)
        object1.consumers = [];
        setSendMoneyObject(object1)
        setShowViewModal(false)
        setShowSenderModal(false)
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + '/api/ria/consumerLookup',
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
            data: {
                sendingCountry: "SL",
                PhoneNo: document.getElementById("searchText").value
            },
        })
            .then((res) => {
                dispatch(hideLoading());
                console.log(res)
                if(res.data.status != "SUCCESS") {
                    dispatch( showToast(["danger", "ERROR", res.data.message]))
                    return;
                }
                setConsumers(res.data.data.customers)
                var object = cloneDeep(sendMoneyObject)
                let senders = []
                for (let i = 0; i < res.data.data.customers.length; i++) {
                    let consumer = res.data.data.customers[i]
                    let sender = {}
                    sender.id = consumer.id
                    sender.typeID = consumer.typeID
                    sender.status = consumer.status
                    sender.typeID = consumer.typeID
                    object.searchedPhoneNumber = document.getElementById("searchText").value
                    sender.firstName = consumer.individualInformation.firstName
                    sender.middleName = consumer.individualInformation.middleName
                    sender.firstLastName = consumer.individualInformation.firstLastName
                    sender.secondLastName = consumer.individualInformation.secondLastName
                    sender.dateOfBirth = consumer.individualInformation.dateOfBirth.substring(0, 10)
                    sender.countryOfBirth = consumer.individualInformation.countryOfBirth
                    sender.birthDay = sender.dateOfBirth.substring(8, 10)
                    sender.birthMonth = sender.dateOfBirth.substring(5, 7)
                    sender.birthYear = sender.dateOfBirth.substring(0, 4)
                    sender.gender = consumer.individualInformation.gender
                    sender.occupationId = consumer.individualInformation.occupationId
                    sender.occupationText = sendMoneyObject?.enumOccupations.find(o => o.Value == sender.occupationId).Name
                    sender.nationality = consumer.individualInformation.nationality
                    sender.address = consumer.address.address
                    sender.city = consumer.address.city
                    sender.country = consumer.address.country
                    sender.state = consumer.address.state
                    sender.zipCode = consumer.address.zipCode
                    sender.customerCareEmailNotification = consumer.contactInformation.customerCareEmailNotification
                    sender.customerCareSMSNotification = consumer.contactInformation.customerCareSMSNotification
                    sender.marketingEmailNotification = consumer.contactInformation.marketingEmailNotification
                    sender.marketingSMSNotification = consumer.contactInformation.marketingSMSNotification
                    sender.emailAddress = consumer.contactInformation.emailAddress
                    if (consumer.contactInformation.phones.length > 0) {
                        sender.phoneNo = consumer.contactInformation.phones[0].number.areaCode + consumer.contactInformation.phones[0].number.subscriptionNumber
                        sender.countryPhoneCode = consumer.contactInformation.phones[0].number.countryCallingCode
                    }

                    if(consumer.taxInformation != undefined) {
                        sender.taxId = consumer.taxInformation.taxId
                        sender.taxCountry = consumer.taxInformation.taxCountry
                    }

                    sender.receivers = []
                    senders.push(sender)
                }
                object.consumers = senders
                setSendMoneyObject(object)
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log(error)
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
            });
    }

    function getEnumValues() 
    {
        let CountryFrom = sendMoneyObject.countryFrom
        let CountryTo = sendMoneyObject.sDestCountry.country_code
        let DeliveryMethod = sendMoneyObject.sDeliveryMethod.id
        let ProductID = sendMoneyObject.sProduct.id
        let PayAgentID = sendMoneyObject.sPayoutPartner != null ? sendMoneyObject.sPayoutPartner.id : null
        let PayAgentLocID = sendMoneyObject.sPayoutPartnerLocation != null ? sendMoneyObject.sPayoutPartnerLocation.id : null
        dispatch(showLoading());
        let url1 = process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/getEnumValues?SendingCountry=SL&CountryFrom=SL"
                +"&CountryTo="+CountryTo+"&DeliveryMethod="+DeliveryMethod+"&ProductID="+ProductID

        if(PayAgentID != null) {
            url1 = url1 +"&PayAgentID="+PayAgentID
        } 
        if(PayAgentLocID != null) {
            url1 = url1 +"&PayAgentLocID="+PayAgentLocID
        } 
        axios({
            method: "get",
            url: url1,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
        })
        .then((res) => {
            console.log(res)
            dispatch(hideLoading());
            if(res.data.status == "SUCCESS") {
                setSendMoneyObject(prev => {
                    prev.enumTransferReasons = res.data.data.TransferReasons
                    prev.enumIDTypes = res.data.data.IDTypes
                    prev.enumOccupations = res.data.data.CustOccupations
                    prev.enumSourceFunds = res.data.data.CustSourceFunds
                    prev.enumRelationships = res.data.data.CustBeneRelationship
                    prev.enumBusinessSectors = res.data.data.CustBusinessSector
                })
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch(hideLoading());
        });
    }

    const back = () => {
        setSendMoneyObject(prev => {
            prev.previousStep = 2
            prev.step = 1
        })
    }

    useEffect(() => {
        console.log("formStep", formStep)
        if (formStep == 0) {
            setOpenInfo(true)
            setOpenAddress(false)
            setValue('step', 0);
        } else {
            setOpenInfo(false)
            setOpenAddress(true)
            setValue('step', 1);
        }
    }, [formStep])

    const createSender = () => {
        seteditSender(false)
        setFormStep(0)
        setShowSenderModal(true)
        setShowViewModal(false)
        console.log("new sender")
    }

    function getReceivers() {
        dispatch(showLoading());
        axios({
            method: "get",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + consumerId + "/receivers?sendingCountry=SL",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
        })
            .then((res) => {
                console.log(res)
                dispatch(hideLoading());
                if (res.data.status == "SUCCESS") {
                    dispatch(setCONSUMER_RECEIVERS(res.data.data))
                    dispatch(setStep(3));
                }
            })
            .catch((error) => {
                console.log(error)
                dispatch(setCONSUMER_RECEIVERS([]))
                dispatch(hideLoading());
                dispatch(setStep(3));
            });
    }

    const onConsumerSelect = async (e, consumer) => {
        console.log("onConsumerSelect", consumer)
        await findCustomerPhotoIdInfo(consumer.id)
        dispatch(setStep(3));

    }

    const getInitials = (consumer) => {
        var name = consumer.firstName.charAt(0);
        name += consumer.firstLastName.charAt(0);
        return name
    }

    const getSenderName = (consumer) => {
        var name = '';
        if (consumer.firstName != null) {
            name += consumer.firstName;
        }
        if (consumer.middleName != null) {
            name += ' ' + consumer.middleName;
        }
        if (consumer.firstLastName != null) {
            name += ' ' + consumer.firstLastName;
        }
        if (consumer.secondLastName != null) {
            name += ' ' + consumer.secondLastName;
        }
        return name;
    }

    const onSenderModalClose = () => {
        setShowSenderModal(false)
    }

    const onSenderSubmitInfo = (data, event) => {
        console.log("info data", data)
    }

    const onSenderSubmit = (data, event) => 
    {
        event.preventDefault();
        console.log("onSenderSubmit", data)
        let mPayload = {};
        let url = "";
        if(showViewModal) {
            mPayload =
            {
                riaCustomerId: sSender().id,
                firstName: sSender().firstName,
                middleName: sSender().middleName,
                firstLastName: sSender().firstLastName,
                secondLastName: sSender().secondLastName,
                gender: sSender().gender,
                occupationId: sSender().occupationId,
                occupationText: sSender().occupationText,
                dateOfBirth: sSender().dateOfBirth,
                country: sSender().country,
                state: sSender().state,
                city: sSender().city,
                zipCode: sSender().zipCode,
                address: sSender().address,
                countryCallingCode: sSender().countryPhoneCode,
                subscriptionNumber: sSender().phoneNo,
                emailAddress: sSender().emailAddress,

                photoIdType: data.photoIdType,
                photoIdTypeText: sendMoneyObject?.enumIDTypes.find(o => o.Value == data.photoIdType).Name,
                photoIdNumber: data.photoIdNumber,
                photoIdExpiryDate: data.photoIdExpiryYear + '-' + data.photoIdExpiryMonth + '-' + data.photoIdExpiryDay,
                photoIdIssuingCountry: data.photoIdIssuingCountry,
                photoIdIssuingEntity: data.photoIdIssuingEntity,
                photoIdIssuingState: data.photoIdIssuingState,
                photoIdIssuingDate: data.photoIdIssuingDate,
            }
            url = process.env.REACT_APP_PARTNER_MODULE_BASE_URL + '/api/ria/customers/' + sSender().id + "/updatePhotoIdInfo"
        } else {
            mPayload =
            {
                sendingCountry: "SL",
                typeID: "10",
                status: "Active",
                operationalCountry: "SL",
    
                firstName: data.firstName,
                middleName: data.middleName,
                firstLastName: data.firstLastName,
                secondLastName: data.secondLastName,
                gender: data.gender,
                occupationId: data.occupation,
                occupationText: data.occupationOtherText != undefined ? data.occupationOtherText : sendMoneyObject?.enumOccupations.find(o => o.Value == data.occupation).Name,
                
                dateOfBirth: data.birthYear + '-' + data.birthMonth + '-' + data.birthDay,
                countryOfBirth: data.countryOfBirth,
    
                photoIdType: data.photoIdType,
                photoIdTypeText: sendMoneyObject?.enumIDTypes.find(o => o.Value == data.photoIdType).Name,
                photoIdNumber: data.photoIdNumber,
                photoIdExpiryDate: data.photoIdExpiryYear + '-' + data.photoIdExpiryMonth + '-' + data.photoIdExpiryDay,
                photoIdIssuingCountry: data.photoIdIssuingCountry,
                photoIdIssuingEntity: data.photoIdIssuingEntity,
                photoIdIssuingState: data.photoIdIssuingState,
                photoIdIssuingDate: data.photoIdIssuingDate,
    
                country: data.country,
                state: data.state,
                city: data.city,
                address: data.address,
                countryCallingCode: phoneCode,
                subscriptionNumber: data.phoneNo,
                emailAddress: data.emailAddress,
    
                customerCareEmailNotification: data.customerCareEmailNotification,
                customerCareSMSNotification: data.customerCareSMSNotification,
                marketingSMSNotification: data.marketingSMSNotification,
                marketingEmailNotification: data.marketingEmailNotification,

                maritalStatusId: data.maritalStatusId,
                stateOfBirth: data.stateOfBirth,
                cityOfBirth: data.cityOfBirth,
                nationality: data.nationality,
                countryOfResidence: data.countryOfResidence,
                businessSectorId: data.businessSectorId,
                zipCode: data.zipCode,
                incomeAmount: data.incomeAmount,
                incomeCurrency: data.incomeCurrency,
                incomeFrequencyId: data.incomeFrequencyId,
                incomePaymentMethodId: data.incomePaymentMethodId,
                taxId: data.taxId,
                taxCountry: data.taxCountry,
                fatherName: data.fatherName,
                motherName: data.motherName,
                employerName: data.employerName,
            }
            url = editSender ? process.env.REACT_APP_PARTNER_MODULE_BASE_URL + '/api/ria/updateConsumerLookup/' + sSender().id  : process.env.REACT_APP_PARTNER_MODULE_BASE_URL + '/api/ria/createConsumerLookup'
        }

        dispatch(showLoading());

        axios({
            method: "post",
            url: url,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            },
            data: mPayload,
        })
            .then((res) => {
                console.log(res)
                dispatch(hideLoading());
                if(res.data.status == 'SUCCESS') {
                    let sender = {}

                    if(showViewModal) {
                        let sConsumerIndex = sendMoneyObject.consumers.findIndex(c => c.id === sendMoneyObject.selectedConsumerId)
                        setSendMoneyObject(prev => {
                            prev.consumers[sConsumerIndex].photoIdType = mPayload.photoIdType
                            prev.consumers[sConsumerIndex].photoIdTypeText = mPayload.photoIdTypeText
                            prev.consumers[sConsumerIndex].photoIdNumber = mPayload.photoIdNumber
                            prev.consumers[sConsumerIndex].photoIdExpirationDate = mPayload.photoIdExpiryDate
                            prev.consumers[sConsumerIndex].photoIdExpiryDay = mPayload.photoIdExpiryDate.substring(8, 10)
                            prev.consumers[sConsumerIndex].photoIdExpiryMonth = mPayload.photoIdExpiryDate.substring(5, 7)
                            prev.consumers[sConsumerIndex].photoIdExpiryYear = mPayload.photoIdExpiryDate.substring(0, 4)
                            prev.consumers[sConsumerIndex].photoIdIssuingCountry = mPayload.photoIdIssuingCountry
                            prev.consumers[sConsumerIndex].photoIdIssuingEntity = mPayload.photoIdIssuingEntity
                            prev.consumers[sConsumerIndex].photoIdIssuingState = mPayload.photoIdIssuingState
                            prev.consumers[sConsumerIndex].photoIdIssuingDate = mPayload.photoIdIssuingDate
                            prev.previousStep = 2
                            prev.step = 3
                        })
                        
                    } else {
                        let sConsumerIndex = sendMoneyObject.consumers.findIndex(c => c.id === sendMoneyObject.selectedConsumerId)
                        
                        // if()
                        // sender.id = res.data.data.id
                        sender.status = "Active"
                        sender.typeID = mPayload.typeID
                        sender.firstName = mPayload.firstName
                        sender.middleName = mPayload.middleName
                        sender.firstLastName = mPayload.firstLastName
                        sender.secondLastName = mPayload.secondLastName
                        sender.dateOfBirth = mPayload.dateOfBirth
                        sender.countryOfBirth = mPayload.countryOfBirth
                        sender.gender = mPayload.gender
                        sender.occupationId = mPayload.occupationId
                        sender.occupationText = mPayload.occupationText
                        sender.address = mPayload.address
                        sender.city = mPayload.city
                        sender.country = mPayload.country
                        sender.state = mPayload.state
                        sender.zipCode = mPayload.zipCode
                        sender.customerCareEmailNotification = mPayload.customerCareEmailNotification
                        sender.customerCareSMSNotification = mPayload.customerCareSMSNotification
                        sender.marketingEmailNotification = mPayload.marketingEmailNotification
                        sender.marketingSMSNotification = mPayload.marketingSMSNotification
                        sender.emailAddress = mPayload.emailAddress
                        sender.phoneNo = mPayload.subscriptionNumber
                        sender.countryPhoneCode = mPayload.countryCallingCode
                        sender.photoIdType = mPayload.photoIdType
                        sender.photoIdTypeText = mPayload.photoIdTypeText
                        sender.photoIdNumber = mPayload.photoIdNumber
                        sender.photoIdExpirationDate = mPayload.photoIdExpiryDate
                        sender.photoIdExpiryDay = mPayload.photoIdExpiryDate.substring(8, 10)
                        sender.photoIdExpiryMonth = mPayload.photoIdExpiryDate.substring(5, 7)
                        sender.photoIdExpiryYear = mPayload.photoIdExpiryDate.substring(0, 4)
                        sender.photoIdIssuingCountry = mPayload.photoIdIssuingCountry
                        sender.photoIdIssuingEntity = data.photoIdIssuingEntity
                        sender.photoIdIssuingState = data.photoIdIssuingState
                        sender.photoIdIssuingDate = data.photoIdIssuingDate
                        sender.maritalStatus = data.maritalStatus
                        sender.stateOfBirth = data.stateOfBirth
                        sender.cityOfBirth = data.cityOfBirth
                        sender.nationality = data.nationality
                        sender.countryOfResidence = data.countryOfResidence
                        sender.businessSectorId = data.businessSectorId
                        sender.zipCode = data.zipCode
                        sender.incomeAmount = data.incomeAmount
                        sender.incomeCurrency = data.incomeCurrency
                        sender.incomeFrequencyId = data.incomeFrequencyId
                        sender.incomePaymentMethodId = data.incomePaymentMethodId
                        sender.taxId = data.taxId
                        sender.taxCountry = data.taxCountry
                        sender.fatherName = data.fatherName
                        sender.motherName = data.motherName
                        sender.employerName = data.employerName
    
                        if(editSender) {
                            setSendMoneyObject(prev => {
                                sender.id = sendMoneyObject.selectedConsumerId
                                prev.consumers[sConsumerIndex] = sender
                                prev.previousStep = 2
                                prev.step = 3
                            })
                        } else {
                            setSendMoneyObject(prev => {
                                sender.id = res.data.data.id
                                sender.receivers = []
                                prev.consumers.push(sender)
                                prev.selectedConsumerId = sender.id
                                prev.previousStep = 2
                                prev.step = 3
                            })
                        }
                    }
                }
                else {
                    dispatch(showToast(["danger", "Error", res.data.message]))
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(hideLoading());
                dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
            });

    }

    const onCountryChange = event => {
        console.log(event.target.value);
        let countri = codeTable.countries_info.findIndex((a) => a.country_code === event.target.value);
        console.log(codeTable.countries_info[countri]);
        setCountryCode(event.target.value);
        setPhoneCode(codeTable.countries_info[countri].phone_code);
        getStatesOfCountry()

    }

    function getStatesOfCountry(element, theDefault) {
        var dnd = document.querySelector(`#${element}`);
        if(dnd != null) {
            console.log("getStatesOfCountry", dnd.selectedIndex)
            console.log("getStatesOfCountry", codeTable.countries_info[dnd.selectedIndex].states)
            return codeTable.countries_info[dnd.selectedIndex].states
        } else if(theDefault !== undefined) {
            return codeTable.countries_info.find(c => c.country_code == theDefault).states
        } else return []
    }

    const onModalPrevious = (event) => {
        event.preventDefault();
        setFormStep(0);
    }

    async function findCustomerPhotoIdInfo(myCustomer) {
        dispatch(showLoading());
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/customers/" + myCustomer.id + "/photoIdInfo?sendingCountry=SL",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
                }
            })
            console.log(res)
            dispatch(hideLoading());
            if (res.data.status == "SUCCESS") 
            {
                let sCI = sendMoneyObject.consumers.findIndex(c => c.id === myCustomer.id)
                setSendMoneyObject(prev => {
                    prev.selectedConsumerId = myCustomer.id
                    prev.consumers[sCI].photoIdType = res.data.data.photoIdType
                    prev.consumers[sCI].photoIdTypeText = res.data.data.photoIdTypeText
                    prev.consumers[sCI].photoIdNumber = res.data.data.photoIdNumber
                    prev.consumers[sCI].photoIdExpirationDate = res.data.data.photoIdExpirationDate
                    prev.consumers[sCI].photoIdExpiryDay = res.data.data.photoIdExpirationDate.substring(8, 10)
                    prev.consumers[sCI].photoIdExpiryMonth = res.data.data.photoIdExpirationDate.substring(5, 7)
                    prev.consumers[sCI].photoIdExpiryYear = res.data.data.photoIdExpirationDate.substring(0, 4)
                    prev.consumers[sCI].photoIdIssuingCountry = res.data.data.photoIdIssuingCountry
                })

            }
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
        }

    }

    const onViewDetail = async (event, consumer) => {
        event.stopPropagation()
        setFormStep(0)
        reset()
        SetChildKey((prev) => prev + 1);
        setSendMoneyObject(prev => {
            prev.selectedConsumerId = consumer.id
        })
        await findCustomerPhotoIdInfo(consumer)
        // setShowSenderModal(true)
        // setShowViewModal(true)
        // seteditSender(false)

        seteditSender(true)
        setShowViewModal(false)
        setShowSenderModal(true)

    }

    const onViewModalClose = () => {
        setShowViewModal(false)
    }

    const onEditSender = (event) => {
        event.stopPropagation()
        console.log("onEditSender")
        seteditSender(true)
        setFormStep(0)
        reset()
        SetChildKey((prev) => prev + 1);
        setShowViewModal(false)
        setShowSenderModal(true)
    }

    const onShowOptionalFieldInfo = () => {
        setShowOptFieldInfo(val => !val)
    }

    const onShowOptionalFieldIds = () => {
        setShowOptFieldIds(val => !val)
    }

    const onPhotoIdIssuingCountry = (e) => {
        setTestval(prev => prev + 1)
    }

    const onOccupationChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text);
        setOccupationObject({
            value: event.target.value,
            text: event.target.options[event.target.selectedIndex].text
        })
    };


    return (
        <>
            <div style={{ maxWidth: "900px", margin: "auto" }}>

                <div className='row' style={{ maxWidth: "500px", margin: "auto", marginBottom: '15px' }}>
                    <button className='btn btn-secondary' style={{ maxWidth: '200px', marginLeft: 'auto', marginRight: '0' }} onClick={createSender}> {t("ADD_NEW_SENDER")} </button>
                </div>

                <div className='row' style={{ maxWidth: "500px", margin: "auto" }}>
                    <label htmlFor="searchText" className='marg-btm-5'> {t("SEARCH_SENDER_BY_PHONE_NUMBER")} </label>
                    <input type="text" id="searchText" className='form-control marg-btm-5' defaultValue={SEND_MONEY_OBJECT?.searchedPhoneNumber} onChange={e => setSearchText(e.target.value)} />
                    <button className='btn btn-primary' onClick={onSearch}> {t("SEARCH")} </button>
                </div>

                <div>
                    {
                        sendMoneyObject?.consumers != undefined && sendMoneyObject?.consumers?.length > 0 && <div>
                            <hr />
                            <h5>{t('SENDERS')}</h5>
                            <div className='row'>
                                {
                                    sendMoneyObject?.consumers?.map((consumer, key) => (
                                        <div key={key} id={key} className="card col-6 col-md-2 company consumer" style={{ margin: "25px", cursor: "pointer", padding: "6px" }} onClick={(e) => onViewDetail(e, consumer)} >
                                            <div className="card-body" style={{ padding: "2px" }}>

                                                <div className="recipient-legend">
                                                    <div className="recipient-legend-avatar">{getInitials(consumer)}</div>
                                                </div>
                                                <div className="recipient-content mt-2" style={{ textAlign: "center" }}>
                                                    <div style={{ fontSize: '14px' }}>{getSenderName(consumer)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                        </div>
                        
                    }
                </div>

            </div>

            {showSenderModal && <div key={childKey}>
                <form onSubmit={handleSubmit(onSenderSubmit)}>
                        <hr style={{ marginTop: "40px" }} />
                        <h5> Personal Informations </h5>
                    {
                        showViewModal ?
                            (
                                <>
                                    
                                    <div className="" >
                                        <div className="row" style={{ padding: "10px 1px" }}>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Country</span>
                                                <span className="col-md-8 value">{sSender()?.country}</span>
                                            </div>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">State</span>
                                                <span className="col-md-8 value">{sSender()?.state}</span>
                                            </div>
                                        </div>
                                        <div className="row odds" style={{ padding: "10px 1px" }}>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Address</span>
                                                <span className="col-md-8 value">{sSender()?.address}</span>
                                            </div>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">City</span>
                                                <span className="col-md-8 value">{sSender()?.city}</span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: "10px 1px" }}>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Phone No</span>
                                                <span className="col-md-8 value">{sSender()?.phoneNo}</span>
                                            </div>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Email</span>
                                                <span className="col-md-8 value">{sSender()?.emailAddress}</span>
                                            </div>
                                        </div>
                                        <div className="row odds" style={{ padding: "10px 1px" }}>
                                            
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Gender</span>
                                                <span className="col-md-8 value">{sSender()?.gender}</span>
                                            </div>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Occupation</span>
                                                <span className="col-md-8 value">{sSender()?.occupationId}</span>
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: "10px 1px" }}>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Date of Birth</span>
                                                <span className="col-md-8 value">{sSender()?.dateOfBirth}</span>
                                            </div>
                                            <div className="col-md-6 row">
                                                <span className="col-md-4 key">Country of Birth</span>
                                                <span className="col-md-8 value">{sSender()?.countryOfBirth}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "right", marginTop: "15px" }}>
                                        <button type='button' className="btn btn-primary" onClick={onEditSender}> {t("EDIT")}  </button> &nbsp;
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="firstName"> {t("FIRST_NAME")}/{t("MIDDLE_NAME")} </label> <span style={{ color: "red" }}>*</span>
                                            <div className="input-group">
                                                <input type="text" id="firstName" className='form-control' placeholder={t("FIRST_NAME")} defaultValue={sSender()?.firstName} {...register("firstName")} />
                                                <input type="text" id="middleName" className='form-control' placeholder={t("MIDDLE_NAME")} defaultValue={sSender()?.middleName} {...register("middleName")} />
                                            </div>
                                            {errors.firstName && <p className="input-error-msg"> {errors.firstName?.message} </p>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="firstName"> {t("LAST_NAME")}/{t("SECOND_LAST_NAME")} </label> <span style={{ color: "red" }}>*</span>
                                            <div className="input-group">
                                                <input type="text" id="firstLastName" className='form-control' placeholder={t("LAST_NAME")} defaultValue={sSender()?.firstLastName} {...register("firstLastName")} />
                                                <input type="text" id="secondLastName" className='form-control' placeholder={t("SECOND_LAST_NAME")} defaultValue={sSender()?.secondLastName} {...register("secondLastName")} />
                                            </div>
                                            {errors.firstName && <p className="input-error-msg"> {errors.firstName?.message} </p>}
                                        </div>
                                    </div>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="gender">{t("GENDER")}</label> <span style={{ color: "red" }}>*</span>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name="gender" id="gender" defaultValue={sSender()?.gender} {...register("gender")} >
                                                <option value="">{t("SELECT")}</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </select>
                                            <p className="input-error-msg"> {errors.gender?.message} </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="occupation">{t("OCCUPATION")}</label> <span style={{ color: "red" }}>*</span>
                                            <select style={{ maxWidth: "100%" }} className="form-select" defaultValue={sSender()?.occupationId} name="occupation" id="occupation" {...register("occupation")} onChange={onOccupationChange} >
                                                <option value="">{t("SELECT")}</option>
                                                {
                                                    sendMoneyObject?.enumOccupations?.map((occupation, key) => (
                                                        <option value={occupation.Value} key={key}>{occupation.Name}</option>
                                                    ))
                                                }
                                            </select>
                                            <p className="input-error-msg"> {errors.occupation?.message} </p>
                                            <>
                                                {(occupationObject.text != undefined && occupationObject.text == 'Other' || sSender()?.occupationOtherText == 'Other') && (
                                                    <>
                                                        <input type="text" id="occupationOtherText" placeholder={t("OCCUPATION_OTHER_TEXT")} name="occupationOtherText" className='form-control' defaultValue={sSender()?.occupationOtherText} {...register("occupationOtherText")} style={{ marginTop: "15px" }} />
                                                        <p className="input-error-msg">
                                                            {errors.occupationOtherText?.message}
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="address">{t("ADDRESS")}</label> <span style={{ color: "red" }}>*</span>
                                            <input type="text" id="address" className='form-control' defaultValue={sSender()?.address} {...register("address")} />
                                            <p className="input-error-msg"> {errors.address?.message} </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="city">{t("CITY")}</label> <span style={{ color: "red" }}>*</span>
                                            <input type="text" id="city" className='form-control' defaultValue={sSender()?.city} {...register("city")} />
                                            <p className="input-error-msg"> {errors.city?.message} </p>
                                        </div>
                                    </div>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="country"> {t("COUNTRY")} </label> <span style={{ color: "red" }}>*</span>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name="country" id="country" {...register("country")} onChange={(e) => onCountryChange(e)}
                                                defaultValue={sSender()?.country != undefined ? sSender()?.country : "SL"} >
                                                {codeTable?.countries_info?.map((country, key) => (
                                                    <option value={country.country_code} key={key}>{country.country_name}</option>
                                                ))}
                                            </select>
                                            <p className="input-error-msg"> {errors.country?.message} </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="state">{t("STATE")}</label> <span style={{ color: "red" }}>*</span>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name="state" id="state" {...register("state")}
                                                defaultValue={sSender()?.state} >
                                                    <option value="">Select</option>
                                                {getStatesOfCountry('country', 'SL')?.map((state, key) => (
                                                    <option value={state.code} key={key}>{state.name}</option>
                                                ))}
                                            </select>
                                            <p className="input-error-msg"> {errors.state?.message} </p>
                                        </div>
                                    </div>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="phoneNo">{t("PHONE_NUMBER")}</label> <span style={{ color: "red" }}>*</span>
                                            <div className="input-group col-8" style={{ width: "100%" }}>
                                                <span className="input-group-text" id="basic-addon1">
                                                    {phoneCode}
                                                </span>
                                                <input type="text" id="phoneNo" className='form-control' aria-describedby="basic-addon1" defaultValue={sSender()?.phoneNo} {...register("phoneNo")} />
                                            </div>
                                            <p className="input-error-msg"> {errors.phoneNo?.message} </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="emailAddress">{t("EMAIL")}</label>
                                            <input type="text" id="emailAddress" className='form-control' defaultValue={sSender()?.emailAddress} {...register("emailAddress")} />
                                            <p className="input-error-msg"> {errors.emailAddress?.message} </p>
                                        </div>
                                    </div>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="dateOfBirth">{t("DATE_OF_BIRTH")}</label> <span style={{ color: "red" }}>*</span>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="birthDay" id="birthDay" defaultValue={sSender()?.birthDay} {...register("birthDay")} >
                                                        <option value="">{t("DAY")}</option>
                                                        {DAYS?.map((day, index) => (
                                                            <option key={index} value={day.value}>
                                                                {day.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.birthDay?.message} </p>
                                                </div>
                                                <div className="col-md-5">
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="birthMonth" id="birthMonth" defaultValue={sSender()?.birthMonth} {...register("birthMonth")} >
                                                        <option value="">{t("MONTH")}</option>
                                                        {MONTHS?.map((month, index) => (
                                                            <option key={index} value={month.value}>
                                                                {month.labelEn}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.birthMonth?.message} </p>
                                                </div>
                                                <div className="col-md-4">
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="birthYear" id="birthYear" defaultValue={sSender()?.birthYear} {...register("birthYear")} >
                                                        <option value="">{t("YEAR")}</option>
                                                        {YEARS?.map((year, index) => (
                                                            <option key={index} value={year.value}>
                                                                {year.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.birthYear?.message} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="country"> {t("COUNTRY_OF_BIRTH")} </label> <span style={{ color: "red" }}>*</span>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name="country" id="country" {...register("countryOfBirth")} 
                                                defaultValue={sSender()?.state != undefined ? sSender()?.country : "SL"} >
                                                {codeTable?.countries_info?.map((country, key) => (
                                                    <option value={country.country_code} key={key}>{country.country_name}</option>
                                                ))}
                                            </select>
                                            <p className="input-error-msg"> {errors.country?.message} </p>
                                        </div>
                                    </div>
                                    <div className="row">

                                        {
                                            reqOptFieldInfo.map((field, ind) => {
                                                if (field.type == "string")
                                                    return (
                                                        <div className="col-md-6">
                                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label> <span style={{ color: "red" }}>*</span>
                                                            <input type="text" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                                        </div>
                                                    )
                                                else if (field.type == "date")
                                                    return (
                                                        <div className="col-md-6">
                                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label> <span style={{ color: "red" }}>*</span>
                                                            <input type="date" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                                        </div>
                                                    )
                                                else if (field.type == "enum")
                                                    return (
                                                        <div className="col-md-6">
                                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label> <span style={{ color: "red" }}>*</span>
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name={field.tag} id={field.tag} {...register(field.tag)}
                                                                defaultValue={sSender()?.[field.tag]} >
                                                                <option value=""></option>
                                                                {field.list?.map((item, key) => (
                                                                    <option value={item[field.value]} key={key}>{item[field.label]}</option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                                        </div>
                                                    )
                                            })
                                        }

                                    </div>
                                    
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <fieldset>
                                                <legend>{t("CUSTOMER_CARE_NOTIFICATION")}</legend>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={sSender()?.customerCareSMSNotification} id="customerCareSMSNotification" {...register("customerCareSMSNotification")} />
                                                    <label className="form-check-label" htmlFor="customerCareSMSNotification">  SMS </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={sSender()?.customerCareEmailNotification} id="customerCareEmailNotification" {...register("customerCareEmailNotification")} />
                                                    <label className="form-check-label" htmlFor="customerCareEmailNotification"> Email </label>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-6">
                                            <fieldset>
                                                <legend>{t("MARKETING_NOTIFICATION")}</legend>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={sSender()?.marketingSMSNotification} id="marketingSMSNotification" {...register("marketingSMSNotification")} />
                                                    <label className="form-check-label" htmlFor="marketingSMSNotification"> SMS </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={sSender()?.marketingEmailNotification} id="marketingEmailNotification" {...register("marketingEmailNotification")} />
                                                    <label className="form-check-label" htmlFor="marketingEmailNotification"> Email </label>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <p><button type="button" className="btn btn-link" onClick={onShowOptionalFieldInfo}>{ showOptFieldInfo ? t("HIDE_OPTIONAL_FIELDS") : t("SHOW_OPTIONAL_FIELDS")}</button></p>
                                    <div className="row">

                                        { showOptFieldInfo && (
                                            otherOptFieldInfo.map((field, ind) => {
                                                if (field.type == "string")
                                                    return (
                                                        <div className="col-md-6">
                                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label>
                                                            <input type="text" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                                        </div>
                                                    )
                                                else if (field.type == "date")
                                                    return (
                                                        <div className="col-md-6">
                                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label>
                                                            <input type="date" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                                        </div>
                                                    )
                                                else if (field.type == "enum")
                                                    return (
                                                        <div className="col-md-6">
                                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label>
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name={field.tag} id={field.tag} {...register(field.tag)}
                                                                defaultValue={sSender()?.[field.tag]} >
                                                                <option value=""></option>
                                                                {field.list?.map((item, key) => (
                                                                    <option value={item[field.value]} key={key}>{item[field.label]}</option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                                        </div>
                                                    )
                                            }))
                                        }

                                    </div>
                                    
                                </>

                            )
                    }
                    
                    <div className="row marg-btn">
                        <hr style={{ marginTop: "40px" }} />
                        <h5> Identification </h5>
                    </div>

                    <div className="row marg-btm">
                        <div className="col-md-6">
                            <label className="col-form-label" htmlFor="photoIdType">{t("photoIdType")}</label>
                            <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdType" id="photoIdType" defaultValue={sSender()?.photoIdType} {...register("photoIdType")} key={sSender()?.photoIdType} >
                                <option value="">{t("SELECT")}</option>
                                {sendMoneyObject?.enumIDTypes?.map((type, index) => (
                                    <option value={type.Value} key={index}>{type.Name}</option>
                                ))}
                            </select>
                            <p className="input-error-msg"> {errors.photoIdType?.message} </p>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label" htmlFor="photoIdNumber">{t("photoIdNumber")}</label> <span style={{ color: "red" }}>*</span>
                            <input type="text" id="photoIdNumber" name="photoIdNumber" className='form-control' defaultValue={sSender()?.photoIdNumber} {...register("photoIdNumber")} />
                            <p className="input-error-msg"> {errors.photoIdNumber?.message} </p>
                        </div>
                    </div>
                    <div className="row marg-btm">
                        <div className="col-md-6">
                            <label className="col-form-label" htmlFor="photoIdExpiryDay">{t("PHOTO_ID_EXPIRATION_DATE")}</label> <span style={{ color: "red" }}>*</span>
                            <div className="row">
                                <div className="col-md-3">
                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryDay" id="photoIdExpiryDay" defaultValue={sSender()?.photoIdExpiryDay} {...register("photoIdExpiryDay")} >
                                        <option value="">{t("DAY")}</option>
                                        {DAYS?.map((month, index) => (
                                            <option key={index} value={month.value}>
                                                {month.label}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="input-error-msg"> {errors.photoIdExpiryDay?.message} </p>
                                </div>
                                <div className="col-md-5">
                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryMonth" id="photoIdExpiryMonth" defaultValue={sSender()?.photoIdExpiryMonth} {...register("photoIdExpiryMonth")} >
                                        <option value="">{t("MONTH")}</option>
                                        {MONTHS?.map((month, index) => (
                                            <option key={index} value={month.value}>
                                                {month.labelEn}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="input-error-msg"> {errors.photoIdExpiryMonth?.message} </p>
                                </div>
                                <div className="col-md-4">
                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryYear" id="photoIdExpiryYear" key={sSender()?.photoIdExpiryYear} defaultValue={sSender()?.photoIdExpiryYear} {...register("photoIdExpiryYear")} >
                                        <option value="">{t("YEAR")}</option>
                                        {YEARS_EXPIRY?.map((year, index) => (
                                            <option key={index} value={year.value}>
                                                {year.label}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="input-error-msg"> {errors.photoIdExpiryYear?.message} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label" htmlFor="photoIdIssuingCountry"> {t("ISSUING_COUNTRY")} </label> <span style={{ color: "red" }}>*</span>
                            <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdIssuingCountry" id="photoIdIssuingCountry" {...register("photoIdIssuingCountry")} defaultValue={sSender()?.photoIdIssuingCountry != undefined ? sSender()?.photoIdIssuingCountry : "SL"} onChange={onPhotoIdIssuingCountry} >
                                {codeTable?.countries_info?.map((country, key) => (
                                    <option value={country.country_code} key={key}>{country.country_name}</option>
                                ))}
                            </select>
                            <p className="input-error-msg"> {errors.photoIdIssuingCountry?.message} </p>
                        </div>
                    </div>
                    <div className="row">

                        {
                            reqOptFieldIds.map((field, ind) => {
                                if (field.type == "string")
                                    return (
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label> <span style={{ color: "red" }}>*</span>
                                            <input type="text" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                        </div>
                                    )
                                else if (field.type == "date")
                                    return (
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label> <span style={{ color: "red" }}>*</span>
                                            <input type="date" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                        </div>
                                    )
                                else if (field.type == "enum")
                                    return (
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label> <span style={{ color: "red" }}>*</span>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name={field.tag} id={field.tag} {...register(field.tag)}
                                                defaultValue={sSender()?.[field.tag]} >
                                                <option value=""></option>
                                                {field.list?.map((item, key) => (
                                                    <option value={item[field.value]} key={key}>{item[field.label]}</option>
                                                ))}
                                            </select>
                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                        </div>
                                    )
                            })
                        }

                    </div>
                    <div className="row">
                        <p><button type="button" className="btn btn-link" onClick={onShowOptionalFieldIds}>{showOptFieldIds ? t("HIDE_OPTIONAL_FIELDS") : t("SHOW_OPTIONAL_FIELDS")}</button></p>

                        {showOptFieldIds && (
                            otherOptFieldIds.map((field, ind) => {
                                if (field.type == "string")
                                    return (
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label>
                                            <input type="text" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                        </div>
                                    )
                                else if (field.type == "date")
                                    return (
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label>
                                            <input type="date" id={field.tag} className='form-control' defaultValue={sSender()?.[field.tag]} {...register(field.tag)} />
                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                        </div>
                                    )
                                else if (field.type == "enum")
                                    return (
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor={field.tag}>{t(field.tag)}</label>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name={field.tag} id={field.tag} {...register(field.tag)}
                                                defaultValue={sSender()?.[field.tag]} >
                                                <option value=""></option>
                                                {getStatesOfCountry('photoIdIssuingCountry')?.map((item, key) => (
                                                    <option value={item[field.value]} key={key}>{item[field.label]}</option>
                                                ))}
                                            </select>
                                            <p className="input-error-msg">  {errors[field.tag]?.message} </p>
                                        </div>
                                    )
                            }))
                        }

                    </div>

                    <div className="row marg-btm">
                        <hr style={{ marginTop: "20px" }} />
                    </div>

                    <div className='row marg-btm' style={{ padding: "2px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button type='button' className='btn btn-secondary' onClick={back}> {t('BACK')} </button> 
                            <button type='submit' className="btn btn-primary"> {editSender ? t("UPDATE") : t("SAVE")} </button>
                        </div>
                    </div>


                </form>
            </div>
            }

            {
                showSenderModal == false &&
                <>
                    <hr />
                    <div style={{ textAlign: "left" }}>
                        <button type='button' className='btn btn-secondary' onClick={back}> {t('BACK')} </button> &nbsp; &nbsp;
                    </div>
                </>
            }
            

            


        </>
    );

    function useStateCallback(initialState) {
        const [state, setState] = useImmer(initialState);
        const cbRef = useRef(null);
      
        const setStateCallback = useCallback((state, cb) => {
          cbRef.current = cb; 
          setState(state);
        }, []);
      
        useEffect(() => {
          if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
          }
        }, [state]);
      
        return [state, setStateCallback];
      }

}

export default RiaSender