import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setReceiveData, setStep, setResetReceive } from '../../../slices/send-money-moneygram-slice';
import { getFieldsByCategory, getPhoneCode, getReceiverName } from './moneygram';
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function MoneygramReceiver() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const fields = useSelector((state) => state.sendMoneyMoneygram.fields);
    const codeTable = useSelector((state) => state.sendMoneyMoneygram.codeTable);
    const [categories, setCategories] = useState(['Destination Information', 'Partner Configuration', 'Notification']);
    const [countries, setCountries] = useState([]);
    const receiverScheme = useSelector((state) => state.sendMoneyMoneygram.receiverSchema);
    const [schema, setSchema] = useState(yup.object().shape(receiverScheme));
    const formRef = useRef();
    const feeInfo = useSelector((state) => state.sendMoneyMoneygram.feeInfo);
    const [showForm, setShowForm] = useState(false);
    const resetReceive = useSelector((state) => state.sendMoneyMoneygram.resetReceive);
    const [updateReceiver, setUpdateReceiver] = useState(false);
    const [receiverSelected, setReceiverSelected] = useState(false);
    const [displayConfig, setDisplayConfig] = useState(false);
    const [receiverFields, setReceiverFields] = useState([]);
    const [receiver, setReceiver] = useState(null);
    const tableFields = ['receiverFirstName', "receiverMiddleName", "receiverLastName", "receiverLastName2"];
    const receivers = useSelector((state) => state.sendMoneyMoneygram.receivers);
    const cities = useSelector((state) => state.sendMoneyMoneygram.cities);
    const [direction1s, setDirection1s] = useState([]);

    useEffect(() => {
        console.log('MoneygramReceiver')
        console.log('fields')
        //console.log(fields)

        var countries = codeTable?.country_info;
        setCountries(countries ?? []);

        setSchema(yup.object().shape(receiverScheme))

        setDisplayConfig(feeInfo?.receiveAgentID != null && feeInfo?.receiveAgentID !== '' && getFieldsByCategory(fields, categories[1])?.length != 0);

        updateReceiverFields()

    }, [fields, receiverScheme, codeTable, resetReceive, feeInfo]);

    const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    /*const includeReceiverCity = () => {
        var rCity = fields?.filter(f => f.receiverCity != undefined && f.receiverCity !== '')
    }*/

    const back = () => {
        console.log('back');
        dispatch(setStep(2));
    }

    const next = () => {
        console.log('next')
        console.log('errors', errors)
        if (Object.keys(errors).length === 0 && errors.constructor === Object) {
            dispatch(setStep(4));

            const formData = new FormData(formRef.current);
            const data = Object.fromEntries(formData);
            if (data?.direction1 != undefined && data?.direction1 !== '') {
                var direction1 = direction1s[data?.direction1];
                data['direction1'] = direction1?.address
            }
            console.log('formData', data);
            dispatch(setReceiveData(data));
        }
    }

    const newReceiver = () => {
        const allWithClass = Array.from(
            document.getElementsByClassName('receiver')
        );
        allWithClass.forEach(element => {
            element.classList.remove('sCompany')
        });
        var newFields = {}
        fields?.forEach(field => {
            newFields[field.xmlTag] = ''
        });
        reset(newFields)

        setReceiverSelected(false)
        dispatch(setResetReceive(false));
        setShowForm(true)
        setUpdateReceiver(false)
    }

    const onReceiverSelect = (e, receiver) => {
        console.log(receiver.receiverFirstName);
        setDirection1s([]);

        const allWithClass = Array.from(
            e.currentTarget.parentElement.getElementsByClassName('card')
        );
        allWithClass.forEach(element => {
            element.classList.remove('sCompany')
        });
        e.currentTarget.classList.add('sCompany')

        var newFields = {}
        fields?.forEach(field => {
            newFields[field.xmlTag] = receiver[field.xmlTag] || ''
        });
        reset(newFields)

        dispatch(setResetReceive(false));
        setShowForm(true)
        setUpdateReceiver(true)
        setReceiverSelected(true)
        setReceiver(receiver)
    }

    const editReceiver = () => {
        setReceiverSelected(false)
    }

    const getHiddenField = (field) => {
        var params = {
            ...register(field.xmlTag)
        }

        return <input type="hidden" name={field.xmlTag} id={field.xmlTag} {...params} />
    }

    const updateReceiverFields = () => {
        console.log('updateReceiverFields')
        setReceiverFields(getFieldsByCategory(fields, categories[0]).sort((f1, f2) => f1.displayOrder - f2.displayOrder))
    }

    const getField = (field) => {
        var result = '';
        var isRequired = (field.visibility === 'REQ');
        var label = <label htmlFor={field.xmlTag}>{field.fieldLabel}
            {
                isRequired && <span style={{ color: "red" }}> *</span>
            }
        </label>
        var error = <p className='input-error-msg'> {errors[field.xmlTag]?.message} </p>
        var params = {
            ...register(field.xmlTag, {
                onChange: (e) => onChangeField(field, e.target.value)
            })
        }

        if (field.enumerated == true || field.dataType === 'cntrycode') {
            result = <div className="form-group">
                {label}
                {(() => {
                    if (field.enumerated == true) {
                        var options = field.enumeratedValues.enumeratedValueInfo;
                        return <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                options.map((enumVal, key) => (
                                    <option value={enumVal.value}>{enumVal.label}</option>
                                ))
                            }
                        </select>;
                    } else if (field.dataType === 'cntrycode') {
                        return <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                countries.map((country, key) => (
                                    <option value={country.country_code}>{country.country_name}</option>
                                ))
                            }
                        </select>;
                    }
                })()}
                {error}
            </div>
        } else {
            if (field.dataType === 'string') {
                if (field.xmlTag == 'receiverPhone') {
                    result = <div className="form-group">
                        {label}
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" style={{ height: "100%", marginLeft: "-4px" }} id={field.xmlTag}>+{getPhoneCode(codeTable, feeInfo?.receiveCountry)}</span>
                            </div>
                            <input type="text" name={field.xmlTag} className={`form-control ${errors[field.xmlTag] ? 'is-invalid' : ''}`} {...params} />
                        </div>
                        {error}
                    </div>
                } else if (field.xmlTag == 'receiverCity') {
                    result = <div className="form-group">
                        {label}
                        <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                cities.map((city, key) => (
                                    <option value={city}>{city}</option>
                                ))
                            }
                        </select>
                        {error}
                    </div>
                } else if (field.xmlTag == 'direction1') {
                    result = <div className="form-group">
                        {label}
                        <select className={`form-select ${errors[field.xmlTag] ? 'is-invalid' : ''}`} type="dropdown" name={field.xmlTag} id={field.xmlTag} {...params}>
                            <option value="">{t('SELECT')}</option>
                            {
                                direction1s.map((dir, key) => (
                                    <option key={key} value={key}>{dir.agentName + ' - ' + dir.address} {(dir?.agentPhone) ? '(' + dir?.agentPhone + ')' : ''}</option>
                                ))
                            }
                        </select>
                        {error}
                    </div>
                } else {
                    result = <div className="form-group">
                        {label}
                        <input type="text" name={field.xmlTag} className={`form-control ${errors[field.xmlTag] ? 'is-invalid' : ''}`} {...params} />
                        {error}
                    </div>
                }
            } else if (field.dataType === 'int' || field.dataType === 'decimal') {
                result = <div className="form-group">
                    {label}
                    <input type="number" name={field.xmlTag} className='form-control' {...params} />
                    {error}
                </div>
            } else if (field.dataType === 'date') {
                result = <div className="form-group">
                    {label}
                    <input type="date" name={field.xmlTag} className='form-control' {...params} />
                    {error}
                </div>
            } else if (field.dataType === 'boolean') {
                result = <div className="form-group">
                    <input type="checkbox" id={field.xmlTag} name={field.xmlTag} className="form-check-input" {...params} /><br />
                    {label}
                    {error}
                </div>
            }
        }

        return result;
    }

    const onChangeField = (field, value) => {
        console.log('onChangeField')
        console.log('field.xmlTag', field.xmlTag)
        console.log('value', value)

        var notifications = ['senderTransactionEmailNotificationOptIn', 'senderTransactionSMSNotificationOptIn', 'senderMarketingEmailNotificationOptIn',
            'senderMarketingSMSNotificationOptIn', 'receiverTransactionEmailNotificationOptIn', 'receiverTransactionSMSNotificationOptIn'];
        if (!notifications.includes(field.xmlTag)) {
            setValue(field.xmlTag, value)

            if (field.xmlTag == 'receiverCity') {
                reset({
                    direction1: '',
                    direction2: '',
                    direction3: '',
                    receiverCity: value
                })
                directoryOfAgentsByCity(value)
            } else if (field.xmlTag == 'direction1') {
                //var direction1 = direction1s?.find(({ address }) => address === value);
                var direction1 = direction1s[value];
                reset({
                    direction2: direction1?.city,
                    direction3: direction1?.state,
                })
            }
        }
    }

    const getInitials = (receiver) => {
        var name = receiver.receiverFirstName.charAt(0);
        name += receiver.receiverLastName.charAt(0);
        return name
    }

    const directoryOfAgentsByCity = (city) => {
        setDirection1s([]);

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/directoryOfAgentsByCity', {
            country: feeInfo?.receiveCountry,
            city: city,
            language: i18next.language
        }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);

            var status = res.data.status
            var items = [];
            if (status == 1) {
                var agentInfo = res.data.data.agentInfo ?? []
                if (Array.isArray(agentInfo)) {
                    items = agentInfo
                } else {
                    items = [agentInfo]
                }
            }
            setDirection1s(items);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit(next)} ref={formRef}>
                <div style={{ textAlign: "right" }}>
                    <button type='button' className='btn btn-primary' onClick={newReceiver}>{t('NEW_RECEIVER')}</button>
                </div>

                <br />
                {
                    receivers.length != 0 && <div>
                        <hr />
                        <p>{t('RECEIVERS')}</p>
                        <div className='row'>
                            {
                                receivers.map((receiver, key) => (
                                    <div key={key} id={key} className="card col-6 col-md-2 company receiver" style={{ margin: "25px", cursor: "pointer" }} onClick={(e) => onReceiverSelect(e, receiver)} >
                                        <div className="card-body">
                                            <div className="recipient-legend">
                                                <div className="recipient-legend-avatar">{getInitials(receiver)}</div>
                                            </div>
                                            <div className="recipient-content mt-2" style={{ textAlign: "center" }}>
                                                <div style={{ fontSize: '14px' }}>{getReceiverName(receiver)}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                }
                <br />

                <div className='row'>
                    {
                        showForm && !resetReceive &&
                        <div>
                            <fieldset className="border p-3 mt-4">
                                <legend className="float-none w-auto">{t('DESTINATION_INFORMATION')}</legend>
                                <div>
                                    {
                                        updateReceiver &&
                                        <div style={{ textAlign: "right" }}>
                                            <button type='button' className='btn btn-primary mb-3' onClick={editReceiver}>{t('UPDATE_RECEIVER')}</button>
                                        </div>
                                    }

                                    {receiverSelected &&
                                        <div className="row">
                                            <div className='col-sm-9'>
                                                <table className="table table-sm table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "40%" }}></th>
                                                            <th style={{ width: "60%" }}></th>
                                                        </tr>
                                                        {
                                                            receiverFields.filter(f => (receiver[f.xmlTag] != null && receiver[f.xmlTag] != '') || tableFields.includes(f.xmlTag)).map((field, key) => (
                                                                <tr key={key} style={{ height: "38px", }}>
                                                                    <td>{field.fieldLabel}
                                                                        {getHiddenField(field)}:
                                                                    </td>
                                                                    <td>
                                                                        <strong>{receiver[field.xmlTag]}</strong>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                receiverFields.filter(f => (receiver[f.xmlTag] == null || receiver[f.xmlTag] == '') && !tableFields.includes(f.xmlTag)).map((field, key) => (
                                                    <div key={key} className="form-group col-sm-6" style={{ marginBottom: "25px" }}>
                                                        {getField(field)}
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    }
                                    {!receiverSelected &&
                                        <div className='row'>
                                            {
                                                getFieldsByCategory(fields, categories[0]).sort((f1, f2) => f1.displayOrder - f2.displayOrder).map((field, key) => (
                                                    <div key={key} className="form-group col-sm-6" style={{ marginBottom: "25px" }}>
                                                        {getField(field)}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </fieldset>
                            {
                                displayConfig &&
                                <fieldset className="border p-3 mt-4">
                                    <legend className="float-none w-auto">{t('PARTNER_CONFIGURATION')}</legend>
                                    <div className='row'>
                                        {
                                            getFieldsByCategory(fields, categories[1]).sort((f1, f2) => f1.displayOrder - f2.displayOrder).map((field, key) => (
                                                <div key={key} className="form-group col-sm-6" style={{ marginBottom: "25px" }}>
                                                    {getField(field)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </fieldset>
                            }
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#my-modal">Notification</button>
                            </div>
                            <div className="modal fade" id="my-modal" tabIndex="-1">
                                <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Notification</h5>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                <div className='row'>
                                                    {
                                                        getFieldsByCategory(fields, categories[2]).sort((f1, f2) => f1.displayOrder - f2.displayOrder).map((field, key) => (
                                                            <div className="form-group col-sm-6" style={{ marginBottom: "25px" }}>
                                                                {getField(field)}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('CLOSE')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <br />
                <div style={{ textAlign: "right" }}>
                    <button type='button' className='btn btn-info' onClick={back}>
                        <FaArrowCircleLeft size={18} color={'white'} /> {t('BACK')}
                    </button> &nbsp; &nbsp;
                    <button type='submit' className='btn btn-primary'>
                        {t('NEXT')} <FaArrowCircleRight size={18} fill={'white'} />
                    </button>
                </div>
            </form>
        </div>
    );

}

export default MoneygramReceiver