import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/index';
import $ from 'jquery';
import Popper from 'popper.js';
import App from './App';
import { AuthProvider } from 'react-auth-kit';
import './i18nextConf';
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <AuthProvider
        authType = {'cookie'}
        authName = {'_auth'}
        cookieDomain = {window.location.hostname}
      >
        {/* <BrowserRouter> */}
        <App />
        {/* </BrowserRouter> */}
      </AuthProvider>
    </Provider>
  // </React.StrictMode>
);