import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  globalAuthUser: null,
  globalSearchPickupCodeResult: null,
  globalMGrefenceNoResp: null,
  globalMGreceiveCS: 1,
  MG_GFFP_USED_OPT: [],
  MG_GFFP_NOT_USED_OPT: [],
  RECEIVER_INFO: null,
  RECEIVER_COMPLIANCE: null,
  RECEIVER_OPT_FIELDS: {},
  REF_NO_RESP: null,
  GFFP_RESP: null,
  COUNTRIES: [],
  CODETABLE: null,
  showLoading: false,
  RECEIVER_OPT_INFO: null,
  RECEIVER_OPT_COMPLIANCE: null,
  LANGUAGE: "en",
  API_SOMETHING_WENT_WRONG: "",
  FORM_FREE_STAGING: false,
  FORM_FREE_COMPLETION: false,
  AGENT_TRANSACTION_ID: null,
  FORM_FREE_RECEIVE_LOOKUP_RESP: {},
  PAYOUT: {},
  expiresIn: 10,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    setGlobalAuthUser(state, action) {
      state.globalAuthUser = action.payload;
      console.log('state.globalAuthUser');
      console.log(state.globalAuthUser);
    },
    setGlobalSearchPickupCodeResult(state, action) {
      state.globalSearchPickupCodeResult = action.payload;
    },
    setGlobalMGrefenceNoResp(state, action) {
      state.globalMGrefenceNoResp = action.payload;
    },
    setGlobalMGreceiveCS(state, action) {
      state.globalMGreceiveCS = action.payload;
    },
    set_MG_GFFP_USED_OPT(state, action) {
      state.MG_GFFP_USED_OPT = action.payload;
    },
    set_MG_GFFP_NOT_USED_OPT(state, action) {
      state.MG_GFFP_NOT_USED_OPT = action.payload;
    },
    set_RECEIVER_INFO(state, action) {
      state.RECEIVER_INFO = action.payload;
    },
    set_RECEIVER_COMPLIANCE(state, action) {
      state.RECEIVER_COMPLIANCE = action.payload;
    },
    set_RECEIVER_OPT_FIELDS(state, action) {
      state.RECEIVER_OPT_FIELDS = action.payload;
    },
    set_REF_NO_RESP(state, action) {
      state.REF_NO_RESP = action.payload;
    },
    set_GFFP_RESP(state, action) {
      state.GFFP_RESP = action.payload;
    },
    set_COUNTRIES(state, action) {
      state.COUNTRIES = action.payload;
    },
    setShowLoading(state, action) {
      state.showLoading = action.payload;
    },
    showToast(state, action) { },
    set_RECEIVER_OPT_INFO(state, action) {
      state.RECEIVER_OPT_INFO = action.payload;
    },
    set_RECEIVER_OPT_COMPLIANCE(state, action) {
      state.RECEIVER_OPT_COMPLIANCE = action.payload;
    },
    set_LANGUAGE(state, action) {
      state.LANGUAGE = action.payload;
    },
    set_CODETABLE(state, action) {
      state.CODETABLE = action.payload;
    },
    set_FORM_FREE_STAGING(state, action) {
      state.FORM_FREE_STAGING = action.payload;
    },

    set_FORM_FREE_COMPLETION(state, action) {
      state.FORM_FREE_COMPLETION = action.payload;
    },
    set_AGENT_TRANSACTION_ID(state, action) {
      state.AGENT_TRANSACTION_ID = action.payload;
    },
    set_FORM_FREE_RECEIVE_LOOKUP_RESP(state, action) {
      state.FORM_FREE_RECEIVE_LOOKUP_RESP = action.payload;
    },
    set_PAYOUT(state, action) {
      state.PAYOUT = action.payload;
    },
    setExpiresIn(state, action) {
      state.expiresIn = action.payload;
    }
  },
});

export const {
  login,
  logout,
  setGlobalAuthUser,
  setGlobalSearchPickupCodeResult,
  setGlobalMGrefenceNoResp,
  setGlobalMGreceiveCS,
  set_MG_GFFP_USED_OPT,
  set_RECEIVER_INFO,
  set_RECEIVER_COMPLIANCE,
  set_RECEIVER_OPT_FIELDS,
  set_REF_NO_RESP,
  set_GFFP_RESP,
  set_COUNTRIES,
  setShowLoading,
  set_RECEIVER_OPT_INFO,
  set_RECEIVER_OPT_COMPLIANCE,
  set_LANGUAGE,
  set_CODETABLE,
  set_FORM_FREE_STAGING,
  set_FORM_FREE_COMPLETION,
  set_AGENT_TRANSACTION_ID,
  set_FORM_FREE_RECEIVE_LOOKUP_RESP,
  set_PAYOUT,
  setExpiresIn
} = authSlice.actions;

export default authSlice;
