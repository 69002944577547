import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// const fallbackLng = ['en'];
// const availableLanguages = ['en', 'ar', 'fr'];

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector) // detect user language
  .use(HttpApi) // load translations using http (default   
  .init({
    supportedLngs: ['en', 'fr'],
    fallbackLng: 'en', // fallback language is english.

    detection: {
    //   checkWhitelist: true, // options for language detection
        order: ['cookie', 'htmlTag'],
        caches: ['cookie'],
    },
    backend: {
        loadPath: '/assets/locals/{{lng}}/translation.json',
    },
    react: { useSuspsense: false},

    // debug: false,

    // whitelist: availableLanguages,

    // interpolation: {
    //   escapeValue: false, // no need for react. it escapes by default
    // },
  });

export default i18n;