import React, { useState, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useParams } from "react-router-dom";
import CustomPaginateOption from "../../CustomPaginateOption";

const CommissionDetail = () => {
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [reports,setReports]= useState([]);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const { date } = useParams();
    const [param,setParams] = useState({
    
        from_date:date,
        to_date:date,
        per_page: 10,
        commission_type:""
      })
    
      const [total, setTotal] = useState(0);
      const [recordPerPage, setRecordPerPage] = useState(2);
      const npage = Math.ceil(total / recordPerPage);
      
      
      const [commissionTypes,setCommissionTypes] = useState([]);
      const [fromElmt, setFromElmt] = useState(0);
      const [toElmt, setToElmt] = useState(0);
      const [fromPage, setFromPage] = useState(1);
      const [currentPage, setCurrentPage] = useState(1);
    
      let cmpt = 0;
    
      const numbers = [];
      for (let i = 1; i <= npage; i++) {
        numbers.push(i);
      }
    

     useEffect(() => {
        getCommissionDetails()
     },[])

    const handleDateInvalid = (event)  => {
        event.target.setCustomValidity("Please select a date.")
      }
    
      const handleInput = (e) => {
        e.persist();
        setParams((prevParams) => {
            const updatedAccount = {
                ...prevParams,
                [e.target.name]: e.target.value,
              };
              if (e.target.name === "per_page") {
                param.per_page = e.target.value;
               searchByParam(e)
              }
    
              if (e.target.name === "from_date") {
                const from_date = e.target.value;
                updatedAccount.from_date = from_date;
        
                const fromDateObject = new Date(from_date);
                const toDateObject = new Date(
                  fromDateObject.getFullYear(),
                  fromDateObject.getMonth() + 1,
                  fromDateObject.getDate()
                );
                const to_date = toDateObject.toISOString().split("T")[0];
                updatedAccount.to_date = to_date;
              }
              return updatedAccount;
        })

      }

      const getCommissionDetails = () => {
        let payload = {
                from_date:date,
                to_date:date ,
                commission_type:param.commission_type,
                per_page:param.per_page,
        }
        const headers = {
            Authorization: authUser().tokenType + " " + authUser().token,
          };
    
          dispatch(showLoading());
    
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getCommissionDetails', payload,{headers})
            .then(response =>{
              dispatch(hideLoading());
                setReports(response.data.data.transactions.data)
                setCommissionTypes(response.data.data.commission_types)
                setRecordPerPage(response.data.data.per_page)
                setTotal(response.data.data.total)
                setFromElmt(response.data.data.from)
                setFromPage(response.data.data.from)
                setToElmt(response.data.data.to)
                setCurrentPage(currentPage)
            })
      }

      
  function searchByParam(event) {
    dispatch(showLoading());
    event.preventDefault();


    const from_date_error = document.getElementById('from_date_error');
    const to_date_error = document.getElementById('to_date_error');
   
        if (param.from_date === "") {
            // from_date_error.textContent = 'Please enter a valid date';
            dispatch(hideLoading());
            return ;
        } else {
            // from_date_error.textContent = '';
        }
        if (param.to_date === "") {
            // to_date_error.textContent = 'Please enter a valid date';
            dispatch(hideLoading());
            return ;
        } else {
            // to_date_error.textContent = '';
        }
    
    
        getCommissionDetails(currentPage);
    
} 




      return (
        <React.Fragment>
        <div style={{ padding: "20px" }}>
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h5 className="col-sm-4 mt-2">{t("COMMISSION_SUMMARY")}</h5>
              <span>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigate("/reports/agent-commission/summary/"+ date)}
                >
                  <FaArrowLeft size={18} fill={"white"} /> &nbsp;
                  {t("BACK")}
                </button>
              </span>
            </div>
    
            <div className="card-body mt-2">
              <form  id="my-form">
                  <div className="form-group row justify-content-md-center">
  
                      <div className="col-md-3">
                      <label className=""  htmlFor="from_date">{t('FROM_DATE')}</label>
                      <input
                          className="form-control custom-field"
                          value={param.from_date}
                          required
                          onInvalid={handleDateInvalid}
                          id="from_date"
                          type="date"
                          onChange={handleInput}
                          name="from_date"
                        />
                      </div>
                      <div className="col-md-3">
                      <label className=""  htmlFor="to_date">{t('TO_DATE')}</label>
                      <input
                          className="form-control custom-field"
                          value={param.to_date}
                          required
                          onInvalid={handleDateInvalid}
                          id="to_date"
                          type="date"
                          name="to_date"
                        />
                      </div>

                      <div className="col-md-3">
                    <label className="" htmlFor="commission_type">
                      {t("SOURCE")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleInput}
                      id="commission_type"
                      type="commission_type"
                      name="commission_type"
                      aria-label="Default select example"
                    >
                      <option value={""}>{t("SELECT_SOURCE")}</option>
                      {Object.keys(commissionTypes).map((key, index) => (
                        <option key={index} value={key}>
                          {commissionTypes[key]}
                        </option>
                      ))}
                    </select>
                  </div>
    
                      <div className="form-group row justify-content-md-center mt-3 mb-5">
                    <button
                      type="button"
                        onClick={searchByParam}
                      className="col-sm-2 btn btn-primary"
                      id="apply-filter-paid"
                      data-count="0"
                    >
                      {t("SEARCH")}
                    </button>
                    &nbsp;
                    {/* <button
                      type="reset"
                      onClick={resetSearch}
                      className="col-sm-2 btn btn-secondary"
                      id="apply-filter-clear-paid"
                    >
                      {t("CLEAR_SEARCH")}
                    </button>*/}
                  </div> 
                  </div>

                  <div className="per_page m-2">
                  <CustomPaginateOption
                    per_page={param.per_page}
                    handleInput={handleInput}
                    total={total}
                  />
                  </div>
             
                 
              </form>
    
              <div className="row mt-4">
                  <div className="col-sm-12">
                      <table className="table table-bordered grid-table">
                          <thead>
                              <tr>
                                  <th>#</th>
                                  <th>{t("DATE")}</th>
                                  <th>{t("LOCATION")}</th>
                                  <th>{t("COMMISSION_TYPE")}</th>
                                  <th>{t("REFERENCE_NUMBER")}</th>
                                  <th>{t("amount")}</th>
                              </tr>
                          </thead>
    
                          <tbody>
    
                          {reports && reports.map((item, index) => (
                            <>
                            <tr key={index }>
                              <td>{index +1}</td>
                              <td>
                               
                                  {item.created_at}
                                  
                              </td>
                              <td>
                              {item.agent_location_name}
                              </td>
                              <td>
                              { item.agent_paid_transaction_id === 0 ||  item.agent_paid_transaction_id === null ? "SENT":"PAID" }
                              </td>
                              <td>
                                {item.agent_transaction_id ? (
                                  <button
                                  onClick={() => navigate('/paid-transaction/' + item.agent_transaction_id )}
                                    
                                    className="btn btn-link"  style={{ textDecoration: 'none',padding:0 }}
                                  >
                                  {item.reference}
                                  </button>
                                ):
                                ( item.reference)}
                              
                                </td>
                              
                              <td>{item.credit_amount}{item.agent_location_currency}</td>
                            </tr>
                            
    
                            </>
                         
                          ))}
                          
                          </tbody> 
        
                      </table>
                  </div>
              </div>
            </div>
    
            
          </div>
        </div>
      </React.Fragment>
    )
    

}

export default CommissionDetail;