import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCodeTable, setFormFree } from '../../../../slices/send-money-moneygram-slice';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import MoneygramFormFreeSend from './MoneygramFormFreeSend';
import MoneygramFormFreeSendComplete from './MoneygramFormFreeSendComplete';

function MoneygramFormFreeSendHome() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formFree = useSelector((state) => state.sendMoneyMoneygram.formFree);

    useEffect(() => {
        console.log('MoneygramFormFreeSendHome')
        dispatch(setFormFree(false))

        let params = {
            language: i18next.language
        }

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/codeTable', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            dispatch(setCodeTable(res.data.data));
        });
    }, []);

    return (
        <div style={{ padding: "20px" }}>
            <div className="card">
                <div className="card-header d-flex justify-content-between" style={{ fontWeight: "600" }}>
                    <h5 className="card-label">{t("MG_FORM_FREE_SEND_COMPLETION")}</h5>
                </div>
                <div className="card-body">
                    {!formFree && <MoneygramFormFreeSend />}
                    {formFree && <MoneygramFormFreeSendComplete />}
                </div>
            </div>
        </div>
    );

}

export default MoneygramFormFreeSendHome;