import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUser } from 'react-auth-kit';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showToast } from "../../slices/toast-slice";
import { isEmptyObject } from "jquery";
import { formatAmount } from "../../utils/helper";
import GlobalConfirmPasscod from "../GlobalConfirmPasscod";

const BankRepayment = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [amount, setAmount] = useState('');
    const dispatch = useDispatch();
    const [currency, setCurrency] = useState('');
    const [comment, setComment] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);

    const [ isModalOpen, setIsModalOpen ] = useState(false);


    useEffect(() => {

        // check if the authenticated user has a valid passcode
        const signature = globalAuthUser?.signature;

        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            if(!signature) {
                // no signature is set for the user, prompt the user for a new signature
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
                navigate('/create-passcode');
                return;
            }

            if(!signature?.isActive || signature?.isActive == 2) {
                // signature code not activated
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
                navigate('/activate-passcode');
                return;
            }
        }

        // enable the submit repayment button
        setBtnDisabled(false);

        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            method: "BNB"
        }
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getFeesSendToAndPickupAt',data,{headers})
        .then(response => {
            if(response.data.status === 0 && response.data.message === "PASSCOD_VALIDATION"){
                handleAlert();
            }
        }).catch(err => {
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const initiateRepayment = () => {
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            openModal();
            return;
        }
        // save the local txn
        processRepayment();
    }

    function processRepayment (event) {
        setBtnDisabled(true)
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            method: "BNB",
            currency: (currency !== null && currency !== "") ? currency : globalAuthUser?.location?.currency,
            amount:amount,
            agent_comment:comment,
            origin:"POS",
            agentLocationId: globalAuthUser?.location?.id
        };

        //call the checkLimit api 
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/txnLimitsCheck',data,{headers})
        .then(response => {
            if(response.data.status === 1){ 
                if(!isEmptyObject(response.data.data)){
                    dispatch(hideLoading());
                    if (response.data.data.max_limit_per_txn !== "") {
                        dispatch(showToast(["danger", t("INFORMATION"), t('LIMIT_PER_TRANSACTION_REACHED',{ amount: formatAmount(response.data.data.max_limit_per_txn)})])); 
                    }
                    if (response.data.data.max_daily_limit_per_location !== "") {
                        dispatch( showToast(["danger", t("INFORMATION"), t('DAILY_LIMIT_PER_LOCATION_REACHED',{ amount: formatAmount(response.data.data.max_daily_limit_per_location)})])); 
                    }
                    return;
                }else{
                    //initiate repayment
                    axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/initiateRepayment',data,{headers})
                    .then(response => {
                        setBtnDisabled(true)
                        dispatch(showLoading());
                        if(response.data.status === 1){ 
                            let data = response.data.data;
                            if(response.data.message === "REPAYMENT_REQUEST_SUBMITED_SUCCESSFULLY"){
                                const btn = document.getElementsByClassName('btn')[0];
                                // Navigate to a new page after a delay
                                btn.setAttribute('disabled','disabled');
                                dispatch( showToast(["success", t("INFORMATION"), t('REPAYMENT_REQUEST_SUBMITED_SUCCESSFULLY', {data})])); 
                                setTimeout(() => {
                                    navigate('/repayment-requests');
                                }, 1000);
                            }
                        }else{
                            dispatch(hideLoading())
                            if (response.data.message === "AMOUNT_LIMIT_EXCEED") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('AMOUNT_LIMIT_EXCEED')])); 
                            }
                            if (response.data.message === "REPAYMENT_AMOUNT_EXCEEDED") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('REPAYMENT_AMOUNT_EXCEEDED')])); 
                            }
                            if (response.data.message === "MAX_CREDIT_AMOUNT_REACHED") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('MAX_CREDIT_AMOUNT_REACHED')])); 
                            }
                            if (response.data.message === "REPAYMENT_PENDING_AMOUNTS") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('REPAYMENT_PENDING_AMOUNTS')])); 
                            }
                            if(response.data.message === "IDENTICAL_TXN"){
                                dispatch( showToast(["danger", t("INFORMATION"), t('IDENTICAL_TXN')])); 
                            }
                            if (response.data.message === "VALID_PICKUP_CODE_NOT_FOUND") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('VALID_PICKUP_CODE_NOT_FOUND')])); 
                            }
                        }
                        setBtnDisabled(false)
                    }).catch(err => {
                        dispatch(hideLoading());
                        dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                        setBtnDisabled(false)
                    })
                }
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                return;
            }
            setBtnDisabled(false)
        }).catch(err => {
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            setBtnDisabled(false)
            return;
        });
        
    }

    
    const handleAlert = () => {
        Swal.fire({
            title: t('PASSCODE_INVALID'),
            confirmButtonText: t('UPDATE'),
            cancelButtonText: t('CANCEL'),
            showCancelButton: true,
            html: t('PASSCOD_VALIDATION'), 
            }).then((result) => {
                if (result.value !== undefined && result.value === true) {
                    navigate('/create-passcode');
                }else{
                    navigate('/repayment-requests');
                }
            }
        );
    }

   
    const schema = yup.object().shape({
        amount: yup.number("Invalid amount").positive().required(t('FIELD_REQUIRED')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
    
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header row">
                        <h5 className="col-sm-4">{t('BANK_REPAYMENT')}</h5>
                        <div className="col-sm-8">
                            <div className="card-toolbar float-end mb-2 mt-2">
                                <button type="button" className="btn btn-primary" onClick={() => navigate('/repayment-requests')}>{t("BACK")}</button> &nbsp; &nbsp;
                            </div>
                        </div>
                    </div>
                    
                    <div className="card-body">
                        <form action="" onSubmit={handleSubmit(initiateRepayment)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="amount">{ t('AMOUNT') } <span className="text-danger">*</span></label>
                                        <input type="number" name="amount" placeholder="Amount" id="amount" className={`form-control ${errors.amount ? 'is-invalid' : ''}`} {...register('amount', {
                                            onChange: (e) => setAmount(e.target.value),
                                        })}/>
                                        {errors?.amount != null && <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors?.amount?.message} </p>}
                                        
                                    </div>
                                    
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="comment">{ t('COMMENT') } </label>
                                        <input type="text" name="comment" className="form-control" placeholder="Comment"
                                        onChange={(e) => {
                                            setComment(e.target.value);
                                        }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="currency">{ t('CURRENCY') } <span className="text-danger">*</span></label>
                                        <select
                                            key={globalAuthUser?.location?.currency} 
                                            name="currency"
                                            id="currency"
                                            aria-label="Default select example"
                                            className="form-select mb-3"
                                            onChange={(e) => {
                                                setCurrency(e.target.value);
                                            }}
                                            required
                                            value={currency} 
                                        >
                                            <option value={globalAuthUser?.location?.currency} >
                                                {globalAuthUser?.location?.currency}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div className="form-group row justify-content-md-center mt-3">
                                    <button type="submit" className="col-sm-2 btn btn-primary" disabled={(btnDisabled === true) ? "disabled" : '' } id="apply-filter-paid" data-count="0">{t('SUBMIT')}</button>
                                    &nbsp;
                                    <button type="reset" className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CANCEL')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
            </div>

            {/** passcode modal */}
            <div>
                <GlobalConfirmPasscod
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callParentFunction={processRepayment}
                />
            </div>

        </React.Fragment>
    );
}

export default BankRepayment;