import axios from "axios";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";


const VerifyEmail = () => {

    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate();

    function send_verification() {
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {};

        // axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfile', data, { headers })
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/userEmail/sendCode', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            setIsVisible(true);
            navigate("/validate-code");
        })
        .catch(error => {
            dispatch(hideLoading());
            console.error(error);
            //display the error alert
        });
    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("VERIFY_YOUR_EMAIL")}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/user-profile" className="btn btn-primary btn-sm">
                                        {t("BACK")}
                                    </NavLink>
                                </div>
                            </h3>
                            
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <input className="form-control" disabled type="email" name="email" id="" value={globalAuthUser?.email} />
                                    <br />
                                    {
                                        isVisible && (<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            {t("EMAIL_SENT")}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        )
                                    }
                                    {
                                        !isVisible && (
                                            <>
                                            <button type="button" onClick={send_verification} disabled={globalAuthUser?.email ? "" : "disabled"} className="btn btn-success btn-sm">
                                                {t("SEND_VERIFICATION_EMAIL")}
                                            </button> &nbsp;
                                            <button type="button" onClick={() => navigate("/change-email")} className="btn btn-info btn-sm">
                                                {t("USE_A_DIFFERENT_EMAIL")}
                                            </button>
                                            </>
                                    ) }
                                            
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default VerifyEmail;