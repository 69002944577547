import React from 'react'
import CreateUserButton from './localSendingUtils/CreateUserButton'
import PhoneNumberSearch from './localSendingUtils/PhoneNumberSearch'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setSenderDetails } from '../../slices/local-sending-slice';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import { useState } from 'react';
import UserDetails from './localSendingUtils/UserDetails';
import CustomerRegisterForm from './localSendingUtils/CustomerRegisterForm';
import OtpVerificationForm from './localSendingUtils/OtpVerificationForm';
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { useTranslation } from 'react-i18next';

export default function () {
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const { t } = useTranslation();

    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);
    const currentStep = useSelector(state => state.localSending.currStep);
    const senderDetails = useSelector(state => state.localSending.senderDetails);

    const [searchError, setSearchError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [currentForm, setCurrentForm] = useState('');
    const [tmpId, setTmpId] = useState();
    const [registeredUser, setRegisteredUser] = useState('');

    const handleNext = () => {
        // make sure the sender details is set
        if(!Object.entries(senderDetails).length){
            // sender details not set
            dispatch(showToast(["danger", "Error", t("LOCAL_SENDER_REQUIRED")]));
            return;
        }
        
        // move to the next step
        dispatch(setCurrentStep(currentStep + 1));
    }

    /**
     * handling the click event for the add sender button
     */
    const handleAddSender = () => {
        // reset the selected sender
        dispatch(setSenderDetails({}));

        // set the current form as the registration form
        setCurrentForm("register");
    }

    /**
     * processing the sender phone number search
     * @param {*} serarchPhone 
     */
    const processSenderSearch = async(serarchPhone) => {
        // update the phone number
        setPhoneNumber(serarchPhone);
        setCurrentForm('');

        // start the loading activity
        dispatch(showLoading());

        // search for the phone number
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/searchCustomer`;
        const agentCountryDetails = globalAuthUser.location.agent_country;

        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                "searchParam": serarchPhone,
                "agent_country_id": agentCountryDetails.id
            }
        }).then(res => {
            // stop the loading activity
            dispatch(hideLoading());

            // extract the response
            const resData = res.data;
            if(resData.status === 1){
                // extract the data 
                const userDetails = resData?.data;

                // clear the error message
                setSearchError("");
                dispatch(setSenderDetails({}));

                // temporily set the user details
                setRegisteredUser(userDetails)
                
                const authorized_statuses = ["Lite", "Active", "KYC_VERIFIED", "KYC_EXPIRED", "Verified"];
                if(authorized_statuses.includes(userDetails.status)){
                    // update the sender details in the local sending store
                    dispatch(setSenderDetails(userDetails));
                    return;
                }

                // request user OTP
                const res_tmpId = userDetails?.tempId ? userDetails?.tempId : "";
                console.log(res_tmpId);
                setTmpId(res_tmpId);
                requestUserOTP(res_tmpId);
                
            } else {
                // extract the message
                let errMessage = resData?.message;

                // display the error message on the console
                dispatch(setSenderDetails({}));
                dispatch(showToast(["danger", "Error", t(errMessage)]));
            }

        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            console.log(err);
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }

    /**
     * Processing the sender registration
     * @param {*} event 
     */
    const processSenderRegistration = (e) => {
        e.preventDefault();

        // start the loading activity
        dispatch(showLoading());

        // extract the form data
        const formData = new FormData(e.target);
        const jsonData = Object.fromEntries(formData.entries());

        // get the agent country details
        const agentCountryDetails = globalAuthUser.location.agent_country;

        // register the user
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/api/customerRegister`;
        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            data: {
                "first_name": jsonData.first_name,
                "last_name": jsonData.last_name,
                "phone_number": jsonData.phone_number,
                "hdn_dialing_code": agentCountryDetails.phone_code,
                "country_id": globalAuthUser.location.main_country_id,
                "is_incomplete_user": 1, // user is registered as an incomplete user
                "is_otp_required": 1, // otp is required for senders
                "agree": 1
            }
        }).then(res => {
            console.log(res.data);
            // stop the loading activity
            dispatch(hideLoading());
            
            // process the response
            const resData = res?.data;
            console.log(resData?.status);
            
            // show the otp form
            setCurrentForm("otp");

            if(resData.status){
                // extract and set the tmp id
                const res_tmpId = resData?.data?.tempID ? resData?.data?.tempID : "";

                setTmpId(res_tmpId);

                // notify the user
                dispatch(showToast(['info', 'OTP Verification', t("PLEASE_VERIFY_OTP")]));

                // temporarily set the registered user details
                const userDetails = resData.additionalParams.userDetails;
                setRegisteredUser(userDetails);
            } else {
                // notify the user
                const message = res?.data?.message;
                dispatch(showToast(['danger', 'OTP Code', t(message)]));
                return;
            }
        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            console.log(err);
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }

    /**
     * Processing the sender otp verification
     * @param {*} e 
     */
    const processOTPVerification = (e) => {
        e.preventDefault();

        // get the form data
        const formData = new FormData(e.target);
        const jsonData = Object.fromEntries(formData.entries());

        // verify user otp
        validateUserOTP(jsonData);
    }

    const requestUserOTP = async (req_tmpId) => {
        // show the loading activity
        dispatch(showLoading());

        // request user OTP
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/api/requestNewOTP`;
        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            data: {
                "tempID": req_tmpId
            }
        }).then(res => {
            // stop the loading activity
            dispatch(hideLoading());

            // processs the response
            const resData = res.data;
            if(resData.status){
                // otp request sent successfully, show the otp verification form
                setCurrentForm("otp");
                dispatch(showToast(['warning', 'OTP', t("VERIFICATION_CODE_SENT")]));
                return;
            }

            // failed to send request
            dispatch(showToast(['warning', 'OTP', t(resData.message)]));

        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            console.log(err);
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }

    const validateUserOTP = (verify_json_data) => {
        // start the loading activity
        dispatch(showLoading());

        // verify the otp
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/api/verifyOTP`;
        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            data: {
                "tempID": tmpId,
                "otp": verify_json_data.otp_code
            }
        }).then(res => {
            // stop the loading activity
            dispatch(hideLoading());

            // process the response
            const resData = res.data;
            if(resData.status){
                // user regestered and otp verified successfully
                setCurrentForm('');
                dispatch(setSenderDetails(registeredUser));

                // notify the user
                dispatch(showToast(['success', 'OTP Verification', t("CODE_HAS_BEEN_VERIFIED")]));
                return;
            }

            // failed to verify otp
            dispatch(showToast(['warning', 'OTP', t(resData.message)]));
            
        }).catch(err => {
            // stop the loading activity
            dispatch(hideLoading());

            console.log(err);
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
        })
    }

    return (
        <>
            {/** create user button */}
            <CreateUserButton children={ t("ADD_NEW_SENDER") } handleClick = {handleAddSender}/>

            {/** searching by phone number */}
            <PhoneNumberSearch children={ t("SEARCH_SENDER_BY_PHONE_NUMBER") } handleSearch={processSenderSearch} searchError={searchError} />

            {/** user registration form */}
            {
                currentForm === "register" &&
                <CustomerRegisterForm  handleSubmit = {processSenderRegistration} phoneNumber={phoneNumber} />
            }

            {/** otp validation form */}
            {
                currentForm === "otp" &&
                <OtpVerificationForm handleOtpSubmit={processOTPVerification} />
            }

            {/** sender details */}
            <UserDetails {...senderDetails} />

            {/** control buttons */}
            <div className="row mb-2">
                <div className="col-12 col-lg-2"></div>
                <div className="col-12 col-lg-8 d-flex justify-content-between">
                    <button type='button' className="btn btn-sm btn-secondary" onClick={() => dispatch(setCurrentStep(currentStep - 1))}>
                        { t("BACK") }
                    </button>
                    {
                        Object.entries(senderDetails).length !== 0 &&
                        <button type='button' className="btn btn-sm btn-primary" onClick={handleNext}>
                            { t("NEXT") }
                        </button>
                    }
                </div>
            </div>
        </>
    )
}
