import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./customLoading.css";
import { BiLoaderCircle } from "react-icons/bi";
import spinner from "./../assets/images/spinner.gif";
import spinner2 from "./../assets/images/spinner2.gif";
import spinner3 from "./../assets/images/spinner3.gif";
import spinner4 from "./../assets/images/spinner4.gif";
import dot from "./../assets/images/dot.gif";
import { useTranslation } from "react-i18next";

const CustomLoading = (props) => {
  const { t } = useTranslation();
  const toastType = useSelector((state) => state.toast.type);
  const dispatch = useDispatch();

  return (
    <>
      <div className="spinner-container">
        {/* <div style={{display: 'block'}}><img src={spinner4} alt="" width={80} /></div> */}
        <div
          className="spinner-border text-info"
          style={{ width: "60px", height: "60px" }}
          role="status"
        >
          {/* <span className="sr-only">Loading...</span> */}
        </div>

        <div>
          <p style={{ color: "white", marginTop: "15px", fontWeight: "400" }}>
            {t("PLEASE_WAIT_PROCESSING_IN_PROGRESS")}
          </p>
        </div>
        {/* <BiLoaderCircle size={50} fill='white' /> */}
        {/* <div className="loading-spinner">
              </div> */}
      </div>
    </>
  );
};

export default CustomLoading;
