import axios from "axios";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";


const ValidateCode = () => {

    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(true)
    const [verifCode, setVerifCode] = useState("")
    const [verification, setVerification] = useState(false)
    const navigate = useNavigate();

    function send_verification() {
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {};

        // axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfile', data, { headers })
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/userEmail/sendCode', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            setIsVisible(true);
            setVerification(false);
        })
        .catch(error => {
            dispatch(hideLoading());
            console.error(error);
            setIsVisible(false);
        });
    }

    const handleInput = (e) => {
        e.persist();
        setVerifCode(e.target.value);
        console.log(verifCode);

    }

    function submitVerificationCode(event) {
        event.preventDefault();
        setIsVisible(false);
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            'verificationCode': verifCode
        };

        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/userEmail/validateCode', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if(response.data.status === 1){
               navigate("/user-profile") 
            }else{
                setIsVisible(false);
                setVerification(true);
            }
        })
        .catch(error => {
            dispatch(hideLoading());
            setIsVisible(false);
            setVerification(true);
            console.error(error);
        });
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("VERIFY_YOUR_EMAIL")}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/verify-email" className="btn btn-primary btn-sm">
                                        {t("BACK")}
                                    </NavLink>
                                </div>
                            </h3>
                            
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    
                                    {
                                        isVisible && !verification && (
                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                {t("EMAIL_SENT")} 
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                        )
                                    }
                                    {
                                        verification && (<div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {t("INVALIDE_CODE")}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        )
                                    }
                                    <>
                                        <form  className="p-3 mt-2">
                                            <div className="row mt-2 mb-3">
                                                <div className="col-12">
                                                    <div className='form-group'>
                                                        <label htmlFor='verification_code'>{t('VERIFICATION_CODE')} <span style={{ color: "red" }}>*</span></label>
                                                        <input type="number" name="verification_code" id="verification_code" value={verifCode} placeholder={t('VERIFICATION_CODE')} className="form-control"  onChange={handleInput}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <button type="submit" onClick={submitVerificationCode} disabled={globalAuthUser?.email ? "" : "disabled"} className="btn btn-success ">
                                                {t('SUBMIT')}
                                            </button> &nbsp;
                                            <button type="button" onClick={send_verification} className="btn btn-info ">
                                                {t('RESEND')}
                                            </button>
                                        </form>
                                    </>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default ValidateCode;