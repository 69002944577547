import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import storage from "../firebaseConfig";
import { uuidv4 } from '@firebase/util';
import { showToast } from "../../slices/toast-slice";

const CreateValidationRequest = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyCode, setCompanyCode] = useState("");
  const [govIdTypes, setGovIdTypes] = useState([]);
  const [frontPreview, setFrontPreview] = useState();
  const [frontFile, setFrontFile] = useState();


  var schemaObject = {};

  if (companyCode == 4){
    schemaObject['pickup_code'] = yup.string().length(10, t("PICK_UP_CODE_LENGTH")).required(t("FIELD_REQUIRED"))
  }else  {
    schemaObject['pickup_code'] = yup.string().required(t("FIELD_REQUIRED"))
  }
    schemaObject['beneficiary_phone'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaObject['beneficiary_address'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaObject['government_id_type'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaObject['government_id_no'] = yup.string().required(t("FIELD_REQUIRED"))




  const schema = yup.object().shape(
    schemaObject
)
const { register, handleSubmit, formState: { errors }, trigger, setValue, reset } = useForm({
  resolver: yupResolver(schema),
  mode: "onChange",
  reValidateMode: "onChange"
});



const onClose = () => {
    navigate("/validateRequest")
  }


  useEffect(() => {
    fetchGovIdTypes();
    const payload = {
        duration:1,
        agent_location_id: globalAuthUser?.location.id,
        per_page: 10
    };
    fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/agentCompanies',
    currentPage,
    payload);
}, []);


  function fetchData(url,currentPage, payload) {
    const headers = {
        'Authorization': authUser().tokenType + ' ' + authUser().token
    };
    const data = payload;
    dispatch(showLoading());
    axios.post(url, data, { headers })
    .then(response => {
        dispatch(hideLoading());
        setCompanies(response.data.data);
    })
    .catch(error => {
    });
};

const onCompanySelect = (e) => {
  setCompanyCode(e.currentTarget.id);
    const allWithClass = Array.from(
      e.currentTarget.parentElement.getElementsByClassName("card")
    );
    allWithClass.forEach((element) => {
      element.classList.remove("sCompany");
    });
    e.currentTarget.classList.add("sCompany");
  };


  const fetchGovIdTypes = () => {
    axios.get(
        process.env.REACT_APP_BNB_BASE_URL + '/api/guest/web/govtIdTypes', {
        headers: {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        }
    }).then(res => {
        setGovIdTypes(res.data.data.id_type_list)
        dispatch(hideLoading());

    });
  }

  const onSaveRequest = async (data) => {
    dispatch(showLoading());

    let payload = {}
    payload.code = data.pickup_code;
    payload.company = companyCode;
    payload.receiver_mobile = data.beneficiary_phone;
    payload.receiver_address = data.beneficiary_address;
    payload.government_id_type = data.government_id_type;
    payload.government_id_no = data.government_id_no;
      
    if(process.env.REACT_APP_ENVIRONMENT != undefined && process.env.REACT_APP_ENVIRONMENT == "production") {
      if(frontFile != null) {
        const storageRef = ref(storage, `/UserKYCDocs/${uuidv4() + '-' + frontFile.name}`);
        await uploadBytesResumable(storageRef, frontFile);
        const url = await getDownloadURL(storageRef)
        payload.government_id_photo = url
    }
    }else {
      payload.government_id_photo = "https://source.unsplash.com/random/300x200"
    }

    const headers = {
        'Authorization': authUser().tokenType + ' ' + authUser().token
    };

    axios.post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveValidationRequest', payload,{headers }).then(res => {
          if (res && res.data.status == 1){
            dispatch( showToast(["success", t("INFORMATION"), t("VALIDATION_REQUEST_SUCCESS")])); 
            setTimeout(() => {
                navigate('/validateRequest');
            }, 1000);  
          }else {
            dispatch( showToast(["danger", "ERROR", res.data.message]))
          }
        
        
        dispatch(hideLoading());

    }).catch(error => {
      dispatch(hideLoading());
      dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
    });

  }


function handleChangeFront(event) {
    setFrontFile(event.target.files[0]);
}

  useEffect(() => {
    if (!frontFile) {
        setFrontPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(frontFile)
    setFrontPreview(objectUrl)


    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [frontFile])

  return (
    <React.Fragment>
      <div>
        <div style={{ padding: "20px" }}>
          <div className="card">
            <div className="card-header d-flex justify-content-between p-4">
              <div className="card-label">
                <h5>{t("SEND_VALIDATION_REQUEST")}</h5>
              </div>
              <div className="card-toolbar">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/validateRequest");
                  }}
                  className="btn btn-primary"
                >
                  {t("BACK")}
                </button>
              </div>
            </div>
            <div className="card-body">
            <div className="form-group row justify-content-center">

<form onSubmit={handleSubmit(onSaveRequest)}>
    <div className="form-group row justify-content-center">
    { 
                companies && companies?.map((companies, index) => {
                if(companies.type == null && companies.api_connected == 0)
                      {
                        return (
                          <div id={companies.company_id} key={index} className="card col-6 col-md-2 company"
                          style={{ marginRight: "15px", marginBottom: "15px", cursor: "pointer"}} onClick={onCompanySelect} >
                         <div style={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px", margin:"auto"}}>
                             <div style={{ maxWidth: "100px", maxHeight: "100px", margin: "auto"}}  >
                                 <img src={process.env.REACT_APP_BNB_BASE_URL + "/" + companies.company_logo} className="card-img-top" alt="..."  />
                             </div>
                         </div>
                     </div>
                        )
                    }
                })
            }
    </div>
 

            <div className="form-group row">
                <div className="col-md-6">
                    <label htmlFor="pickup_code" className="col-form-label">{t("PICKUP_CODE")}</label>
                    <span style={{ color: "red" }}>*</span>
                      {(companyCode == '5')? 
                       ( <input type="text" id="pickup_code" className='form-control' {...register("pickup_code")} />) :(<input type="number" id="pickup_code" className='form-control' {...register("pickup_code")} />)
                      } 
                        
                        <p className="input-error-msg"> {errors.pickup_code?.message} </p>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <label htmlFor="beneficiary_phone" className="col-form-label"> {t('BENEFICIARY_PHONE')}</label>
                    <span style={{ color: "red" }}>*</span>
                        <input type="number" id="beneficiary_phone" className='form-control' {...register("beneficiary_phone")} />
                        <p className="input-error-msg"> {errors.beneficiary_phone?.message} </p>
                </div>
                <div className="col-md-6">
                    <label htmlFor="beneficiary_address" className="col-form-label">{t("BENEFICIARY_ADDRESS")}</label>
                        <input type="text" id="beneficiary_address" className='form-control' {...register("beneficiary_address")} />
                        <p className="input-error-msg"> {errors.beneficiary_address?.message} </p>
                </div>
            </div>
            
            <div className="form-group row">
                <div className="col-md-6">
                    <label htmlFor="government_id_type" className="col-form-label">{t('ID_TYPE')}</label>
                    <select style={{ maxWidth: "100%" }} className="form-select" id="government_id_type" {...register("government_id_type")}>
                    <option value="">{t("SELECT")}</option>
                    {govIdTypes?.map((item) => (
                        <option value={item.type_description} key={item.type_id}>
                        {item.type_description}
                        </option>
                    ))}
                    </select>
                    <p className="input-error-msg"> {errors.government_id_type?.message} </p>
                </div>
                <div className="col-md-6">
                    <label htmlFor="government_id_no" className="col-form-label">{t('ID_NUMBER')}</label>
                        <input type="number" id="government_id_no" className='form-control' {...register("government_id_no")} />
                        <p className="input-error-msg"> {errors.government_id_no?.message} </p>
                </div>
            </div>
   
            <div className="form-group-row">
            <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="government_photo">{t("PHOTO_ID_FRONT")}</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                    <input type="file" id="government_photo" className='form-control' accept="/image/*"  onChange={handleChangeFront}  required/>
                                                    
                                                    <img style={{ marginTop: '10px' }} width="200px" alt="" src={frontPreview} />
                                                </div>
            </div>

          <div className="card-footer">
          <div style={{ textAlign: "right", marginTop: "15px" }}>
                                                <button type="button"  onClick={onClose} className="btn btn-light"> {t("CANCEL")}  </button> &nbsp;
                                                <button type='submit' className="btn btn-primary" > {t("SAVE")}  </button> &nbsp;
                                            </div>
          </div>
</form>

            </div>
          </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateValidationRequest;
