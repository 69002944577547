import { configureStore } from "@reduxjs/toolkit"
import authSlice from "../slices/auth-slice";
import loadingSlice from "../slices/loading-slice";
import sendMoneyMoneygramSlice from "../slices/send-money-moneygram-slice";
import sendMoneyRiaSlice from "../slices/send-money-ria-slice";
import toastSlice from "../slices/toast-slice";
import LocalSendingSlice from "../slices/local-sending-slice";
import MerchantPaymentSlice from "../slices/merchant-payment-slice";
import AgentSelfRegistrationSlice  from "../slices/agent-self-registration-slice";
import LocationUsersSlice from "../slices/location-users-slice";


// const reduceFn = (state = {}, action) => {};
const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        sendMoneyRia: sendMoneyRiaSlice.reducer,
        sendMoneyMoneygram: sendMoneyMoneygramSlice.reducer,
        toast: toastSlice.reducer,
        loading: loadingSlice.reducer,
        localSending: LocalSendingSlice.reducer,
        merchantPayment: MerchantPaymentSlice.reducer,
        agentSelfRegistration: AgentSelfRegistrationSlice.reducer,
        locationUsers: LocationUsersSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})

export default store;