import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
// const app = initializeApp ({
//     apiKey: "AIzaSyC051uJmAgPnkGUZhc1qaamrufb25b8F6Y",
//     authDomain: "bnb-cashapp-314714.firebaseapp.com",
//     projectId: "bnb-cashapp-314714",
//     storageBucket: "bnb-cashapp-314714.appspot.com",
//     messagingSenderId: "695065005001",
//     appId: "1:695065005001:web:627ad78ee5fa438d157618",
//     measurementId: "G-FPLYNLSX68"
// });

const app = initializeApp ({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;