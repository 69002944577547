import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getCountryName, getPhoneCode, getSenderName, getReceiverName } from './ria';
import { FaArrowCircleLeft, FaArrowCircleRight, FaTimes } from "react-icons/fa";
import { setStep, setSendResponse, setComplete, setSEND_MONEY_OBJECT } from '../../../slices/send-money-ria-slice';
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import "./ria.css"
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { DaysList, formatNumber, MonthList, YearDobList, YearExpirationList } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../../slices/toast-slice';
import GlobalConfirmPasscod from '../../GlobalConfirmPasscod';

const schema = yup.object().shape({
    transferReason: yup.string(),
    sourceFund: yup.string(),
})

function RiaTransaction() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const codeTable = useSelector((state) => state.sendMoneyRia.codeTable);
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [transferRaisons, setTransferRaisons] = useState([]);
    const [consumerInfo, setConsumerInfo] = useState({});
    const [receiveInfo, setReceiverInfo] = useState({});
    const [sourceFounds, setSourceFounds] = useState([]);
    const [idTypes, setIdTypes] = useState([]);
    const DAYS = DaysList();
    const MONTHS = MonthList();
    const YEARS = YearExpirationList();
    const [relationships, setRelationships] = useState([]);

    const [ sendingAllowed, setSendingAllowed ] = useState(false);
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const schema = yup.object().shape({
        transferReason: yup.string(),
        sourceFounds: yup.string(),
    })

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });
   
    useEffect(() => {

        // check if the authenticated user has a valid passcode
        const signature = globalAuthUser?.signature;

        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            if(!signature) {
                // no signature is set for the user, prompt the user for a new signature
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
                navigate('/create-passcode');
                return;
            }

            if(!signature?.isActive || signature?.isActive == 2) {
                // signature code not activated
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
                navigate('/activate-passcode');
                return;
            }
        }

        // set the sending allowed
        setSendingAllowed(true);


        console.log("SEND_MONEY_OBJECT", SEND_MONEY_OBJECT)
        let sConsumerIndex = SEND_MONEY_OBJECT.consumers.findIndex(consumer => consumer.id === SEND_MONEY_OBJECT.selectedConsumerId)
        setConsumerInfo(SEND_MONEY_OBJECT.consumers[sConsumerIndex])

        let sReceiverIndex = SEND_MONEY_OBJECT.consumers[sConsumerIndex].receivers.findIndex(receiver => receiver.id === SEND_MONEY_OBJECT.selectedReceiverId)
        setReceiverInfo(SEND_MONEY_OBJECT.consumers[sConsumerIndex].receivers[sReceiverIndex])
        console.log("setReceiverInfo", SEND_MONEY_OBJECT.consumers[sConsumerIndex].receivers[sReceiverIndex])

        var codes = codeTable?.enum_values_info.filter((a) => a.enum_type === "CustBeneRelationship");
        if (codes !== undefined && codes.length > 0) {
            setRelationships(JSON.parse(codes[0].enum_values))
        }

    }, []);

    useEffect(() => 
    {
        var transferReasons = codeTable?.enum_values_info.filter((a) => a.enum_type === "TransferReasons");
        if(transferReasons !== undefined && transferReasons.length > 0) { setTransferRaisons(JSON.parse(transferReasons[0].enum_values)) }

        var sourceFunds = codeTable?.enum_values_info.filter((a) => a.enum_type === "CustSourceFunds");
        if(sourceFunds !== undefined && sourceFunds.length > 0) { setSourceFounds(JSON.parse(sourceFunds[0].enum_values)) }

        var idTypess = codeTable?.enum_values_info.filter((a) => a.enum_type === "IDTypes");
        if(idTypess !== undefined && idTypess.length > 0) { setIdTypes(JSON.parse(idTypess[0].enum_values)) }

    }, [codeTable]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onSubmitTransaction = () => {
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            openModal();
            return;
        }
        // save the ria payment
        handlePaymentSubmission();
    }

    const handlePaymentSubmission = (data) => {
        console.log("on submit transaction")
        let payload = {}
        // {
            // Transaction
            payload.sendingCountry = "SL"
            payload.CountryFrom = "SL"
            payload.CountryTo = SEND_MONEY_OBJECT.sDestCountry.country_code
            payload.ProductID = SEND_MONEY_OBJECT.sProduct.id
            payload.DeliveryMethod = SEND_MONEY_OBJECT.sDeliveryMethod.id
            payload.TransferReasonID = receiveInfo.transferReasonId
            payload.TransferReason = (SEND_MONEY_OBJECT?.enumTransferReasons.find(o => o.Name == 'Other') != null && SEND_MONEY_OBJECT?.enumTransferReasons.find(o => o.Name == 'Other').Value == receiveInfo.transferReasonId) ? receiveInfo.transferReasonText : undefined
            // TransferReason = ""
            payload.CustPaymentMethodID = 10
            // CustPaymentMethod = ""

            // Quotation
            payload.PaymentCurrency = SEND_MONEY_OBJECT.sendCurrency
            payload.PaymentAmount = SEND_MONEY_OBJECT.sendAmount
            // CommissionCurrency = ""
            // CommissionAmount = ""
            payload.CustChargeCurrency =  SEND_MONEY_OBJECT.sendCurrency
            payload.CustChargeAmount = SEND_MONEY_OBJECT.sendingFee
            payload.BeneCurrency = SEND_MONEY_OBJECT.sReceivingCurrency
            payload.BeneAmount = SEND_MONEY_OBJECT.receiveAmount
            payload.PromoCode = SEND_MONEY_OBJECT.promoCode != "" ? SEND_MONEY_OBJECT.promoCode : undefined
            payload.ExchangeRate = SEND_MONEY_OBJECT.rate

            // Customer
            payload.RiaCustID = consumerInfo.id
            payload.CustCountry = consumerInfo.country
            payload.CustTypeID = 10
            payload.CustFirstName = consumerInfo.firstName
            payload.CustMiddleName = consumerInfo.middleName
            payload.CustLastName = consumerInfo.firstLastName
            payload.CustLastName2 = consumerInfo.secondLastName
            // CustMotherName = ""
            // CustFatherName = ""
            payload.CustNationality = consumerInfo.nationality
            payload.CustDateOfBirth = consumerInfo.dateOfBirth.slice(0, 10)
            payload.CustCountryOfBirth = consumerInfo.countryOfBirth
            payload.CustOccupationID = consumerInfo.occupationId
            payload.CustOccupation = (SEND_MONEY_OBJECT?.enumOccupations.find(o => o.Name == 'Other') != null && SEND_MONEY_OBJECT?.enumOccupations.find(o => o.Name == 'Other').Value == consumerInfo.occupationId) ? consumerInfo.occupationText : undefined
            // CustBusinessSectorID = ""
            payload.CustSourceOfFundsID = receiveInfo.sourceOfFundsId
            payload.CustSourceOfFunds = (SEND_MONEY_OBJECT?.enumSourceFunds.find(o => o.Name == 'Other') != null && SEND_MONEY_OBJECT?.enumSourceFunds.find(o => o.Name == 'Other').Value == receiveInfo.sourceOfFundsId) ? receiveInfo.sourceOfFundsText : undefined
            payload.CustBeneRelationshipID = receiveInfo.relationshipId
            payload.CustBeneRelationship = (relationships.find(o => o.Name == 'Other') != null && relationships.find(o => o.Name == 'Other').Value == receiveInfo.relationshipId) ? receiveInfo.relationshipText : undefined
            // CustEmployerName = ""
            // CustIncomeAmount = ""
            // CustIncomeCurrency = ""
            // CustIncomeFrequency = ""
            // CustIncomePaymentMethod = ""
            payload.CustGender = consumerInfo.gender
            // Answer = ""
            // QuestionID = ""
            payload.CustID1Type = consumerInfo.photoIdType
            payload.CustID1No = consumerInfo.photoIdNumber
            payload.CustID1IssuedBy = consumerInfo.photoIdIssuingEntity
            payload.CustID1IssuedByState = consumerInfo.photoIdIssuingState
            payload.CustID1IssuedByCountry = consumerInfo.photoIdIssuingCountry
            payload.CustID1IssuedDate = consumerInfo.photoIdIssuingDate != undefined ? consumerInfo.photoIdIssuingDate.slice(0, 10) : undefined
            payload.CustID1ExpirationDate = consumerInfo.photoIdExpirationDate != undefined ? consumerInfo.photoIdExpirationDate.slice(0, 10) : undefined
            payload.CustTaxID = consumerInfo.taxId
            payload.CustTaxCountry = consumerInfo.taxCountry
            payload.CustAddress = consumerInfo.address
            payload.CustCity = consumerInfo.city
            payload.CustState = consumerInfo.state
            payload.CustZipCode = consumerInfo.zipCode
            payload.CustCountryofResidence = consumerInfo.country
            payload.CustPhoneCountryCode = consumerInfo.country
            payload.CustPhoneCode = consumerInfo.countryPhoneCode
            payload.CustPhoneNo = consumerInfo.phoneNo
            payload.CustCellCountryCode = consumerInfo.country
            payload.CustCellNo = consumerInfo.phoneNo
            payload.CustEmailAddress = consumerInfo.emailAddress

            // SendingPartner
            // payload.SendingCorrespBranchNo = globalAuthUser.location.code
            payload.SendingLocationCode = globalAuthUser.location.code


            // Beneficiary
            payload.RiaBeneID = receiveInfo.riaReceiverId
            payload.BeneTypeID = (SEND_MONEY_OBJECT.sProduct.id == 116) ? 30  : 10
            // BeneQuestion = ""
            // BeneAnswer = ""
            payload.BeneFirstName = receiveInfo.firstName
            payload.BeneMiddleName = receiveInfo.middleName
            payload.BeneLastName = receiveInfo.lastName
            payload.BeneLastName2 = receiveInfo.lastName2
            payload.BeneEntityName = receiveInfo.entityName
            // BeneNationality = ""
            // BeneDateOfBirth = ""
            // BeneFatherName = ""
            // BeneMotherName = ""
            // BeneCountryOfBirth = ""
            // BeneIDNo = ""
            // BeneIDType = ""
            // BeneTaxID = ""
            payload.BeneAddress = receiveInfo.address
            payload.BeneCity = receiveInfo.city
            payload.BeneState = receiveInfo.state
            payload.BeneZipCode = receiveInfo.zipCode
            payload.BeneCountry = receiveInfo.country
            payload.BenePhoneNo = receiveInfo.phoneCode + receiveInfo.phoneNumber
            payload.BeneCellNo = receiveInfo.phoneCode + receiveInfo.phoneNumber
            payload.BeneEmailAddress = receiveInfo.emailAddress
            // BeneMessage = ""
            
            // PayoutPartner
            if(SEND_MONEY_OBJECT.sDeliveryMethod.id == 2) {
                payload.BankID = SEND_MONEY_OBJECT.sPayoutPartner != null ? SEND_MONEY_OBJECT.sPayoutPartner.id : undefined
                payload.BankAccountNo = receiveInfo.bankAccountNo
                payload.BankRoutingCode = receiveInfo.bankRoutingCode
                payload.BankBranchName = receiveInfo.bankBranchName
                payload.BankBranchNo = receiveInfo.bankBranchNo
                payload.UnitaryBankAccountNo = receiveInfo.unitaryBankAccountNo
            } 
            else {
                payload.PayingCorrespID = SEND_MONEY_OBJECT.sPayoutPartner != null ? SEND_MONEY_OBJECT.sPayoutPartner.id : undefined
                payload.PayingCorrespLocID = SEND_MONEY_OBJECT.sPayoutPartnerLocation != null ? SEND_MONEY_OBJECT.sPayoutPartnerLocation.id : undefined
                if (SEND_MONEY_OBJECT.sDeliveryMethod.id == 34)  {
                    payload.MobileWalletAccountNo = receiveInfo.mobileWalletAccountNo
                }
                
            } 

            payload.SenderOccupationText = consumerInfo.occupationText
            payload.SourceOfFundsText = receiveInfo.sourceOfFundsText
            payload.TransferReasonText = receiveInfo.transferReasonText
            payload.SenderIdTypeText = consumerInfo.photoIdTypeText
            payload.SenderRelationshipText = receiveInfo.relationshipText


            payload.sendingTax = SEND_MONEY_OBJECT.sendingTax
            payload.grandTotal = SEND_MONEY_OBJECT.grandTotal
            payload.receiveAmount = SEND_MONEY_OBJECT.receiveAmount


        console.log("Payload", payload)

        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/ria/submitTransaction",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: authUser().tokenType + " " + authUser().token,
            },
            data: payload
        })
        .then((res) => {
            console.log(res)
            dispatch(hideLoading());
            if(res.data.status == 1) {
                let dataProp = {
                    PIN: res.data.data.PIN,
                }
                navigate("/send-money/ria-completed", { replace: true, state: dataProp });
            } else {
                dispatch(
                    showToast(["danger", "ERROR", res.data.message])
                )
            }
        })
        .catch((error) => {
            console.log(error)
            dispatch(hideLoading());
            dispatch(
                showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
            );
        });
    }

    const back = () => {
        dispatch(setSEND_MONEY_OBJECT({...SEND_MONEY_OBJECT, previousStep: 4, step: 3})) 
    }

    return (
        <div>

            <div style={{ maxWidth: "700px", margin: "auto" }}>
                <div className={`row rowsep `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("SENDER_NAME") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ consumerInfo.firstName + ' ' + consumerInfo.lastName }</span>
                    </div>
                </div>
                <div className={`row rowsep odds `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("RECEIVER_NAME") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ receiveInfo.firstName + ' ' + receiveInfo.lastName }</span>
                    </div>
                </div>
                <div className={`row rowsep `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("DELIVERY_METHOD") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ SEND_MONEY_OBJECT.sDeliveryMethod.name }</span>
                    </div>
                </div>
                <div className={`row rowsep odds `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("DESTINATION_COUNTRY") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ SEND_MONEY_OBJECT.sDestCountry.country_name }</span>
                    </div>
                </div>
                <div className={`row rowsep `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("SENDING_AMOUNT") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ formatNumber(SEND_MONEY_OBJECT.sendAmount) + ' ' + SEND_MONEY_OBJECT.sendCurrency }</span>
                    </div>
                </div>
                <div className={`row rowsep  odds`}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("SENDING_FEE") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ formatNumber(SEND_MONEY_OBJECT.sendingFee) + ' ' + SEND_MONEY_OBJECT.sendCurrency }</span>
                    </div>
                </div>
                <div className={`row rowsep `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("SENDING_TAX") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ formatNumber(SEND_MONEY_OBJECT.sendingTax) + ' ' + SEND_MONEY_OBJECT.sendCurrency }</span>
                    </div>
                </div>
                <div className={`row rowsep odds`}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("GRAND_TOTAL") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ formatNumber(SEND_MONEY_OBJECT.grandTotal) + ' ' + SEND_MONEY_OBJECT.sendCurrency }</span>
                    </div>
                </div>
                <div className={`row rowsep `}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("EXCHANGE_RATE") }</label>
                    </div>
                    <div className="col-md-6  text-end">
                        <span className="value"> 1.00 { SEND_MONEY_OBJECT.sendCurrency + ' = ' + formatNumber( SEND_MONEY_OBJECT.rate) + ' ' + SEND_MONEY_OBJECT.sReceivingCurrency }</span>
                    </div>
                </div>
                <div className={`row rowsep odds`}>
                    <div className="col-md-6">
                        <label htmlFor="">{ t("receiveAmount") }</label>
                    </div>
                    <div className="col-md-6 text-end">
                        <span className="value">{ formatNumber(SEND_MONEY_OBJECT.receiveAmount) + ' ' +  SEND_MONEY_OBJECT.sReceivingCurrency }</span>
                    </div>
                </div>
            </div>

            <div style={{ maxWidth: "700px", margin: "auto", marginTop: '20px' }}>
                <form onSubmit={handleSubmit(onSubmitTransaction)}>
                    <hr />
            <div style={{ textAlign: "right" }}>
                <button type='button' className='btn btn-info' onClick={back} >
                    <FaArrowCircleLeft size={18} color={'white'} /> {t('BACK')}
                </button> &nbsp; &nbsp;
                <button type='submit' className='btn btn-primary' disabled={(sendingAllowed != true) ? "disabled" : '' }>
                    {t('SUBMIT')} &nbsp;
                </button>
            </div>

                </form>
            </div>


            {/** passcode modal */}
            <div>
                <GlobalConfirmPasscod
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callParentFunction={handlePaymentSubmission}
                />
            </div>

        </div>
    );

}

export default RiaTransaction