import React from 'react';
import { TiTick } from "react-icons/ti";

export default function StepComponent({steps, currentStep}) {
  return (
            <>
                <div className="tw-flex tw-justify-between border-bottom mb-2">
                    {steps?.map((step, i) => (
                        <div key={i} className={`step-item ${currentStep >= i && "active"}`}>
                            <div className="step">
                                <TiTick size={24} />
                            </div>
                            <p className="tw-text-gray-500">{step}</p>
                        </div>
                    ))}
                </div>
            </>
  )
}
