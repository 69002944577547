import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isToastVisible: false,
  type: null,
  title: "",
  message: "",
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast(state, action) {
      console.log(action.payload);
      // console.log(gg.payload);
      state.isToastVisible = true;
      state.type = action.payload[0];
      state.title = action.payload[1];
      state.message = action.payload[2];
    },
    closeToast(state, action) {
      state.isToastVisible = false;
    },
  },
});

export const { showToast, closeToast } = toastSlice.actions;

export default toastSlice;
