import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import CustomPaginateOption from "../CustomPaginateOption";

const ValidationIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
  const dispatch = useDispatch();
  const [statuses, setStatuses] = useState([]);
  const [source, setSources] = useState([]);

  const [duration, setDuration] = useState("1");
  const [companies, setCompanies] = useState([]);
  const [validationReq, setValidationReq] = useState([]);
  const [fromPage, setFromPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [account, setAccount] = useState({
    agent_location_id: globalAuthUser?.location.id,
    pickup_code: "",
    duration: duration,
    agent_company_id: "",
    from_date: "",
    to_date: "",
    source: "",
    status: "",
    format: "",
    per_page: 10,
    search_field: "reference_no",
  });
  const COSTUM_DATE = 6;
  const [total, setTotal] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [fromElmt, setFromElmt] = useState(0);
  const [toElmt, setToElmt] = useState(0);
  const npage = Math.ceil(total / recordPerPage);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);

  let cmpt = 0;

  const numbers = [];
  for (let i = 1; i <= npage; i++) {
    numbers.push(i);
  }

  function changeCPage(page) {
    dispatch(showLoading());
    var payload = {
      service: account.service,
      countryCode: account.countryCode,
      operatorId: account.operatorId,
      productId: account.productId,
      search_field: account.search_field,
      search_key: account.search_key,
      duration: duration,
      status: account.status,
      from_date: account.from_date,
      to_date: account.to_date,
      per_page: account.per_page !== null ? account.per_page : 10,
    };
    fetchData(
      `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/listValidationRequest?page=${page}`,
      page,
      payload
    );
  }

  function handleDurationChange(event) {
    setDuration(event.target.value);
  }

  function handleDateInvalid(event) {
    event.target.setCustomValidity("Please select a date.");
  }

  useEffect(() => {
    fetchData(
      process.env.REACT_APP_BNB_BASE_URL + "/api/portal/listValidationRequest",
      currentPage,
      account
    );
  }, []);

  function fetchData(url, currentPage, payload) {
    const headers = {
      Authorization: authUser().tokenType + " " + authUser().token,
    };
    const data = payload;
    dispatch(showLoading());
    axios
      .post(url, data, { headers })
      .then((response) => {
        dispatch(hideLoading());
        setValidationReq(response?.data?.data?.validation_requests?.data);
        setFromPage(response.data.data.validation_requests.from);
        setTotal(response.data.data.validation_requests.total);
        setFromElmt(response.data.data.validation_requests.from);
        setToElmt(response.data.data.validation_requests.to);
        setRecordPerPage(response.data.data.validation_requests.per_page);
        setCurrentPage(currentPage);
        setStatuses(response.data.data.statuses);
        setSources(response.data.data.sources);
        setCompanies(response.data.data.agentCompanies);
        console.log("source", source);
        console.log("statuses", statuses);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleInput = (e) => {
    e.persist();
    setAccount((prevAccount) => {
      const updatedAccount = {
        ...prevAccount,
        [e.target.name]: e.target.value,
      };
      if (e.target.name === "per_page") {
        account.per_page = e.target.value;
        searchByParam(e);
      }
      if (e.target.name === "from_date") {
        const from_date = e.target.value;
        updatedAccount.from_date = from_date;

        const fromDateObject = new Date(from_date);
        const toDateObject = new Date(
          fromDateObject.getFullYear(),
          fromDateObject.getMonth() + 1,
          fromDateObject.getDate()
        );
        const to_date = toDateObject.toISOString().split("T")[0];
        updatedAccount.to_date = to_date;
      }
      return updatedAccount;
    });
  };

  const handleStatusChange = (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    setSelectedStatus(selectedValues)
    
  };

  const handleSourceChange = (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    setSelectedSource(selectedValues)
  }

  function searchByParam(event) {
    dispatch(showLoading());
    event.preventDefault();
    var payload = {
      duration: duration,
      agent_company_id: account.agent_company_id,
      pickup_code: account.pickup_code,
      status: selectedStatus,
      source:selectedSource,
      from_date: account.from_date,
      to_date: account.to_date,
      per_page: account.per_page !== null ? account.per_page : 10,
    };

    if (duration == COSTUM_DATE) {
      const from_date_error = document.getElementById("from_date_error");
      const to_date_error = document.getElementById("to_date_error");
      if (account.from_date === "") {
        from_date_error.textContent = "Please enter a valid date";
        dispatch(hideLoading());
        return;
      } else {
        from_date_error.textContent = "";
      }
      if (account.to_date === "") {
        to_date_error.textContent = "Please enter a valid date";
        dispatch(hideLoading());
        return;
      } else {
        to_date_error.textContent = "";
      }
    }

    fetchData(
      process.env.REACT_APP_BNB_BASE_URL + "/api/portal/listValidationRequest",
      currentPage,
      payload
    );
  }

  function resetSearch(event) {
    var myForm = document.getElementById("my-form");
    myForm.reset();
    setDuration(1);
    setSelectedSource([]);
    setSelectedStatus([])
    account.pickup_code = "";
    dispatch(showLoading());
    event.preventDefault();
    var payload = {
      duration: duration,
      agent_company_id: account.agent_company_id,
      pickup_code: account.pickup_code,
      status:account.status,
      source:account.source,
      from_date: account.from_date,
      to_date: account.to_date,
      per_page: account.per_page !== null ? account.per_page : 10,
    };

    fetchData(
      process.env.REACT_APP_BNB_BASE_URL + "/api/portal/listValidationRequest",
      currentPage,
      payload
    );
  }

  var listRequests = "";
  listRequests = validationReq?.map((item, index) => {
    const {
      id,
      pickup_code,
      agent_company_name,
      created_at,
      paid_at,
      source,
      status,
      notes,
      employeeUser,
    } = item;
    return (
      <tr key={id}>
        <td>{fromPage + index}</td>
        <td>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              navigate("/validateRequest/" + id);
            }}
          >
            {pickup_code}
          </button>
        </td>
        <td>{ globalAuthUser?.location.name}</td>
        <td>{agent_company_name}</td>
        <td>{status}</td>
        <td>{created_at}</td>
        <td>{employeeUser}</td>
        <td>{paid_at}</td>
        <td>{notes}</td>
        <td>{source}</td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <div>
        <div style={{ padding: "20px" }}>
          <div className="card">
            <div className="card-header d-flex justify-content-between p-4">
              <div className="card-label">
                <h5>{t("VALIDATION_REQUEST")}</h5>
              </div>
              <div className="card-toolbar">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/validateRequest/create");
                  }}
                  className="btn btn-primary"
                >
                  {t("NEW_VALIDATION_REQUEST")}
                </button>
              </div>
            </div>

            <div className="card-body">
              <form id="my-form">
                <div className="form-group row ">
                  <div className="col-md-3 md:mt-2">
                    <label className="" htmlFor="pickup_code">
                      {t("PICKUP_CODE")}
                    </label>
                    <input
                      type="text"
                      id="pickup_code"
                      value={account.pickup_code}
                      onChange={handleInput}
                      className="form-control custom-field"
                      name="pickup_code"
                    />
                  </div>

                  <div className="col-md-3">
                    <label className="" htmlFor="source">
                      {t("SOURCE")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleSourceChange}
                      name="source[]"
                      id="source"
                      aria-label="Default select example"
                    >
                      <option value={""}>{t("SELECT_SOURCE")}</option>
                      {Object.keys(source).map((key, index) => (
                        <option key={index} value={key}>
                          {source[key]}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-3">
                    <label className="" htmlFor="agent_company_id">
                      {t("COMPANY")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleInput}
                      name="agent_company_id"
                      id="agent_company_id"
                      aria-label="Default select example"
                    >
                      <option value={""}>{t("SELECT_A_COMPANY")}</option>
                      {Object.keys(companies).map((key, index) => (
                        <option key={index} value={key}>
                          {companies[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <div className="col-md-3">
                    <label className="" htmlFor="status">
                      {t("STATUS")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleStatusChange}
                      value={selectedStatus}
                      name="status[]"
                      id="status"
                      aria-label="Default select example"
                    >
                      <option value={""}>{t("SELECT_A_STATUS")}</option>
                      {Object.keys(statuses).map((key, index) => (
                        <option key={index} value={key}>
                          {statuses[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="" htmlFor="duration">
                      {t("DURATION")}
                    </label>
                    <select
                      className="form-select"
                      onChange={handleDurationChange}
                      name="duration"
                      id="duration"
                      value={duration}
                      aria-label="Default select example"
                    >
                      <option value="1">{t("TODAY")}</option>
                      <option value="2">{t("THIS_WEEK")}</option>
                      <option value="3">{t("THIS_MONTH")}</option>
                      <option value="4">{t("PREVIOUS_WEEK")}</option>
                      <option value="5">{t("PREVIOUS_MONTH")}</option>
                      <option value="6">{t("CUSTOM_DATE")}</option>
                    </select>
                  </div>
                </div>

                {duration == COSTUM_DATE && (
                  <>
                    <div className="col-md-3 customDate">
                      <label htmlFor="from_date">
                        {t("FROM_DATE")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control custom-field"
                        value={account.from_date}
                        required
                        onInvalid={handleDateInvalid}
                        id="from_date"
                        type="date"
                        onChange={handleInput}
                        name="from_date"
                      />
                      <span id="from_date_error" className="text-danger"></span>
                    </div>

                    <div className="col-md-3 customDate">
                      <label htmlFor="to_date">
                        {t("TO_DATE")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control custom-field"
                        value={account.to_date}
                        required
                        readOnly
                        onInvalid={handleDateInvalid}
                        id="to_date"
                        type="date"
                        name="to_date"
                      />
                      <span id="to_date_error" className="text-danger"></span>
                    </div>
                  </>
                )}

                <div className="form-group row justify-content-md-center mt-3 mb-5">
                  <button
                    type="submit"
                    onClick={searchByParam}
                    className="col-sm-2 btn btn-primary"
                    id="apply-filter-paid"
                    data-count="0"
                  >
                    {t("SEARCH")}
                  </button>
                  &nbsp;
                  <button
                    type="reset"
                    onClick={resetSearch}
                    className="col-sm-2 btn btn-secondary"
                    id="apply-filter-clear-paid"
                  >
                    {t("CLEAR_SEARCH")}
                  </button>
                </div>
                <div className="per_page m-2">
                  <CustomPaginateOption
                    per_page={account.per_page}
                    handleInput={handleInput}
                    total={total}
                  />
                </div>
              </form>
              <table
                id="datatable"
                className="table table-bordered grid-table responsive nowrap w-100"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("PICKUP_CODE")}</th>
                    <th>{t("LOCATION")}</th>
                    <th>{t("COMPANY")}</th>
                    <th>{t("STATUS")}</th>
                    <th>{t("CREATED_AT")}</th>
                    <th>{t("PROCESSED_BY")}</th>
                    <th>{t("COMPLETED_AT")}</th>
                    <th>{t("NOTE")}</th>
                    <th>{t("SOURCE")}</th>
                  </tr>
                </thead>
                <tbody>{listRequests}</tbody>
              </table>
            </div>

            <div className="card-footer">
              {numbers.length > 0 ? (
                <>
                  <div
                    className="pagination"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="left-info">
                      <span>
                        {t("SHOWING")} {fromElmt} {t("TO")} {toElmt} {t("OF")}{" "}
                        {total} {t("ENTRIES")}
                      </span>
                    </div>
                    <div className="float-end">
                      <nav aria-label="Page navigation example">
                        <ul
                          className="pagination"
                          style={{
                            display: "flex",
                            listStyle: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <li className="page-item">
                            <button
                              type="button"
                              onClick={() => changeCPage(currentPage - 1)}
                              className={`page-link ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                            </button>
                          </li>

                          {numbers.map((page, index) => (
                            <li
                              className={`page-item ${
                                currentPage === page ? "active" : ""
                              }`}
                              key={index}
                            >
                              {page === 1 ||
                              page === numbers.length ||
                              currentPage === page ||
                              currentPage + 1 === page ||
                              currentPage + 2 === page ||
                              currentPage - 1 === page ||
                              currentPage - 2 === page ? (
                                <button
                                  type="button"
                                  onClick={() => changeCPage(page)}
                                  className="page-link"
                                >
                                  {page}
                                </button>
                              ) : cmpt === 1 && page === currentPage + 3 ? (
                                <button className="page-link">
                                  {(() => {
                                    cmpt++;
                                    return "...";
                                  })()}
                                </button>
                              ) : cmpt === 0 ? (
                                <button className="page-link">
                                  {(() => {
                                    cmpt++;
                                    return "...";
                                  })()}
                                </button>
                              ) : null}
                            </li>
                          ))}
                          <li className="page-item">
                            <button
                              type="button"
                              onClick={() => changeCPage(currentPage + 1)}
                              className={`page-link ${
                                currentPage === numbers.length ? "disabled" : ""
                              }`}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&raquo;</span>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValidationIndex;
