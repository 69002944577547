import axios from "axios";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from "../../slices/toast-slice";


const ActivatePasscode = () => {

    const { t } = useTranslation();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(true)
    const [validationCode, setValidationCode] = useState("")
    const [verification, setVerification] = useState(false)
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');

    function resendPasscode() {
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {};
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/signature/resendPasscode', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if(response.data.status === 1){
                setIsVisible(true);
                setVerification(false);
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        })
        .catch(error => {
            dispatch(hideLoading());
            console.error(error);
            setIsVisible(false);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }

    const handleInput = (e) => {
        e.persist();
        setValidationCode(e.target.value);
        console.log(validationCode);

    }

    function submitVerificationCode(event) {
        event.preventDefault();
        setIsVisible(false);
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            'validationCode': validationCode
        };

        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/signature/activatePasscode', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if (response.data.status === 1) {
                //move to another page success.
                setVerification(false);
                setSuccessMessage(t('PASSCODE_SUCCESS'));
                const btn = document.getElementsByClassName('btn')[0];
                // Navigate to a new page after a delay
                btn.setAttribute('disabled','disabled');
                setTimeout(() => {
                navigate('/user-profile');
                }, 2000);
            } else {
                setVerification(true);
            }
        })
        .catch(error => {
            dispatch(hideLoading());
            setIsVisible(false);
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            console.error(error);
        });
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("PASSCODE")} {t("ACTIVATION")}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/create-passcode" className="btn btn-primary btn-sm">
                                        {t("BACK")}
                                    </NavLink>
                                </div>
                            </h3>
                            
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    
                                    {
                                        isVisible && !verification && (
                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                {t("EMAIL_SENT")} 
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                        )
                                    }
                                    {
                                        verification && (<div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            {t("INVALIDE_OTP")}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        )
                                    }
                                    { successMessage !== '' && (<div className="alert alert-success alert-dismissible fade show" role="alert">
                                        {successMessage}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        ) 
                                    }
                                    <>
                                        <form className="p-3 mt-2">
                                            <div className="row mt-2 mb-3">
                                                <div className="col-12">
                                                    <div className='form-group'>
                                                        <label htmlFor='validationCode'>{t('VERIFICATION_CODE')} <span style={{ color: "red" }}>*</span></label>
                                                        <input type="text" name="validationCode" id="validationCode" value={validationCode} placeholder={t('VERIFICATION_CODE')} className="form-control"  onChange={handleInput}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <button type="submit" onClick={submitVerificationCode} className="btn btn-success ">
                                                {t('SUBMIT')}
                                            </button> &nbsp;
                                            <button type="button" onClick={resendPasscode} className="btn btn-info ">
                                                {t('RESEND')} {t('CODE')}
                                            </button>
                                        </form>
                                    </>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default ActivatePasscode;