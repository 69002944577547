import React from 'react'
import { useState } from 'react';
import { useId } from 'react'
import { useTranslation } from 'react-i18next';

export default function PhoneNumberSearch({children, handleSearch }) {
    const id = useId();
    const { t } = useTranslation();
    const [phoneNumber, setPhoneNumber] = useState('');

    return (
        <>
            <div className="row mb-3 border-bottom">
                <div className="col-12 col-lg-3"></div>
                <div className="col-12 col-lg-6">
                    
                    {/** the serarch input field */}
                    <div className="mb-1">
                        <label htmlFor={id + "userPhoneNumber"}>
                            { t("PHONE_NO") }
                        </label>
                        <input type="text" name="" id={ id + "userPhoneNumber"} className="form-control"
                        value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>

                    {/** search phone button */}
                    <div className="mb-2 d-grid">
                        <button type='button' className="btn btn-sm btn-block btn-primary" onClick={() => handleSearch(phoneNumber)}>
                            { t("SEARCH") }
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
