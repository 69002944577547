import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./stepper.css";
import { TiTick } from "react-icons/ti";
import RcvStep1 from "./RcvStep1";
import RcvStep2 from "./RcvStep2";
import RcvStep3 from "./RcvStep3";
import RcvStep4 from "./RcvStep4";
import RcvStep5 from "./RcvStep5";
import { setCodeTable } from "../../../slices/send-money-moneygram-slice";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function RcvProcess() {
  const propsData = useLocation().state;
  console.log(propsData);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const currentStep = useSelector((state) => state.auth.globalMGreceiveCS);
  const referenceNoResp = useSelector((state) => state.auth.REF_NO_RESP);

  const info = t("INFORMATION");
  const complaince = t("COMPLIANCE");
  const summary = t("SUMMARY");

  const steps = [info, complaince, summary];
  const [complete, setComplete] = useState(false);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div className="card">
          <div className="card-header" style={{ fontWeight: "600" }}>
            MONEY GRAM RECEIVE - { referenceNoResp.referenceNumber }
          </div>
          <div className="card-body">
            <div className="tw-flex tw-justify-between">
              {steps?.map((step, i) => (
                <div
                  key={i}
                  className={`step-item ${currentStep === i + 1 && "active"} ${
                    (i + 1 < currentStep || complete) && "complete"
                  } `}
                >
                  <div className="step">
                    {i + 1 < currentStep || complete ? (
                      <TiTick size={24} />
                    ) : (
                      i + 1
                    )}
                  </div>
                  <p className="tw-text-gray-500">{step}</p>
                </div>
              ))}
            </div>

            {currentStep == 1 && <RcvStep1 items={propsData} />}

            {currentStep == 2 && <RcvStep2 items={propsData} />}

            {/* {currentStep == 3 &&
                            <RcvStep3 items={propsData} />
                        } */}

            {currentStep == 3 && <RcvStep4 items={propsData} />}

            {/* {currentStep == 5 &&
                            <RcvStep5 />
                        } */}

            {/* {!complete && (
                            <button
                                className="tw-btn"
                                onClick={() => {
                                    currentStep === steps.length
                                        ? setComplete(true)
                                        : setCurrentStep((prev) => prev + 1);
                                }}
                            >
                                {currentStep === steps.length ? "Finish" : "Next"}
                            </button>
                        )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default RcvProcess;
