import React, { useEffect, useState } from 'react';
import bnblogo from "../../assets/images/bnb.png";
import PersonalRegForm from './reg_forms/PersonalRegForm';
import BusinessDetailsForm from './reg_forms/BusinessDetailsForm';
import RegistrationSummary from './RegistrationSummary';
import RegistrationSuccess from './RegistrationSuccess';
import { useDispatch, useSelector } from 'react-redux';
import { setAgentCountries, setBusinessInfo, setPersonalInfo } from '../../slices/agent-self-registration-slice';
import { set_LANGUAGE } from '../../slices/auth-slice';
import i18next from "i18next";
import { useTranslation } from 'react-i18next';

export default function AgentRegistrationForm() {

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 4;
    const dispatch = useDispatch();
    const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
    const { t } = useTranslation();

    const formHeaders = {
        PERSONAL_INFO:  t("PERSONAL_INFO"),
        BUSINESS_DETAILS: t("BUSINESS_REG_INFO"),
    }

    const handleNext = (e) => {
        if(currentStep < totalSteps) {
            let step = currentStep
           setCurrentStep(step + 1);
        }
    }

    const handlePrev = () => {
        if(currentStep > 1) {
            let step = currentStep;
            setCurrentStep(step - 1);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const resetRegistrationDetails = () => {
        // reset the current step

        // reset the sender and receiver details
        dispatch(setPersonalInfo({}));
        dispatch(setBusinessInfo({}));
        dispatch(setAgentCountries({}));
    }


  return (
    <>
        <div className="row mt-xl-5 m-md-3">
            <div className="col-12 col-lg-3"></div>

            {/* main form container */}
            <div className="col-12 col-xl-6 shadow rounded-2 p-3">
                {/* header details */}
                {
                    currentStep != 4 &&
                    <div className="mb-3">
                        <div className="mb-4 logo text-center">
                            <img src={bnblogo} alt="bnbLogo" />
                        </div>
                        <div className="border-bottom">
                            <div className="row mb-1 text-center">
                                <div className="col-12 d-flex flex-column">
                                    <strong className='mb-2'>{ t("AGENT_REGISTER_FORM") }</strong>
                                    <small>
                                        { currentStep === 1 && formHeaders.PERSONAL_INFO }
                                        { currentStep === 2 && formHeaders.BUSINESS_DETAILS }
                                    </small>
                                </div>
                            </div>

                            {/* the language row */}
                            <div className="row mb-1 text-start">
                                <div className="col-12">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" data-bs-toggle="dropdown">
                                            {
                                                LANGUAGE === "en" && 
                                                <i className="flag-icon flag-icon-gb"></i>
                                            }
                                            {
                                                LANGUAGE === "fr" && 
                                                <i className="flag-icon flag-icon-fr"></i>
                                            }
                                        </span>
                                    
                                        <ul className="dropdown-menu">
                                            <li>
                                            <a className="dropdown-item" style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    i18next.changeLanguage("en");
                                                    dispatch(set_LANGUAGE("en"));
                                                }}
                                                >
                                                    <span className="flag-icon flag-icon-gb mx-2"></span>
                                                    English
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        i18next.changeLanguage("fr");
                                                        dispatch(set_LANGUAGE("fr"));
                                                    }}
                                                    >
                                                    <span className="flag-icon flag-icon-fr mx-2"></span>
                                                    Francais
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }

                {/* end of header */}

                {/* the registration forms */}
                <div className="mb-3 p-2">
                    { currentStep === 1 && <PersonalRegForm processNext = {handleNext}/> }
                    { currentStep === 2 && <BusinessDetailsForm processNext={handleNext} processPrev={handlePrev} /> }
                    { currentStep === 3 && <RegistrationSummary processNext={handleNext} processPrev={handlePrev} /> }
                    { currentStep === 4 && <RegistrationSuccess processReset={resetRegistrationDetails} /> }
                </div>

            </div>
        </div>
    </>
  )
}
