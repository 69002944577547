import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    idTypes: [],
    statuses: ["New", "Under Review", "Approved", "Rejected", "Update Requested", "Completed"],
    editableStatuses: [0, 4], // new and update_requeted
    acceptedFiles: ".png, .jpg, .jpeg",
};

export const LocationUsersSlice = createSlice({
    name: "location-users-slice",
    initialState: initialState,
    reducers: {
        setIDTypes(state, action) {
            state.idTypes = action.payload; 
        },
    }
})

// exporting the actions
export const { setIDTypes } = LocationUsersSlice.actions;

// exporting the reducers
export default LocationUsersSlice;