import axios from "axios";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from '../../slices/toast-slice';

const CreatePasscode = () => {

    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const dispatch = useDispatch();
    const [passcode, setPasscode] = useState("");
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(true);

    const handleInput = (e) => {
        if (e.target.value.length >=5) {
            setDisabled(false);
        }else{
            setDisabled(true);
        }
        e.persist();
        setPasscode(e.target.value);
        console.log(passcode);
        

    }

    function createPasscode(event) {
        event.preventDefault();
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            'passcode': passcode
        };

        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/signature/createPasscode', data, { headers })
        .then(response => {
            dispatch(hideLoading());
            console.log(response.data);
            if(response.data.status === 1){
               navigate("/activate-passcode"); 
            }else{
                //display an error message
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        })
        .catch(error => {
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">

                    <div className="card card-custom gutter-b example1 example1-compact">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("PASSCODE")}
                                <div className="card-toolbar float-end">
                                    <NavLink to="/user-profile" className="btn btn-primary btn-sm">
                                        {t("BACK")}
                                    </NavLink>
                                </div>
                            </h3>
                            
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <>
                                        <form  className="p-3 mt-2">
                                            <div className="row mt-2 mb-3">
                                                <div className="col-12">
                                                    <div className='form-group'>
                                                        <label htmlFor='passcode'>{t('PASSCODE')} <span style={{ color: "red" }}>*</span></label>
                                                        <input type="password" name="passcode" id="passcode" value={passcode} placeholder={t('PASSCODE')} className="form-control"  onChange={handleInput}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <button type="submit" onClick={createPasscode} disabled={disabled} className="btn btn-success ">
                                                {t('SUBMIT')}
                                            </button> &nbsp;
                                            
                                        </form>
                                    </>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default CreatePasscode;