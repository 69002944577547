import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login, setGlobalAuthUser, setExpiresIn } from "../../slices/auth-slice";
import bnblogo from "../../assets/images/bnb.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { showToast } from "../../slices/toast-slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function SecurityCode() {

    const [mail, setMail] = useState('***');
    const [phone, setPhone] = useState('***');

    const signIn = useSignIn();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const schema = yup.object().shape({
        security_code: yup.string().required(t('FIELD_REQUIRED')).min(5, t("FIELD_MIN", { min: 5 })),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = () => {
        dispatch(showLoading());
        axios.get(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/otp/' + id, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        }).then(response => {
            console.log(response);
            dispatch(hideLoading());

            setMail(response.data?.data?.emailHash)
            setPhone(response.data?.data?.phoneHash)
        });
    };

    const resend = () => {
        dispatch(showLoading());
        axios.get(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/otp/' + id + '/generate', {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        }).then(response => {
            console.log(response);
            dispatch(hideLoading());

            var status = response.data?.status
            var message = response.data?.message
            if (status == 1) {
                dispatch(showToast(["success", "NOTICE", t(message)]));
            } else {
                dispatch(showToast(["danger", "NOTICE", t(message ?? 'ERROR_HAS_OCCURED')]));
            }
        });
    };

    const submitForm = (data) => {
        axios.defaults.withCredentials = false;
        dispatch(showLoading())

        data.otp = data.security_code
        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/auth/otp/' + id + '/login', data, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        }).then(response => {
            console.log(response);
            dispatch(hideLoading())

            var status = response.data.status
            var message = response.data.message

            if (status == 0) {
                dispatch(showToast(["danger", "NOTICE", t(message ?? 'ERROR_HAS_OCCURED')]));
                return;
            }

            var token = response.data?.data?.access_token;
            console.log('token', token)
            var id = response.data?.data?.user?.id;
            var expiresIn = response.data?.data?.expires_in;

            signIn({
                token: token,
                expiresIn: expiresIn,
                tokenType: "Bearer",
                authState: { token: token, tokenType: "Bearer" }
            });
            dispatch(setExpiresIn(expiresIn))
            document.cookie = "portal=" + id + ";max-age=2592000" // 30 days

            dispatch(showLoading())
            axios.get(
                process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData', {
                withCredentials: false,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': "Bearer " + token
                }
            }).then(response => {
                console.log(response);
                dispatch(hideLoading())

                status = response.data?.status
                message = response.data?.message
                if (status == 0) {
                    dispatch(showToast(["danger", "NOTICE", t(message ?? 'ERROR_HAS_OCCURED')]));
                    return;
                }
                dispatch(login());
                dispatch(setGlobalAuthUser(response.data.data));
                navigate("/home");
            });
        }).catch((error) => {
            console.log(error);
            dispatch(hideLoading());
        });
    };

    return (
        <>
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div className="wrapper">
                    <div className="logo">
                        <img src={bnblogo} alt="" />
                    </div>
                    <div className="text-center mt-4 name">BnB Portal</div>
                    <form onSubmit={handleSubmit(submitForm)} className="p-3 mt-3">
                        <div className="mb-4" style={{ textAlign: "center" }}>
                            <p style={{ fontSize: '14px' }}><b>{t('SECURITY_CODE_WAS_SENT_TO_YOUR_EMAIL', { mail: mail, phone: phone })}</b></p>
                            <p style={{ fontSize: '12px' }}>{t('VERIFY_YOUR_EMAIL_AND_ENTER_THE_CODE_TO_SIGN_IN')}</p>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className='form-group'>
                                    <label htmlFor='security_code'>{t('SECURITY_CODE')} <span style={{ color: "red" }}>*</span></label>
                                    <input type="text" name="security_code" id="security_code" placeholder={t('SECURITY_CODE')} className={`form-control w-100 ${errors.security_code ? 'is-invalid' : ''}`} {...register('security_code')} />
                                    {errors?.security_code && <p className="input-error-msg"> {errors.security_code?.message} </p>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-4">
                            <button type="submit" className="btn mt-4" style={{ marginRight: '12px' }}>
                                {t('SUBMIT')}
                            </button>
                            <button type="button" className="btn btn-nostyle btn-custom btn-dark mt-4" onClick={() => resend()}>
                                {t('RESEND')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SecurityCode;
