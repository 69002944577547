import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAgentCountries, setBusinessInfo, setPersonalInfo } from '../../slices/agent-self-registration-slice';
import { useNavigate } from 'react-router-dom';

export default function RegistrationSuccess({processReset}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const backToLogin = () => {
        processReset();
        navigate("/")
    }

  return (
    <>
            {/** complete icon */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <i className='bx bxs-badge-check text-success p-3' style={{ fontSize: "60px" }}></i>
                </div>
            </div>

            {/** complete icon */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <span className="text-success">{t("TTL_AGENT_REGISTRATION_SUCCESS")}</span>
                    <p>
                        {t("AGENT_REGISTRATION_SUCCESS")}
                    </p>
                </div>
            </div>

            {/** action buttons */}
            <div className="mb-2 row">
                <div className="col-12 text-center">
                    <button type='button' className="btn btn-primary btn-sm" onClick={backToLogin}>
                        {t("BACK_TO_LOGIN")}
                    </button>
                </div>
            </div>
        </>
  )
}
