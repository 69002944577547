import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import {
    setFeeInfoState, setFieldsState, setSenderSchemaState, setReceiverSchemaState,
    setStep, setOnPrevious, setResetSend, setResetReceive, setSEND_MONEY_OBJECT
} from '../../../slices/send-money-ria-slice';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { complianceFields, destinationFieldsIgnore, senderFieldsIgnore } from './ria';
import { FaArrowCircleRight, FaSearch, FaChevronDown } from "react-icons/fa";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import "./ria.css"
import { formatNumber } from '../../../utils/helper';
import { useImmer } from 'use-immer';
import { showToast } from '../../../slices/toast-slice';

function RiaFees() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const DeliveryMethod = useSelector((state) => state.sendMoneyRia.DeliveryMethod);
    const ProductId = useSelector((state) => state.sendMoneyRia.ProductId);
    const CurrencyFrom = useSelector((state) => state.sendMoneyRia.CurrencyFrom);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const codeTable = useSelector((state) => state.sendMoneyRia.codeTable);
    const feeInfoState = useSelector((state) => state.sendMoneyRia.feeInfo);
    const onPrevious = useSelector((state) => state.sendMoneyRia.onPrevious);
    const [receivingCountries, setReceivingCountries] = useState([]);
    // const [ReceivingCurrencies, setReceivingCurrencies] = useState([]);
    const [ReceivingCurrency, setReceivingCurrency] = useState([]);
    const [feeInfos, setFeeInfos] = useState([]);
    const [searchLoader, setSearchLoader] = useState(false);
    const [nextLoader, setNextLoader] = useState(false);
    const [message, setMessage] = useState(null);
    const [test, setTest] = useStateCallback(0);
    const [sendMoneyObject, setSendMoneyObject] = useStateCallback(SEND_MONEY_OBJECT);

    console.log("ria send fees component render")
    console.log("SEND_MONEY_OBJECT", SEND_MONEY_OBJECT)


    const schema = yup.object().shape({
        receiving_country: yup.string().required(t('FIELD_REQUIRED')),
        deliveryMethod: yup.string().required(t('FIELD_REQUIRED')),
        payoutPartner: yup.string(),
        payoutPartnerLocation: yup.string(),
        // product: yup.string().required(t('FIELD_REQUIRED')),
        receiving_currency: yup.string().required(t('FIELD_REQUIRED')),
        amount: yup.string().required(t('FIELD_REQUIRED')),
        promoCode: yup.string(),
        ack_fraud_warning: yup.bool().oneOf([true], t('ACKNOWLEDGE_FW_REQUIRED')),
        ack_terms_conditions: yup.bool().oneOf([true], t('ACKNOWLEDGE_TCS_REQUIRED')),
    })

    const { register, reset, handleSubmit, setValue, setError, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    useEffect(() => {
        reset()
    }, []);

    useEffect(() => {
        var recCountries = codeTable?.countries_info;
        setReceivingCountries(recCountries ?? []);
        setTest(1)
    }, [codeTable, globalAuthUser, DeliveryMethod]);

    const [amount, setAmount] = useState("");
    const [receivingCountry, setReceivingCountry] = useState(null);
    const [AmountType, setAmountType] = useState("LocalAmount");
    const [promoCode, setPromoCode] = useState("");
    const [feeSelect, setFeeSelect] = useState("");
    const [feeInfo, setFeeInfo] = useState(null);

    useEffect(() => {
        if(sendMoneyObject.previousStep == 2) return
        console.log("in sendMoneyObject.currencies useEffect") 
        if(sendMoneyObject.currencies != undefined) {
            if(sendMoneyObject.currencies.length == 1) {
                setValue('receiving_currency', sendMoneyObject.currencies[0])
                onChangeReceivingCurrency()
            }
        }
    }, [sendMoneyObject.currencies]);

    useEffect(() => {
        dispatch(setSEND_MONEY_OBJECT(sendMoneyObject))
    }, [sendMoneyObject]);


    const onChangeReceivingCountry = async event => {
        var selected            = event.target.value;
        setFeeInfos([])
        // var receiveCurrencies   = codeTable?.country_currency_info?.filter(c => c.country_code === selected).map((c) => c.currency_code);
        // receiveCurrencies       = Array.from(new Set(receiveCurrencies ?? []))

        setSendMoneyObject((prev) => {
            prev.sDestCountry = codeTable?.countries_info?.find(c => c.country_code === selected)
            prev.deliveryMethods = []; prev.sDeliveryMethod = null
            prev.products = []; prev.sProduct = null;
            prev.currencies = []; prev.sReceivingCurrency = null;
            prev.payoutPartners = []; prev.sPayoutPartner = null;
            prev.payoutPartnerLocations = []; prev.sPayoutPartnerLocation = null;
        })


        setValue('deliveryMethod', "")
        setValue('product', "")
        setValue('receiving_currency', "")
        setValue('payoutPartner', "")
        setValue('payoutPartnerLocation', "")

        await fetchDeliveryMethods(selected)
    }

    const onDeliveryChange = async event => {
        setFeeInfos([])
        setValue('product', "")
        setValue('receiving_currency', "")
        setValue('payoutPartner', "")
        setValue('payoutPartnerLocation', "")
        var deliveryMethodId = event.target.value;
        console.log("deliveryMethodId", deliveryMethodId)
        console.log("deliveryMethod", sendMoneyObject.deliveryMethods.find(d => d.id == deliveryMethodId))
        let productlist = sendMoneyObject.deliveryMethods.find(d => d.id == deliveryMethodId).products
        let c2cProduct = productlist.find(d => d.id == 111)
        let currencies = c2cProduct.currencies
        
        setSendMoneyObject((prev) => 
        {
            prev.sDeliveryMethod = prev.deliveryMethods.find(d => d.id == deliveryMethodId)
            prev.products = prev.deliveryMethods.find(d => d.id == deliveryMethodId).products
            prev.currencies = []; prev.sReceivingCurrency = null
            prev.payoutPartners = []; prev.sPayoutPartner = null
            prev.payoutPartnerLocations = []; prev.sPayoutPartnerLocation = null

            if(c2cProduct != null && c2cProduct != undefined) {
                prev.sProduct = c2cProduct
                prev.currencies = c2cProduct.currencies
                prev.payoutPartners = []; prev.sPayoutPartner = null;
                prev.payoutPartnerLocations = []; prev.sPayoutPartnerLocation = null;

            }

        })
    }

    const onProductChange = async event => {
        var productId = event.target.value;
        console.log("productId", productId)
        setSendMoneyObject((prev) => {
            prev.sProduct = sendMoneyObject.products.find(d => d.id == productId)
            prev.currencies = sendMoneyObject.products.find(d => d.id == productId).currencies
            prev.payoutPartners = []; prev.sPayoutPartner = null;
            prev.payoutPartnerLocations = []; prev.sPayoutPartnerLocation = null;
        })
        setValue('receiving_currency', "")
        setValue('payoutPartner', "")
        setValue('payoutPartnerLocation', "")
    }

    function getProductArray() {
        return sendMoneyObject?.products;
    }

    function getCurrencyArray() {
        return sendMoneyObject?.currencies;
    }

    function getPayoutPartnersArray() {
        return sendMoneyObject?.payoutPartners;
    }

    function getPayoutPartnerLocationArray() {
        return sendMoneyObject?.payoutPartnerLocations;
    }

    async function fetchDeliveryMethods(countryCode) {
        dispatch(showLoading());
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/ria/allowedDeliveryMethods?sendingCountry=SL&countryTo=" + countryCode,
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
                }
            })
            console.log(res.data.data.deliveryMethods)
            dispatch(hideLoading());
            if (res.data.status == "SUCCESS") {
                let methods = res.data.data.deliveryMethods 
                let ollowedM = methods.filter(m => (m.id == 1 || m.id == 2 || m.id == 34))
                setSendMoneyObject((prev) => { prev.deliveryMethods = ollowedM })
            }
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
        }
    }

    async function fetchPayoutPartners(receivingCurrency) {
        let countryTo = sendMoneyObject.sDestCountry.country_code
        let deliveryMethodId = sendMoneyObject.sDeliveryMethod.id
        let productId = sendMoneyObject.sProduct.id
        dispatch(showLoading());
        let urlStart = (deliveryMethodId == 2) ? "/api/ria/banks" : "/api/ria/payoutPartners"
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL +  urlStart + "?sendingCountry=SL&countryTo=" + countryTo 
                    + "&deliveryMethodId=" + deliveryMethodId + "&receivingCurrency=" + receivingCurrency + "&productId=" + productId ,
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
                }
            })
            console.log(res.data.data)
            let data = (deliveryMethodId == 2) ? res.data.data.banks : res.data.data.payoutPartners 
            dispatch(hideLoading());
            if (res.data.status == "SUCCESS") {
                setSendMoneyObject((prev) => { prev.payoutPartners = data })
            }
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
        }
    }

    async function fetchPayoutPartnerLocations(payoutPartnerId) {
        let countryTo = sendMoneyObject.sDestCountry.country_code
        let deliveryMethodId = sendMoneyObject.sDeliveryMethod.id
        let receivingCurrency = sendMoneyObject.sReceivingCurrency
        let productId = sendMoneyObject.sProduct.id
        dispatch(showLoading());
        let urlStart = (deliveryMethodId == 2) ? "/api/ria/bankLocations" : "/api/ria/payoutPartnerLocations"
        let subStr = (deliveryMethodId == 2) ? "&bankId=" : "&payoutPartnerId="
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + urlStart + "?sendingCountry=SL&countryTo=" + countryTo 
                    + "&deliveryMethodId=" + deliveryMethodId + "&receivingCurrency=" + receivingCurrency + "&productId=" + productId + subStr + payoutPartnerId,
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
                }
            })
            console.log(res.data.data)
            let data = (deliveryMethodId == 2) ? res.data.data.bankLocations : res.data.data.payoutPartnerLocations
            dispatch(hideLoading());
            if (res.data.status == "SUCCESS") {
                setSendMoneyObject((prev) => { prev.payoutPartnerLocations = data })
            }
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
        }
    }

    const onChangeReceivingCurrency = async event => {
        setFeeInfos([])
        var currency = '';
        if(event != undefined ) {
            currency = event.target.value;
        } else {
            currency = document.getElementById("receiving_currency").value
        }
        console.log("currency", currency)
        setSendMoneyObject((prev) => {
            prev.sReceivingCurrency = currency
            prev.payoutPartners = []; prev.sPayoutPartner = null;
            prev.payoutPartnerLocations = []; prev.sPayoutPartnerLocation = null;
            prev.isPayoutPartnerRequired = false
            prev.isPayoutPartnerLocationRequired = false
        })
        setReceivingCurrency(currency)

        console.log("actual sendObject", sendMoneyObject)

        let necessity = checkPayoutPartnerNecessity()
        console.log("necessity", necessity)
        if(necessity[0]) {
            await fetchPayoutPartners(currency)
        }
    }

    const onChangePayoutPartner = async event => {
        setFeeInfos([])
        var payoutPartnerId = event.target.value;
        console.log("payoutPartnerId", payoutPartnerId)
        setSendMoneyObject((prev) => {
            prev.sPayoutPartner = sendMoneyObject.payoutPartners.find(d => d.id == payoutPartnerId)
            prev.payoutPartnerLocations = []; prev.sPayoutPartnerLocation = null;
        })
        setValue('payoutPartnerLocation', "")

        if(sendMoneyObject.isPayoutPartnerLocationRequired) {
            await fetchPayoutPartnerLocations(payoutPartnerId)
        }
    }

    const onChangePayoutPartnerLocation = async event => {
        setFeeInfos([])
        var payoutPartnerLocationId = event.target.value;
        console.log("payoutPartnerLocationId", payoutPartnerLocationId)
        setSendMoneyObject((prev) => {
            prev.sPayoutPartnerLocation = sendMoneyObject.payoutPartnerLocations.find(d => d.id == payoutPartnerLocationId)
        })
    }

    const onFeeSelect = (e, feeInfo) => {
        console.log(feeInfo);

        setFeeSelect(feeInfo.mgiTransactionSessionID)
        handleEpandRow(e, feeInfo.mgiTransactionSessionID)
        setFeeInfo(feeInfo)
    }

    const getFeeName = feeInfo => {
        var name = feeInfo.deliveryOptDisplayName;
        var agent = '';
        if (feeInfo.receiveAgentName != null) {
            agent += ' ' + feeInfo.receiveAgentName;
        }
        if (feeInfo.receiveAgentAbbreviation != null) {
            agent += ' (' + feeInfo.receiveAgentAbbreviation + ')';
        }

        return name + agent
    }

    const feeLookup = () => {

        console.log("amountTypee", Array.from(document.getElementsByName('AmountType')).find(c => c.checked == true).value)
        let stop = false;
        if(sendMoneyObject.isPayoutPartnerRequired && sendMoneyObject.sPayoutPartner == null) {
            setError("payoutPartner", "required",  t('FIELD_REQUIRED'))
            stop = true
        }
        if(sendMoneyObject.isPayoutPartnerLocationRequired && sendMoneyObject.sPayoutPartnerLocation == null) {
            setError({"payoutPartnerLocation": t('FIELD_REQUIRED')})
            stop = true
        }
        if(stop) return

        // return
        
        console.log('feeLookup VV')
        setFeeInfo(null)
        setFeeInfos([])
        setMessage(null)

        //Check limits
        var limits = codeTable?.limits ?? []
        console.log('limits')
        console.log(limits)
        let sendingAmount = document.getElementById('amount').value;
        // var limit = limits[globalAuthUser?.location?.agent_country?.isoname][sendingCurrency] ?? null
        var limit = 100000000
        console.log('limit', limit)
        if (limit != null) {
            if (parseFloat(sendingAmount) > parseFloat(limit)) {
                setMessage(t('SEND_AMOUNT_LIMIT', { limit: limit + ' ' + 'SLE' }))
                return;
            }
        }

        setSearchLoader(true)
        dispatch(showLoading());

        let params = {}
        // params.sendingCountry = globalAuthUser.location.agent_country.sortname ?? "SL"
        params.sendingCountry = "SL"
        params.DeliveryMethod = sendMoneyObject.sDeliveryMethod.id
        params.ProductID = sendMoneyObject.sProduct.id
        params.CurrencyFrom = "SLE"
        params.CountryTo = sendMoneyObject.sDestCountry.country_code 
        params.CurrencyTo = sendMoneyObject.sReceivingCurrency
        params.Amount = sendingAmount
        params.PromoCode = document.getElementById('promoCode').value
        params.AmountType = Array.from(document.getElementsByName('AmountType')).find(c => c.checked == true).value
        if(sendMoneyObject.sDeliveryMethod.id == 2) params.BankID = sendMoneyObject.sPayoutPartner.id
        else { 
            if(sendMoneyObject.sPayoutPartner != null) params.PayingCorrespID = sendMoneyObject.sPayoutPartner.id
            if(sendMoneyObject.sPayoutPartnerLocation != null) params.PayingCorrespLocID = sendMoneyObject.sPayoutPartnerLocation.id
        }

        axios.post(
            process.env.REACT_APP_PARTNER_MODULE_BASE_URL + '/api/ria/feeLookup', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            }
        }).then(res => {
            setSearchLoader(false)
            dispatch(hideLoading());
            var feeInfo = []
            var status = res.data.status
            if (status == "SUCCESS") {
                feeInfo = res.data.data ?? []
                if (!Array.isArray(feeInfo)) {
                    feeInfo = [feeInfo]
                }
            } else {
                let errorMsg = "";
                let msg = res.data?.message;
                if(typeof msg == 'object' && msg != null) {
                    if('Error' in msg && typeof msg.Error == 'object' && msg.Error != null) {
                        errorMsg = msg.Error.ErrorMsg;
                    }
                } else {
                    errorMsg = msg;
                }
                dispatch(showToast(["danger", "", errorMsg]))
                // setMessage(errorMsg ?? t('ERROR_OCCURED'))
            }

            setFeeInfos(feeInfo);
            if(feeInfo.length == 1) {
                setFeeInfo(feeInfo[0])
            }
            
        }) 
        .catch((error) => {
            dispatch(hideLoading());
            console.log(error)
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
        });
    }

    const onNext = async () => {
        console.log("in next", feeInfo)
        let isBalanceOk = false
        let balanceResp = await checkAgentBalance()
        console.log('in 11', balanceResp)
        console.log('in 1a', Number(balanceResp.balance))
        console.log('in 1b', Number(globalAuthUser.location.max_credit_limit))
        console.log('in 1c', Number(feeInfo.GrandTotal))
        if(globalAuthUser.location.max_credit_limit != null) {
            console.log('in 1')
            if(balanceResp != null && (Number(balanceResp.balance) + Number(globalAuthUser.location.max_credit_limit) >= Number(feeInfo.GrandTotal)) ) {
                isBalanceOk = true
                console.log('in 2')
            }
        } else if(globalAuthUser.location.bnb_owned != 1) {
            console.log('in 3')
            if(balanceResp != null && (Number(balanceResp.balance)  >= Number(feeInfo.GrandTotal)) ) {
                isBalanceOk = true
            }
        }

        if(isBalanceOk == false) {
            dispatch(
                showToast(["danger", "ERROR", t("INSUFFICIENT_BALANCE")])
            )
            return;
        } else {
            setTest(prev => prev + 1, s => console.log("test val", s))

            setSendMoneyObject(prev => {
                // prev.ProductID = test
                // prev.ProductID = ProductId
                prev.sendCurrency = "SLE"
                // prev.receiveCountry = receivingCountry
                // prev.receiveCurrency = ReceivingCurrency
                prev.sendAmount = feeInfo.LocalAmount
                prev.promoCode = document.getElementById('promoCode').value
                prev.amountType = Array.from(document.getElementsByName('AmountType')).find(c => c.checked == true).value
                prev.feeInfos = feeInfos
                prev.sendingFee = feeInfo.CustomerCharge
                prev.sendingTax = feeInfo.Tax
                prev.grandTotal = feeInfo.GrandTotal
                prev.rate = feeInfo.Rate
                prev.receiveAmount = feeInfo.ForeignAmount
                // prev.consumers = []
                prev.previousStep = 1
                prev.step = 2
                prev.requiredFields = feeInfo.RequiredFields?.RequiredField
            })
        }






        // let res = await checkAgentBalance()
        // console.log("balance", res)
        // if(res.data.status == 0) {
        //     console.log("balance not found")
        //     return;
        // }
        // let balanceData = res.data.data
        // if(+feeInfos[0].GrandTotal > +balanceData.balance) {
        //     // console.log("unsiffisant balance")
        //     dispatch(showToast(["danger", "NOTICE", "unsiffisant balance"]));
        //     return;
        // }

       
    }

    

    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});

    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleEpandRow = (event, mgiId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(mgiId);

        let obj = {};
        isRowExpanded ? (obj[mgiId] = false) : (obj[mgiId] = true);
        setExpandState(obj);

        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== mgiId) :
            currentExpandedRows.concat(mgiId);

        setExpandedRows(newExpandedRows);
    }

    const trStyle = {
        height: "30px"
    }

    function checkPayoutPartnerNecessity() {
        let deliveryMethod = sendMoneyObject.sDeliveryMethod
        switch(deliveryMethod.id) {
            case 1: {
                if(deliveryMethod.networkModel.model == "Open Payment") {
                    setSendMoneyObject((prev) => {
                        prev.isPayoutPartnerRequired = false
                        prev.isPayoutPartnerLocationRequired = false
                    })
                    return [ false, false]
                } 
                else if(deliveryMethod.networkModel.model == "Open Network") {
                    setSendMoneyObject((prev) => {
                        prev.isPayoutPartnerRequired = true
                        prev.isPayoutPartnerLocationRequired = false
                    })
                    return [ true, false]
                } 
                else if(deliveryMethod.networkModel.model == "Non-Open Network") {
                    setSendMoneyObject((prev) => {
                        prev.isPayoutPartnerRequired = true
                        prev.isPayoutPartnerLocationRequired = true
                    })
                    return [ true, true]
                } 
            }
            case 2: {
                if(deliveryMethod.networkModel.model == "Open Network") {
                    setSendMoneyObject((prev) => {
                        prev.isPayoutPartnerRequired = true
                        prev.isPayoutPartnerLocationRequired = false
                    })
                    return [ true, false]
                } 
                else if(deliveryMethod.networkModel.model == "Non-Open Network") {
                    setSendMoneyObject((prev) => {
                        prev.isPayoutPartnerRequired = true
                        prev.isPayoutPartnerLocationRequired = true
                    })
                    return [ true, true]
                } 
            }
            case 34: {
                setSendMoneyObject((prev) => {
                    prev.isPayoutPartnerRequired = true
                    prev.isPayoutPartnerLocationRequired = true
                })
                return [ true, true]
            }
            default: {
                setSendMoneyObject((prev) => {
                    prev.isPayoutPartnerRequired = false
                    prev.isPayoutPartnerLocationRequired = false
                })
                return [ false, false]
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(feeLookup)}>
                <div className='row'>

                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <label htmlFor='receiving_country'>{t("RECEIVING_COUNTRY")} <span style={{ color: "red" }}>*</span></label>
                        <select style={{ maxWidth: "100%" }} className={`form-select ${errors.receiving_country ? 'is-invalid' : ''}`}  name="receiving_country" id="receiving_country" defaultValue={SEND_MONEY_OBJECT.sDestCountry?.country_code}
                         {...register('receiving_country', {
                            onChange: (e) => onChangeReceivingCountry(e)
                        })}
                        >
                            <option value="">{t("SELECT")}</option>
                            {
                                codeTable?.countries_info.map((receivingCountry, key) => (
                                    <option value={receivingCountry.country_code} key={key}>{receivingCountry.country_name}</option>
                                ))
                            }
                        </select>
                        <p className='input-error-msg'> {errors.receiving_country?.message} </p>
                    </div>

                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <label htmlFor='product'>{t("deliveryMethod")} <span style={{ color: "red" }}>*</span></label>
                        <select style={{ maxWidth: "100%" }} className={`form-select ${errors.deliveryMethod ? 'is-invalid' : ''}`}  name="deliveryMethod" id="deliveryMethod" defaultValue={SEND_MONEY_OBJECT.sDeliveryMethod?.id} {...register('deliveryMethod') } onChange={(e) => onDeliveryChange(e)}
                        >
                            <option value="">{t("SELECT")}</option>
                            {
                                sendMoneyObject?.deliveryMethods?.map((deliveryMethod, key) => (
                                    <option value={deliveryMethod.id} key={key}>{deliveryMethod.name}</option>
                                ))
                            }
                        </select>
                        <p className='input-error-msg'> {errors.receiving_country?.message} </p>
                    </div>

                    {/* <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <label htmlFor='product'>{t("product")} <span style={{ color: "red" }}>*</span></label>
                        <select style={{ maxWidth: "100%" }} className={`form-select ${errors.product ? 'is-invalid' : ''}`}  name="product" id="product" defaultValue={SEND_MONEY_OBJECT.deliveryMethod} {...register('product') } onChange={(e) => onProductChange(e)}
                        >
                            <option value="">{t("SELECT")}</option>
                            {
                                getProductArray()?.map((product, key) => (
                                    <option value={product.id} key={key}>{product.name}</option>
                                ))
                            }
                        </select>
                        <p className='input-error-msg'> {errors.receiving_country?.message} </p>
                    </div> */}

                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <div className='form-group'>
                            <label htmlFor='receiving_currency'>{t("RECEIVING_CURRENCY")} <span style={{ color: "red" }}>*</span></label>
                            <select style={{ maxWidth: "100%" }} className={`form-select ${errors.receiving_currency ? 'is-invalid' : ''}`} id="receiving_currency" name="receiving_currency" defaultValue={SEND_MONEY_OBJECT.sReceivingCurrency} {...register('receiving_currency', {
                            onChange: (e) => onChangeReceivingCurrency(e)
                        })}>
                                <option value="">{t("SELECT")}</option>
                                {
                                    getCurrencyArray()?.map((currency, key) => (
                                        <option value={currency} key={key}>{currency}</option>
                                    ))
                                }
                            </select>
                            <p className='input-error-msg'> {errors.receiving_currency?.message} </p>
                        </div>
                    </div>

                    <div className="col-sm-6" style={{ marginTop: "25px" }}>
                        <div className="form-group">
                            <label htmlFor='amount'>{t("AMOUNT")} <span style={{ color: "red" }}>*</span></label>
                            <input type="number" id='amount' style={{ maxWidth: "100%" }} className={`form-control ${errors.amount ? 'is-invalid' : ''}`} defaultValue={SEND_MONEY_OBJECT.sendAmount} placeholder={t("AMOUNT")} {...register('amount', {
                                onChange: (e) => setAmount(e.target.value),
                            })} />
                        </div>
                        <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors.amount?.message} </p>
                        <div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="AmountType" value="LocalAmount" id="LocalAmount" className="form-check-input" checked={AmountType == 'LocalAmount' || SEND_MONEY_OBJECT.amountType == 'LocalAmount' } onChange={() => setAmountType('LocalAmount')} />
                                <label className="form-check-label" htmlFor='LocalAmount'>{t("EXCLUDE_FEES")}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="AmountType" value="GrandTotal" id="GrandTotal" className="form-check-input" checked={AmountType == 'GrandTotal'  || SEND_MONEY_OBJECT.amountType == 'GrandTotal'} onChange={() => setAmountType('GrandTotal')} />
                                <label className="form-check-label" htmlFor='GrandTotal'>{t("INCLUDE_FEES")}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="AmountType" value="ForeignAmount" id="ForeignAmount" className="form-check-input" checked={AmountType == 'ForeignAmount'  || SEND_MONEY_OBJECT.amountType == 'ForeignAmount'} onChange={() => setAmountType('ForeignAmount')} />
                                <label className="form-check-label" htmlFor='ForeignAmount'>{t("RECEIVE_AMOUNT")}</label>
                            </div>
                        </div>
                    </div>

                    {
                        sendMoneyObject.isPayoutPartnerRequired &&
                        <div className="col-sm-6" style={{ marginTop: "25px" }}>
                            <div className='form-group'>
                                <label htmlFor='payoutPartner'>{t("payoutPartner")} <span style={{ color: "red" }}>*</span></label>
                                <select style={{ maxWidth: "100%" }} className={`form-select ${errors.payoutPartner ? 'is-invalid' : ''}`} name="payoutPartner" defaultValue={SEND_MONEY_OBJECT.sPayoutPartner?.id} id="payoutPartner" {...register('payoutPartner')} onChange={(e) => onChangePayoutPartner(e)}>
                                    <option value="">{t("SELECT")}</option>
                                    {
                                        getPayoutPartnersArray()?.map((partner, key) => (
                                            <option value={partner.id} key={key}>{partner.name}</option>
                                        ))
                                    }
                                </select>
                                <p className='input-error-msg'> {errors.payoutPartner?.message} </p>
                            </div>
                        </div>
                    } 

                    {
                        sendMoneyObject.isPayoutPartnerLocationRequired &&
                        <div className="col-sm-6" style={{ marginTop: "25px" }}>
                            <div className='form-group'>
                                <label htmlFor='payoutPartnerLocation'>{t("payoutPartnerLocation")} <span style={{ color: "red" }}>*</span></label>
                                <select style={{ maxWidth: "100%" }} className={`form-select ${errors.payoutPartnerLocation ? 'is-invalid' : ''}`} name="payoutPartnerLocation" defaultValue={SEND_MONEY_OBJECT.sPayoutPartnerLocation?.id} id="payoutPartnerLocation" {...register('payoutPartnerLocation')} onChange={(e) => onChangePayoutPartnerLocation(e)}>
                                    <option value="">{t("SELECT")}</option>
                                    {
                                        getPayoutPartnerLocationArray()?.map((location, key) => (
                                            <option value={location.id} key={key}>{location.name}</option>
                                        ))
                                    }
                                </select>
                                <p className='input-error-msg'> {errors.payoutPartnerLocation?.message} </p>
                            </div>
                        </div>
                    } 

                    <div className='col-sm-12' style={{ padding: "5px 15px" }}>
                        <hr   />
                    </div>

                    <div className="col-sm-6" style={{ marginTop: "0px" }}>
                        <div className="form-group">
                            <label htmlFor='promoCode'> {t("PROMO_CODE")} <span style={{ color: "red" }}>*</span></label>
                            <input type="number" id='promoCode' style={{ maxWidth: "100%" }} className={`form-control ${errors.promoCode ? 'is-invalid' : ''}`} placeholder={t("PROMO_CODE")} defaultValue={SEND_MONEY_OBJECT.promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        </div>
                        <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors.promoCode?.message} </p>
                    </div>

                    <div className='col-sm-12' style={{ padding: "5px 15px" }}>
                        <hr   />
                    </div>

                    <div className="col-sm-12 form-group" style={{ marginTop: "0px" }}>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name='ack_fraud_warning' id='ack_fraud_warning' {...register('ack_fraud_warning', {
                                onChange: (e) => console.log('ack_fraud_warning'),
                            })} />
                            <label className="form-check-label" htmlFor='ack_fraud_warning'>
                                {t("ACKNOWLEDGE_FRAUD_WARNINGS")}
                            </label>
                            {
                                errors.ack_fraud_warning?.message &&
                                <p className='input-error-msg'> {errors.ack_fraud_warning?.message} </p>
                            }
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name='ack_terms_conditions' id='ack_terms_conditions' {...register('ack_terms_conditions', {
                                onChange: (e) => console.log('ack_terms_conditions'),
                            })} />
                            <label className="form-check-label" htmlFor='ack_terms_conditions'>
                                {t("ACKNOWLEDGE_TCS")}
                            </label>
                            {
                                errors.ack_terms_conditions?.message &&
                                <p className='input-error-msg'> {errors.ack_terms_conditions?.message} </p>
                            }
                        </div>
                    </div>

                    <hr style={{ marginTop: "15px" }} />

                    <div className="col-sm-12 form-group" style={{ marginTop: "25px", textAlign: "right" }}>
                        <button className='btn btn-info' type='submit' disabled={searchLoader}>{t("SEARCH")} &nbsp;
                            <FaSearch size={18} color={'white'} />
                        </button>
                    </div>
                </div>
            </form>
            <br />
            <div>
                {
                    feeInfos.length != 0 &&
                    <div className='table-responsive'>
                        <table className="table table-hover w-100">
                            <thead>
                                <tr>
                                    <th>{t('SELECT')}</th>
                                    <th>{t('AMOUNT')}</th>
                                    <th>{t('FEE')}</th>
                                    <th>{t('TAX')}</th>
                                    <th>{t('TOTAL')}</th>
                                    <th>{t('FX_RATE')}</th>
                                    <th>{t('RECEIVING_AMOUNT')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    feeInfos.map((feeInfo, key) => (
                                        <>
                                            <tr key={key} style={{ cursor: "pointer" }} onClick={(e) => onFeeSelect(e, feeInfo)}>
                                                <td><input type="radio" className="form-check-input" checked/></td>
                                                <td>{formatNumber(feeInfo?.LocalAmount) + " SLE"}</td>
                                                <td>{formatNumber(feeInfo?.CustomerCharge) + " SLE"}</td>
                                                <td>{formatNumber(feeInfo?.Tax) + " SLE"}</td>
                                                <td>{formatNumber(feeInfo?.GrandTotal) + " SLE"}</td>
                                                <td>{formatNumber(feeInfo?.Rate) + " SLE"}</td>
                                                <td>{formatNumber(feeInfo?.ForeignAmount) + ' ' + ReceivingCurrency}</td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <div className='container mt-4'>
                {
                    message != null &&
                    <p className='input-error-msg'>{message}</p>
                }
            </div>
            <br />

            <div style={{ textAlign: "right" }}>
                <button type='submit' className='btn btn-primary' onClick={onNext} disabled={nextLoader || feeInfos.length == 0 }>
                    {t('NEXT')} 
                </button>
            </div>
        </div>
    );

    function useStateCallback(initialState) {
        const [state, setState] = useImmer(initialState);
        const cbRef = useRef(null);
      
        const setStateCallback = useCallback((state, cb) => {
          cbRef.current = cb; 
          setState(state);
        }, []);
      
        useEffect(() => {
          if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
          }
        }, [state]);
      
        return [state, setStateCallback];
      }

      async function checkAgentBalance() {
        dispatch(showLoading());
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/locationBalance",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': authUser().tokenType + ' ' + authUser().token
                }
            })
            console.log(res.data.data)
            dispatch(hideLoading());
            // return res;
            if (res.data.status == 1) {
               return res.data.data
            } else return null
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
            return null
        }
    }

}

export default RiaFees