import React from 'react'
import axios from 'axios';
import { FaCheckCircle, FaPrint } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import i18next from "i18next";
import { useAuthUser } from 'react-auth-kit';
import { showLoading, hideLoading } from '../../../../slices/loading-slice';

function MoneygramFormFreeSendComplete() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const reference = useSelector((state) => state.sendMoneyMoneygram.reference);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    const receipt = () => {
        dispatch(showLoading())

        let params = {
            location: globalAuthUser?.location?.code,
            language: i18next.language,
            transaction_reference: reference
        }

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/receipt', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
            responseType: 'blob'
        }).then(res => {
            dispatch(hideLoading())

            console.log(res);
            var status = res?.data?.status
            if (status == 0) {
                // Display message
            }

            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }).catch((error) => {
            console.log(error);
            dispatch(hideLoading());
        });
    }

    return (
        <div>
            <div style={{ textAlign: "right" }}>
                <button type='button' className='btn btn-primary' onClick={() => receipt()}>
                    {t('RECEIPT')} &nbsp;
                    <FaPrint size={18} fill={'white'} />
                </button>
            </div>
            <div style={{ marginTop: "25px" }}>
                <div style={{ textAlign: "center", marginTop: "40px" }}>
                    <FaCheckCircle size={80} fill={'green'} />
                    <h1>{t('TRANSMITTED')}</h1>

                    <div style={{ marginTop: "40px" }}>
                        <h3 className='mt-5'><b>{t('REFERENCE')}:</b> {reference}</h3>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: "right", marginTop: "25px" }}>
                <button type='button' className='btn btn-primary' onClick={() => navigate('/send-money/' + reference)}>
                    Transaction Details
                </button> &nbsp;
                <button type='button' className='btn btn-info' onClick={() => navigate('/send-money')}>
                    Sent Transactions
                </button>
            </div>
        </div>
    )

}

export default MoneygramFormFreeSendComplete;