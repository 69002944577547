import React, { useState, useEffect } from "react";
import {
  setGlobalMGreceiveCS,
  set_RECEIVER_OPT_FIELDS,
} from "../../../slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { t } from "i18next";
import { countryStates, DaysList, MonthList, YearExpirationList, YearIssuingList } from "../../../utils/helper";

// const schema = yup.object().shape({
// })

function RcvStep3(props) {
  const { show, displayStatus, handleClose, propsData } = props;

  console.log("props", props)

  const DAYS = DaysList();
  const MONTHS = MonthList();
  const YEARS_EXPIRATION = YearExpirationList();
  const YEARS_ISSUING = YearIssuingList();
  const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);

  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.auth.globalMGreceiveCS);
  let RECEIVER_OPT_FIELDS = useSelector(
    (state) => state.auth.RECEIVER_OPT_FIELDS
  );

  let MG_GFFP_USED_OPT = useSelector((state) => state.auth.MG_GFFP_USED_OPT);
  let MG_GFFP_NOT_USED_OPT = useSelector(
    (state) => state.auth.MG_GFFP_NOT_USED_OPT
  );
  let RECEIVER_INFO = useSelector((state) => state.auth.RECEIVER_INFO);
  let RECEIVER_COMPLIANCE = useSelector((state) => state.auth.RECEIVER_COMPLIANCE);
  let CODETABLE = useSelector((state) => state.auth.CODETABLE);

  let RECEIVER_OPT_INFO = useSelector((state) => state.auth.RECEIVER_OPT_INFO);
  let RECEIVER_OPT_COMPLIANCE = useSelector(
    (state) => state.auth.RECEIVER_OPT_COMPLIANCE
  );

  const [receverStates, setReceverStates] = useState(countryStates(CODETABLE, RECEIVER_INFO?.receiverCountry));
  const [photoIdStates, setPhotoIdStates] = useState(countryStates(CODETABLE, RECEIVER_COMPLIANCE?.receiverPhotoIdCountry));
  const [legalIdStates, setLegalIdStates] = useState(countryStates(CODETABLE, RECEIVER_OPT_FIELDS?.receiverLegalIdIssueCountry));


  //   const propsData = props.items;
  console.log(propsData);

  let informationFields = [];
  let complianceFields = [];

  useEffect(() => {
    console.log("useEffect");
    console.log(RECEIVER_OPT_FIELDS);
  }, [show]);

  const onPrevious = () => {
    dispatch(setGlobalMGreceiveCS(2));
  };

  const onNext = (data, event) => {
    console.log(data);
    dispatch(set_RECEIVER_OPT_FIELDS(data));
    handleClose();
    // reset();
  };

  groupField();
  console.log(informationFields);
  console.log(complianceFields);

  function groupField() {
    propsData.GFFP.GFFP.optionalInfo.forEach((element) => {
      if (element.fieldCategory.includes("Information")) {
        informationFields = [...informationFields, element];
        if (RECEIVER_INFO?.receiverState !== undefined) {
          informationFields = informationFields.filter(
            (info) => info.xmlTag !== "receiverState"
          );
        }
        if (RECEIVER_INFO?.receiverZipCode !== undefined) {
          informationFields = informationFields.filter(
            (info) => info.xmlTag !== "receiverZipCode"
          );
        }
        if (RECEIVER_INFO?.receiverOccupation !== undefined) {
          informationFields = informationFields.filter(
            (info) => info.xmlTag !== "receiverOccupation"
          );
        }
        informationFields = informationFields.filter( (info) => info.xmlTag !== "receiverOccupationOther");

      } else if (
        element.fieldCategory.includes("Identification") ||
        element.fieldCategory.includes("Compliance")
      ) {
        complianceFields = [...complianceFields, element];
        complianceFields = complianceFields.filter( (info) => info.xmlTag !== "relationshipToSender" );
        complianceFields = complianceFields.filter( (info) => info.xmlTag !== "receiverIntendedUseOfMGIServices");
        if (RECEIVER_COMPLIANCE?.receiverPhotoIdState !== undefined) {
          complianceFields = complianceFields.filter((info) => info.xmlTag !== "receiverPhotoIdState");
        }
      }
    });
    // if (RECEIVER_COMPLIANCE?.receiverPhotoIdCountry !== undefined) {
    //   setPhotoIdStates(countryStates(CODETABLE, RECEIVER_COMPLIANCE?.receiverPhotoIdCountry))
    // }
  }

  var rcvOptional = {};
  var cycleArray = [];
  informationFields?.forEach((field) => {
    var val = yup
      .string()
      .nullable()
      .notRequired()
      .max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }))
      .when(field.xmlTag, {
        is: (value) => value?.length,
        then: (rule) => rule.min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })),
      });

    if (field.validationRegEx != null) {
      val = yup
        .string()
        .nullable()
        .notRequired()
        .max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }))
        .when(field.xmlTag, {
          is: (value) => value?.length,
          then: (rule) => rule.min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })),
        })
        .matches(field.validationRegEx, t("MUST_MATCH_REGEX") + " " + field.validationRegEx)
    }

    if (field.dataType === "enum") {
      val = yup
        .string()
        .nullable()
        .notRequired()
    }

    var cyc = [];
    cyc.push(field.xmlTag);
    cyc.push(field.xmlTag);
    cycleArray.push(cyc);

    rcvOptional[field.xmlTag] = val;
  });
  complianceFields?.forEach((field) => {
    var val = yup
      .string()
      .nullable()
      .notRequired()
      .max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }))
      .when(field.xmlTag, {
        is: (value) => value?.length,
        then: (rule) => rule.min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })),
      });

    if (field.validationRegEx != null) {
      val = yup
        .string()
        .nullable()
        .notRequired()
        .max(field.fieldMax, t("FIELD_MAX", { max: field.fieldMax }))
        .when(field.xmlTag, {
          is: (value) => value?.length,
          then: (rule) => rule.min(field.fieldMin, t("FIELD_MIN", { min: field.fieldMin })),
        })
        .matches(field.validationRegEx, t("MUST_MATCH_REGEX") + " " + field.validationRegEx)
    }

    if (field.dataType === "enum") {
      val = yup
        .string()
        .nullable()
        .notRequired()
    }

    var cyc = [];
    cyc.push(field.xmlTag);
    cyc.push(field.xmlTag);
    cycleArray.push(cyc);

    rcvOptional[field.xmlTag] = val;
  });
  // complianceFields?.forEach(field => {
  //     var val = yup.string().min(field.fieldMin).max(field.fieldMax);
  //     if (field.validationRegEx != null) {
  //         val = yup.string().min(field.fieldMin).max(field.fieldMax).matches(field.validationRegEx);
  //     }
  //     rcvOptional[field.xmlTag] = val;
  // });

  console.log(rcvOptional);
  console.log(cycleArray);

  const schema = yup.object().shape(rcvOptional, cycleArray);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  function labelName(xmlTag) {
    // return GFFP_RESP.productFieldInfo.find((info) => info.xmlTag === xmlTag)
    //   .fieldLabel;
    return t("MONEYGRAM." + xmlTag);
  }

  function getYersList(field) {
    if(field.xmlTag.endsWith('ExpirationYear')) {
      return YEARS_EXPIRATION;
    } else if(field.xmlTag.endsWith('IssueYear')) {
      return YEARS_ISSUING;
    }
  }

  const onCountryChange = (event, field) => {
    console.log("event", event);
    console.log("field", field);
    if(field.xmlTag === "receiverPhotoIdCountry") {
      setPhotoIdStates(countryStates(CODETABLE, event.target.value))
    }
    if(field.xmlTag === "receiverLegalIdIssueCountry") {
      setLegalIdStates(countryStates(CODETABLE, event.target.value))
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onNext)}>
        <div
          className={`modal fade ${show}`}
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          role="dialog"
          tabIndex={-1}
          style={{ display: displayStatus }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("RECEIVER_OPTIONAL_INFO")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                />
              </div>
              <div
                className="modal-body"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {t("OPTIONAL_INFO")}
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          {informationFields.map((field, ind) =>
                            field.enumerated == true ? (
                              <div key={ind} className="col-md-6">
                                <label
                                  htmlFor={field.xmlTag}
                                  className="col-form-label"
                                >
                                  {labelName(field.xmlTag)}
                                </label>
                                <select
                                  id={field.xmlTag}
                                  name={field.xmlTag}
                                  className="form-select form-control"
                                  aria-label="Default select example"
                                  defaultValue={
                                    RECEIVER_OPT_FIELDS !== null
                                      ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                      : null
                                  }
                                  {...register(field.xmlTag)}
                                >
                                  <option value="" defaultValue>
                                    {" "}
                                  </option>
                                  {field.enumeratedValues.enumeratedValueInfo.map(
                                    (item, index) => (
                                      <option key={index} value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                <p className="input-error-msg">
                                  {" "}
                                  {errors[field.xmlTag]?.message}{" "}
                                </p>
                              </div>
                            ) : field.dataType === "cntrycode" ? (
                              <div key={ind} className="col-md-6">
                                <label
                                  htmlFor={field.xmlTag}
                                  className="col-form-label"
                                >
                                  {labelName(field.xmlTag)}
                                </label>
                                <select
                                  id={field.xmlTag}
                                  name={field.xmlTag}
                                  className="form-select form-control"
                                  aria-label="Default select example"
                                  defaultValue={
                                    RECEIVER_OPT_FIELDS !== null
                                      ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                      : null
                                  }
                                  {...register(field.xmlTag)}
                                >
                                  <option value="" defaultValue>
                                    {" "}
                                  </option>
                                  {CODETABLE.country_info.map((country, index) => (
                                    <option
                                      key={index}
                                      value={country.country_code}
                                    >
                                      {country.country_name}
                                    </option>
                                  ))}
                                </select>
                                <p className="input-error-msg">
                                  {" "}
                                  {errors[field.xmlTag]?.message}{" "}
                                </p>
                              </div>
                            ) 
                            
                            : field.xmlTag === "receiverState" ? (
                              <div key={ind} className="col-md-6">
                                <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                <select id={field.xmlTag} name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                  defaultValue={ RECEIVER_OPT_FIELDS !== null ? RECEIVER_OPT_FIELDS[field.xmlTag] : null }
                                  {...register(field.xmlTag)}
                                >
                                  <option value="" defaultValue> </option>
                                  {receverStates.map((state, index) => (
                                    <option key={index} value={state.state_province_code}>
                                      {state.state_province_name}
                                  </option>
                                  ))}
                                </select>
                                <p className="input-error-msg"> {errors[field.xmlTag]?.message} </p>
                              </div>
                            )
                            
                            
                            : (
                              <div key={ind} className="col-md-6">
                                <label
                                  htmlFor={field.xmlTag}
                                  className="col-form-label"
                                >
                                  {labelName(field.xmlTag)}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={field.xmlTag}
                                  defaultValue={
                                    RECEIVER_OPT_FIELDS !== null
                                      ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                      : null
                                  }
                                  {...register(field.xmlTag)}
                                />
                                <p className="input-error-msg">
                                  {" "}
                                  {errors[field.xmlTag]?.message}{" "}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h1 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {t("OPTIONAL_COMPLIANCE")}
                        </span>
                      </button>
                    </h1>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          {
                            complianceFields.map((field, ind) =>
                              field.enumerated == true ? (
                                <div key={ind} className="col-md-6">
                                  <label
                                    htmlFor={field.xmlTag}
                                    className="col-form-label"
                                  >
                                    {labelName(field.xmlTag)}
                                  </label>
                                  <select
                                    id={field.xmlTag}
                                    name={field.xmlTag}
                                    className="form-select form-control"
                                    aria-label="Default select example"
                                    defaultValue={
                                      RECEIVER_OPT_FIELDS !== null
                                        ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                        : null
                                    }
                                    {...register(field.xmlTag)}
                                  >
                                    <option value="" defaultValue>
                                      {" "}
                                    </option>
                                    {field.enumeratedValues.enumeratedValueInfo.map(
                                      (item, index) => (
                                        <option key={index} value={item.value}>
                                          {item.label}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <p className="input-error-msg">
                                    {" "}
                                    {errors[field.xmlTag]?.message}{" "}
                                  </p>
                                </div>
                              ) 
                              
                              : field.dataType === "cntrycode" ? (
                                <div key={ind} className="col-md-6">
                                  <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                  <select id={field.xmlTag} name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                    defaultValue={ RECEIVER_OPT_FIELDS !== null ? RECEIVER_OPT_FIELDS[field.xmlTag] : null }
                                    {...register(field.xmlTag)}
                                    onChange={e => onCountryChange(e, field)}
                                  >
                                    <option value="" defaultValue> </option>
                                    {CODETABLE.country_info.map((country, index) => (
                                      <option key={index} value={country.country_code}>
                                        {country.country_name}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="input-error-msg"> {errors[field.xmlTag]?.message} </p>
                                </div>
                              )

                              : (field.xmlTag.endsWith('Day') && field.fieldMax === 2 && field.validationRegEx === "^[0-9]*$") ? (
                                <div key={ind} className="col-md-6">
                                  <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                  <select id={field.xmlTag}  name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                    defaultValue={
                                      RECEIVER_OPT_FIELDS !== null
                                        ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                        : null
                                    }
                                    {...register(field.xmlTag)}
                                  >
                                    {/* <option value=""> {LANGUAGE === "en" ? "Day" : "Jour"} </option> */}
                                    <option value=""> </option>
                                    { DAYS?.map((day, index) => (
                                      <option key={ index } value={ day.value }>
                                        { day.label }
                                      </option>
                                    )) }
                                  </select>
                                  <p className="input-error-msg"> { errors[field.xmlTag]?.message } </p>
                                </div>
                              )

                              : (field.xmlTag.endsWith('Month') && field.fieldMax === 2 && field.validationRegEx === "^[0-9]*$") ? (
                                <div key={ind} className="col-md-6">
                                  <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                  <select id={field.xmlTag}  name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                    defaultValue={
                                      RECEIVER_OPT_FIELDS !== null
                                        ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                        : null
                                    }
                                    {...register(field.xmlTag)}
                                  >
                                    {/* <option value=""> {LANGUAGE === "en" ? "Month" : "Mois"} </option> */}
                                    <option value="">  </option>
                                      {MONTHS?.map((month, index) => (
                                        <option key={index} value={month.value}>
                                          {LANGUAGE === "en" ? month.labelEn : month.labelFr}
                                        </option>
                                      ))}
                                  </select>
                                  <p className="input-error-msg"> { errors[field.xmlTag]?.message } </p>
                                </div>
                              )

                              : (field.xmlTag.endsWith('Year') && field.fieldMax === 4 && field.validationRegEx === "^[0-9]*$") ? (
                                <div key={ind} className="col-md-6">
                                  <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                  <select id={field.xmlTag}  name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                    defaultValue={
                                      RECEIVER_OPT_FIELDS !== null
                                        ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                        : null
                                    }
                                    {...register(field.xmlTag)}
                                  >
                                    {/* <option value=""> {LANGUAGE === "en" ? "Year" : "Annee"} </option> */}
                                    <option value="">  </option>
                                    { getYersList(field)?.map((year, index) => (
                                      <option key={ index } value={ year.value }>
                                        { year.label }
                                      </option>
                                    )) }
                                  </select>
                                  <p className="input-error-msg"> { errors[field.xmlTag]?.message } </p>
                                </div>
                              )

                              
                              
                              : field.xmlTag === "receiverPhotoIdState" ? (
                                <div key={ind} className="col-md-6">
                                  <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                  <select id={field.xmlTag} name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                    defaultValue={ RECEIVER_OPT_FIELDS !== null ? RECEIVER_OPT_FIELDS[field.xmlTag] : null }
                                    {...register(field.xmlTag)}
                                  >
                                    <option value="" defaultValue> </option>
                                    {photoIdStates.map((state, index) => (
                                      <option key={index} value={state.state_province_code}>
                                        {state.state_province_name}
                                    </option>
                                    ))}
                                  </select>
                                  <p className="input-error-msg"> {errors[field.xmlTag]?.message} </p>
                                </div>
                              )

                              : field.xmlTag === "receiverLegalIdState" ? (
                                <div key={ind} className="col-md-6">
                                  <label htmlFor={field.xmlTag} className="col-form-label" > {labelName(field.xmlTag)} </label>
                                  <select id={field.xmlTag} name={field.xmlTag} className="form-select form-control" aria-label="Default select example"
                                    defaultValue={ RECEIVER_OPT_FIELDS !== null ? RECEIVER_OPT_FIELDS[field.xmlTag] : null }
                                    {...register(field.xmlTag)}
                                  >
                                    <option value="" defaultValue> </option>
                                    {legalIdStates.map((state, index) => (
                                      <option key={index} value={state.state_province_code}>
                                        {state.state_province_name}
                                    </option>
                                    ))}
                                  </select>
                                  <p className="input-error-msg"> {errors[field.xmlTag]?.message} </p>
                                </div>
                              )
                              
                              : (
                                <div key={ind} className="col-md-6">
                                  <label
                                    htmlFor={field.xmlTag}
                                    className="col-form-label"
                                  >
                                    {labelName(field.xmlTag)}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={field.xmlTag}
                                    defaultValue={
                                      RECEIVER_OPT_FIELDS !== null
                                        ? RECEIVER_OPT_FIELDS[field.xmlTag]
                                        : null
                                    }
                                    {...register(field.xmlTag)}
                                  />
                                  <p className="input-error-msg">
                                    {" "}
                                    {errors[field.xmlTag]?.message}{" "}
                                  </p>
                                </div>
                              )
                            )

                            // complianceFields.map((field, ind) => (
                            //     field.enumerated == false ?
                            //         <div key={ind} className="col-md-4">
                            //             <label htmlFor={field.xmlTag} className="col-form-label"> {field.fieldLabel} </label>
                            //             <input type="text" className="form-control" id={field.xmlTag} />
                            //         </div> :
                            //         <div key={ind} className="col-md-4">
                            //             <label htmlFor={field.xmlTag} className="col-form-label"> {field.fieldLabel} </label>
                            //             <select id={field.xmlTag} name={field.xmlTag} className="form-select form-control" aria-label="Default select example">
                            //                 <option value="" defaultValue> </option>
                            //                 {field.enumeratedValues.enumeratedValueInfo.map((item, index) => (
                            //                     <option key={index} value={item.value}>{item.label}</option>
                            //                 ))}
                            //             </select>
                            //         </div>
                            // ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  className=""
                  style={{
                    padding: ".75rem .5rem",
                    borderTop: ".5px solid rgba(0,0,0,.125)",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    onClick={onPrevious}
                  >
                    <FaArrowCircleLeft size={18} fill="white" /> Previous{" "}
                  </button>{" "}
                  &nbsp; &nbsp;
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <FaArrowCircleRight size={18} fill="white" /> Next{" "}
                  </button>
                </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  onClick={handleClose}
                >
                  {t("CLOSE")}
                </button>{" "}
                &nbsp; &nbsp;
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  {t("SAVE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default RcvStep3;
