import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function LocalSendFees(props) {
    const { t } = useTranslation();
    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);
    
    const formatMoney = (money) => {
        let currency = globalAuthUser.location.agent_country ? globalAuthUser.location.agent_country.currency : "";
        let formatedAmt = Number(money).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2});
        
        // return the formatted fee including the currency
        return currency != "" ? `${formatedAmt} (${currency})` : formatedAmt;
    }

    return (
        <>
            {
                (props.amount && props.amount != 0) &&
                <div className="row mb-3 border-bottom">
                    <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-lg-6 mb-2">
                        <ul className='list-group'>
                            <li className='list-group-item d-flex justify-content-between'><span>{ t("AMOUNT_TO_SEND") }:</span> <strong>{formatMoney(props?.amount)}</strong></li>
                            <li className='list-group-item d-flex justify-content-between'><span>{ t("FEES") }:</span> <strong>{formatMoney(props?.fees)}</strong></li>
                            <li className='list-group-item d-flex justify-content-between'><span>{ t("TAX") }:</span> <strong>{formatMoney(props?.taxes)}</strong></li>
                            <li className='list-group-item d-flex justify-content-between'><span>{ t("RECEIVING_AMOUNT") }:</span> <strong>{formatMoney(props?.receive_amount)}</strong></li>
                            <li className='list-group-item d-flex justify-content-between'><span>{ t("TOTAL_AMOUNT") }:</span> <strong>{formatMoney(props?.total_amount)}</strong></li>
                        </ul>
                    </div>
                </div>
            }
        </>
    )
}
