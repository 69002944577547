import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import { getCountryName, getPhoneCode, getSenderName, getReceiverName } from './moneygram';
import { FaArrowCircleLeft, FaArrowCircleRight, FaTimes } from "react-icons/fa";
import { setStep, setSendResponse, setComplete } from '../../../slices/send-money-moneygram-slice';
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { showToast } from "../../../slices/toast-slice";
import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom'

function MoneygramSummary() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const codeTable = useSelector((state) => state.sendMoneyMoneygram.codeTable);
    const consumer = useSelector((state) => state.sendMoneyMoneygram.consumer);
    const sendData = useSelector((state) => state.sendMoneyMoneygram.sendData);
    const receiveData = useSelector((state) => state.sendMoneyMoneygram.receiveData);
    const feeInfo = useSelector((state) => state.sendMoneyMoneygram.feeInfo);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState(null);
    const [promoCodeValid, setPromoCodeValid] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        console.log('MoneygramSummary')

        setPromoCodeValid(feeInfo?.promotionInfo?.promotionErrorMessage == null && feeInfo?.promotionInfo?.promotionCode != null)
    }, [codeTable, sendData, receiveData, feeInfo]);

    const modalRef = useRef()

    const showModal = () => {
        const modalEle = modalRef.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        bsModal.show()
    }

    const hideModal = () => {
        const modalEle = modalRef.current
        const bsModal = Modal.getInstance(modalEle)
        bsModal.hide()
    }

    const schema = yup.object().shape({
        passcode: yup.string().required(t('FIELD_REQUIRED'))
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    const submit = () => {
        console.log('submit')

        //Check Passcode
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            showModal();
            return;
        }

        sendTransaction();
    }

    const sendTransaction = () => {
        console.log('sendTransaction')
        setLoader(true)
        dispatch(showLoading());
        setMessage(null)

        let ops = {};

        ops.consumerId = consumer?.consumerId
        ops.customerId = consumer?.id
        ops.mgiRewardsNumber = consumer?.mgiRewardsNumber ?? consumer?.freqCustCardNumber ?? null
        if (ops.mgiRewardsNumber != null && ops.mgiRewardsNumber != '') {
            if (receiveData.senderTransactionEmailNotificationOptIn == null) {
                ops.senderTransactionEmailNotificationOptIn = false;
            }
            if (receiveData.senderTransactionSMSNotificationOptIn == null) {
                ops.senderTransactionSMSNotificationOptIn = false;
            }
            if (receiveData.senderMarketingEmailNotificationOptIn == null) {
                ops.senderMarketingEmailNotificationOptIn = false;
            }
            if (receiveData.senderMarketingSMSNotificationOptIn == null) {
                ops.senderMarketingSMSNotificationOptIn = false;
            }
            if (receiveData.receiverTransactionEmailNotificationOptIn == null) {
                ops.receiverTransactionEmailNotificationOptIn = false;
            }
            if (receiveData.receiverTransactionSMSNotificationOptIn == null) {
                ops.receiverTransactionSMSNotificationOptIn = false;
            }
        }
        ops.mgiTransactionSessionID = feeInfo?.mgiTransactionSessionID
        ops.destinationCountry = feeInfo?.receiveCountry
        ops.amount = feeInfo?.sendAmounts?.sendAmount
        ops.amountTotal = feeInfo?.sendAmounts?.totalAmountToCollect
        ops.sendCurrency = feeInfo?.sendAmounts?.sendCurrency
        ops.receiveCurrency = feeInfo?.validReceiveCurrency
        ops.deliveryOption = feeInfo?.deliveryOption
        ops.validCurrencyIndicator = feeInfo?.receiveAmounts?.validCurrencyIndicator
        if (receiveData.receiverPhone != null && receiveData.receiverPhone !== '') {
            ops.receiverPhoneCountryCode = getPhoneCode(codeTable, feeInfo?.receiveCountry)
        }
        if (feeInfo?.receiveAgentID != null && feeInfo?.receiveAgentID !== '') {
            ops.receiveAgentID = feeInfo?.receiveAgentID
            ops.receiveAgentName = feeInfo?.receiveAgentName
        }
        ops.senderLegalIdNumber = sendData?.senderPhotoIdNumber
        if (promoCodeValid) {
            ops.promoCode = feeInfo?.promotionInfo?.promotionCode
        }
        ops.language = i18next.language
        ops.sendCountry = globalAuthUser?.location?.agent_country?.isoname
        ops.locationCode = globalAuthUser?.location?.code

        let transaction = { ...ops, ...sendData, ...receiveData }

        delete transaction['customer_phone'];
        delete transaction['format'];

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/sendTransaction', transaction, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            setLoader(false)
            dispatch(hideLoading());

            var status = res.data.status
            if (status == 1 || status == 2) { // Success | pending
                dispatch(setStep(5));
                dispatch(setComplete(true));
                dispatch(setSendResponse(res.data))
            } else {
                setMessage(res.data.message ?? t('ERROR_OCCURED'))
                if (typeof res.data.data === 'string') {
                    setDisableSubmit(res.data.data.includes('4055'))
                }
            }
        }).catch(error => {
            console.log(error);
            setLoader(false)
            dispatch(hideLoading());
        });
    }

    const validatePasscode = (data) => {
        axios.defaults.withCredentials = false;
        hideModal();
        dispatch(showLoading())
        axios({
            method: 'post', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/validatePasscode',
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }, data: data
        }).then(response => {
            console.log(response);
            dispatch(hideLoading())

            var status = response.data.status
            var message = response.data.message

            if (status == 0) {
                dispatch(showToast(["danger", "NOTICE", t(message)]));
                showModal()
                return;
            }

            sendTransaction()

        })
    }

    const back = () => {
        console.log('back');
        setMessage(null)
        dispatch(setStep(3));
    }

    return (
        <div>
            <div style={{ marginLeft: "50px", marginTop: "25px" }}>
                <div className='row'>
                    <div className='col-sm-7'>
                        <table className="table table-sm table-borderless">
                            <tr style={{ height: "38px" }}>
                                <td>{t('SENDER_NAME')}:</td>
                                <td><strong>{getSenderName(sendData)}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('SENDER_ADDRESS')}:</td>
                                <td><strong>{sendData?.senderAddress}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('SENDER_COUNTRY')}:</td>
                                <td><strong>{getCountryName(codeTable, sendData?.senderCountry)}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('DESTINATION')}:</td>
                                <td><strong>{getCountryName(codeTable, feeInfo?.receiveCountry)}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('DELIVERY_OPTION')}:</td>
                                <td><strong>{feeInfo?.deliveryOptDisplayName}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('RECEIVER_NAME')}:</td>
                                <td><strong>{getReceiverName(receiveData)}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('AMOUNT')}:</td>
                                <td><strong>{feeInfo?.sendAmounts?.sendAmount}{' ' + feeInfo?.sendAmounts?.sendCurrency}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('FEE')}:</td>
                                <td><strong>{feeInfo?.sendAmounts?.totalSendFees}{' ' + feeInfo?.sendAmounts?.sendCurrency}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('TAX')}:</td>
                                <td><strong>{feeInfo?.sendAmounts?.totalSendTaxes}{' ' + feeInfo?.sendAmounts?.sendCurrency}</strong></td>
                            </tr>
                            {
                                promoCodeValid &&
                                <tr style={{ height: "38px" }}>
                                    <td>{t('DISCOUNT_AMOUNT')}:</td>
                                    <td><strong>{feeInfo?.sendAmounts?.totalDiscountAmount}{' ' + feeInfo?.sendAmounts?.sendCurrency}</strong></td>
                                </tr>
                            }
                            <tr style={{ height: "38px" }}>
                                <td>{t('TOTAL_AMOUNT')}:</td>
                                <td><strong>{feeInfo?.sendAmounts?.totalAmountToCollect}{' ' + feeInfo?.sendAmounts?.sendCurrency}</strong></td>
                            </tr>
                            <tr style={{ height: "38px" }}>
                                <td>{t('RECEIVE_AMOUNT')}:</td>
                                <td><strong>{parseFloat(+feeInfo?.receiveAmounts?.receiveAmount - feeInfo?.receiveAmounts?.totalReceiveFees - feeInfo?.receiveAmounts?.totalReceiveTaxes).toFixed(2)} {feeInfo?.receiveAmounts?.payoutCurrency} {feeInfo?.receiveAmounts?.validCurrencyIndicator == false && <span style={{ color: "red" }}>*</span>}</strong></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                {
                    message != null &&
                    <p className='input-error-msg'>{message}</p>
                }
            </div>
            <br />
            <div style={{ textAlign: "right" }}>
                <button type='button' className='btn btn-info' onClick={back} disabled={loader}>
                    <FaArrowCircleLeft size={18} color={'white'} /> {t('BACK')}
                </button> &nbsp; &nbsp;
                <button type='button' className='btn btn-primary' onClick={submit} disabled={loader || disableSubmit}>
                    {t('SUBMIT')} &nbsp;
                    <FaArrowCircleRight size={18} fill={'white'} />
                </button>
            </div>

            <form onSubmit={handleSubmit(validatePasscode)}>
                <div className='modal fade' ref={modalRef} id="modal-passcode" tabIndex="-1">
                    <div className="modal-dialog modal-md modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Passcode</h5>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className='form-group'>
                                                <label htmlFor='passcode'>{t('PASSCODE')} <span style={{ color: "red" }}>*</span></label>
                                                <input type="password" name="passcode" id="passcode" placeholder={t('PASSCODE')} className={`form-control w-100 ${errors.passcode ? 'is-invalid' : ''}`} {...register('passcode')} />
                                                {errors?.passcode && <p className="input-error-msg"> {errors.passcode?.message} </p>}
                                            </div>
                                            {/**<small className='d-flex justify-content-center mt-4'>{t('PASSCODE_FORGOT')}? {t('CLICK')} &nbsp;<Link to={"/passcode"}>{t('HERE')} </Link> </small>**/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">{t('VERIFY')}</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('CLOSE')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );

}

export default MoneygramSummary